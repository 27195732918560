import React, { useEffect } from "react";
import Header from "./../../header";
import Footer from "./../../footer";
import { Link } from "react-router-dom";
import "./index.scss";

const AssetIn = () => {
    useEffect(() => {
        document.body.scrollIntoView();
    }, [])
    return (
        <div>
            <Header />

            <div className="session">
                <div className="banner_box">
                    {/* <span></span>
                    <h5>服务商招商中心启动</h5>
                    <p>
                        欢迎司法拍卖法院辅助机构，破产管理人，拍卖公司，资产公司，律师事务所入驻讯拍网拍卖频道
                    </p> */}
                </div>
                {/* <div className="instructions_box">
                    <h6 className="title_h6"><span>服务商</span>招商介绍</h6>
                    <p className="instructions_p">
                        腾云讯拍服务平台现面向广大服务商进行公开招商，主要涉及腾云讯拍下属的司法拍卖、破产资产、金融资产、非金融资产、贷款、保险、代办等业务。其中，服务商主要分为资产处置服务商、资产处置渠道商两大类，请服务商按照各业务的规则、流程进行报名。如有问题请拨打400-000-1102进行相关咨询。
                    </p>
                    <h6 className="title_h6"><span>服务商</span>招商</h6>
                    <div className="instructions_merchants_box">
                        <div className="instructions_merchants">
                            <h6>银行入驻</h6>
                            <p>
                                为腾云讯拍用户提供线上化的，便捷高效，实惠标准的“一键贷款”金融服务，解决用户融资需求。
                            </p>
                            <Link to="/assets_bank_in?0">点击入驻</Link>
                        </div>
                        <div className="instructions_merchants">
                            <h6>保险机构入驻</h6>
                            <p>
                                基于腾云讯拍业务场景，为拍卖用户提供交易保障险、房产贷款保证保险等保险服务，保障用户权益。入驻主体需为保险公司。
                            </p>
                            <Link to="/assets_bank_in?1">点击入驻</Link>
                        </div>

                        <div className="instructions_merchants">
                            <h6>代办服务机构入驻</h6>
                            <p>
                                包含但不限于为腾云讯拍用户提供拍卖咨询、尽职调查、税费测算、评估鉴定、实地看样、过户办证等服务。
                            </p>
                            <Link to="/assets_bank_in?2">点击入驻</Link>
                        </div>

                        <div className="instructions_merchants">
                            <h6>金融授权机构入驻</h6>
                            <p>
                                为优化金融资产平台处置交易环境，构建“金融资产服务商”服务网络，便于各地优质金融资产处置机构入驻平台提升资产处置效能，有效提升服务效率及客户满意度。
                            </p>
                            <Link to="/assets_bank_in?3">点击入驻</Link>
                        </div>

                        <div className="instructions_merchants">
                            <h6>非金融授权机构入驻</h6>
                            <p>
                                授权服务机构其职责在于拓展各地有资产处置需求的机构入驻腾云讯拍平台，通过腾云讯拍处置资产，机构类型包括政府、国企、商业等，是腾云讯拍在各地的风控中心、服务中心、业务中心。
                            </p>
                            <Link to="/assets_bank_in?4">点击入驻</Link>
                        </div>
                    </div>
                </div> */}
            </div>
            <Footer />
        </div>
    )
}
export default AssetIn;