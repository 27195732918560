import React, { useEffect, useState } from "react";
import Header from "../header/index";
import about1 from "./../img/about-wx.png";
import about2 from "./../img/about-1.png";
import about3 from "./../img/about-2.png";
import Footer from "./../footer/index";
import "./index.scss";

const About: React.FC = () => {
    return (
        <div>
            <Header />
            <div className="session">
                <div className="about_cooperation_box">
                    <div className="about_banner">
                        腾云拍卖·讯拍网
                    </div>
                    <div className="cooperation_title">
                        <h1>关于我们</h1>
                        <h2>ABOUT US</h2>
                        <p style={{ textAlign: 'center' }}>“腾云拍卖·讯拍网”平台全新亮相，以探索国内网络拍卖行业的未来发展新路径。</p>
                        <p style={{ margin: "10px auto 0", textAlign: "center", fontSize: "14px", width: "770px", display: "block" }}>
                            腾云拍卖·讯拍网以“整合资源+细分市场”的营销策略，打造集司法拍卖、破产拍卖、资产拍卖、挂牌招标、藏品拍卖、拍卖直播为一体的大型综合拍卖交易智能服务平台，全面覆盖法院、企业（辅助机构、交易所、拍卖公司）、当事人、公众用户等多重角色，旨在打破行业信息壁垒、聚合优质服务商、提升资产处置效率，营造竞优的行业经营生态，以品质推动行业升级。
                        </p>
                    </div>
                    <div className="cooperation_list" style={{ height: "200px", }}>
                        {/* <div className="cooperation_list_img" >
                            <img src={about1} style={{ width: "90px", height: "112px" }} alt="" />
                        </div> */}
                        <div className="cooperation_list_content">
                            <p>腾云拍卖·讯拍网平台将整合最优质的全媒体资源，依据全天候互联网品牌营销战略，形成文字、图片、视频、音频，全方位、多维度、跨平台的新媒体营销矩阵，从而打开“平台+品牌+产品”全面出击，多点开花的长远发展新格局。</p>
                        </div>

                    </div>
                    <div className="cooperation_list">

                        <div className="cooperation_list_content">
                            <p>根据腾云拍卖·讯拍网平台战略发展规划，司法拍卖是未来讯拍平台打开不良资产处置市场的重要切入点，因此异地司法拍卖辅助服务的品质提升成为关键一环。</p>
                            <p>一方面，平台为客户提供资产智能估价计算器、全国城市交易数据榜、金融服务小管家等拍卖辅助智能服务，同时，用户可在线申请金融贷款服务，根据需要选择不同的贷款方案，以最大限度满足客户的交易需求，安全、便捷、智能是腾云拍卖·讯拍网平台服务的核心目标。</p>
                            <p>另一方面，腾云拍卖·讯拍网平台将接入移动微法院执行模块，在买受人完成拍卖交易后，通过人脸识别的安全方式进入案件空间，有利于执行案件法官、当事人实时了解执行进度。另外，对于异地资产交付，平台将引入电子签章技术，买受人可在线上完成拍卖成交确认书、裁定书的签名确认环节，真正实现为客户提供“一站式”便捷化拍卖辅助服务。</p>
                        </div>
                        <div className="cooperation_list_img" style={{ width: "300px" }}>
                            <img src={about3} style={{ width: "294px", height: "400px" }} alt="" />
                        </div>
                    </div>
                    <div className="cooperation_list">
                        <div className="cooperation_list_img">
                            <img src={about2} alt="" />
                        </div>
                        <div className="cooperation_list_content">
                            <p>目前腾云拍卖·讯拍网平台立足四川，面向全国，未来将不断引入行业领先的互联网技术，整合全国范围内的行业资源，优化产权配置，从整体层面统筹布局，不断活跃资产拍卖交易市场，为资产买卖双方搭建一个专业化、智能化、高效化的资产管理交易服务平台。</p>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default About;