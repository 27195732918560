import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Form, Input, message, Spin } from 'antd';
import Header from "./../header";
import Footer from "./../footer";
import { useLocation } from 'react-router-dom';
import axios from './../api';
import "./index.scss";

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 }
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 14 },
};
const AnotherDetail: React.FC = () => {
    const id = useLocation().search;
    const [detail, set_detail]: any = useState({});
    const [state, set_state] = useState("未领取");
    const [time, set_time] = useState('');
    const [spinning, set_spinning] = useState(true)
    const [visible, set_visible] = useState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const get_detail = useCallback(async () => {
        const res: any = await axios.http.newGet(axios.api.get_entrust, { entrust_id: id.split("=")[1] });
        if (res.status === 0) {
            set_detail(res.result)
            if (res.result.state == "1") {
                set_state("已领取")
                set_time(res.result.get_time)
            } else if (res.result.state == "2") {
                set_state("已完成")
                set_time(res.result.end_time)
            } else {
                set_time(res.result.create_time)
            }
        }
        set_spinning(false)
    })
    //表单提交 
    const onFinish = async (value: any) => {
        const res: any = await axios.http.post(axios.api.entrust_save, { ...value, id: id.split("=")[1] });
        if (res.status === 0) {
            message.success('领取成功');
            set_visible(false);
            // @ts-ignore
            get_detail();
        } else {
            message.error(res.message)
        }
    };
    useEffect(() => {
        // @ts-ignore
        get_detail();
    }, [get_detail])
    return (
        <div>
            <Header />
            <Spin spinning={spinning} tip="加载中...">
                <div className="session">
                    <div className="another_detail_header">
                        <em></em>
                        <div>
                            <h6> {detail.title} <span className={detail.state == '0' ? '' : 'active'}>{state}</span> </h6>
                            <p>{time}</p>
                        </div>
                    </div>

                    <div className="another_detail_info">
                        <h6>基础信息</h6>
                        <div className="another_basis_flex">
                            <span>委托机构:{detail.agency}</span>
                            <span>发布时间:{detail.create_time}</span>
                            <span>任务名称:{detail.title}</span>
                            <span>截止时间:{detail.end_time}</span>
                        </div>
                        <div className="another_basis_flex">
                            <span>所在地:{detail.city_zh}</span>
                            <span>详细地址:{detail.address}</span>
                            <span>联系人:{detail.contact}</span>
                            <span>联系方式:{
                                state === "未领取" ? '领取后查看联系方式' :
                                    detail.qr_code ? <img src={`https://file.qqpai.com${detail.qr_code}`} alt="" /> : '无'
                            }  </span>
                        </div>
                    </div>

                    <div className="another_detail_info" style={{ border: 'none' }}>
                        <h6>任务详情</h6>
                        <div dangerouslySetInnerHTML={{ __html: detail.content }}></div>
                    </div>
                    <Button type="primary" disabled={detail.state == '0' ? false : true} onClick={() => { set_visible(true) }}>领取任务</Button>
                </div>
            </Spin>

            <Footer />

            <Modal
                visible={visible}
                title="领取任务"
                footer={null}
                onCancel={() => { set_visible(false) }}
            >
                <div>
                    <Form
                        {...layout}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="姓名"
                            name="get_name"
                            rules={[{ required: true, message: '姓名不能为空' }]}
                        >
                            <Input placeholder="请输入您的姓名" />
                        </Form.Item>
                        <Form.Item
                            label="联系方式"
                            name="get_phone"
                            rules={[{ required: true, message: '联系方式不正确', pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/ }]}
                        >
                            <Input placeholder="请输入您的联系方式" />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button onClick={() => { set_visible(false) }}>
                                取消
                            </Button> &emsp;&emsp;
                            <Button type="primary" htmlType="submit">
                                确定
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}
export default AnotherDetail;

function useCallback(arg0: () => Promise<void>) {
    throw new Error("Function not implemented.");
}
