import React from "react";
import NotImg from "./../img/da@2x.png";
import { Result, Tooltip } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./list.scss";

interface listProps {
  list: string[];
  color?: boolean;
  narrow?: boolean;
  notList: boolean;
  link?: string;
}
const List = (props: listProps) => {
  return (
    <div>
      <ul className={props.narrow ? "narrow list_box" : "list_box"}>
        {props.list.map((item: any) => {
          let isWan = "";
          let price: any = 0;
          let link = props.link;
          let consult_price: any =
            item.consult_price || item.initial_price * 0.7;
          let price_wan = "";
          let status_text = "进行中";
          let status_class = "";
          let status_time = "开拍时间:";
          let time_text = item.end_time;
          if (item.consult_price >= 100000 && item.consult_price < 100000000) {
            consult_price = (item.consult_price / 10000).toFixed(4);
            price_wan = "万";
          } else if (item.consult_price >= 100000000) {
            consult_price = (item.consult_price / 100000000).toFixed(4);
            price_wan = "亿";
          }

          switch (item.status) {
            case 0:
              status_text = "进行中";
              status_time = "预计:";
              time_text = `${item.end_time}结束`;
              break;
            case 1:
              status_text = "预告中";
              status_class = "status_af";
              time_text = `${item.start_time}开始`;
              break;
            case 2:
              status_text = "已结束";
              status_class = "status_on";
              status_time = "结束时间:";
              break;
            case 3:
              status_text = "已中止";
              status_class = "status_on";
              status_time = "结束时间:";
              break;
            case 4:
              status_text = "已撤回";
              status_class = "status_on";
              status_time = "结束时间:";
              break;
          }

          if (item.status === 1) {
            if (
              item.initial_price >= 100000 &&
              item.current_price < 100000000
            ) {
              price = ((item.initial_price * 1) / 10000).toFixed(4);
              isWan = "万";
            } else if (item.current_price >= 100000000) {
              price = (item.current_price / 100000000).toFixed(4);
              isWan = "亿";
            } else {
              price = item.initial_price * 1;
              isWan = "";
            }
          } else {
            if (
              item.current_price >= 100000 &&
              item.current_price < 100000000
            ) {
              price = (item.current_price / 10000).toFixed(4);
              isWan = "万";
            } else if (item.current_price >= 100000000) {
              price = (item.current_price / 100000000).toFixed(4);
              isWan = "亿";
            } else {
              price = item.current_price * 1;
              isWan = "";
            }
          }

          if (item.auction_type) {
            if (item.auction_type === 3) {
              link = "/bankruptcy_matter_details";
            }
            if (item.auction_type === 2) {
              link = "/asset_matter_details";
            }
            if (item.auction_type === 1) {
              link = "/matter_details";
            }
          }
          if (item.main_pic.indexOf("/jfs/") > -1) {
            const jd_img = item.main_pic.substring(item.main_pic.indexOf("/jfs/"))
            item.main_pic = `https://m.360buyimg.com/mobilecms${jd_img}`;
          }
          return (
            <Link
              target="_blank"
              to={link ? `${link}?${item.id}` : `/matter_details?${item.id}`}
              key={item.id}
            >
              <li>
                <div className="li_img">
                  <img
                    onError={(e: any) => {
                      e.target.src = NotImg;
                    }}
                    // @ts-ignore
                    referrerpolicy="no-referrer"
                    src={
                      item.main_pic.indexOf("img.qqpai.com") > -1
                        ? `${item.main_pic}!w300`
                        : item.main_pic
                    }
                    alt=""
                  />
                  <div className="li_status">
                    <div className={`li_status_left ${status_class}`}>
                      {status_text}
                    </div>
                    <div className="li_status_right">
                      {status_time}
                      {time_text}
                    </div>
                  </div>
                </div>
                <h3 title={item.title}>{item.title}</h3>

                <p>
                  {item.status === 1
                    ? "起拍价"
                    : item.status === 2 && item.bid_count > 0
                      ? "成交价"
                      : "当前价"}
                  ：<em className={item.status === 1 ? "color" : ""}>￥</em>
                  <b className={item.status == 1 ? "color" : ""}>
                    {price}
                    {isWan}
                  </b>{" "}
                  {!props.color && <span>({item.bid_count}次出价)</span>}
                </p>
                <p>
                  评估价：￥
                  {consult_price}
                  {price_wan}
                </p>
                <p>
                  {item.view_count}次围观 &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                  {item.apply_count}人报名
                </p>
              </li>
            </Link>
          );
        })}
      </ul>
      {props.notList && (
        <Result
          status="404"
          title="很抱歉"
          subTitle="没有您要找的标的物，换个条件试试~"
        />
      )}
    </div>
  );
};

export default List;
