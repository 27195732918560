import React from "react";
import { Result, Button } from 'antd';
import {Link} from "react-router-dom";

interface result {
    title: string;
    content: string;
}
const ResultOK: React.FC<result> = (props) => {
    return (
        <Result
            status="success"
            title={props.title}
            subTitle={props.content}
            extra={[
                <Button type="primary" key="console">
                    <Link to="/">去首页逛逛</Link>
                </Button>,
            ]}
        />
    )
}

export default ResultOK;