import React, { useEffect, useState, useRef } from "react";
import Header from "./../../header/index";
import { Link } from "react-router-dom";
import { message, Carousel } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";

import List from "./../../component/list";
import AssetsList from "./../../component/assetsList";
import Footer from "./../../footer/index";
import Data from "./../../home/data";
import axios from "./../../api";
import "./../../home/index.scss";
// @ts-ignore
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, EffectFade } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

const Home = () => {
  const carousel: any = useRef(null);
  const localCityList = window.localStorage.getItem("cityList") || "[]";
  const city_list = JSON.parse(localCityList);
  const [view_count, set_view_count]: any = useState([]); //今日围观次数
  const [latest_news, set_latest_news]: any = useState([]); //最近新闻列表
  const [latest_notice_list, set_latest_notice_list]: any = useState([]); //最近公告列表
  const [category_id, set_category_id] = useState("56950002"); //今日拍卖房产类型
  const [today_real_list, set_today_real_list] = useState([]); //今日拍卖房产拍卖列表
  const [today_real_total, set_today_real_total] = useState(0); //今日拍卖房产拍卖列表

  const [vehicle_id, set_vehicle_id] = useState("56968001"); //今日拍卖房产拍卖id
  const [vehicle_total, set_vehicle_total] = useState(0); //今日拍卖机动车总数
  const [today_vehicle_list, set_today_vehicle_list] = useState([]); //今日拍卖机动车拍卖列表

  const [other_id, set_other_id] = useState("56972001"); //今日拍卖其他拍卖id
  const [other_total, set_other_total] = useState(0); //今日拍卖其他总数
  const [today_other_list, set_today_other_list] = useState([]); //今日拍卖其他拍卖列表

  const [recommended_list, set_recommended] = useState([]); //推荐房源

  const [not_list, set_not_list] = useState(false);
  const [list, set_list] = useState([]);

  const [banner, set_banner]: any = useState([]); //banner列表
  const [discount_list, set_discount_list]: any = useState([]); //折扣房源列表

  SwiperCore.use([Navigation])
  // 获取围观次数
  const viewCount = async () => {
    const result: any = await axios.http.newGet(axios.api.zc_view_count, {});
    if (result.status === 0) {
      try {
        const view_count = result.result.view_count.split("");
        set_view_count(view_count);
      } catch {
        set_view_count(["0"]);
      }
    }
  };
  // 获取最近新闻
  const latestNews = async () => {
    const result: any = await axios.http.newGet(axios.api.latest_news, {
      belong: 2,
      page_num: 1,
      page_size: 3,
    });
    if (result.status === 0) {
      set_latest_news(result.result);
    } else {
      message.error(result.message);
    }
  };
  // 获取最新公告
  const latestNotice = async () => {
    const result: any = await axios.http.newGet(axios.api.zc_notice_list, {
      page_num: 1,
      page_size: 3,
    });
    if (result.status === 0) {
      set_latest_notice_list(result.result.data);
    }
  };
  // 房产类数据列表
  const getCategory = async (
    category_id: string,
    page_size: number,
    type: string
  ) => {
    let postBody: any = {
      category_id,
      page_num: 1,
      page_size: page_size,
      sort_type: type === "recommended" ? 5 : 1,
    };
    if (type === "recommended") {
      postBody.sort_order = 1;
      postBody.status = 1;
    }

    const result: any = await axios.http.newGet(axios.api.zc_auction_list, {
      ...postBody,
    });
    if (result.status === 0) {
      const list = result.result.data || [];
      if (type === "real") {
        set_today_real_list(list);
        set_today_real_total(result.result.total);
      } else if (type === "vehicle") {
        set_today_vehicle_list(list);
        set_vehicle_total(result.result.total);
      } else if (type === "other") {
        set_today_other_list(list);
        set_other_total(result.result.total);
      } else if (type === "recommended") {
        set_recommended(list);
      }
    }
  };
  const auctionList = async () => {
    const result: any = await axios.http.newGet(axios.api.zc_tj_list, {
      sort_type: 1,
      sort_order: 0,
      page_num: 1,
      page_size: 4,
    });
    if (result.status === 0) {
      set_not_list(result.result.data.length === 0);
      set_list(result.result.data);
    } else {
      message.error(result.message);
    }
  };
  const getBanner = async () => {
    const banner: any = await axios.http.get(`${axios.api.bannerList}`);
    if (banner.status === 0) {
      set_banner(banner.data);
    }
  };

  // 获取banner页面
  // const getBanner = async () => {
  //   const banner: any = await axios.http.newGet(axios.api.value_add_data_list, {
  //     page_num: 1,
  //     page_size: 6,
  //     put_position: 1,
  //     put_channel: 3,
  //     city_id: cityId
  //   });
  //   if (banner.status === 0) {
  //     set_banner(banner.result.data);
  //   }
  // };
  // 获取折扣房源
  // const discountList = async () => {
  //   const banner: any = await axios.http.newGet(axios.api.value_add_data_list, {
  //     page_num: 1,
  //     page_size: 6,
  //     put_position: 2,
  //     put_channel: 3,
  //     city_id: cityId
  //   });
  //   if (banner.status === 0) {
  //     set_discount_list(banner.result.data);
  //   }
  // };

  // 围观次数 最新新闻 法院公告
  useEffect(() => {
    viewCount();
    latestNews();
    latestNotice();
    getBanner();
  }, []);
  // 房产类数据加载
  useEffect(() => {
    getCategory(category_id, 6, "real");
  }, [category_id]);
  //机动车类
  useEffect(() => {
    getCategory(vehicle_id, 3, "vehicle");
    auctionList();
    getCategory(category_id, 4, "recommended");
  }, [category_id, vehicle_id]);
  //其他
  useEffect(() => {
    getCategory(other_id, 3, "other");
  }, [other_id]);

  return (
    <div>
      <Header />
      <div className="session">
        <div className="home_banner_nav" style={{ height: "490px" }}>
          <div className="home_banner_left">
            <div className="home_banner_nav_box">
              <h2>
                <Link to="/assets_matter_list">标的物类型</Link>
              </h2>
              <div className="home_banner_nav_list">
                {Data.zcMatterType.map((item: any) => {
                  return (
                    <Link
                      to={`/assets_matter_list?matterType=${item.id}`}
                      key={item.id}
                    >
                      {item.name}
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className="home_banner_nav_box">
              <h2>
                <Link to="/assets_matter_list">标的所在地</Link>
              </h2>
              <div className="home_banner_nav_list">
                {city_list.map((item: any) => {
                  return (
                    <Link
                      key={item.id}
                      to={`/assets_matter_list?matterAdd=${item.id}`}
                    >
                      {item.shortname}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="home_banner_middle">
            <div className="home_banner_box">
              <div
                className="right"
                onClick={() => {
                  carousel.current.next();
                }}
              >
                <RightOutlined />
              </div>
              <div
                className="left"
                onClick={() => {
                  carousel.current.prev();
                }}
              >
                <LeftOutlined />
              </div>
              <Carousel autoplay={true} ref={carousel}>
                {banner &&
                  banner.map((item: any, index: number) => {
                    if (item.main_pc) {
                      if (item.imgs_pc.length) {
                        return (
                          <div>
                            <Link to={`/banner_link?${item.index}`} target="_blank">
                              <img
                                src={`https://file.qqpai.com${item.main_pc}`}
                                alt=""
                              />
                            </Link>
                          </div>
                        );
                      } else {
                        return (
                          <div>
                            <img
                              src={`https://file.qqpai.com${item.main_pc}`}
                              alt=""
                            />
                          </div>
                        );
                      }
                    }
                  })}
              </Carousel>
            </div>

            <div className="home_new_box">
              <div className="news_box">
                <div className="news_title">
                  <h6>行业资讯</h6>
                  <Link to="/assets_news" target="_blank">
                    查看更多&gt;
                  </Link>
                </div>
                <ul className="news_list_box">
                  {latest_news &&
                    latest_news.map((item: any) => {
                      return (
                        <Link
                          key={item.id}
                          title={item.title}
                          target="_blank"
                          to={`/assets_news_details?${item.id}&${item.column_id}`}
                        >
                          <li>
                            <em>NEW</em>
                            <p>{item.title}</p>
                          </li>
                        </Link>
                      );
                    })}
                </ul>
              </div>
              <div className="news_box">
                <div className="news_title">
                  <h6>公告</h6>
                  <Link to="/assets_announcement_list" target="_blank">
                    查看更多&gt;
                  </Link>
                </div>
                <ul className="news_list_box">
                  {latest_notice_list &&
                    latest_notice_list.map((item: any) => {
                      return (
                        <Link
                          key={item.id}
                          target="_blank"
                          title={item.title}
                          to={`/assets_announcement_details?${item.id}`}
                        >
                          <li>
                            <p>{item.title}</p>
                          </li>
                        </Link>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
          <div className="home_banner_right">
            <h6 className="h6_title" style={{ marginTop: "-10px" }}>
              帮助中心
            </h6>
            <div className="home_help" style={{ height: "160px" }}>
              <Link to="/help?1&id=33">
                <img src={require("./../../img/process@2x.png")} alt="" />
                <p>竞价流程</p>
              </Link>
              <Link to="/help?1&id=31">
                <img src={require("./../../img/rule@2x.png")} alt="" />
                <p>竞价规则</p>
              </Link>
              <Link to="/help?1&id=8">
                <img src={require("./../../img/c_money.png")} alt="" />
                <p>交保证金</p>
              </Link>
              <Link to="/help?1&id=14">
                <img src={require("./../../img/delivery@2x.png")} alt="" />
                <p>标的交割</p>
              </Link>
              <Link to="/help?1&id=9">
                <img src={require("./../../img/c_pay.png")} alt="" />
                <p>支付限额</p>
              </Link>
              <Link to="/help?1">
                <img src={require("./../../img/c_right.png")} alt="" />
                <p>查看更多</p>
              </Link>
            </div>

            <div className="home_type_box">
              <Link to="/assets_announcement_type?0">国有资产</Link>
              <Link to="/assets_announcement_type?1">金融资产</Link>
              <Link to="/assets_announcement_type?4">交易所</Link>
              <Link to="/assets_announcement_type?7">拍卖行</Link>
            </div>
            <div
              className="home_server_box"
              style={{ height: "210px", margin: "0", paddingTop: "6px" }}
            >
              <div className="home_right_code">
                <img src={require("./../../img/qrcode_for.png")} alt="" />
              </div>
              <p style={{ lineHeight: "30px" }}>扫码关注讯拍网</p>
              <Link to="/assets_cooperation" className="home_server_bottom">
                <em></em>
                平台合作机构
                <RightOutlined />
              </Link>
            </div>
          </div>
        </div>

        <div className="home_process_box">
          <Link to="/help?3&id=133">
            <div className="home_process_title">竞拍流程</div>
          </Link>
          <Link to="/help?3&id=133">
            <div className="process_steps">
              <span></span>
              <p>阅读公告</p>
            </div>
          </Link>
          <RightOutlined style={{ color: "#DCE7F8" }} />
          <Link to="/help?3&id=133">
            <div className="process_steps">
              <span className="ste1"></span>
              <p>实地看样</p>
            </div>
          </Link>
          <RightOutlined style={{ color: "#DCE7F8" }} />
          <Link to="/help?3&id=133">
            <div className="process_steps">
              <span className="ste2"></span>
              <p>交保证金</p>
            </div>
          </Link>
          <RightOutlined style={{ color: "#DCE7F8" }} />
          <Link to="/help?3&id=133">
            <div className="process_steps">
              <span className="ste3"></span>
              <p>开始竞拍</p>
            </div>
          </Link>
          <RightOutlined style={{ color: "#DCE7F8" }} />
          <Link to="/help?3&id=133">
            <div className="process_steps">
              <span className="ste4"></span>
              <p>竞拍成功</p>
            </div>
          </Link>
          <RightOutlined style={{ color: "#DCE7F8" }} />
          <Link to="/help?3&id=107">
            <div className="process_steps">
              <span className="ste5"></span>
              <p>支付尾款</p>
            </div>
          </Link>
          <RightOutlined style={{ color: "#DCE7F8" }} />
          <Link to="/help?3&id=114">
            <div className="process_steps">
              <span className="ste6"></span>
              <p>办理交割</p>
            </div>
          </Link>
        </div>

        {/* 折扣房源 */}
        {
          banner.length > 0 &&
          <div className="today_title floor_home" id="jrpm">
            <h6>折扣房源</h6>
            <div className="today_onlookers">
              <Link to={`/discount?type=1`}>
                更多{">"}
              </Link>
            </div>
          </div>
        }
        <div className="discount_box">
          {
            banner.length > 0 &&
            <Swiper
              spaceBetween={50}
              slidesPerView={banner.length >= 3 ? 3 : 1}
              slidesPerGroup={3}
              navigation
              loop
            >
              {banner &&
                banner.map((item: any, index: number) => {
                  if (item.main_pc) {
                    if (item.imgs_pc.length) {
                      return (
                        <SwiperSlide className='swiperBanner'>
                          <Link to={`/banner_link?${item.index}`} target="_blank">
                            <img
                              src={`https://file.qqpai.com${item.main_pc}`}
                              alt=""
                            />
                          </Link>
                        </SwiperSlide>
                      );
                    } else {
                      return (
                        <SwiperSlide className='swiperBanner'>
                          <div>
                            <img
                              src={`https://file.qqpai.com${item.main_pc}`}
                              alt=""
                            />
                          </div>
                        </SwiperSlide>
                      );
                    }
                  }
                })}
            </Swiper>
          }
        </div>

        {/* 今日推荐 */}
        <div className="today_title">
          <h6>今日拍卖</h6>
          <div className="today_onlookers">
            {view_count &&
              view_count.length > 0 &&
              view_count.map((item: any, index: number) => {
                return <span key={index}>{item}</span>;
              })}
            次围观
          </div>
        </div>
        <div className="today_auction">
          <div className="today_left">
            {today_real_total > 0 && (
              <div className="today_type_box">
                <em></em> 房产
                <span>
                  <Link to={`/assets_matter_list?matterType=${category_id}`}>
                    共{today_real_total}件 &emsp; 更多{">"}
                  </Link>
                </span>
              </div>
            )}

            <List
              notList={false}
              list={today_real_list}
              link="/asset_matter_details"
              narrow={true}
            />
            {vehicle_total > 0 && (
              <div className="today_type_box">
                <em className="jidongche"></em> 机动车
                <span>
                  <Link to={`/assets_matter_list?matterType=${vehicle_id}`}>
                    共{vehicle_total}件 &emsp; 更多{">"}
                  </Link>
                </span>
              </div>
            )}

            <List
              notList={false}
              link="/asset_matter_details"
              list={today_vehicle_list}
              narrow={true}
            />

            <div className="today_type_box">
              <em className="qita"></em>
              <b
                onClick={() => {
                  set_other_id("56972001");
                }}
                className={other_id === "56972001" ? "active" : ""}
              >
                土地
              </b>
              <b
                onClick={() => {
                  set_other_id("56950003");
                }}
                className={other_id === "56950003" ? "active" : ""}
              >
                股权
              </b>
              <b
                onClick={() => {
                  set_other_id("56956002");
                }}
                className={other_id === "56956002" ? "active" : ""}
              >
                债权
              </b>

              <span>
                <Link to={`/assets_matter_list?matterType=${other_id}`}>
                  共{other_total}件 &emsp; 更多{">"}
                </Link>
              </span>
            </div>
            <List
              notList={false}
              list={today_other_list}
              link="/asset_matter_details"
              narrow={true}
            />
          </div>
          <div className="today_advertising">
            <h6>房源推荐</h6>
            <div className="advertising_list">
              <List
                notList={false}
                link="/asset_matter_details"
                list={recommended_list}
              />
            </div>

            <Link to="/assets_matter_list">
              <div className="advertising_more">
                查看更多
                <RightOutlined className="one4" />
                <RightOutlined className="one6" />
                <RightOutlined className="one8" />
              </div>
            </Link>
          </div>
        </div>

        {/* 合作法院 */}

        <div className="cooperation_court_asset" style={{ marginTop: "20px" }}>
          <div className="cooperation_court_box">
            <h6>
              招商推介
              <Link to="/assets_promote">更多{">"}</Link>
            </h6>
            <div className="home_assets_promote" style={{ paddingTop: "10px" }}>
              <AssetsList
                notList={not_list}
                list={list}
                link="assets_details"
              />
            </div>
          </div>

          <div className="cooperation_court_box">
            <h6>
              <span>入驻</span>机构
            </h6>

            <h5>资产管理公司</h5>
            <div className="cooperation_court_list">
              {Data.assetsList.map((item: any) => {
                return (
                  <div className="assets_court_list" key={item.name}>
                    <Link target="_blank" to={`${item.link}?${item.id}`}>
                      {item.name}
                    </Link>
                  </div>
                );
              })}
            </div>

            <h5>银行</h5>
            <div className="cooperation_court_list">
              {Data.bankList.map((item: any) => {
                return (
                  <div className="assets_court_list" key={item.name}>
                    <Link to={`${item.link}?${item.id}`}>{item.name}</Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
