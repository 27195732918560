
import React, { useState, useEffect } from 'react';
import { RightOutlined, LeftOutlined, CloseCircleOutlined, RedoOutlined } from '@ant-design/icons';
import noImg from './../../img/da@2x.png';
import "./index.scss";

interface style {
  style?: object;
  width?: number;
  imgList: string[];
}

function Amplifier(props: style) {

  const [move_coordinate, set_move_coordinate] = useState({
    clientX: 0,
    clientY: 0
  })

  const [rotating, set_rotating] = useState(0)

  const [visible, set_visible] = useState(false)

  const [img_move, set_img_move] = useState({
    transform: "translate(0px,0px)"
  });

  const [img_index, set_img_index]: any = useState(0)

  const [li_active, set_active] = useState(0)

  const [div_dom_position, set_div_dom_position] = useState({
    box_div_left: -1,
    box_div_top: -1
  })

  const [ul_width, set_ul_width] = useState({
    width: "650px",
    transform: 0
  })

  const [trans_cont, set_trans_cont] = useState(0);

  const [enlarge_img, set_enlarge_img] = useState(props.imgList[0] || '');

  const [modal_img, set_modal_img] = useState({
    url: props.imgList[0] || noImg,
    index: 0
  })
  // 鼠标移动
  const mouseMove = (e: any) => {
    e.stopPropagation();
    const div_dom_class = e.target.getAttribute("class");
    if ((div_dom_class == "enlarge-box" || div_dom_class == "img") && div_dom_position.box_div_left == -1) {
      const box_div_left = e.target.getBoundingClientRect().left;
      const box_div_top = e.target.getBoundingClientRect().top;
      set_div_dom_position({ box_div_left, box_div_top });
    }

    let clientX = e.clientX - 50 - div_dom_position.box_div_left;
    let clientY = e.clientY - 50 - div_dom_position.box_div_top;
    if (div_dom_position.box_div_left == -1) {
      clientX = e.clientX - 50 - e.target.getBoundingClientRect().left;
      clientY = e.clientY - 50 - e.target.getBoundingClientRect().top;
    }
    if (clientX >= 450) {
      clientX = 450
    }
    if (clientY >= 370) {
      clientY = 370
    }
    if (clientX <= 0) {
      clientX = 0
    }
    if (clientY <= 0) {
      clientY = 0
    }
    let big_x = clientX / 260 * (400 * 2 - 490);
    let big_y = clientY / 300 * 380;
    set_img_move({
      transform: `translate(${-big_x}px,${-big_y}px)`
    })
    set_move_coordinate({ clientX, clientY })

  }
  // 切换图片
  const checkImg = (src: string) => {
    set_enlarge_img(src)
  }
  // 旋转图片
  const rotatingImg = () => {
    set_rotating(rotating + 4)
  }

  const index_img = (type: string) => {
    const img_len = props.imgList.length;
    if (type == 'right') {
      set_img_index(img_index == img_len - 1 ? 0 : img_index + 1);
    } else {
      set_img_index(img_index <= 0 ? img_len - 1 : img_index - 1);
    }
    set_modal_img({ url: props.imgList[img_index], index: img_index })
  }


  useEffect(() => {
    const imgLen = props.imgList.length;
    const transform = (0 + trans_cont * 140);
    set_ul_width({
      width: `${imgLen * 140 - 18}px`,
      transform
    })
  }, [props.imgList.length, trans_cont])

  return (
    <div className="enlarge-warp" style={{ width: "550px" }}>
      <div className="enlarge-box"
        onClick={() => { set_visible(true) }}
        onMouseLeave={() => { set_div_dom_position({ box_div_left: -1, box_div_top: -1 }) }}
        onMouseMove={(e) => { mouseMove(e) }} style={props.style ? props.style : {}}>
        <img className="img" src={enlarge_img ? enlarge_img.indexOf("img.qqpai.com") > -1 ? `${enlarge_img}!w500` : enlarge_img : (props.imgList[0] || noImg)} alt="" onError={(e: any) => { e.target.src = noImg }} />
        <div className="move-div" style={{ left: `${move_coordinate.clientX}px`, top: `${move_coordinate.clientY}px` }}></div>
        <div className="enlarge-box-big">
          <img
            style={img_move}

            src={enlarge_img ? enlarge_img.indexOf("img.qqpai.com") > -1 ? `${enlarge_img}!w800` : enlarge_img : (props.imgList[0] || noImg)} alt="" />
        </div>
      </div>
      <div className="ul-box">
        {/* <div className="list-left" onClick={() => { ul_trans("left") }}>
          <LeftOutlined />
        </div> */}
        {/* <div className="list-right" onClick={() => { ul_trans("right") }}>
          <RightOutlined />
        </div> */}
        <ul className="ul" style={{ width: ul_width.width, transform: `translateX(${ul_width.transform}px)` }}>
          {
            props.imgList.map((item, index) => {
              return (
                <li key={index}
                  onClick={() => { set_visible(true) }}
                  className={li_active == index ? "li active" : "li"} onMouseMove={() => { checkImg(item); set_active(index) }}>
                  <img src={item.indexOf("img.qqpai.com") > -1 ? `${item}!w100` : item} alt="" />
                </li>
              )
            })
          }
        </ul>
      </div>

      <div className="img_modal-box" style={{ display: visible ? "block" : "none" }}>
        <CloseCircleOutlined className="modal_x" onClick={() => { set_visible(false); set_rotating(0) }} />
        <RedoOutlined title="旋转图片" className="rotating-img" onClick={rotatingImg} />
        <div className="img_modal_big">
          <LeftOutlined className="modal_right" onClick={() => { index_img("left") }} />
          <img style={{ transform: `rotate(${rotating / 4 * 90}deg)` }} src={modal_img.url.indexOf('img.qqpai.com') > -1 ? `${modal_img.url}!w400` : modal_img.url} alt="" onError={(e: any) => { e.target.src = noImg }} />
          <RightOutlined className="modal_left" onClick={() => { index_img("right") }} />
        </div>
        <div className="modal-img-list">
          {
            props.imgList.map((item, index) => {
              return (
                <img
                  onError={(e: any) => { e.target.src = noImg }}
                  className={index == modal_img.index ? "active" : ""}
                  onClick={() => { set_modal_img({ url: item, index }); set_rotating(0); set_img_index(index) }}
                  key={index} src={item.indexOf('img.qqpai.com') > -1 ? `${item}!w300` : item} alt="" />
              )
            })
          }
        </div>
      </div>
    </div>

  );
}

export default Amplifier;
