import React, { useState, useEffect } from "react";
import Heard from "./../header";
import Footer from "./../footer";
import axios from "./../api";
import { Spin, message, Pagination, Result } from "antd";
import data from "./../home/data";
import List from "./../component/announcementList";
import Paging from "./../component/pagination";
import "./index.scss";

interface posyBody {
  page_num: number;
  page_size: number;
  zc_type?: number;
}
const AnnouncementList = () => {
  const [list, set_list] = useState([]);
  const [spinning, set_spinning] = useState(true);
  const [post_body, set_post_body]: any = useState({
    page_num: 1,
    page_size: 30,
    zc_type: "",
  });
  const [total, set_total] = useState(-1);
  const noticeList = async () => {
    const body: posyBody = {
      page_num: post_body.page_num,
      page_size: post_body.page_size,
    };
    if (post_body.zc_type) {
      body.zc_type = post_body.zc_type;
    }
    const result: any = await axios.http.newGet(axios.api.zc_notice_list, {
      ...body,
    });
    if (result.status === 0) {
      set_list(result.result.data);
      set_total(result.result.total);
      const dom = document.getElementById("list_top");
      dom && dom.scrollIntoView({ behavior: "smooth" });
    } else {
      message.error(result.message);
    }
    set_spinning(false);
  };
  useEffect(() => {
    noticeList();
  }, [post_body]);
  return (
    <div id="list_top">
      <Heard />
      <div className="session">
        <Spin tip="数据加载中..." spinning={spinning}>
          <p>公告内容均来源于人民法院诉讼资产网</p>
          <div className="asset_announcement_type">
            <span
              className={post_body.zc_type === "" ? "active" : ""}
              onClick={() => {
                set_post_body({ ...post_body, zc_type: "" });
              }}
            >
              全部
            </span>
            {data.zc_type.map((item: any) => {
              return (
                <span
                  onClick={() => {
                    set_post_body({
                      ...post_body,
                      zc_type: item.id,
                      page_num: 1,
                    });
                  }}
                  className={post_body.zc_type === item.id ? "active" : ""}
                  key={item.id}
                >
                  {item.name}
                </span>
              );
            })}
          </div>
          <List list={list || []} link="/assets_announcement_details" />
          <div className="more_box">
            <Paging
              pageSize={post_body.page_size}
              current={post_body.page_num}
              total={total}
              onShowSizeChange={(size: number) => {
                set_post_body({ ...post_body, page_size: size });
              }}
              onChange={(page: number) => {
                set_post_body({ ...post_body, page_num: page });
              }}
            />
          </div>
        </Spin>
        {total === 0 && (
          <Result status="404" title="很抱歉" subTitle="没有公告列表~" />
        )}
      </div>
      <Footer />
    </div>
  );
};
export default AnnouncementList;
