import React from "react";
import Header from "./../header";
import {
  HashRouter as Router,
  useLocation,
} from "react-router-dom";
import Footer from "./../footer";
import "./index.scss";

const Agreement: React.FC = (props: any) => {
  const path = useLocation().pathname;
  return (
    <div>
      <Header />
      <div className="session">
        <div className="agreement_box">
          <h5>用户注册协议</h5>
          <h3>
            本协议是用户（以下简称“您”）与腾云拍卖平台网站（网址：包括但不限于https://www.qqpai.com等，简称“本站”）所有者之间就腾云拍卖平台网站服务等相关事宜所订立的契约，请您仔细阅读本注册协议，您点击"同意并继续"按钮后，即视为您接受并同意遵守本协议的约定。
          </h3>
          <h3>第1条 本站服务条款的确认和接纳</h3>
          <p>
            1.1本站的各项电子服务的所有权和运作权归腾云拍卖平台所有。您同意所有注册协议条款并完成注册程序，才能成为本站的正式用户。您确认：本协议条款是处理双方权利义务的依据，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定或约定。
            <br />
            1.2您点击同意本协议的，即视为您确认自己具有享受本站服务、参与竞拍等相应的权利能力和行为能力，能够独立承担法律责任。
            <br />
            1.3您确认，如果您在18周岁以下，您只能在父母或其他监护人的监护参与下才能使用本站。
            <br />
            1.4腾云拍卖平台保留在中华人民共和国大陆地区施行之法律允许的范围内独自决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利。
            <br />
            1.5您使用本站提供的服务时，应同时接受适用于本站特定服务、活动等的准则、条款和协议（以下统称为“其他条款”）；如果以下使用条件与“其他条款”有不一致之处，则以“其他条款”为准。
            <br />
            1.6为表述便利，拍品和服务简称为“拍品”。
          </p>
          <h3>第2条 本站服务</h3>
          <p>
            2.1腾云拍卖平台通过互联网依法为您提供互联网信息等服务，您在完全同意本协议及本站相关规定的情况下，方有权使用本站的相关服务。
            <br />
            2.2您必须自行准备如下设备和承担如下开支：
            <br />
            （1）上网设备，包括并不限于电脑或者其他上网终端、调制解调器及其他必备的上网装置；
            <br />
            （2）上网开支，包括并不限于网络接入费、上网设备租用费、手机流量费等。
            <br />
            2.2.1上网设备，包括并不限于电脑或者其他上网终端、调制解调器及其他必备的上网装置；
            <br />
            2.2.2上网开支，包括并不限于网络接入费、上网设备租用费、手机流量费等。
          </p>
          <h3>第3条 用户信息收集及保护</h3>
          <p>
            3.1您应自行诚信向本站提供注册资料，您保证提供的注册资料真实、准确、完整、合法有效，您的注册资料如有变动的，应及时更新其注册资料。如果您提供的注册资料不合法、不真实、不准确、不详尽的，您需承担因此引起的相应责任及后果，并且腾云拍卖平台保留单方终止您使用腾云拍卖平台各项服务的权利。
            <br />
            3.2您在本站进行注册、浏览、参与竞拍等行为时，涉及您真实姓名/名称、通信地址、联系电话、电子邮箱、拍品详情、投诉内容、cookies等信息的，本站有权从完成交易、客户服务的客户体验等多种角度予以收集，并将对其中涉及个人隐私信息予以严格保密，除非得到您的授权、为履行强行性法律义务（如国家安全机关指令）或法律另有规定、本注册协议或其他条款另有约定外，本站不会向外界披露您的隐私信息。
            <br />
            3.3您注册成功后，将产生用户名和密码等账户信息，您可以根据本站规定更改您的密码。您应谨慎合理的保存、使用您的账户信息。您若发现任何非法使用您的账户或其他存在安全漏洞的情况的，请立即通知本站并向公安机关报案。
            <br />
            3.4您同意，腾云拍卖平台拥有通过邮件、短信、电话、网站通知或声明等形式，向在本站注册、竞拍的用户发送拍品信息、客户服务等告知信息的权利。如您不希望接收上述信息，可退订。
            <br />
            3.5您同意：您选择向本站的拍品发布商或服务提供商（以下统称为“销售商”，含腾云拍卖平台及第三方卖家）提交购买拍品或服务的，视为您向销售商披露个人相关信息，接受销售商向您提供拍品推荐、配送、售后服务、客户服务、处理信用卡付款、数据分析或其他必要事宜；如前述全部或部分事宜之一涉及由销售商外的第三方履行的，销售商有权将您的信息以必要方式向第三方披露，第三方有权以履行上述事宜为目的联系您。
            <br />
            3.6您不得将在本站注册获得的账号、密码等账户信息提供给他人使用，否则您应承担由此产生的全部责任，并与实际使用人承担连带责任。
            <br />
            3.7您同意，腾云拍卖平台有权使用您的注册信息、用户名、密码等信息，登陆进入您的注册账户，进行证据保全，包括但不限于公证、见证、协助司法机关进行调查取证等。
          </p>
          <h3>第4条 用户依法言行义务</h3>
          <h3>本协议依据国家相关法律法规规章制定，您同意严格遵守以下义务：</h3>
          <p>
            4.1不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；
            <br />
            4.2从中国大陆向境外传输资料信息时必须符合中国有关法律法规；
            <br />
            4.3不得利用本站从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；
            <br />
            4.4不得干扰本站的正常运转，不得侵入本站及国家计算机信息系统；
            <br />
            4.5不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料；
            <br />
            4.6不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；
            <br />
            4.7不得教唆他人从事本条所禁止的行为；
            <br />
            4.8除本注册协议、其他条款或另有其他约定外，您不得利用在本站注册的账户进行经营活动、牟利行为及其他未经本站许可的行为，
            <br />
            4.8.1发布广告、垃圾邮件；
            <br />
            4.8.2以再销售或商业使用为目的对本站商品或服务进行购买的（与腾云拍卖平台另有合同约定的除外）；
            <br />
            4.8.3任何对拍品目录、说明、价格、数量、其他用户信息或其他内容的下载、转载、收集、衍生利用、复制、出售、转售或其他形式的使用，无论是否通过Robots、Spiders、自动仪器或手工操作；
            <br />
            4.8.4本站相关规则、政策、或网页活动规则中限制、禁止的行为；
            <br />
            4.8.5其他影响腾云拍卖平台对用户账户正常管理秩序的行为。
            <br />
            4.9您不得利用任何非法手段获取其他用户个人信息，不得将其他用户信息用于任何营利或非营利目的，不得泄露其他用户或权利人的个人隐私，否则腾云拍卖平台有权采取本协议规定的合理措施制止您的上述行为，情节严重的，将提交公安机关进行刑事立案。
            <br />
            4.10您不得发布任何侵犯他人著作权、商标权等知识产权或其他合法权利的内容；如果有其他用户或权利人发现您发布的信息涉嫌知识产权、或其他合法权益争议的，这些用户或权利人有权要求腾云拍卖平台删除您发布的信息，或者采取其他必要措施予以制止，腾云拍卖平台将会依法采取这些措施。
            4.11您应不时关注并遵守本站不时公布或修改的各类规则规定。本站保有删除站内各类不符合法律政策或不真实的信息内容而无须通知您的权利。
          </p>
          <h3> 第5条 拍品信息</h3>
          <p>
            5.1本站上的拍品信息随时都有可能发生变动，本站不作特别通知。由于网站上拍品信息的数量极其庞大，虽然本站会尽最大努力保证您所浏览拍品信息的准确性，但由于众所周知的互联网技术因素等客观原因存在，本站网页显示的信息可能会有一定的滞后性或差错，对此情形您知悉并理解；腾云拍卖平台欢迎纠错。
          </p>
          <h3>第6条参拍</h3>
          <p>
            6.1在您准备参拍时，请您仔细确认所参拍拍品的拍卖公告、竞拍须知等信息。并对竞拍成功的行为承担法律责任。
          </p>
          <h3>第7条 配送</h3>
          <p>
            7.1关于小件拍品销售商将会把商品（货物）送到您所指定的收货地址，所有在本站上列出的送货时间为参考时间，参考时间的计算是根据送货时间、送货地点等相关信息估计得出的。
            <br />
            7.2因如下情况造成拍品延迟或无法配送、交货等，销售商不承担延迟配送、交货的责任：
            <br />
            （1）您提供的信息错误、地址不详细等原因导致的；
            <br />
            （2）拍品送达后无人签收，导致无法配送或延迟配送的；
            <br />
            （3）情势变更因素导致的；
            <br />
            （4）未能在本站所示的送货参考时间内送货的；
            <br />
            （5）不可抗力因素导致的，例如：自然灾害、交通戒严、突发战争等。
          </p>
          <h3>第8条 所有权及知识产权条款</h3>
          <p>
            8.1您一旦接受本协议，即表明您主动将其在任何时间段在本站发表的任何形式的信息内容（包括但不限于客户评价、客户咨询、各类话题文章等信息内容）的财产性权利等任何可转让的权利，如著作权财产权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），全部独家且不可撤销地转让给腾云拍卖平台所有，您同意腾云拍卖平台有权就任何主体侵权而单独提起诉讼。
            <br />
            8.2本协议已经构成《中华人民共和国著作权法》第二十五条（条文序号依照2011年版著作权法确定）及相关法律规定的著作财产权等权利转让书面协议，其效力及于您在腾云拍卖平台网站上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。
            <br />
            8.3您同意并已充分了解本协议的条款，承诺不将已发表于本站的信息，以任何形式发布或授权其他主体以任何方式使用（包括但不限于在各类网站、媒体上使用）。
            <br />
            8.4腾云拍卖平台有权不时地对本协议及本站的内容进行修改，并在本站张贴，无须另行通知您。在法律允许的最大限度范围内，腾云拍卖平台对本协议及本站内容拥有解释权。
            <br />
            8.5除法律另有强制性规定外，未经腾云拍卖平台明确的特别书面许可,任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本站的信息内容，否则，腾云拍卖平台有权追究其法律责任。
            <br />
            8.6本站所刊登的资料信息（包括但不限于文字、图表、商标、logo、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件等），均是腾云拍卖平台或其内容提供者的财产，受中国和国际相关版权法规、公约等的保护，未经腾云拍卖平台书面许可，任何第三方无权将上述资料信息复制、出版、发行、公开展示、编码、翻译、传输或散布至任何其他计算机、服务器、网站或其他媒介。本站上所有内容的汇编是腾云拍卖平台的排他财产，受中国和国际版权法的保护。本站上所有软件都是腾云拍卖平台或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。您不得鼓励、协助或授权任何其他人复制、修改、反向工程、反向编译或反汇编、拆解或者试图篡改全部或部分软件，或利用软件创设衍生产品。
          </p>
          <h3>第9条 责任限制及不承诺担保</h3>
          <p>
            9.1除非另有明确的书面说明,本站及其所包含的或以其他方式通过本站提供给您的全部信息、内容、材料、产品（包括软件）和服务，均是在“按现状”和“按现有”的基础上提供的。
            <br />
            9.2除非另有明确的书面说明,腾云拍卖平台不对本站的运营及其包含在本站上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。
            <br />
            9.3腾云拍卖平台不担保本站所包含的或以其他方式通过本站提供给您的全部信息、内容、材料、产品（包括软件）和服务、其服务器或从本站发出的电子信件、信息没有病毒或其他有害成分。
            <br />
            9.4如因不可抗力或其他本站无法控制的原因使本站销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，腾云拍卖平台会合理地尽力协助处理善后事宜。
            <br />
            9.5您应对账户信息及密码承担保密责任，因您未能尽到信息安全和保密责任而致使您的账户发生任何问题的，您应承担全部责任。同时，因网络环境存在众多不可预知因素，因您自身终端网络原因（包括但不限于断网、黑客攻击、病毒等）造成您的腾云拍卖平台账户或个人信息等被第三方窃取的，腾云拍卖平台不承担赔偿责任。
            <br />
            9.6您了解并同意，腾云拍卖平台有权应国家有关机关的要求，向其提供您在腾云拍卖平台的用户信息和交易记录等必要信息。如您涉嫌侵犯他人合法权益，则腾云拍卖平台有权在初步判断涉嫌侵权行为可能存在的情况下，向权利人提供您必要的个人信息。
          </p>
          <h3>第10条 协议更新及用户关注义务</h3>
          <p>
            根据国家法律法规变化及网站运营需要，腾云拍卖平台有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在本站上即生效，并代替原来的协议。您可随时登陆查阅最新协议；您有义务不时关注并阅读最新版的协议、其他条款及网站公告。如您不同意更新后的协议，可以且应立即停止接受腾云拍卖平台网站依据本协议提供的服务；如您继续使用本站提供的服务的，即视为同意更新后的协议。腾云拍卖平台建议您在使用本站之前阅读本协议及本站的公告。如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
          </p>
          <h3>第11条 法律管辖和适用</h3>
          <p>
            本协议的订立、执行和解释及争议的解决均适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其他条款继续有效。如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向有管辖权的中华人民共和国大陆地区法院提起诉讼。
          </p>
          <h3>第12条 其他</h3>

          <p>
            12.1腾云拍卖平台网站所有者是指在政府部门依法许可或备案的腾云拍卖平台网站经营主体。
            <br />
            12.2腾云拍卖平台尊重您的合法权利，本协议及本站上发布的各类规则、声明、售后服务政策等其他内容，均是为了更好的、更加便利的为您提供服务。本站欢迎您和社会各界提出意见和建议，腾云拍卖平台将虚心接受并适时修改本协议及本站上的各类规则。
            <br />
            12.3本协议内容中以黑体、加粗、下划线、斜体等方式显著标识的条款，请您着重阅读。
            <br />
            12.4您点击本协议下方的“同意并继续”按钮即视为您完全接受本协议，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Agreement;
