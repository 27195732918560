import React, { useState, useEffect, useRef } from 'react';
import Header from "./../header";
import Footer from "./../footer";
import axios from "./../api";
import { Form, Input, Button, message } from 'antd';
import { useLocation } from "react-router-dom";
import Result from "./../component/result";
import "./index.scss";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};
const tailLayout = {
    wrapperCol: { offset: 10, span: 12 },
};
const Opinion: React.FC = () => {
    const loginRef: any = useRef(null);
    const path = useLocation().pathname;
    const search = useLocation().search;
    const [phone, set_phone] = useState('');
    const [result, set_result] = useState(false);
    const onFinish = async (values: any) => {
        if (!window.localStorage.getItem("user")) {
            message.warning("请登录后进行提交");
            loginRef && loginRef.current.wnLogin(path, search);
            return;
        }
        const result: any = await axios.http.post(axios.api.feedback_save, { ...values })
        if (result.status === 0) {
            set_result(true);
        } else {
            message.error(result.message)
        }
    };
    return (
        <div>
            <Header ref={loginRef} />
            <div className="session">
                <div className="appointment_box">
                    <h6>意见反馈</h6>
                    {
                        !result && <Form
                            {...layout}
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                label="您的姓名"
                                name="name"
                                rules={[{ required: true, message: '姓名不能为空' }]}
                            >
                                <Input maxLength={16} placeholder="请输入您的姓名" />
                            </Form.Item>

                            <Form.Item
                                label="电话号码"
                                name="phone"
                                rules={[{ required: true, pattern: /^1[3456789]\d{9}$/, message: '电话号码不正确' }]}
                            >
                                <Input maxLength={11} onChange={(e) => { set_phone(e.target.value) }} placeholder="请输入您的联系方式" />
                            </Form.Item>


                            <Form.Item
                                label="意见或建议"
                                name="content"
                                rules={[{ required: true, message: '意见或建议不能为空' }]}
                            >
                                <Input.TextArea maxLength={500} placeholder="请输入意见或建议" />
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <Button type="primary" htmlType="submit">
                                    提交
                            </Button>
                            </Form.Item>
                        </Form>
                    }

                    {
                        result && <Result title="意见提交成功" content="感谢您的宝贵意见，我们会持续改进" />
                    }

                </div>


            </div>
            <Footer />
        </div>
    )
}
export default Opinion;
