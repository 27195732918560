import React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { HomeSearch, HeaderType } from "./../storesMobx";
import "./index.scss";
const Footer = () => {
  const { headerType }: any = HeaderType();
  return (
    <footer>
      <div className="footer">
        <div className="footer_left">
          {(headerType === "1" ||
            headerType === "4" ||
            headerType === "5" ||
            headerType === "6" ||
            headerType === "7") && (
              <ul>
                <li>
                  <h6 className="logo"></h6>
                  <Link to="/about">关于我们</Link>
                  <Link to="/liability">免责声明</Link>
                  <Link to="/cooperation">合作招商</Link>
                </li>
                <li>
                  <h6>竞价帮助</h6>
                  <Link to="/help?1&id=30">讯拍用户注册流程</Link>
                  <Link to="/help?1&id=33">讯拍用户竞价流程</Link>
                  <Link to="/help?1&id=31">司法拍卖竞价规则</Link>
                  <Link to="/help?1&id=32">名词解释</Link>
                </li>
                <li>
                  <h6>保证金须知</h6>
                  <Link to="/help?1&id=8">保证金缴纳</Link>
                  <Link to="/help?1&id=14">竞拍成功未及时支付尾款</Link>
                  <Link to="/help?1&id=8">保证金冻结期限</Link>
                  <Link to="/help?1&id=7">竞拍失败保证金退回</Link>
                </li>
                <li>
                  <h6>用户中心</h6>
                  <Link to="/help?1&id=7">竞拍商品来源</Link>
                  <Link to="/help?1&id=21">实名认证规则</Link>
                  <Link to="/help?1&id=21">延时规则</Link>
                  <Link to="/help?1&id=21">优先购买权人报名</Link>
                  <Link to="/help?1&id=21">委托竞买规则</Link>
                </li>
                <li>
                  <h6>常见问题必看</h6>
                  <Link to="/help?1&id=9">个人网银支付限额</Link>
                  <Link to="/help?1&id=7">报名资料是否可修改</Link>
                  <Link to="/help?1&id=7">如何确认竞价成功</Link>
                  <Link to="/help?1&id=7">竞价成功如何支付尾款</Link>
                  <Link to="/help?1&id=7">悔拍会产生什么后果</Link>
                </li>
                <li>
                  <h6>客服专线</h6>
                  <h5>400-000-1102</h5>
                  <div className="code"></div>
                </li>
              </ul>
            )}
          {headerType === "2" && (
            <ul>
              <li>
                <h6 className="logo"></h6>
                <Link to="/about">关于我们</Link>
                <Link to="/liability">免责声明</Link>
                <Link to="/cooperation">合作招商</Link>
              </li>
              <li>
                <h6>竞价帮助</h6>
                <Link to="/help?2&id=232">“保留价”功能介绍</Link>
                <Link to="/help?2&id=232">评估价功能介绍</Link>
                <Link to="/help?2&id=232">“出价延时”功能介绍</Link>
                <Link to="/help?2&id=207">佣金规则介绍</Link>
                <Link to="/help?2&id=241">何为竞买人资质</Link>
              </li>
              <li>
                <h6>保证金须知</h6>
                <Link to="/help?2&id=208">如何报名并缴纳保证金</Link>
                <Link to="/help?2&id=207">尾款如何支付</Link>
                <Link to="/help?2&id=207">出局者保证金退还</Link>
              </li>
              <li>
                <h6>用户中心</h6>
                <Link to="/help?2&id=230">如何注册账号</Link>
                <Link to="/help?2&id=233">参与竞价详细教程</Link>
                <Link to="/help?2&id=233">竞价流程图</Link>
              </li>
              <li>
                <h6>常见问题必看</h6>
                <Link to="/help?2&id=209">个人网银支付限额</Link>
                <Link to="/help?2&id=207">报名资料是否可修改</Link>
                <Link to="/help?2&id=207">如何确认竞价成功</Link>
                <Link to="/help?2&id=207">竞价成功如何支付尾款</Link>
                <Link to="/help?2&id=207">悔拍会产生什么后果</Link>
              </li>
              <li>
                <h6>客服专线</h6>
                <h5>400-000-1102</h5>
                <div className="code"></div>
              </li>
            </ul>
          )}
          {headerType === "3" && (
            <ul>
              <li>
                <h6 className="logo"></h6>
                <Link to="/about">关于我们</Link>
                <Link to="/liability">免责声明</Link>
                <Link to="/cooperation">合作招商</Link>
              </li>
              <li>
                <h6>竞价帮助</h6>
                <Link to="/help?3&id=132">“保留价”功能介绍</Link>
                <Link to="/help?3&id=132">评估价功能介绍</Link>
                <Link to="/help?3&id=132">“出价延时”功能介绍</Link>
                <Link to="/help?3&id=107">佣金规则介绍</Link>
                <Link to="/help?3&id=141">何为竞买人资质</Link>
              </li>
              <li>
                <h6>保证金须知</h6>
                <Link to="/help?3&id=108">如何报名并缴纳保证金</Link>
                <Link to="/help?3&id=107">尾款如何支付</Link>
                <Link to="/help?3&id=107">出局者保证金退还</Link>
              </li>
              <li>
                <h6>用户中心</h6>
                <Link to="/help?3&id=130">如何注册账号</Link>
                <Link to="/help?3&id=133">参与竞价详细教程</Link>
                <Link to="/help?3&id=133">竞价流程图</Link>
              </li>
              <li>
                <h6>常见问题必看</h6>
                <Link to="/help?3&id=109">个人网银支付限额</Link>
                <Link to="/help?3&id=107">报名资料是否可修改</Link>
                <Link to="/help?3&id=107">如何确认竞价成功</Link>
                <Link to="/help?3&id=107">竞价成功如何支付尾款</Link>
                <Link to="/help?3&id=107">悔拍会产生什么后果</Link>
              </li>
              <li>
                <h6>客服专线</h6>
                <h5>400-000-1102</h5>
                <div className="code"></div>
              </li>
            </ul>
          )}
        </div>
      </div>

      <div className="other_link">
        <a
          href="https://www.rmfysszc.gov.cn"
          target="_blank"
          rel="noopener noreferrer"
        >
          人民法院诉讼资产网
        </a>
        &emsp;
        <a
          href="https://auction.jd.com/home.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;京东拍卖
        </a>
        &emsp;
        <a
          href="https://gf.trade.icbc.com.cn"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;工商银行“融e购”
        </a>
        &emsp;
        <a
          href="https://paimai.caa123.org.cn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;中国拍卖行业协会网
        </a>
        &emsp;
        <a
          href="http://www.gpai.net/sf"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;公拍网
        </a>
        &emsp;
        <a
          href="http://www.cbex.com.cn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;北京产权交易所
        </a>
        &emsp;
        <a
          href="http://www.yfbudong.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;海豚选房
        </a>
        &emsp;
        <a
          href="https://www.360pai.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;360PAI
        </a>
        &emsp;
        <a
          href="https://www.suaee.com/suaeeHome/#/home"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;上海联合产权交易所
        </a>
        &emsp;
        <a
          href="https://www.tpre.cn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;天津交易所&emsp;
        </a>
        <a
          href="https://cquae.cqggzy.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;重庆产权交易网
        </a>
        <br />
        <a
          href="http://www.cinda.com.cn/xdjt/xdjtpd/index.shtml"
          target="_blank"
          rel="noopener noreferrer"
        >
          中国信达资产管理股份有限公司
        </a>
        &emsp;
        <a
          href="http://www.coamc.com.cn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;中国东方资产管理公司
        </a>
        &emsp;
        <a
          href="http://www.gwamcc.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;中国长城资产管理公司
        </a>
        &emsp;
        <a
          href="http://www.chamc.com.cn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;中国华融资产管理公司
        </a>
        &emsp;
        <a
          href="http://www.icbc.com.cn/icbc/"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;中国工商银行
        </a>
        &emsp;
        <a
          href="http://www.abchina.com/cn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;中国农业银行
        </a>
        &emsp;
        <a
          href="http://www.ccb.com/cn/home/indexv3.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;中国建设银行&emsp;
        </a>
        &emsp;
        <a href="https://www.boc.cn/" target="_blank" rel="noopener noreferrer">
          |&emsp;中国银行&emsp;
        </a>
        <a
          href="http://www.bankcomm.com/BankCommSite/default.shtml"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;交通银行
        </a>
        &emsp;
        <br />
        <a
          href="http://www.cguardian.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          中国嘉德国际拍卖有限公司
        </a>
        &emsp;
        <a
          href="http://www.polypm.com.cn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;北京保利国际拍卖有限公司
        </a>
        &emsp;
        <a
          href="https://www.sothebys.com/en/"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;香港苏富比有限公司
        </a>
        &emsp;
        <a
          href="https://www.christies.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;Christie's佳士得
        </a>
        &emsp;
        <a
          href="http://www.council.com.cn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;北京匡时国际拍卖有限公司
        </a>
        &emsp;
        <a
          href="https://www.fapai.cn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;北京瀚海拍卖有限公司&emsp;
        </a>
        <a
          href="http://www.sungari1995.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;中贸圣佳&emsp;
        </a>
        <a
          href="http://www.xlysauc.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;西冷印社拍卖有限公司
        </a>
        <br />
        <a
          href="https://auction.artron.net/shanghaiduoyunxuan/"
          target="_blank"
          rel="noopener noreferrer"
        >
          上海朵云轩集团
        </a>
        &emsp;
        <a
          href="http://www.xianguoyihui.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          |&emsp;西安国艺汇
        </a>

        <div className="tencent_box">
          {/* <a href="https://www.qq.com/" className="tencent_logo"></a> */}
          <div className="tencent_link">
            <a href="https://www.qq.com/" target="_blank" rel="noopener noreferrer">腾讯网</a> &emsp;
            <a href="https://cloud.tencent.com/" target="_blank" rel="noopener noreferrer">|&emsp;腾讯云</a> &emsp;
            <a href="https://e.qq.com" target="_blank" rel="noopener noreferrer">|&emsp;腾讯广告</a> &emsp;
            <a href="https://cloud.tencent.com/product/cvm" target="_blank" rel="noopener noreferrer">|&emsp;云服务器</a> &emsp;
            <a href="https://cloud.tencent.com/product/tencentdb-catalog" target="_blank" rel="noopener noreferrer">|&emsp;云数据库</a> &emsp;
            <a href="https://cloud.tencent.com/product/cos" target="_blank" rel="noopener noreferrer">|&emsp;云存储</a> &emsp;
            <a href="https://cloud.tencent.com/product/cdn" target="_blank" rel="noopener noreferrer">|&emsp;CDN加速</a> &emsp;
            <a href="https://cloud.tencent.com/product/ssl" target="_blank" rel="noopener noreferrer">|&emsp;SSL证书</a> &emsp;
            <a href="https://cloud.tencent.com/solution/data_protection" target="_blank" rel="noopener noreferrer">|&emsp;数据安全</a> &emsp;

        </div>
        </div>
        <p>
          本网站部分素材来源于网络，若侵害了您的权益，请立即联系客服，我们会尽快为您处理
          发送邮箱：349775989@qq.com 联系客服：400-000-1102
        </p>
        <p>
          © 2016-现在&nbsp;讯拍网&nbsp;版权所有 &nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://beian.miit.gov.cn"
          >
            蜀ICP备18030264号
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
