import React, { useEffect, useState } from "react";
import axios from "./../api";
import { Spin, Result } from "antd";
import List from "./../component/list";
import AssetsList from "./../component/assetsList";
import Paging from "./../component/pagination";
import { observer } from "mobx-react";
import { HeaderType } from "./../storesMobx";
import { useLocation } from "react-router-dom";
const BrowsingHistory: React.FC = () => {
  const path = useLocation().pathname;
  const search = useLocation().search;
  const { city, cityId }: any = HeaderType();
  const [histort_type, set_histort_type] = useState("lots");
  const [spinning, set_spinning] = useState(false);
  const [recommended_list, set_recommended] = useState([]); //推荐房源
  const [tj_list, set_tj_list] = useState([]); //推荐资产列表
  const [post_body, set_post_body] = useState({
    page_num: 1,
    page_size: 15,
  });

  const [not_list, set_not_list] = useState(false);
  const [list, set_list] = useState([]);
  const [total, set_total] = useState(-1);
  const { loginWx }: any = HeaderType();
  const getList = async () => {
    set_spinning(true);
    const url =
      histort_type === "lots"
        ? axios.api.buyer_view_list
        : axios.api.buyer_view_investment_list;
    const result: any = await axios.http.post(url, { ...post_body });
    if (result.status === 0) {
      set_list(result.result.data);
      set_total(result.result.total);
    }
    if (result.status === -101) {
      loginWx(path, search);
    }
    set_spinning(false);
  };

  // 房产类数据列表
  const getCategory = async () => {
    const postBody: any = {
      category_id: 50025969,
      page_num: 1,
      page_size: 3,
      sort_order: 1,
      sort_type: 5,
      status: 1,
      city_id: cityId,
    };
    const result: any = await axios.http.newGet(axios.api.auction_list, {
      ...postBody,
    });
    if (result.status === 0) {
      const list = result.result.data || [];
      set_recommended(list);
    }
  };

  // 招商推介
  const getZCTjList = async () => {
    const queryBody = {
      city_id: cityId, //标的所在地
      sort_type: 1, //1默认 2价格 3次数
      sort_order: 0, //0升序 1降序
      page_num: 1,
      page_size: 3,
    };
    const result: any = await axios.http.newGet(axios.api.zc_tj_list, {
      ...queryBody,
    });
    if (result.status === 0) {
      set_tj_list(result.result.data);
    }
  };
  useEffect(() => {
    if (cityId) {
      histort_type === "lots" ? getCategory() : getZCTjList();
    }
  }, [cityId, histort_type]);
  useEffect(() => {
    getList();
  }, [post_body]);
  return (
    <div className="browsing_history_box">
      <div className="browsing_history_nav">
        <span
          onClick={() => {
            set_histort_type("lots");
            set_list([]);
            set_post_body({ ...post_body, page_num: 1 });
          }}
          className={histort_type === "lots" ? "active" : ""}
        >
          拍品记录
        </span>
        <span
          onClick={() => {
            set_histort_type("merchants");
            set_list([]);
            set_post_body({ ...post_body, page_num: 1 });
          }}
          className={histort_type === "merchants" ? "active" : ""}
        >
          招商记录
        </span>
      </div>
      <Spin tip="加载中..." spinning={spinning}>
        {histort_type === "lots" ? (
          <List notList={not_list} list={list} narrow={true} />
        ) : (
          <AssetsList
            notList={not_list}
            list={list}
            narrow={true}
            link="assets_details"
          />
        )}

        {total === 0 && (
          <Result
            status="404"
            title="当前无相关浏览记录"
            subTitle="您最近还没有浏览过任何项目哦,去逛一逛吧~"
          />
        )}
        <div className="more_box">
          <Paging
            pageSize={post_body.page_size}
            current={post_body.page_num}
            pageSizeOptions={["15", "24", "36"]}
            total={total}
            onShowSizeChange={(size: number) => {
              set_post_body({ ...post_body, page_size: size });
            }}
            onChange={(page: number) => {
              set_post_body({ ...post_body, page_num: page });
            }}
          />
        </div>
      </Spin>
      {histort_type === "lots" && recommended_list.length > 0 && (
        <div className="recommended">
          <h6>相关推荐</h6>
          <div>
            <List notList={false} list={recommended_list} narrow={true} />
          </div>
        </div>
      )}
      {histort_type === "merchants" && tj_list.length > 0 && (
        <div className="recommended">
          <h6>相关推荐</h6>
          <div>
            <AssetsList
              notList={not_list}
              list={tj_list}
              narrow={true}
              link="assets_details"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(BrowsingHistory);
