/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "../header/index";
import format from "./../component/format";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { message, Carousel } from "antd";
// @ts-ignore
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, EffectFade } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import "./index.scss";
import List from "../component/list";
import Footer from "./../footer/index";
import { HeaderType } from "./../storesMobx";
import Data from "./data";
import axios from "./../api";
import FooterCode from "./../component/footer_code";

let lazy: boolean[] = [
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
];
const lazyId = [
  "jr",
  "jd",
  "cq",
  "hz1",
  "hz2",
  "hz3",
  "hz4",
  "hz5",
  "hz6",
  "yg1",
  "yg2",
  "yg3",
];
const windeowHeight =
  document.body.clientHeight || document.documentElement.clientHeight;
const Home: React.FC = () => {
  const carousel: any = useRef(null);
  const { city, cityId }: any = HeaderType();
  const localCityList = window.localStorage.getItem("cityList") || "[]";
  const city_list = JSON.parse(localCityList);
  const toDay = format().data;
  const tomorrow = format(true).data;
  const week = format(true, true).week;
  const tomorrowDay = format(true, true).data;
  const [view_count, set_view_count]: any = useState([]); //今日围观次数
  const [latest_news, set_latest_news]: any = useState([]); //最近新闻列表
  const [latest_notice_list, set_latest_notice_list]: any = useState([]); //最近公告列表
  const [category_id, set_category_id] = useState("50025969"); //今日拍卖房产类型
  const [today_real_list, set_today_real_list] = useState([]); //今日拍卖房产拍卖列表
  const [discount_list, set_discount_list] = useState([]); //折扣房源列表
  const [today_real_total, set_today_real_total] = useState([]); //今日拍卖房产拍卖列表

  const [vehicle_id, set_vehicle_id] = useState("50025972"); //今日拍卖房产拍卖id
  const [vehicle_total, set_vehicle_total] = useState(0); //今日拍卖机动车总数
  const [today_vehicle_list, set_today_vehicle_list] = useState([]); //今日拍卖机动车拍卖列表

  const [other_id, set_other_id] = useState("50025970"); //今日拍卖其他拍卖id
  const [other_total, set_other_total] = useState(0); //今日拍卖其他总数
  const [today_other_list, set_today_other_list] = useState([]); //今日拍卖其他拍卖列表

  const [tomorrow_list, set_tomorrow_list] = useState([]); //明日数据列表
  const [tomorrow_total, set_tomorrow_total] = useState(0); //明日数据总数

  const [tomorrowDay_list, set_tomorrowDay_list] = useState([]); //后日数据列表
  const [tomorrowDay_total, set_tomorrowDay_total] = useState(0); //后日数据总数

  const [toDay_list, set_toDay_list] = useState([]); //今日数据列表
  const [toDay_total, set_toDay_total] = useState([]); //今日数据总数

  const [recommended_list, set_recommended] = useState([]); //推荐房源

  const [rm_list, set_rm_list] = useState([]);
  const [bj_list, set_bj_list] = useState([]);
  const [gs_list, set_gs_list] = useState([]);
  const [pm_list, set_pm_list] = useState([]);
  const [gp_list, set_gp_list] = useState([]);
  const [jd_list, set_jd_list] = useState([]);

  const [banner, set_banner]: any = useState([]);

  const [floor, set_floor] = useState("");

  SwiperCore.use([Navigation])

  // 获取围观次数
  const viewCount = async () => {
    const result: any = await axios.http.newGet(axios.api.view_count, {});
    if (result.status == 0) {
      try {
        const view_count = result.result.view_count.split("");
        set_view_count(view_count);
      } catch {
        set_view_count(["0"]);
      }
    }
  };
  // 获取最近新闻
  const latestNews = async () => {
    const result: any = await axios.http.newGet(axios.api.latest_news, {
      belong: 1,
      page_num: 1,
      page_size: 4,
    });

    if (result.status === 0) {
      set_latest_news(result.result);
    } else {
      message.error(result.message);
    }
  };
  // 获取最新公告
  const latestNotice = async () => {
    const result: any = await axios.http.newGet(axios.api.latest_notice, {
      page_num: 1,
      page_size: 4,
    });
    if (result.status === 0) {
      set_latest_notice_list(result.result);
    }
  };
  // 房产类数据列表
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // @ts-ignore
  const getCategory = useCallback(async (
    category_id: string,
    page_size: number,
    type: string
  ) => {
    const postBody: any = {
      category_id,
      page_num: 1,
      page_size: page_size,
      sort_order: 1,
      city_id: cityId,
      sort_type: type === "category_id" ? 5 : 1,
    };
    if (type === "category_id") {
      postBody.status = 1;
    }
    console.log(789)
    const result: any = await axios.http.newGet(axios.api.auction_list, {
      ...postBody,
    });
    if (result.status === 0) {
      const list = result.result.data || [];
      if (type === "real") {
        set_today_real_list(list);
        set_today_real_total(result.result.total);
      } else if (type === "vehicle") {
        set_today_vehicle_list(list);
        set_vehicle_total(result.result.total);
      } else if (type === "other") {
        set_today_other_list(list);
        set_other_total(result.result.total);
      }
    }
  });
  // 获取推荐房源
  const getAuction = async function () {
    const result: any = await axios.http.newGet(axios.api.auction_recommend, {
      page_num: 1,
      page_size: 4,
    });
    if (result.status === 0) {
      const list = result.result.data || [];
      set_recommended(list);
    }
  };
  //按照时间获取列表
  const getTimeCategory = async (
    time: string,
    page_size: number,
    type: string
  ) => {
    console.log(456)
    const result: any = await axios.http.newGet(axios.api.auction_list, {
      start_time: time,
      end_time: time,
      page_num: 1,
      page_size,
      city_id: cityId,
    });
    if (result.status == 0) {
      const list = result.result.data || [];
      if (type === "toDay") {
        set_toDay_list(list);
        set_toDay_total(result.result.total);
      } else if (type === "tomorrow") {
        set_tomorrow_list(list);
        set_tomorrow_total(result.result.total);
      } else if (type === "tomorrowDay") {
        set_tomorrowDay_list(list);
        set_tomorrowDay_total(result.result.total);
      }
    }
  };
  // 获取合作伙伴列表
  const getPartnerList = async (data_from: string, type: string) => {
    console.log(123)
    const postBody = {
      data_from: parseInt(data_from),
      page_num: 1,
      page_size: 8,
      sort_order: 1,
      sort_type: 1,
      city_id: cityId,
    };
    if (
      type === "bj" ||
      type === "jd" ||
      type === "gs" ||
      type === "gp" ||
      type === "rm" ||
      type === "pm"
    ) {
      delete postBody.city_id;
    }
    const result: any = await axios.http.newGet(axios.api.auction_list, {
      ...postBody,
    });
    if (result.status === 0) {
      if (type === "bj") {
        set_bj_list(result.result.data);
      }
      if (type === "jd") {
        set_jd_list(result.result.data);
      }
      if (type === "gs") {
        set_gs_list(result.result.data);
      }
      if (type === "gp") {
        set_gp_list(result.result.data);
      }
      if (type === "rm") {
        set_rm_list(result.result.data);
      }
      if (type === "pm") {
        set_pm_list(result.result.data);
      }
    }
  };

  const lazyLogin = () => {
    lazyId.map((item, index) => {
      const dom = document.getElementById(item);
      if (dom && dom.getBoundingClientRect().top < windeowHeight) {
        requestList(index, lazy[index]);
        lazy[index] = true;
      }
    });
    window.addEventListener("scroll", bindScroll, false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // @ts-ignore
  const bindScroll = useCallback(() => {
    const provinces_boxs: any = Array.from(
      document.querySelectorAll(".floor_home")
    );
    provinces_boxs.map((item: any) => {
      const top = item.getBoundingClientRect().top;
      if (top > 100 && top < 300) {
        const szm = item.getAttribute("id");
        set_floor(szm);
      }
    });
    if (provinces_boxs[0].getBoundingClientRect().top > 500) {
      set_floor("");
    }

    lazyId.map((item, index) => {
      const dom = document.getElementById(item);
      if (dom && dom.getBoundingClientRect().top < windeowHeight) {
        requestList(index, lazy[index]);
        lazy[index] = true;
      }
    });
  });

  const requestList = (index: number, lazy: boolean) => {
    switch (index) {
      case 1:
        if (!lazy) {
          getCategory(vehicle_id, 3, "vehicle");
        }
        break;
      case 3:
        if (!lazy) {
          getPartnerList("6", "rm");
        }
        break;
      case 4:
        if (!lazy) {
          getPartnerList("3", "bj");
        }
        break;
      case 5:
        if (!lazy) {
          getPartnerList("4", "gs");
        }
        break;
      case 6:
        if (!lazy) {
          getPartnerList("7", "pm");
        }
        break;
      case 7:
        if (!lazy) {
          getPartnerList("5", "gp");
        }
        break;
      case 8:
        if (!lazy) {
          getPartnerList("2", "jd");
        }
        break;
      case 9:
        if (!lazy) {
          getTimeCategory(toDay, 8, "toDay"); //获取今日拍卖数据
        }
        break;
      case 10:
        if (!lazy) {
          getTimeCategory(tomorrow, 8, "tomorrow"); //获取明日拍卖数据
        }
        break;
      case 11:
        if (!lazy) {
          getTimeCategory(tomorrowDay, 8, "tomorrowDay"); //获取后天拍卖数据
        }
        break;

      default:
        break;
    }
  };

  const getBanner = async () => {
    const banner: any = await axios.http.get(`${axios.api.bannerList}`);
    if (banner.status === 0) {
      set_banner(banner.data);
    }
  };
  // 获取banner页面
  // const getBanner = async () => {
  //   const banner: any = await axios.http.newGet(axios.api.value_add_data_list, {
  //     page_num: 1,
  //     page_size: 6,
  //     put_position: 1,
  //     put_channel: 1,
  //     city_id: cityId
  //   });
  //   if (banner.status === 0) {
  //     set_banner(banner.result.data);
  //   }
  // };
  // 获取折扣房源
  // const discountList = async () => {
  //   const banner: any = await axios.http.newGet(axios.api.value_add_data_list, {
  //     page_num: 1,
  //     page_size: 6,
  //     put_position: 2,
  //     put_channel: 1,
  //     city_id: cityId
  //   });
  //   if (banner.status === 0) {
  //     set_discount_list(banner.result.data);
  //   }
  // };

  const scrollLocation = (indexStr: string) => {
    const widget: any = document.getElementById(indexStr);
    const scrollTop = window.scrollY;
    window.scrollTo(0, widget.getBoundingClientRect().top + scrollTop - 70);
  };
  // 房产类数据加载
  useEffect(() => {
    if (cityId) {
      getCategory(category_id, 6, "real");
    }
  }, [category_id, cityId]);
  //机动车类
  useEffect(() => {
    // 围观次数 最新新闻 法院公告
    viewCount();
    latestNews();
    latestNotice();
    getBanner();
    getAuction();
  }, []);
  //其他
  useEffect(() => {
    if (cityId) {
      getCategory(other_id, 3, "other");
    }
  }, [cityId, other_id]);
  useEffect(() => {
    if (cityId) {
      getCategory(other_id, 3, "other");
      getCategory(category_id, 4, "category_id");
      getCategory(category_id, 6, "real");
      lazyLogin();
    }
    lazy = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];
    return () => {
      window.removeEventListener("scroll", bindScroll, false);
    };
  }, [category_id, cityId, other_id]);

  return (
    <div>
      <Header />
      <div className="session">
        <div className="home_banner_nav sf_home_banner_nav">
          <div className="home_banner_left sf_banner_left">
            <div className="home_banner_nav_box">
              <h2>
                <Link to="/matter_list">司法拍卖平台</Link>
                <Link to="/matter_list" style={{ fontSize: "14px" }}>
                  更多&gt;
                </Link>
              </h2>
              <div className="home_banner_nav_list">
                {Data.cooperationHomeList.map((item: any) => {
                  if (
                    item.name !== "淘宝" &&
                    item.name !== "中国拍卖行业协会"
                  ) {
                    return (
                      <Link
                        style={{ width: "50%" }}
                        to={`/matter_list${item.id ? `?partner=${item.id}` : ""
                          }`}
                        key={item.id}
                      >
                        {item.name}
                      </Link>
                    );
                  }
                })}
              </div>
            </div>
            <div className="home_banner_nav_box">
              <h2>
                <Link to="/matter_list">标的物类型</Link>
              </h2>
              <div className="home_banner_nav_list">
                {Data.matterType.map((item: any) => {
                  return (
                    <Link
                      to={`/matter_list?matterType=${item.id}`}
                      key={item.id}
                    >
                      {item.name}
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className="home_banner_nav_box">
              <h2>
                <Link to="/matter_list">标的所在地</Link>
              </h2>
              <div className="home_banner_nav_list">
                {city_list &&
                  city_list.map((item: any) => {
                    return (
                      <Link
                        key={item.id}
                        to={`/matter_list?matterAdd=${item.id}`}
                      >
                        {item.shortname}
                      </Link>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="home_banner_middle">
            <div className="home_banner_box">
              <div
                className="right"
                onClick={() => {
                  carousel.current.next();
                }}
              >
                <RightOutlined />
              </div>
              <div
                className="left"
                onClick={() => {
                  carousel.current.prev();
                }}
              >
                <LeftOutlined />
              </div>
              {
                banner.length > 0 &&
                <Carousel autoplay={true} ref={carousel}>
                  {banner &&
                    banner.map((item: any, index: number) => {
                      if (item.main_pc) {
                        if (item.imgs_pc.length) {
                          return (
                            <div>
                              <Link to={`/banner_link?${item.index}`} target="_blank">
                                <img
                                  src={`https://file.qqpai.com${item.main_pc}`}
                                  alt=""
                                />
                              </Link>
                            </div>
                          );
                        } else {
                          return (
                            <div>
                              <img
                                src={`https://file.qqpai.com${item.main_pc}`}
                                alt=""
                              />
                            </div>
                          );
                        }
                      }
                    })}
                </Carousel>
              }
            </div>

            <div className="home_new_box">
              <div className="news_box" style={{ height: "150px" }}>
                <div className="news_title">
                  <h6>行业资讯</h6>
                  <Link to="/news?1" target="_blank">
                    更多&gt;
                  </Link>
                </div>
                <ul className="news_list_box">
                  {latest_news &&
                    latest_news.map((item: any) => {
                      return (
                        <Link
                          key={item.id}
                          title={item.title}
                          target="_blank"
                          to={`/news_details?${item.id}&${item.column_id}&1`}
                        >
                          <li style={{ padding: "8px 0" }}>
                            <em>NEW</em>
                            <p>{item.title}</p>
                          </li>
                        </Link>
                      );
                    })}
                </ul>
              </div>
              <div className="news_box" style={{ height: "150px" }}>
                <div className="news_title">
                  <h6>法院公告</h6>
                  <Link to="/announcement_list" target="_blank">
                    更多&gt;
                  </Link>
                </div>
                <ul className="news_list_box">
                  {latest_notice_list &&
                    latest_notice_list.map((item: any) => {
                      return (
                        <Link
                          target="_blank"
                          title={item.title}
                          key={item.id}
                          to={`/announcement?${item.id}`}
                        >
                          <li style={{ padding: "8px 0" }}>
                            <p>{item.title}</p>
                          </li>
                        </Link>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>

          <div className="home_banner_right">
            <h6 className="h6_title" style={{ marginTop: "-12px" }}>
              帮助中心
            </h6>
            <div className="home_help">
              <Link to="/help?1&id=33" className="a">
                <img src={require("./../img/process@2x.png")} alt="" />
                <p>竞价流程</p>
              </Link>
              <Link to="/help?1&id=31" className="a">
                <img src={require("./../img/rule@2x.png")} alt="" />
                <p>竞价规则</p>
              </Link>
              <Link to="/help?1&id=8" className="a">
                <img src={require("./../img/c_money.png")} alt="" />
                <p>交保证金</p>
              </Link>
              <Link to="/help?1&id=14" className="a">
                <img src={require("./../img/delivery@2x.png")} alt="" />
                <p>标的交割</p>
              </Link>
              <Link to="/help?1&id=9" className="a">
                <img src={require("./../img/c_pay.png")} alt="" />
                <p>支付限额</p>
              </Link>
              <Link to="/help?1" className="a">
                <img src={require("./../img/c_right.png")} alt="" />
                <p>查看更多</p>
              </Link>
            </div>
            <h6 className="h6_title">客服专线</h6>
            <div className="home_server_box">
              <h5>400-000-1102</h5>
              <div className="home_right_code">
                <img src={require("./../img/qrcode_for.png")} alt="" />
              </div>
              <p>扫码关注讯拍网</p>
            </div>
          </div>
        </div>

        <div className="home_process_box">
          <Link to="/help?1&id=33">
            <div className="home_process_title">竞拍流程</div>
          </Link>
          <Link to="/help?1&id=33">
            <div className="process_steps">
              <span></span>
              <p>阅读公告</p>
            </div>
          </Link>
          <RightOutlined style={{ color: "#DCE7F8" }} />
          <Link to="/help?1&id=33">
            <div className="process_steps">
              <span className="ste1"></span>
              <p>实地看样</p>
            </div>
          </Link>
          <RightOutlined style={{ color: "#DCE7F8" }} />
          <Link to="/help?1&id=33">
            <div className="process_steps">
              <span className="ste2"></span>
              <p>交保证金</p>
            </div>
          </Link>
          <RightOutlined style={{ color: "#DCE7F8" }} />
          <Link to="/help?1&id=33">
            <div className="process_steps">
              <span className="ste3"></span>
              <p>开始竞拍</p>
            </div>
          </Link>
          <RightOutlined style={{ color: "#DCE7F8" }} />
          <Link to="/help?1&id=33">
            <div className="process_steps">
              <span className="ste4"></span>
              <p>竞拍成功</p>
            </div>
          </Link>
          <RightOutlined style={{ color: "#DCE7F8" }} />
          <Link to="/help?1&id=7">
            <div className="process_steps">
              <span className="ste5"></span>
              <p>支付尾款</p>
            </div>
          </Link>
          <RightOutlined style={{ color: "#DCE7F8" }} />
          <Link to="/help?1&id=14">
            <div className="process_steps">
              <span className="ste6"></span>
              <p>办理交割</p>
            </div>
          </Link>
        </div>

        {/* 折扣房源 */}
        {
          banner.length > 0 &&
          <div className="today_title floor_home" id="jrpm">
            <h6>折扣房源</h6>
            <div className="today_onlookers">
              <Link to={`/discount?type=1`}>
                更多{">"}
              </Link>
            </div>
          </div>
        }
        <div className="discount_box">
          {
            banner.length > 0 &&
            <Swiper
              spaceBetween={50}
              slidesPerView={banner.length >= 3 ? 3 : 1}
              slidesPerGroup={3}
              navigation
              loop
            >
              {banner &&
                banner.map((item: any, index: number) => {
                  if (item.main_pc) {
                    if (item.imgs_pc.length) {
                      return (
                        <SwiperSlide className='swiperBanner'>
                          <Link to={`/banner_link?${item.index}`} target="_blank">
                            <img
                              src={`https://file.qqpai.com${item.main_pc}`}
                              alt=""
                            />
                          </Link>
                        </SwiperSlide>
                      );
                    } else {
                      return (
                        <SwiperSlide className='swiperBanner'>
                          <div>
                            <img
                              src={`https://file.qqpai.com${item.main_pc}`}
                              alt=""
                            />
                          </div>
                        </SwiperSlide>
                      );
                    }
                  }
                })}
            </Swiper>
          }
        </div>

        {/* 今日推荐 */}
        {/* <div className="today_title floor_home" id="jrpm">
          <h6>今日拍卖</h6>
          <div className="today_onlookers">
            {view_count &&
              view_count.length > 0 &&
              view_count.map((item: any, index: number) => {
                return <span key={index}>{item}</span>;
              })}
            次围观
          </div>
        </div> */}
        {/* <div className="today_auction">
          <div className="today_left">
            <div className="today_type_box" id="jr">
              <em></em> 房产 &nbsp;
              <b
                onClick={() => {
                  set_category_id("50025969");
                }}
                className={category_id === "50025969" ? "active" : ""}
              >
                住宅用房
              </b>
              <b
                onClick={() => {
                  set_category_id("200782003");
                }}
                className={category_id === "200782003" ? "active" : ""}
              >
                商业用房
              </b>
              <b
                onClick={() => {
                  set_category_id("200788003");
                }}
                className={category_id === "200788003" ? "active" : ""}
              >
                工业用房
              </b>
              <b
                onClick={() => {
                  set_category_id("200798003");
                }}
                className={category_id === "200798003" ? "active" : ""}
              >
                其他用房
              </b>
              <span>
                <Link to={`/matter_list?matterType=${category_id}`}>
                  共{today_real_total}件 &emsp; 更多{">"}
                </Link>
              </span>
            </div>
            <List notList={false} list={today_real_list} narrow={true} />

            <div className="today_type_box" id="jd">
              <em className="jidongche"></em>机动车
              <span>
                <Link to={`/matter_list?matterType=${vehicle_id}`}>
                  共{vehicle_total}件 &emsp; 更多{">"}
                </Link>
              </span>
            </div>
            <List notList={false} list={today_vehicle_list} narrow={true} />

            <div className="today_type_box" id="cq">
              <em className="qita"></em>
              产权 &nbsp;
              <b
                onClick={() => {
                  set_other_id("50025970");
                }}
                className={other_id === "50025970" ? "active" : ""}
              >
                土地
              </b>
              <b
                onClick={() => {
                  set_other_id("50025976");
                }}
                className={other_id === "50025976" ? "active" : ""}
              >
                其他
              </b>
              <span>
                <Link to={`/matter_list?matterType=${other_id}`}>
                  共{other_total}件 &emsp; 更多{">"}
                </Link>
              </span>
            </div>
            <List notList={false} list={today_other_list} narrow={true} />
          </div>
          <div className="today_advertising">
            <h6>推荐拍品</h6>
            <div className="advertising_list">
              <List notList={false} list={recommended_list} />
              <Link to="/matter_list">查看更多{">"}</Link>
            </div>
          </div>
        </div> */}

        {/* 预告 */}
        {/* <div className="trailer_box">
          <div className="today_title">
            <h6>近期预告</h6>
            <div className="today_onlookers">
              {view_count &&
                view_count.length > 0 &&
                view_count.map((item: any, index: number) => {
                  return <span key={index}>{item}</span>;
                })}
              次围观
            </div>
          </div>

          <div className="trailer_day_box floor_home" id="yg1">
            <span>
              {" "}
              <em></em> 明天
            </span>
            <div className="trailer_day_time">
              <span>开拍时间：{toDay}</span>
              <Link to={`/matter_list?time=${toDay}`}>更多{toDay_total}件</Link>
            </div>
          </div>
          <div className="trailer_day_list_box">
            {toDay_list.length > 0 && (
              <List notList={false} list={toDay_list} color={true} />
            )}
          </div>

          <div className="trailer_day_box floor_home" id="yg2">
            <span>
              {" "}
              <em></em> 后天
            </span>
            <div className="trailer_day_time">
              <span>开拍时间：{tomorrow}</span>
              <Link to={`/matter_list?time=${tomorrow}`}>
                更多{tomorrow_total}件
              </Link>
            </div>
          </div>
          <div className="trailer_day_list_box">
            {tomorrow_list.length > 0 && (
              <List notList={false} list={tomorrow_list} color={true} />
            )}
          </div>

          <div className="trailer_day_box floor_home" id="yg3">
            <span>
              {" "}
              <em></em> 周{week}
            </span>
            <div className="trailer_day_time">
              <span>开拍时间：{tomorrowDay}</span>
              <Link to={`/matter_list?time=${tomorrowDay}`}>
                更多{tomorrowDay_total}件
              </Link>
            </div>
          </div>
          <div className="trailer_day_list_box">
            {tomorrowDay_list.length > 0 && (
              <List notList={false} list={tomorrowDay_list} color={true} />
            )}
          </div>
        </div> */}

        {/* <div className="partner_list_box floor_home" id="sqpt">
          <h6 className="title_h6">人民法院授权网络司法拍卖平台</h6>
          <div id="hz1">
            <div className="partner_logo">
              <span></span>
              <Link to={`/matter_list?partner=6`}>更多{">"}</Link>
            </div>
            <List notList={false} list={rm_list}></List>
          </div>
          <div id="hz2">
            <div className="partner_logo">
              <span className="bj"></span>
              <Link to={`/matter_list?partner=3`}>更多{">"}</Link>
            </div>
            <List notList={false} list={bj_list}></List>
          </div>
          <div id="hz3">
            <div className="partner_logo">
              <span className="gs"></span>
              <Link to={`/matter_list?partner=4`}>更多{">"}</Link>
            </div>
            <List notList={false} list={gs_list}></List>
          </div>
          <div id="hz5">
            <div className="partner_logo">
              <span className="gp"></span>
              <Link to={`/matter_list?partner=5`}>更多{">"}</Link>
            </div>
            <List notList={false} list={gp_list}></List>
          </div>
          <div id="hz6">
            <div className="partner_logo">
              <span className="jd"></span>
              <Link to={`/matter_list?partner=2`}>更多{">"}</Link>
            </div>
            <List notList={false} list={jd_list}></List>
          </div>
          <div id="hz4">
            <div className="partner_logo">
              <span className="pm"></span>
              <Link to={`/matter_list?partner=7`}>更多{">"}</Link>
            </div>
            <List notList={false} list={pm_list}></List>
          </div>
        </div> */}

        {/* 合作法院 */}
        <FooterCode />
      </div>

      {/* <div className="home_floor">
        <span
          className={floor === "jrpm" ? "active" : ""}
          onClick={() => {
            scrollLocation("jrpm");
            set_floor("jrpm");
          }}
        >
          今日
          <br />
          拍卖
        </span>
        <span
          className={floor === "yg1" ? "active" : ""}
          onClick={() => {
            scrollLocation("yg1");
            set_floor("yg1");
          }}
        >
          明天
          <br />
          开拍
        </span>
        <span
          className={floor === "yg2" ? "active" : ""}
          onClick={() => {
            scrollLocation("yg2");
            set_floor("yg2");
          }}
        >
          后天
          <br />
          开拍
        </span>
        <span
          className={floor === "yg3" ? "active" : ""}
          onClick={() => {
            scrollLocation("yg3");
            set_floor("yg3");
          }}
        >
          周{week}
          <br />
          开拍
        </span>
        <span
          className={floor === "sqpt" ? "active" : ""}
          onClick={() => {
            scrollLocation("sqpt");
            set_floor("sqpt");
          }}
        >
          司拍
          <br />
          平台
        </span>
        <span
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          返回
          <br />
          顶部
        </span>
      </div> */}
      <Footer />
    </div>
  );
};

export default observer(Home);
