import React, { useState, useEffect, useRef } from "react";
import Header from "./../header";
import Footer from "./../footer";
import axios from "./../api";
import { Form, Input, Button, message } from "antd";
import { useLocation } from "react-router-dom";
import Result from "./../component/result";
import "./index.scss";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};
const tailLayout = {
  wrapperCol: { offset: 10, span: 12 },
};

let time: any = null;
const Appointment: React.FC = () => {
  const loginRef: any = useRef(null);
  const path = useLocation().pathname;
  const search = useLocation().search;
  const [phone, set_phone] = useState("");
  const [times, set_times] = useState(60);
  const [interval, set_Interval] = useState(false);
  const [result, set_result] = useState(false);
  const typeName: any = search.split("&")[0].split("=")[1].split("-")[2];

  const onFinish = async (values: any) => {
    if (!window.localStorage.getItem("user")) {
      message.warning("请登录后进行提交");
      loginRef && loginRef.current.wnLogin(path, search);
      return;
    }
    const auction_id = search.split("&")[0].split("=")[1].split("-")[0];
    const auction_type = search.split("&")[0].split("=")[1].split("-")[1];

    // consult_save 咨询
    // loan_save 贷款
    // appointment_save 看样
    let url = typeName === "1" ? axios.api.consult_save : typeName === "2" ? axios.api.appointment_save : axios.api.loan_save
    const result: any = await axios.http.post(url, {
      ...values,
      remark: values.remark || "",
      auction_id,
      auction_type,
    });

    if (result.status === 0) {
      set_result(true);
    } else {
      message.error(result.message);
    }
  };

  const getVerificationCode = async () => {
    if (!/^1[3456789]\d{9}$/.test(phone)) {
      message.error("手机号码不正确");
      return;
    }
    set_Interval(true);
    let type = typeName === "1" ? "11" : typeName === "2" ? "3" : "12"
    const result: any = axios.http.post(axios.api.get_verification_code, {
      phone,
      type,
    });
  };
  useEffect(() => {
    if (interval) {
      time = setInterval(() => {
        let num = times;
        num -= 1;
        if (num === 0) {
          num = 60;
          clearInterval(time);
          set_Interval(false);
        }
        set_times(num);
      }, 1000);
    }
    return () => clearInterval(time);
  }, [times, interval]);
  useEffect(()=>{
   const title = typeName === "1" ? "拍卖咨询" : typeName === "2" ? "预约看样" : "申请贷款";
   document.title = `${title}-讯拍网`;
  },[])
  return (
    <div>
      <Header ref={loginRef} />
      <div className="session">
        <div className="appointment_box">
          <h6>{ typeName === "1" ? "拍卖咨询" : typeName === "2" ? "预约看样" : "申请贷款"}</h6>
          {!result && (
            <Form
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item
                label="您的姓名"
                name="name"
                rules={[{ required: true, message: "姓名不能为空" }]}
              >
                <Input maxLength={16} placeholder="请输入您的姓名" />
              </Form.Item>

              <Form.Item
                label="电话号码"
                name="phone"
                rules={[
                  {
                    required: true,
                    pattern: /^1[3456789]\d{9}$/,
                    message: "电话号码不正确",
                  },
                ]}
              >
                <Input
                  maxLength={11}
                  onChange={(e) => {
                    set_phone(e.target.value);
                  }}
                  placeholder="请输入您的联系方式"
                />
              </Form.Item>

              <Form.Item
                label="验证码"
                rules={[{ required: true, message: "验证码不能为空" }]}
              >
                <Input.Group compact>
                  <Form.Item
                    rules={[{ required: true, message: "验证码不能为空" }]}
                    name="code"
                    style={{ width: "200px" }}
                  >
                    <Input
                      maxLength={6}
                      placeholder="请输入验证码"
                      style={{ width: "200px" }}
                    />
                  </Form.Item>{" "}
                  &emsp;
                  <Form.Item>
                    <Button
                      type="primary"
                      disabled={interval}
                      onClick={getVerificationCode}
                    >
                      {!interval ? "获取验证码" : `${times}s后重新获取`}
                    </Button>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item label="备注信息" name="remark">
                <Input.TextArea maxLength={500} placeholder="请输入备注信息" />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  提交
                </Button>
              </Form.Item>
            </Form>
          )}

          {result && (
            <Result
              title="提交成功"
              content="请保持电话畅通,我们将在7个工作日内与您联系"
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Appointment;
