import React, { useEffect, useState } from "react";
import Header from "./../../header";
import Footer from "./../../footer";
import List from "./../../component/list";
import { DatePicker, Select, Checkbox, InputNumber, message, Pagination, Spin } from "antd";
import axios from "./../../api";
import moment from "moment";
import 'moment/locale/zh-cn';
import data from "./../../home/data";
import { useLocation } from "react-router-dom";
import Paging from "./../../component/pagination";
import "./../../matterList/index.scss";
moment.locale('en');
const { Option }: any = Select;
interface orgType {
    org_type?: number
}

const MatterList: React.FC<orgType> = (props) => {
    const parameterId = useLocation().search;
    let categoryId = "";
    let isMatterAdd = false;
    let time = "";
    let isNew = false;
    let cityId = '';
    if (parameterId.indexOf("matterType") > -1) {
        const addSplit = parameterId.split("&");
        categoryId = addSplit[0].split("=")[1];
        isNew = addSplit[1] ? true : false;
    }
    if (parameterId.indexOf("matterAdd") > -1) {
        isMatterAdd = true;
        cityId = parameterId.split("=")[1]
    }
    if (parameterId.indexOf("time") > -1) {
        const endTime = parameterId.split("&");
        time = endTime[0].split("=")[1];
    }
    const [is_matter_add, set_is_matter_add] = useState(isMatterAdd)
    const [place_province_left, set_place_province_left] = useState(0);
    const [province_id, set_province_id] = useState(0);  //省ID
    const [city_id, set_city_id] = useState(0);  //市ID
    const [area_id, set_area_id] = useState(0);  //县区ID
    const [id_type, set_id_type] = useState('province');  //城市级别
    const [province_list, set_province_list]: any = useState([]); //省级列表
    const [city_list, set_city_list]: any = useState([]); //市级列表
    const [area_list, set_area_list]: any = useState([]); //县区列表
    const [sf_ategory_list, set_sf_ategory_list] = useState([]); //司法分类


    const [list_search, set_list_search]: any = useState({
        category_id: categoryId, //标的类型 没有时不传
        city_id: cityId,   //标的所在地
        status: "",   //标的状态 0进行中 1未开始 2已结束 3终止 4撤回
        time_type: "",  //单选开拍时间 3三天 7七天 30一月
        start_time: time,  //输入开拍起始时间 没有不传
        end_time: time,  //开拍结束时间 没有不传
        zc_type: "", //1国有资产 2普通资产 4涉案资产 5涉公资产 7.金融资产
        org_type: (props.org_type && props.org_type < 11 ? props.org_type + 1 : "") || '', //1政府机关 2银行 3AMC 4其他金融机构 5交易所 6电信运营商 7商业类 8拍卖行 9特定的处置小组（非法人单位） 10服务商 11其他
        org_group_id: (props.org_type && props.org_type > 11 ? props.org_type : "") || '', //处置单位id
        org_id: "",  //机构ID
        sell_type: "",  //拍卖阶段 1一拍 2二拍 3重新 4变卖 没有不传
        can_loan: false, //可贷款  勾选传 true  未勾选不传
        limit_purchase: false,  //是否限购 同上
        start_price: "", //价格 没有不传
        end_price: "", //价格 没有不传
        sort_type: isNew ? 4 : 1, //1默认 2价格 3次数 4最新发布 5人气
        sort_order: 1, //0升序 1降序
        page_num: 1,
        page_size: 20
    })

    const [list, set_list] = useState([]);
    const [total, set_total] = useState(0);
    const [not_list, set_not_list] = useState(false);
    const [spinning, set_spinning] = useState(false);

    // 获取城市列表
    const getCity = async () => {
        let cityId = 0;
        if (id_type === "province" || id_type === "city") {
            cityId = province_id;
        }
        if (id_type === "area") {
            cityId = city_id;
        }
        const res: any = await axios.http.newGet(`${axios.api.sub_city}?id=${cityId}`, {})
        if (res.status === 0) {
            if (id_type === "province") {
                set_province_list(res.result)
            } else if (id_type === "city") {
                set_city_list(res.result)
            } else {
                set_area_list(res.result)
            }
        }
        if (is_matter_add) {
            set_is_matter_add(false);
            const addSplit: any = parameterId.split("=");
            set_id_type("city");
            set_province_id(addSplit[1] * 1);
            provinceAdd(null, addSplit[1] * 1)
        }
    }
    // 获取司法分类
    const sfCategoryList = async () => {
        const res: any = await axios.http.newGet(axios.api.zc_category_list, {});
        if (res.status === 0) {
            set_sf_ategory_list(res.result)
        }

    }
    //省点击时位置的改变
    const provinceAdd = (e: any, id: number) => {
        let tagName = null;
        let left = 0;
        if (e) {
            tagName = e.target.tagName;
            left = e.target.offsetLeft;
        } else {
            const item: any = document.getElementById(`${id}`);
            tagName = item.tagName;
            left = item.offsetLeft
        }
        set_id_type("city");
        set_city_id(0);
        set_province_id(id);
        if (tagName === "SPAN") {
            set_place_province_left(-left + 22)
        } else {
            set_place_province_left(-left)
        }
    }

    //获取列表
    const auctionList = async () => {
        set_spinning(true)
        const queryBody: any = {};
        for (let key in list_search) {
            if ((list_search[key] || list_search[key] === 0) && list_search[key] !== -1) {
                queryBody[key] = list_search[key]
            }
        }
        const result: any = await axios.http.newGet(axios.api.zc_auction_list, { ...queryBody });
        if (result.status === 0) {
            set_not_list(result.result.data.length === 0);
            set_total(result.result.total);
            set_list(result.result.data);
            const listConditionsDom = document.getElementById("list_conditions_nav");
            const listConditionsTop: any = listConditionsDom && listConditionsDom.getBoundingClientRect().top
            if (listConditionsTop && listConditionsTop < 100) {
                const dom = document.getElementById("list_top")
                dom && dom.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            message.error(result.message)
        }
        set_spinning(false)
    }
    useEffect(() => {
        getCity();
    }, [province_id, city_id]);

    useEffect(() => {
        sfCategoryList();
    }, []);

    // 查询列表
    useEffect(() => {
        auctionList();
    }, [list_search]);

    return (
        <div >
            <ul className="list_conditions_box">
                <li>
                    <span>标的物类型</span>
                    <div className="class_list">
                        <div onClick={() => { set_list_search({ ...list_search, category_id: "", page_num: 1 }) }} style={{ width: "60px" }} className={list_search.category_id == "" ? "class_list_span active" : "class_list_span"}>
                            <span>不限</span>
                        </div>
                        <div className="class_list_multilayer_box">
                            {
                                sf_ategory_list && sf_ategory_list.map((item: any) => {
                                    return (
                                        <div onClick={() => { set_list_search({ ...list_search, category_id: `${item.id}`, page_num: 1 }) }} key={item.id} className={item.id == list_search.category_id ? "class_list_div active" : "class_list_div"}>
                                            <span >{item.name}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </li>
                <li>
                    <span>标的所在地</span>
                    <div className="class_list">
                        <div onClick={() => { set_province_id(0); set_list_search({ ...list_search, city_id: "", page_num: 1 }) }} className={province_id == 0 ? "class_list_span active" : "class_list_span"}>
                            <span>不限</span>
                        </div>
                        <div className="class_list_multilayer_box">
                            {
                                province_list && province_list.map((item: any, index: number) => {
                                    return (
                                        <div onClick={(e) => { provinceAdd(e, item.id); set_list_search({ ...list_search, city_id: `${item.id}`, page_num: 1 }) }}
                                            key={item.id}
                                            id={item.id}
                                            className={province_id == item.id ? "place_province active" : "place_province"}>
                                            <span>
                                                {item.shortname}
                                                {
                                                    province_id !== item.id && " ∨"
                                                }
                                                {
                                                    province_id == item.id && " ∧"
                                                }
                                            </span>
                                            <div className="place_province_secondary_box" style={{ left: `${place_province_left}px` }}>
                                                <div style={{ width: "80px" }}
                                                    onClick={(e) => { e.stopPropagation(); set_city_id(0); set_list_search({ ...list_search, city_id: `${item.id}`, page_num: 1 }) }}
                                                    className={city_id == 0 ? "class_list_span active" : "class_list_span"}>
                                                    <span>全省</span>
                                                </div>
                                                <div className="class_list_multilayer_box">
                                                    {
                                                        city_list && city_list.map((city: any) => {
                                                            return (
                                                                <div onClick={(e) => { e.stopPropagation(); set_id_type("area"); set_city_id(city.id); set_list_search({ ...list_search, city_id: `${city.id}`, page_num: 1 }) }}
                                                                    key={city.id}
                                                                    className={city_id == city.id ? "place_province active" : "place_province"}>
                                                                    <span>
                                                                        {city.shortname}
                                                                        {
                                                                            city_id !== city.id && " ∨"
                                                                        }
                                                                        {
                                                                            city_id == city.id && " ∧"
                                                                        }
                                                                    </span></div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        city_id !== 0 &&
                                                        <div className="class_list_multilayer_area">
                                                            <div className="place_province_secondary_box">
                                                                <div style={{ width: "80px" }}
                                                                    onClick={(e) => { e.stopPropagation(); set_area_id(0);; set_list_search({ ...list_search, city_id: `${city_id}`, page_num: 1 }) }}
                                                                    className={area_id == 0 ? "class_list_span active" : "class_list_span"}>
                                                                    <span>全部</span>
                                                                </div>
                                                                <div className="class_list_multilayer_box">
                                                                    {
                                                                        area_list && area_list.map((area: any) => {
                                                                            return (
                                                                                <div onClick={(e) => { e.stopPropagation(); set_id_type("area"); set_area_id(area.id); set_list_search({ ...list_search, city_id: `${area.id}`, page_num: 1 }) }}
                                                                                    key={area.id}
                                                                                    className={area_id == area.id ? "place_province active" : "place_province"}>
                                                                                    <span>
                                                                                        {area.shortname}
                                                                                    </span>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                </li>
                <li>
                    <span>资产类型</span>
                    <div className="class_list">
                        <div onClick={() => { set_list_search({ ...list_search, zc_type: "", page_num: 1 }) }} style={{ width: "60px" }} className={list_search.zc_type === "" ? "class_list_span active" : "class_list_span"}>
                            <span>不限</span>
                        </div>
                        <div className="class_list_multilayer_box">
                            {
                                data.zc_type && data.zc_type.map((item: any) => {
                                    return (
                                        <div onClick={() => { set_list_search({ ...list_search, zc_type: item.id, page_num: 1 }) }} key={item.id} className={item.id === list_search.zc_type ? "class_list_div active" : "class_list_div"}>
                                            <span >{item.name}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </li>
                <li>
                    <span>拍卖状态</span>
                    <div className="class_list">
                        <div onClick={() => { set_list_search({ ...list_search, status: "", page_num: 1 }) }} style={{ width: "60px" }} className={list_search.status === "" ? "class_list_span active" : "class_list_span"}>
                            <span>不限</span>
                        </div>
                        <div className="class_list_multilayer_box">
                            {
                                data.statusList && data.statusList.map((item: any) => {
                                    return (
                                        <div onClick={() => { set_list_search({ ...list_search, status: item.value, page_num: 1 }) }} key={item.id} className={item.value === list_search.status ? "class_list_div active" : "class_list_div"}>
                                            <span >{item.name}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </li>

            </ul>
            <div className="list_conditions_nav" id="list_conditions_nav">
                <div className="court_matter_number">
                    共有标的物({total})
                        </div> &emsp;
                    价格：
                    <InputNumber formatter={value => `￥${value}`} min={0} onBlur={(e: any) => { set_list_search({ ...list_search, start_price: e.target.value.slice(1), page_num: 1 }) }} />&emsp;-&emsp;
                    <InputNumber formatter={value => `￥${value}`} min={0} onBlur={(e: any) => { set_list_search({ ...list_search, end_price: e.target.value.slice(1), page_num: 1 }) }} /> &emsp;
                    <span
                    className={list_search.sort_type === 2 ? "active" : ""}
                    onClick={() => {
                        const sort_order = list_search.sort_type === 2 ? list_search.sort_order ? 0 : 1 : 0
                        set_list_search({ ...list_search, sort_type: 2, sort_order, page_num: 1 });
                    }}>
                    价格
                        {
                        list_search.sort_order && list_search.sort_type === 2 ? "↓" : "↑"
                    }
                </span> &emsp;
                    <span
                    className={list_search.sort_type === 3 ? "active" : ""}
                    onClick={() => {
                        const sort_order = list_search.sort_type === 3 ? list_search.sort_order ? 0 : 1 : 0
                        set_list_search({ ...list_search, sort_type: 3, sort_order, page_num: 1 })
                    }}>
                    出价次数
                        {
                        list_search.sort_order && list_search.sort_type === 3 ? "↓" : "↑"
                    }
                </span> &emsp;
                    
                    <span
                    className={list_search.sort_type === 5 ? "active" : ""}
                    onClick={() => {
                        const sort_order = list_search.sort_type === 5 ? list_search.sort_order ? 0 : 1 : 0
                        set_list_search({ ...list_search, sort_type: 5, sort_order, page_num: 1 })
                    }}>
                    人气
                        {
                        list_search.sort_order && list_search.sort_type === 5 ? "↓" : "↑"
                    }
                </span> &emsp;
                <span
                    className={list_search.sort_type === 4 ? "active" : ""}
                    onClick={() => {
                        set_list_search({ ...list_search, sort_type: 4, sort_order:1, page_num: 1 })
                    }}>
                    最新发布
                        
                </span> &emsp;
                    <span
                    className={list_search.sort_type === 1 ? "active" : ""}
                    onClick={() => {
                        const sort_order = list_search.sort_type === 1 ? list_search.sort_order ? 0 : 1 : 0
                        set_list_search({ ...list_search, sort_type: 1, sort_order, page_num: 1 })
                    }}>
                    默认
                    </span> &emsp;
                </div>

            <Spin tip="加载中..." spinning={spinning}>
                <List notList={not_list} list={list} link="/asset_matter_details" />
            </Spin>

            <div className="more_box">
                <Paging
                    pageSize={list_search.page_size}
                    pageSizeOptions={['20', '32', '56']}
                    current={list_search.page_num}
                    total={total}
                    onShowSizeChange={(size: number) => {
                        set_list_search({ ...list_search, page_size: size })
                    }}
                    onChange={(page: number) => {
                        set_list_search({ ...list_search, page_num: page })
                    }}
                />
            </div>
        </div>
    )
}
export default MatterList;