import React, { useState, useEffect } from "react";
import Header from "./../header";
import Footer from "./../footer";
import axios from "./../api";
import { Link, useLocation } from "react-router-dom";
import List from "./../component/list";
import data from "./../home/data";
import { InputNumber, Select, Checkbox, Pagination } from "antd";
import Paging from "./../component/pagination";
import './index.scss';

const { Option } = Select;

const CourtList = () => {
    const id = useLocation().search;
    const [place_province_left, set_place_province_left] = useState(0);
    const [province_id, set_province_id] = useState(0);  //省ID
    const [city_id, set_city_id] = useState(0);  //市ID
    const [area_id, set_area_id] = useState(0);  //县区ID
    const [id_type, set_id_type] = useState('province');  //城市级别
    const [province_list, set_province_list]: any = useState([]); //省级列表
    const [city_list, set_city_list]: any = useState([]); //市级列表
    const [area_list, set_area_list]: any = useState([]); //县区列表
    const [sf_ategory_list, set_sf_ategory_list] = useState([]); //司法分类
    const [court_count, set_court_count] = useState({
        auction: 0,
        notice: 0,
        title: ""
    })
    const [sf_id, set_sf_id] = useState(0);
    const [is_show_select, set_is_show_select] = useState(false);

    const [total, set_total] = useState(0);


    const [list_search, set_list_search]: any = useState({
        category_id: "", //标的类型 没有时不传
        city_id: "",   //标的所在地
        status: "",   //标的状态 0进行中 1未开始 2已结束 3终止 4撤回
        time_type: "",  //单选开拍时间 3三天 7七天 30一月
        start_time: "",  //输入开拍起始时间 没有不传
        end_time: "",  //开拍结束时间 没有不传
        court_id: id.slice(1),  //法院ID
        sell_type: "",  //拍卖阶段 1一拍 2二拍 3重新 4变卖 没有不传
        can_loan: false, //可贷款  勾选传 true  未勾选不传
        limit_purchase: false,  //是否限购 同上
        start_price: "", //价格 没有不传
        end_price: "", //价格 没有不传
        sort_type: 1, //1默认 2价格 3次数
        sort_order: 0, //0升序 1降序
        page_num: 1,
        page_size: 20
    })

    const [list, set_list] = useState([]);
    const [not_list, set_not_list] = useState(false)

    // 获取城市列表
    const getCity = async () => {
        let cityId = 0;
        if (id_type == "province" || id_type == "city") {
            cityId = province_id;
        }
        if (id_type == "area") {
            cityId = city_id;
        }
        const res: any = await axios.http.newGet(`${axios.api.sub_city}?id=${cityId}`, {})
        if (res.status === 0) {
            if (id_type === "province") {
                set_province_list(res.result)
            } else if (id_type == "city") {
                set_city_list(res.result)
            } else {
                set_area_list(res.result)
            }

        }
    }
    // 获取司法分类
    const sfCategoryList = async () => {
        const res: any = await axios.http.newGet(axios.api.sf_category_list, {});
        if (res.status === 0) {
            set_sf_ategory_list(res.result)
        }

    }
    //省点击时位置的改变
    const provinceAdd = (e: any, id: number) => {
        const tagName = e.target.tagName;
        const left = e.target.offsetLeft
        set_id_type("city");
        set_city_id(0);
        set_province_id(id);
        if (tagName == "SPAN") {
            set_place_province_left(-left + 22)
        } else {
            set_place_province_left(-left)
        }
    }
    //获取列表
    const auctionList = async () => {
        const queryBody: any = {};
        for (let key in list_search) {
            if ((list_search[key] || list_search[key] === 0) && list_search[key] !== -1) {
                queryBody[key] = list_search[key]
            }
        }
        const result: any = await axios.http.newGet(axios.api.auction_list, { ...queryBody });

        if (result.status == 0) {
            let newData = result.result.data;
            set_not_list(newData.length === 0);
            set_total(result.result.total);
            set_list(newData);
            const dom = document.getElementById("list_top")
            dom && dom.scrollIntoView({ behavior: "smooth" });
        }
    }
    // 获取法院信息
    const courtCount = async () => {
        const result: any = await axios.http.newGet(`${axios.api.court_count}`, { court_id: id.slice(1) })
        if (result.status == 0) {
            set_court_count(result.result)
            document.title = `${result.result.title}标的物列表-讯拍网`
        }
    }
    useEffect(() => {
        getCity();
    }, [province_id, city_id]);
    useEffect(() => {
        sfCategoryList();
        courtCount();
    }, []);
    useEffect(() => {
        auctionList();
    }, [list_search])
    return (
        <div id="list_top">
            <Header />
            <div className="session">
                <div className="court_mark_list_box">
                    <div className="court_title">
                        <h6>
                            {court_count.title}
                            <em></em>
                        </h6>
                        <p>
                            拍卖公告 <span>{court_count.notice}</span>&emsp;|&emsp;
                            上拍次数 <span>{court_count.auction}</span>
                        </p>
                        <div className="court_type">
                            <Link to={`/court_list${id}`} className="court_type_nav active">
                                法院标的物
                            </Link>
                            <Link to={`/court_announcement${id}`} className="court_type_nav">
                                法院公告
                            </Link>
                        </div>
                    </div>

                    <ul className="list_conditions_box">
                        <li>
                            <span>标的物类型</span>
                            <div className="class_list">
                                <div onClick={() => { set_list_search({ ...list_search, category_id: "", page_num: 1 }) }} style={{ width: "60px" }} className={list_search.category_id == "" ? "class_list_span active" : "class_list_span"}>
                                    <span>不限</span>
                                </div>
                                <div className="class_list_multilayer_box">
                                    {
                                        sf_ategory_list && sf_ategory_list.map((item: any) => {
                                            return (
                                                <div onClick={() => { set_list_search({ ...list_search, category_id: `${item.id}`, page_num: 1 }) }} key={item.id} className={item.id == list_search.category_id ? "class_list_div active" : "class_list_div"}>
                                                    <span >{item.name}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </li>
                        <li>
                            <span>标的所在地</span>
                            <div className="class_list">
                                <div onClick={() => { set_province_id(0); set_list_search({ ...list_search, city_id: "", page_num: 1 }) }} className={province_id == 0 ? "class_list_span active" : "class_list_span"}>
                                    <span>不限</span>
                                </div>
                                <div className="class_list_multilayer_box">
                                    {
                                        province_list && province_list.map((item: any, index: number) => {
                                            return (
                                                <div onClick={(e) => { provinceAdd(e, item.id); set_list_search({ ...list_search, city_id: `${item.id}`, page_num: 1 }) }}
                                                    key={item.id}
                                                    id={item.id}
                                                    className={province_id == item.id ? "place_province active" : "place_province"}>
                                                    <span>
                                                        {item.shortname}
                                                        {
                                                            province_id !== item.id && " ∨"
                                                        }
                                                        {
                                                            province_id == item.id && " ∧"
                                                        }
                                                    </span>
                                                    <div className="place_province_secondary_box" style={{ left: `${place_province_left}px` }}>
                                                        <div style={{ width: "80px" }}
                                                            onClick={(e) => { e.stopPropagation(); set_city_id(0); set_list_search({ ...list_search, city_id: `${item.id}`, page_num: 1 }) }}
                                                            className={city_id == 0 ? "class_list_span active" : "class_list_span"}>
                                                            <span>全省</span>
                                                        </div>
                                                        <div className="class_list_multilayer_box">
                                                            {
                                                                city_list && city_list.map((city: any) => {
                                                                    return (
                                                                        <div onClick={(e) => { e.stopPropagation(); set_id_type("area"); set_city_id(city.id); set_list_search({ ...list_search, city_id: `${city.id}`, page_num: 1 }) }}
                                                                            key={city.id}
                                                                            className={city_id == city.id ? "place_province active" : "place_province"}>
                                                                            <span>
                                                                                {city.shortname}
                                                                                {
                                                                                    city_id !== city.id && " ∨"
                                                                                }
                                                                                {
                                                                                    city_id == city.id && " ∧"
                                                                                }
                                                                            </span></div>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                city_id !== 0 &&
                                                                <div className="class_list_multilayer_area">
                                                                    <div className="place_province_secondary_box">
                                                                        <div style={{ width: "80px" }}
                                                                            onClick={(e) => { e.stopPropagation(); set_area_id(0);; set_list_search({ ...list_search, city_id: `${city_id}`, page_num: 1 }) }}
                                                                            className={area_id == 0 ? "class_list_span active" : "class_list_span"}>
                                                                            <span>全部</span>
                                                                        </div>
                                                                        <div className="class_list_multilayer_box">
                                                                            {
                                                                                area_list && area_list.map((area: any) => {
                                                                                    return (
                                                                                        <div onClick={(e) => { e.stopPropagation(); set_id_type("area"); set_area_id(area.id); set_list_search({ ...list_search, city_id: `${area.id}`, page_num: 1 }) }}
                                                                                            key={area.id}
                                                                                            className={area_id == area.id ? "place_province active" : "place_province"}>
                                                                                            <span>
                                                                                                {area.shortname}
                                                                                            </span>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </div>
                        </li>
                        <li>
                            <span>拍卖状态</span>
                            <div className="class_list">
                                <div
                                    onClick={() => { set_list_search({ ...list_search, status: "", page_num: 1 }) }}
                                    className={list_search.status === "" ? "class_list_span active" : "class_list_span"}>
                                    <span>不限</span>
                                </div>
                                {
                                    data.statusList.map((item: any) => {
                                        return (
                                            <div
                                                onClick={() => { set_list_search({ ...list_search, status: item.value, page_num: 1 }) }}
                                                key={item.value} className={list_search.status === item.value ? "class_list_div active" : "class_list_div"}>
                                                <span>{item.name}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </li>
                    </ul>


                    <div className="list_conditions_nav">
                        <div className="court_matter_number">
                            共有标的物({total})
                        </div>

                        价格：
                    <InputNumber formatter={value => `￥${value}`} min={0} onBlur={(e: any) => { set_list_search({ ...list_search, start_price: e.target.value.slice(1), page_num: 1 }) }} />&emsp;-&emsp;
                    <InputNumber formatter={value => `￥${value}`} min={0} onBlur={(e: any) => { set_list_search({ ...list_search, end_price: e.target.value.slice(1), page_num: 1 }) }} /> &emsp;

                        <Checkbox.Group
                            onChange={(value) => {
                                let can_loan = false;
                                if (value.length) {
                                    can_loan = true;
                                }
                                set_list_search({ ...list_search, can_loan, page_num: 1 })
                            }}
                        >
                            <Checkbox value={1}>可贷款</Checkbox>
                        </Checkbox.Group>
                        {/* <Checkbox.Group
                            onChange={(value) => {
                                let limit_purchase = false;
                                if (value.length) {
                                    limit_purchase = true;
                                }
                                set_list_search({ ...list_search, limit_purchase, page_num: 1 })
                            }}
                        >
                            <Checkbox value={1}>不限购</Checkbox>
                        </Checkbox.Group> */}
                        <span
                            className={list_search.sort_type === 2 ? "active" : ""}
                            onClick={() => {
                                const sort_order = list_search.sort_type === 2 ? list_search.sort_order ? 0 : 1 : 0
                                set_list_search({ ...list_search, sort_type: 2, sort_order, page_num: 1 });
                            }}>
                            价格
                        {
                                list_search.sort_order && list_search.sort_type === 2 ? "↓" : "↑"
                            }
                        </span> &emsp;
                    <span
                            className={list_search.sort_type === 3 ? "active" : ""}
                            onClick={() => {
                                const sort_order = list_search.sort_type === 3 ? list_search.sort_order ? 0 : 1 : 0
                                set_list_search({ ...list_search, sort_type: 3, sort_order, page_num: 1 })
                            }}>
                            出价次数
                        {
                                list_search.sort_order && list_search.sort_type === 3 ? "↓" : "↑"
                            }
                        </span> &emsp;
                    <span
                            className={list_search.sort_type === 1 ? "active" : ""}
                            onClick={() => {
                                const sort_order = list_search.sort_type === 1 ? list_search.sort_order ? 0 : 1 : 0
                                set_list_search({ ...list_search, sort_type: 1, sort_order, page_num: 1 })
                            }}>
                            默认
                        </span> &emsp;
                </div>



                    <div className="court_list">
                        <List notList={not_list} list={list} />
                    </div>

                    <div className="more_box">
                        <Paging
                            pageSize={list_search.page_size}
                            pageSizeOptions={['20', '32', '56']}
                            current={list_search.page_num}
                            total={total}
                            onShowSizeChange={(size: number) => {
                                set_list_search({ ...list_search, page_size: size })
                            }}
                            onChange={(page: number) => {
                                set_list_search({ ...list_search, page_num: page })
                            }}
                        />
                        {/* <Pagination showQuickJumper
                            hideOnSinglePage={true}
                            pageSize={list_search.page_size}
                            pageSizeOptions={['20', '32', '56']}
                            onShowSizeChange={(current, size) => {
                                set_list_search({ ...list_search, page_size: size })
                            }}
                            current={list_search.page_num} total={total}
                            onChange={(page, pageSize) => {
                                set_list_search({ ...list_search, page_num: page, page_size: pageSize })
                            }} /> */}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CourtList;
