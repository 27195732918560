import React from "react";
const User: React.FC = () => {
    const user = window.localStorage.getItem("user");
    return (
        <div className="user_box">
            <p>昵称：{user && JSON.parse(user).nick_name}</p>
            <p>注册时间：{user && JSON.parse(user).create_time}</p>
        </div>
    )
}
export default User;