import React, { useEffect, useState } from "react";
import Header from "./../header";
import Footer from "./../footer";
import List from "./../component/list";
import { DatePicker, Select, Checkbox, InputNumber, message, Pagination, Spin } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import axios from "./../api";
import moment from "moment";
import 'moment/locale/zh-cn';
import imgType from "./../img/jigou@2x.png";
import data from "./../home/data";
import { useLocation } from "react-router-dom";
import Paging from "./../component/pagination";
import "./index.scss";
moment.locale('en');
const { Option }: any = Select;

const other_content: any = {
    "1001": {
        name: "同伦拍拍科技服务有限公司",
        content: "同伦拍拍提供专业化的拍卖辅助服务和多元化的科技金融服务，由从事司法拍卖辅助服务及金融服务的精英团队联合打造，借助移动互联网和大数据技术，整合法院、处置机构和投资方，通过线上线下多渠道推广，以法院及金融机构网拍资产及投资人信息库为核心价值，建立特殊资产服务平台。"
    },
    "1002": {
        name: "创维智通(北京)网络科技有限公司",
        content: "创维互动是一家专注于中国一线城市不良资产不动产类的投资、管理、处置为一体的专业性投资管理公司，一直秉承着“信誉为本---服务是根”的理念，员工奉行“诚信---求实---严谨---专业”为客户提供优质服务。"
    },
    "1003": {
        name: "杭州易槌科技有限公司",
        content: "一家为资产交易提供全流程服务的第三方服务商。以网络司法拍卖辅助服务为切入口，覆盖资产交易全流程，打造开放的资产交易服务生态链。在网络司法拍卖领域，通过规范化辅助服务，致力于降低法院执行拍卖财产的处置成本，让法院更好的履行管理与监督职能，使专业更专注。"
    },
    "1004": {
        name: "杭州助拍信息技术有限公司",
        content: "杭州助拍信息技术有限公司专注于不良资产行业服务，在发展过程中逐渐聚合了来自于AMC、互联网和拍卖行业的专业人才，围绕不良资产信息结构化，估值模型，交易配套服务等方面开展业务，运用互联网思维加上专业能力提高不良资产流通性，主要业务板块分为不良资产IT解决方案、资产处置服务和金融配资服务。"
    },
    "1005": {
        name: "杭州来拍网络科技有限公司",
        content: "杭州来拍网络科技有限公司是国内首家网络司法拍卖辅助服务机构。近年来，来拍法服不断探索辅助工作的最大可能，协助法院构建基本解决执行难长效机制，实现司法网拍“一切都在阳光下”。"
    },
    "1006": {
        name: "展唐通讯科技(上海)股份有限公司",
        content: "展唐通讯科技是我国司法辅助、资产交易相关业务领军企业，由长期从事司法工作的高管团队负责运营，主营司法拍卖辅助业务、各类资产互联网线上交易的线下辅助业务、相关的金融服务以及资产升值等服务。"
    },
    "1007": {
        name: "万立金融咨询服务有限公司",
        content: "万立，不只是房产拍卖，还有土地，厂房拍卖可贷款，可以说是在不动产方面是比较全面的。不管您是想拥有自己的房子，还是投资，还是想处置资产，找万立是靠谱的。"
    },
    "1008": {
        name: "深圳能拍网络科技有限公司",
        content: "深圳能拍网络科技有限公司是全国首批以网络司法拍卖辅助工作为核心，同时能为法院提供执行全流程辅助服务、卷宗档案管理服务、文书通知送达服务等多项服务的专业司法辅助机构。"
    },
    "0": {
        name: "中环来拍",
        content: "四川中环来拍网络科技有限公司是中国最大网络司法拍卖线下辅助机构来拍法服四川地区服务商，专注于川内施行司法网拍的人民法院提供完整的司法拍卖辅助服务。公司主要业务是接受法院委托，承办网络司法拍卖事项，目前已入驻成都市中级人民法院、成都铁路运输中级法院、宜宾市中级人民法院、成都市金牛区人民法院、成都铁路运输法院、成都市青羊区人民法院等三十余家法院。"
    },
}
const Auxiliary = () => {
    const parameterId = useLocation().search;
    let categoryId = "";
    let isMatterAdd = false;
    let cityId = "";
    let time = "";
    let dataFrom = "";
    let indexNumber = parameterId.split("=")[1];
    if (indexNumber === "0") {
        isMatterAdd = true;
        cityId = "510000"
    }

    const [is_matter_add, set_is_matter_add] = useState(isMatterAdd)
    const [place_province_left, set_place_province_left] = useState(0);
    const [province_id, set_province_id] = useState(0);  //省ID
    const [city_id, set_city_id] = useState(0);  //市ID
    const [area_id, set_area_id] = useState(0);  //县区ID
    const [id_type, set_id_type] = useState('province');  //城市级别
    const [province_list, set_province_list]: any = useState([]); //省级列表
    const [city_list, set_city_list]: any = useState([]); //市级列表
    const [area_list, set_area_list]: any = useState([]); //县区列表
    const [sf_ategory_list, set_sf_ategory_list] = useState([]); //司法分类
    const [sf_home_id, set_sf_home_id] = useState(0);
    const [sf_home_city_id, set_sf_home_city_id] = useState(0);
    const [sf_home_city_list, set_sf_home_city_list] = useState([]);
    const [select_court_list, set_select_court_list] = useState([]);
    const [select_court, set_select_court]: any = useState({
        name: "请选择处置法院",
        id: ""
    })
    const [is_show_select, set_is_show_select] = useState(false);
    const [list_search, set_list_search]: any = useState({
        category_id: categoryId, //标的类型 没有时不传
        city_id: cityId,   //标的所在地
        status: "",   //标的状态 0进行中 1未开始 2已结束 3终止 4撤回
        time_type: "",  //单选开拍时间 3三天 7七天 30一月
        start_time: time,  //输入开拍起始时间 没有不传
        end_time: time,  //开拍结束时间 没有不传
        court_id: "",  //法院ID
        sell_type: "",  //拍卖阶段 1一拍 2二拍 3重新 4变卖 没有不传
        can_loan: false, //可贷款  勾选传 true  未勾选不传
        limit_purchase: false,  //是否限购 同上
        start_price: "", //价格 没有不传
        end_price: "", //价格 没有不传
        sort_type: 1, //1默认 2价格 3次数
        sort_order: 0, //0升序 1降序
        data_from: dataFrom, //司法拍卖平台
        page_num: 1,
        page_size: 20,
        auxiliary_id: indexNumber
    })

    const [list, set_list] = useState([]);
    const [total, set_total] = useState(0);
    const [not_list, set_not_list] = useState(false);
    const [spinning, set_spinning] = useState(false);

    // 获取城市列表
    const getCity = async () => {
        let cityId = 0;
        if (id_type == "province" || id_type == "city") {
            cityId = province_id;
        }
        if (id_type == "area") {
            cityId = city_id;
        }
        const res: any = await axios.http.newGet(`${axios.api.sub_city}?id=${cityId}`, {})
        if (res.status === 0) {
            if (id_type === "province") {
                set_province_list(res.result)
            } else if (id_type == "city") {
                set_city_list(res.result)
            } else {
                set_area_list(res.result)
            }
        }
        if (is_matter_add) {
            set_is_matter_add(false);
            set_id_type("city");
            set_province_id(510000);
            provinceAdd(null, 510000)
        }


    }
    // 获取司法分类
    const sfCategoryList = async () => {
        const res: any = await axios.http.newGet(axios.api.sf_category_list, {});
        if (res.status === 0) {
            set_sf_ategory_list(res.result)
        }

    }
    //省点击时位置的改变
    const provinceAdd = (e: any, id: number) => {
        let tagName = null;
        let left = 0;
        if (e) {
            tagName = e.target.tagName;
            left = e.target.offsetLeft;
        } else {
            const item: any = document.getElementById(`${id}`);
            tagName = item.tagName;
            left = item.offsetLeft
        }
        set_id_type("city");
        set_city_id(0);
        set_province_id(id);
        if (tagName == "SPAN") {
            set_place_province_left(-left + 22)
        } else {
            set_place_province_left(-left)
        }
    }
    // 时间改变事件
    const timeChange = (data: any, dataStr: any, type: string) => {
        set_list_search({ ...list_search, [type]: dataStr, time_type: "", page_num: 1 });
        if ((!list_search.end_time || !list_search.start_time) && !dataStr) {
            set_list_search({ ...list_search, [type]: dataStr, time_type: "", page_num: 1 })
        } else {
            set_list_search({ ...list_search, [type]: dataStr, time_type: -1, page_num: 1 })
        }
    }
    //获取列表
    const auctionList = async () => {
        set_spinning(true)
        const queryBody: any = {};
        for (let key in list_search) {
            if ((list_search[key] || list_search[key] === 0) && list_search[key] !== -1) {
                queryBody[key] = list_search[key]
            }
        }
        const result: any = await axios.http.newGet(axios.api.auction_list, { ...queryBody });
        if (result.status === 0) {
            set_not_list(result.result.data.length === 0);
            set_total(result.result.total);
            set_list(result.result.data);
            const listConditionsDom = document.getElementById("list_conditions_nav");
            const listConditionsTop: any = listConditionsDom && listConditionsDom.getBoundingClientRect().top
            if (listConditionsTop && listConditionsTop < 0) {
                const dom = document.getElementById("list_top")
                dom && dom.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            message.error(result.message)
        }
        set_spinning(false)
    }
    // 法院获取城市列表
    const sfCity = async () => {
        if (!sf_home_id) return;
        const res: any = await axios.http.newGet(`${axios.api.sub_city}?id=${sf_home_id}`, {});
        if (res.status === 0) {
            set_sf_home_city_list(res.result);
        }
    }
    // 法院所在地
    const findByCity = async () => {
        set_select_court_list([]);
        if (!sf_home_id) {
            return;
        }
        const res: any = await axios.http.newGet(`${axios.api.find_by_city}?id=${sf_home_city_id ? sf_home_city_id : sf_home_id}`, {});
        if (res.status === 0) {
            set_select_court_list(res.result);
        }
    }
    useEffect(() => {
        getCity();
    }, [province_id, city_id]);

    useEffect(() => {
        sfCategoryList();
    }, []);

    // 查询列表
    useEffect(() => {
        auctionList();
    }, [list_search]);

    // 获取法院所在地
    useEffect(() => {
        findByCity();
        sfCity();
    }, [sf_home_id, sf_home_city_id]);
    return (
        <div id="list_top">
            <Header />
            <div className="session" >
                <div className="auxiliary_box">
                    <div className="asset_type_bg_introduce">
                        <div className="asset_type_left">
                            <img src={imgType} alt="" />
                            <h3>{other_content[indexNumber].name}</h3>
                        </div>
                        <div className="asset_type_right">
                            <p>
                                {other_content[indexNumber].content}
                            </p>
                        </div>
                    </div>
                </div>

                <ul className="list_conditions_box">
                    <li>
                        <span>标的物类型</span>
                        <div className="class_list">
                            <div onClick={() => { set_list_search({ ...list_search, category_id: "", page_num: 1 }) }} style={{ width: "60px" }} className={list_search.category_id == "" ? "class_list_span active" : "class_list_span"}>
                                <span>不限</span>
                            </div>
                            <div className="class_list_multilayer_box">
                                {
                                    sf_ategory_list && sf_ategory_list.map((item: any) => {
                                        return (
                                            <div onClick={() => { set_list_search({ ...list_search, category_id: `${item.id}`, page_num: 1 }) }} key={item.id} className={item.id == list_search.category_id ? "class_list_div active" : "class_list_div"}>
                                                <span >{item.name}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </li>
                    <li>
                        <span>标的所在地</span>
                        <div className="class_list">
                            <div onClick={() => { set_province_id(0); set_list_search({ ...list_search, city_id: "", page_num: 1 }) }} className={province_id == 0 ? "class_list_span active" : "class_list_span"}>
                                <span>不限</span>
                            </div>
                            <div className="class_list_multilayer_box">
                                {
                                    province_list && province_list.map((item: any, index: number) => {
                                        return (
                                            <div onClick={(e) => { provinceAdd(e, item.id); set_list_search({ ...list_search, city_id: `${item.id}`, page_num: 1 }) }}
                                                key={item.id}
                                                id={item.id}
                                                className={province_id == item.id ? "place_province active" : "place_province"}>
                                                <span>
                                                    {item.shortname}
                                                    {
                                                        province_id !== item.id && " ∨"
                                                    }
                                                    {
                                                        province_id == item.id && " ∧"
                                                    }
                                                </span>
                                                <div className="place_province_secondary_box" style={{ left: `${place_province_left}px` }}>
                                                    <div style={{ width: "80px" }}
                                                        onClick={(e) => { e.stopPropagation(); set_city_id(0); set_list_search({ ...list_search, city_id: `${item.id}`, page_num: 1 }) }}
                                                        className={city_id == 0 ? "class_list_span active" : "class_list_span"}>
                                                        <span>全省</span>
                                                    </div>
                                                    <div className="class_list_multilayer_box">
                                                        {
                                                            city_list && city_list.map((city: any) => {
                                                                return (
                                                                    <div onClick={(e) => { e.stopPropagation(); set_id_type("area"); set_city_id(city.id); set_list_search({ ...list_search, city_id: `${city.id}`, page_num: 1 }) }}
                                                                        key={city.id}
                                                                        className={city_id == city.id ? "place_province active" : "place_province"}>
                                                                        <span>
                                                                            {city.shortname}
                                                                            {
                                                                                city_id !== city.id && " ∨"
                                                                            }
                                                                            {
                                                                                city_id == city.id && " ∧"
                                                                            }
                                                                        </span></div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            city_id !== 0 &&
                                                            <div className="class_list_multilayer_area">
                                                                <div className="place_province_secondary_box">
                                                                    <div style={{ width: "80px" }}
                                                                        onClick={(e) => { e.stopPropagation(); set_area_id(0);; set_list_search({ ...list_search, city_id: `${city_id}`, page_num: 1 }) }}
                                                                        className={area_id == 0 ? "class_list_span active" : "class_list_span"}>
                                                                        <span>全部</span>
                                                                    </div>
                                                                    <div className="class_list_multilayer_box">
                                                                        {
                                                                            area_list && area_list.map((area: any) => {
                                                                                return (
                                                                                    <div onClick={(e) => { e.stopPropagation(); set_id_type("area"); set_area_id(area.id); set_list_search({ ...list_search, city_id: `${area.id}`, page_num: 1 }) }}
                                                                                        key={area.id}
                                                                                        className={area_id == area.id ? "place_province active" : "place_province"}>
                                                                                        <span>
                                                                                            {area.shortname}
                                                                                        </span>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                    </li>
                    <li>
                        <span>拍卖状态</span>
                        <div className="class_list">
                            <div
                                onClick={() => { set_list_search({ ...list_search, status: "", page_num: 1 }) }}
                                className={list_search.status === "" ? "class_list_span active" : "class_list_span"}>
                                <span>不限</span>
                            </div>
                            {
                                data.statusList.map((item: any) => {
                                    return (
                                        <div
                                            onClick={() => { set_list_search({ ...list_search, status: item.value, page_num: 1 }) }}
                                            key={item.value} className={list_search.status === item.value ? "class_list_div active" : "class_list_div"}>
                                            <span>{item.name}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </li>
                    <li>
                        <span>开拍时间</span>
                        <div className="class_list">
                            <div
                                onClick={() => { set_list_search({ ...list_search, time_type: "", start_time: "", end_time: "", page_num: 1 }) }}
                                className={list_search.time_type === "" ? "class_list_span active" : "class_list_span"}>
                                <span>不限</span>
                            </div>
                            <div
                                onClick={() => { set_list_search({ ...list_search, time_type: 3, start_time: "", end_time: "", page_num: 1 }) }}
                                className={list_search.time_type === 3 ? "class_list_div active" : "class_list_div"}>
                                <span>最近3天</span>
                            </div>
                            <div onClick={() => { set_list_search({ ...list_search, time_type: 7, start_time: "", end_time: "", page_num: 1 }) }}
                                className={list_search.time_type === 7 ? "class_list_div active" : "class_list_div"}>
                                <span>最近7天</span>
                            </div>
                            <div onClick={() => { set_list_search({ ...list_search, time_type: 30, start_time: "", end_time: "", page_num: 1 }) }}
                                className={list_search.time_type === 30 ? "class_list_div active" : "class_list_div"}>
                                <span>最近30天</span>
                            </div>

                            <DatePicker
                                value={list_search.start_time ? moment(list_search.start_time, "YYYY-MM-DD") : null}
                                onChange={(data: any, dataStr: any) => { timeChange(data, dataStr, "start_time") }} placeholder="开始时间" /> &emsp;
                            <DatePicker
                                value={list_search.end_time ? moment(list_search.end_time, "YYYY-MM-DD") : null}
                                onChange={(data: any, dataStr: any) => { timeChange(data, dataStr, "end_time") }} placeholder="结束时间" />
                        </div>
                    </li>
                </ul>
                <div className="list_conditions_nav" id="list_conditions_nav">
                    <div className="court_matter_number">
                        拍卖/变卖({total})
                        </div> &emsp;
                    <div className="disposal_court">
                        <Select placeholder="选择处置法院" disabled={true} value={select_court.id} onClick={() => { set_is_show_select(true) }}>
                            <Option value={select_court.id}>{select_court.name}</Option>
                        </Select>
                        {
                            is_show_select && <div className="select_court_box">
                                <div>
                                    法院所在地：
                                    <Select placeholder="选择法院所在地" value={sf_home_id} onChange={(value: number) => { set_sf_home_id(value); set_sf_home_city_id(0) }}>
                                        <Option value={0}>请选择</Option>
                                        {
                                            province_list && province_list.map((city: any) => {
                                                return (
                                                    <Option key={`op2${city.id}`} value={city.id}>{city.shortname}</Option>
                                                )
                                            })
                                        }
                                    </Select> &emsp;

                                    <Select placeholder="选择城市" value={sf_home_city_id} onChange={(value: number) => { set_sf_home_city_id(value) }}>
                                        <Option value={0}>全部</Option>
                                        {sf_home_city_list && sf_home_city_list.map((city: any) => {
                                            return (
                                                <Option key={`op${city.id}`} value={city.id}>{city.shortname}</Option>
                                            )
                                        })}
                                    </Select>

                                    <CloseOutlined onClick={() => { set_is_show_select(false) }} />
                                </div>
                                {
                                    select_court_list && select_court_list.length > 0 &&
                                    <div className="select_court_list">
                                        <div>
                                            <span
                                                className={select_court.id == "" ? "active" : ""}
                                                onClick={() => { set_select_court({ name: "选择处置法院", id: "" }); set_list_search({ ...list_search, court_id: "", page_num: 1 }) }}>清除</span>
                                        </div>
                                        {
                                            select_court_list && select_court_list.map((item: any, index: number) => {
                                                const styleName = item.name.length > 20 ? "len" : "";
                                                const active = select_court.id == item.id ? `active ${styleName}` : `${styleName}`;
                                                return (
                                                    <span key={item.id}
                                                        className={active}
                                                        onClick={() => { set_select_court(item); set_is_show_select(false); set_list_search({ ...list_search, court_id: item.id, page_num: 1 }) }}>
                                                        {item.name}
                                                    </span>
                                                )
                                            })
                                        }
                                    </div>
                                }

                            </div>
                        }

                    </div>

                    拍卖阶段：
                    <Select
                        style={{ width: "100px" }}
                        value={list_search.sell_type} onChange={(value) => { set_list_search({ ...list_search, sell_type: value, page_num: 1 }) }}>
                        <Option value="">全部</Option>
                        <Option value={1}>一拍</Option>
                        <Option value={2}>二拍</Option>
                        <Option value={3}>重新拍卖</Option>
                        <Option value={4}>变卖</Option>
                    </Select> &emsp;

                    <Checkbox.Group
                        onChange={(value) => {
                            let can_loan = false;
                            if (value.length) {
                                can_loan = true;
                            }
                            set_list_search({ ...list_search, can_loan, page_num: 1 })
                        }}
                    >
                        <Checkbox value={1}>可贷款</Checkbox>
                    </Checkbox.Group>
                    {/* <Checkbox.Group
                        onChange={(value) => {
                            let limit_purchase = false;
                            if (value.length) {
                                limit_purchase = true;
                            }
                            set_list_search({ ...list_search, limit_purchase, page_num: 1 })
                        }}
                    >
                        <Checkbox value={1}>不限购</Checkbox>
                    </Checkbox.Group> */}
                    价格：
                    <InputNumber formatter={value => `￥${value}`} min={0} onBlur={(e: any) => { set_list_search({ ...list_search, start_price: e.target.value.slice(1), page_num: 1 }) }} />&emsp;-&emsp;
                    <InputNumber formatter={value => `￥${value}`} min={0} onBlur={(e: any) => { set_list_search({ ...list_search, end_price: e.target.value.slice(1), page_num: 1 }) }} /> &emsp;
                    <span
                        className={list_search.sort_type === 2 ? "active" : ""}
                        onClick={() => {
                            const sort_order = list_search.sort_type === 2 ? list_search.sort_order ? 0 : 1 : 0
                            set_list_search({ ...list_search, sort_type: 2, sort_order, page_num: 1 });
                        }}>
                        价格
                        {
                            list_search.sort_order && list_search.sort_type === 2 ? "↓" : "↑"
                        }
                    </span> &emsp;
                    <span
                        className={list_search.sort_type === 3 ? "active" : ""}
                        onClick={() => {
                            const sort_order = list_search.sort_type === 3 ? list_search.sort_order ? 0 : 1 : 0
                            set_list_search({ ...list_search, sort_type: 3, sort_order, page_num: 1 })
                        }}>
                        出价次数
                        {
                            list_search.sort_order && list_search.sort_type === 3 ? "↓" : "↑"
                        }
                    </span> &emsp;
                    <span
                        className={list_search.sort_type === 1 ? "active" : ""}
                        onClick={() => {
                            const sort_order = list_search.sort_type === 1 ? list_search.sort_order ? 0 : 1 : 0;
                            set_list_search({ ...list_search, sort_type: 1, sort_order, page_num: 1 })
                        }}>
                        默认
                    </span> &emsp;
                </div>

                <Spin tip="加载中..." spinning={spinning}>
                    <List notList={not_list} list={list} />
                </Spin>



                <div className="more_box">
                    <Paging
                        pageSize={list_search.page_size}
                        current={list_search.page_num}
                        pageSizeOptions={['20', '32', '56']}
                        total={total}
                        onShowSizeChange={(size: number) => {
                            set_list_search({ ...list_search, page_size: size })
                        }}
                        onChange={(page: number) => {
                            set_list_search({ ...list_search, page_num: page })
                        }}
                    />
                </div>
            </div>

            <Footer />
        </div>
    )
}
export default Auxiliary;