import React, { useEffect, useState } from "react";
import Header from "./../header";
import Footer from "./../footer";
import { Link, useLocation } from "react-router-dom";
import axios from "./../api";
import { Spin, Result } from "antd";
import NotImg from "./../img/da@2x.png";
import { RedoOutlined } from "@ant-design/icons";
import "./index.scss";
const NewsDetails = () => {
  const id = useLocation().search;
  const pathName = useLocation().pathname;
  let belong = 1;
  let newHome = "/";
  let newList = "/news";
  let newDetails = "/news_details";
  if (pathName === "/bankruptcy_news_details") {
    belong = 3;
    newHome = "/bankruptcy_auction";
    newList = "/bankruptcy_news";
    newDetails = "/bankruptcy_news_details";
  }
  if (pathName === "/assets_news_details") {
    belong = 2;
    newHome = "/asset_auction";
    newList = "/assets_news";
    newDetails = "/assets_news_details";
  }
  const [news, set_news]: any = useState({});
  const [news_body, set_news_body] = useState({
    belong,
    column_id: id.slice(1).split("&")[1],
    page_num: 1,
    page_size: 3,
    current_id: id.slice(1).split("&")[0],
  });
  const [spinning, set_spinning] = useState(true);
  const [total, set_total] = useState(-1);
  const [news_late_list, set_news_late_list] = useState([]);
  const [page_num, set_page_num] = useState(1);

  // 获取新闻详情
  const getNews = async () => {
    const result: any = await axios.http.newGet(
      `${axios.api.get_news}?id=${id.slice(1).split("&")[0]}`,
      {}
    );
    if (result.status == 0) {
      set_news(result.result);
      document.title = result.result.title;
    }

    set_spinning(false);
  };
  // 获取相关推荐
  const newsLatest = async () => {
    const result: any = await axios.http.newGet(axios.api.news_latest, {
      ...news_body,
    });
    if (result.status === 0) {
      set_news_late_list(result.result.data);
      set_total(result.result.total);
      set_page_num(result.result.page_num);
      // set_news_body({...news_body,page_num:result.result.page_num})
    }

    const dom = document.getElementById("list_top");
    dom && dom.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    getNews();
  }, []);

  useEffect(() => {
    newsLatest();
  }, [news_body]);
  return (
    <div id="list_top">
      <Header />
      <div className="session">
        <div className="breadcrumb_box">
          <Link to={newHome}>首页 {">"}</Link>
          <Link to={newList}>资讯中心 {">"}</Link>
          {news.column || ""}
        </div>

        <Spin tip="数据加载中..." spinning={spinning}>
          <div className="news_details_box">
            <div className="news_details_left">
              <h3>{news.title || ""}</h3>
              <h2>{news.sub_title || ""}</h2>
              <p className="title">{news.put_time || ""}</p>

              <div dangerouslySetInnerHTML={{ __html: news.content }}></div>
            </div>
            <div className="news_details_right">
              {
                !spinning &&
                <h6 style={{ marginBottom: "10px" }}>
                  <div>
                    <span>相关</span>推荐
                  </div>
                  {total > 3 && (
                    <div
                      className="change"
                      onClick={() => {
                        set_news_body({ ...news_body, page_num: page_num + 1 });
                      }}
                    >
                      <RedoOutlined />
                    换一换
                    </div>
                  )}
                </h6>
              }

              {news_late_list &&
                news_late_list.map((item: any) => {
                  return (
                    <Link
                      target="_blank"
                      to={`${newDetails}?${item.id}&${item.column_id}`}
                    >
                      <div className="news_list_img_box">
                        <img
                          onError={(e: any) => {
                            e.target.src = NotImg;
                          }}
                          src={
                            item.pic.indexOf("img.qqpai.com") > -1
                              ? `${item.pic}!w150`
                              : item.pic
                          }
                          alt=""
                        />
                        <p title={item.title}>
                          {item.title && item.title.length > 13
                            ? `${item.title.substring(0, 11)}...`
                            : item.title}
                        </p>
                      </div>
                    </Link>
                  );
                })}
              {total === 0 && <Result title="暂无相关推荐新闻" extra={""} />}
            </div>
          </div>
        </Spin>
      </div>
      <Footer />
    </div>
  );
};

export default NewsDetails;
