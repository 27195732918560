import React, { useEffect, useState } from "react";
import Heard from "./../header";
import Footer from "./../footer";
import { Link, useLocation } from "react-router-dom";
import axios from "./../api";
import List from "./../component/list";
import "./index.scss";
const Announcement = () => {
    const id = useLocation().search.split("&")[0];
    const title = useLocation().search.split("&")[1];
    const path = useLocation().pathname;
    const [notice, set_notice] = useState('');
    const [notice_list, set_notice_list] = useState([]);
    const [title_name, set_title_name] = useState("");

    let linkStr = "/announcement_list";
    let fileUrl = axios.api.notice_file;
    let auctionUrl = axios.api.notice_auction;
    let detaileUrl = "/matter_details";
    let noticeUrl = axios.api.sf_notice;


    if (path === "/assets_announcement_details") {
        linkStr = "/assets_announcement_list";
        fileUrl = axios.api.zc_notice_file;
        auctionUrl = axios.api.zc_notice_auction;
        detaileUrl = "/asset_matter_details";
        noticeUrl = axios.api.zc_notice;
    }
    if (path === "/bankruptcy_announcement_details") {
        linkStr = "/bankruptcy_announcement";
        fileUrl = axios.api.pc_notice_file;
        auctionUrl = axios.api.pc_notice_auction;
        detaileUrl = "/bankruptcy_matter_details";
        noticeUrl = axios.api.pc_notice;
    }
    const getNoticeFile = async () => {
        const notice: any = await axios.http.get(`${fileUrl}${id.slice(1).split("&")[0]}.txt`);
        set_notice(notice);
    }
    const noticeAuction = async () => {
        const result: any = await axios.http.newGet(`${auctionUrl}?id=${id.slice(1).split("&")[0]}`, {})
        if (result.status === 0) {
            set_notice_list(result.result)
        }
    }
    const noticeTitle = async () => {
        const result: any = await axios.http.newGet(`${noticeUrl}?id=${id.slice(1).split("&")[0]}`, {})
        set_title_name(result.result.title)
        document.title = result.result.title;
    }
    useEffect(() => {
        getNoticeFile();
        noticeAuction();
        noticeTitle();
    }, [])
    return (
        <div>
            <Heard />
            <div className="session">
                <div className="breadcrumb_box">
                    <Link to={linkStr} style={{ color: "#333" }}>公告列表{'>'}</Link>
                    {title_name}
                </div>
                <div className="announcement_box" style={{ marginTop: "10px" }}>
                    <div className="announcement_left">
                        公告详情
                    </div>
                    <div className="announcement_right" dangerouslySetInnerHTML={{ __html: notice }}></div>
                </div>
                {
                    notice_list.length > 0 &&
                    <div className="announcement_box">
                        <div className="announcement_left">
                            所含标的物
                    </div>
                        <div className="announcement_right">
                            <List notList={false} list={notice_list} link={detaileUrl} narrow={true} />
                        </div>
                    </div>
                }

            </div>
            <Footer />
        </div>
    )
}
export default Announcement;