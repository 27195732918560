import padding from "./padding";
const format = (isTo?: boolean, isTomorrow?: boolean) => {
    let time = new Date();
    let one = time.setDate(time.getDate() + 1);
    let date = new Date(one)
    if (isTo) {
        const to = time.setDate(time.getDate() + 1)
        date = new Date(to);
    }
    if (isTomorrow) {
        const to = time.setDate(time.getDate() + 1)
        date = new Date(to);
    }
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    const getDay = date.getDay();



    return {
        data: `${padding(year)}-${padding(month)}-${padding(day)}`,
        week: '日一二三四五六'.charAt(getDay)
    }
}

export default format;