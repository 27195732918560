import { action, observable } from 'mobx';
import { message } from "antd";
import axios from "./../api";
export interface ITodo {
    value: string;
}
export class Search {
    @observable value = "";
    @observable list = [];
    @observable total = -1;
    @observable loading = false;
    @observable city_id = localStorage.getItem("city_id") || 0;
    @action.bound inputChange(value: string) {
        this.value = value.replace(/\s/g, "");
    }
    @action.bound search_city_id = (city_id: any) => {
        this.city_id = city_id * 1
    }
    @action.bound onSearch = async (type: string, query?: any) => {
        let pattern = new RegExp("[`~!@$^&=|{}\\[\\]<>《》%/?~！@￥&|{}【】]");
        let isQuery = query || { page_size: 20 }
        if (pattern.test(this.value)) {
            message.error("输入不能包含特殊字符");
            return;
        }
        if (this.value.length < 2) {
            message.error("输入长度不够");
            return;
        }
        let url = axios.api.auction_list;
        if (type === "3") {
            url = axios.api.zc_auction_list
        }
        if (type === "2") {
            url = axios.api.pc_auction_list
        }
        this.loading = true;
        const result: any = await axios.http.newGet(url, { ...isQuery, title: this.value, city_id: (query && query.city_id) ? query.city_id : this.city_id })
        if (result.status === 0) {
            let newData = result.result.data;
            this.total = result.result.total;
            this.list = newData;
        }
        this.loading = false;
    }
}


export const STORE_TODO = 'todoStore';