import React, { useEffect, useState } from "react";
import Header from "./../../header";
import Footer from "./../../footer";
import { Form, Input, Upload, message, Button, Popconfirm, Radio } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import "./../../serviceIn/index.scss";


const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 9 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
    },
};
const tailLayout = {
    wrapperCol: { offset: 10, span: 16 },
};

const CourtIn = () => {
    const [loading, set_loading]: any = useState(false)
    const [imageUrl, set_imageUrl]: any = useState({
        img1: '',
        img2: '',
        img3: '',
        img4: '',
        img5: ''
    });
    const [visible, set_visible] = useState(false);

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="ant-upload-text">上传文件</div>
        </div>
    );

    const getBase64 = (img: any, callback: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleChange = (info: any, type: string) => {
        // if (info.file.status === 'uploading') {
        //     set_loading(true)
        //     return;
        // }
        // if (info.file.status === 'done') {
        //     // Get this url from response in real world.
        //     getBase64(info.file.originFileObj, (image: any) => {
        //         set_loading(false)
        //         set_imageUrl({...imageUrl,[type]:image})
        //     }
        //     );
        // }

        getBase64(info.file.originFileObj, (image: any) => {
            set_loading(false)
            set_imageUrl({ ...imageUrl, [type]: image })
        }
        );
    };

    const onFinish = (values: any) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const toggleModal = () => {
        set_visible(!visible);
    }



    const beforeUpload = (file: any) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    const confirm = () => {
        message.success('提交成功');
    }

    useEffect(() => {
        document.body.scrollIntoView();
    }, [])
    return (
        <div>
            <Header />
            <div className="session">
                <div className="server_in">
                    <h6>
                        <span>入驻</span>申请
                    </h6>
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        {...formItemLayout}>
                        <Form.Item
                            label="机构名称"
                            help="务必保证与组织机构/社会信用代码证上的名称一致，该名称将成为机构的网拍帐号"
                        >
                            <Input placeholder="输入机构名称" />
                        </Form.Item>
                        <Form.Item
                            label="机构邮箱"
                            help="新申请一个机构公共邮箱，该邮箱将用于注册机构网拍帐号，请尽量不用个人邮箱，且务必妥善保管邮箱"
                        >
                            <Input placeholder="请输入入驻机构公告邮箱" />
                        </Form.Item>
                        <Form.Item
                            label="组织机构/社会信用代码"
                            help="该项内容用于机构微信商户认证，请填写未过期的、正确的信息"
                        >
                            <Input placeholder="请输入入驻机构组织机构/社会信用代码" />
                        </Form.Item>
                        <Form.Item
                            label="组织机构/社会信用有效期"
                        >
                            <Input placeholder="请输入入驻机构组织机构/社会信用有效期" />
                        </Form.Item>
                        <Form.Item
                            label="组织机构/社会信用代码证详细地址"
                        >
                            <Input placeholder="请输入组织机构/社会信用代码证详细地址" />
                        </Form.Item>
                        <Form.Item
                            label="法人代表姓名"
                        >
                            <Input placeholder="请输入入驻机构法人代表姓名" />
                        </Form.Item>
                        <Form.Item
                            label="法人代表身份证"
                        >
                            <Input placeholder="请输入入驻机构法人代表身份证" />
                        </Form.Item>
                        <Form.Item
                            label="机构联系地址"
                        >
                            <Input placeholder="请输入入驻机构联系地址" />
                        </Form.Item>
                        <Form.Item
                            label="机构联系电话"
                            help="区号-座机号-分机号 ，该项内容用于平台客服与机构联系"
                        >
                            <Input placeholder="请输入入驻机构联系电话" />
                        </Form.Item>
                        <Form.Item
                            label="机构传真"
                        >
                            <Input placeholder="请输入入驻机构传真" />
                        </Form.Item>
                        <Form.Item
                            label="联系人"
                        >
                            <Input placeholder="请输入入驻机构联系人" />
                        </Form.Item>
                        <Form.Item
                            label="联系人手机"
                            help="该项内容用于平台客服与网拍法官联系"
                        >
                            <Input placeholder="请输入入驻机构联系人手机" />
                        </Form.Item>
                        <Form.Item
                            label="开户银行名称"
                        >
                            <Input placeholder="请输入入驻机构开户银行名称" />
                        </Form.Item>
                        <Form.Item
                            label="开户银行所在省份"
                        >
                            <Input placeholder="请输入入驻机构开户银行所在省份" />
                        </Form.Item>
                        <Form.Item
                            label="开户银行所在城市"
                        >
                            <Input placeholder="请输入入驻机构开户银行所在城市" />
                        </Form.Item>
                        <Form.Item
                            label="开户支行名称"
                        >
                            <Input placeholder="请输入入驻机构开户支行名称" />
                        </Form.Item>
                        <Form.Item
                            label="机构银行开户名称"
                        >
                            <Input placeholder="请输入入驻机构银行开户名称" />
                        </Form.Item>
                        <Form.Item
                            label="机构银行账号"
                            help="填写网拍资金流入的机构银行账户，请填写实际的银行账号（如农行17位数字），异地转账时需要加上的前缀"
                        >
                            <Input placeholder="请输入入驻机构银行账号" />
                        </Form.Item>
                        <Form.Item
                            label="重复机构银行账号"
                        >
                            <Input placeholder="请重复输入入驻机构银行账号" />
                        </Form.Item>
                        <Form.Item
                            label="开户许可证"
                            help="请上传机构在银行的开户许可证或含有机构开户信息的银行单据，用于核对账号正确性。"
                        >
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                onChange={(info) => { handleChange(info, "img1") }}
                            >
                                {imageUrl.img1 ? <img src={imageUrl.img1} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            label="法人有效身份证件"
                            help="上传入驻机构法人有效身份证，上传文件格式为jpg或png格式文件大小限制为5M"
                        >
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                onChange={(info) => { handleChange(info, "img3") }}
                            >
                                {imageUrl.img3 ? <img src={imageUrl.img3} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>

                        </Form.Item>
                        <Form.Item
                            label="开户许可证有效日期"
                        >
                            <Input placeholder="请输入开户许可证有效日期" />
                        </Form.Item>
                        <Form.Item
                            label="企业注册资金"
                        >
                            <Input placeholder="请输入企业注册资金" />
                        </Form.Item>
                        <Form.Item
                            label="三证"
                        >
                            <Radio.Group>
                                <Radio value={1}>三证分离</Radio>
                                <Radio value={2}>三证合一</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="有效期内的营业执照"
                            help="上传入驻机构有效期内营业执照，上传文件格式为jpg或png格式文件大小限制为5M"
                        >
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                onChange={(info) => { handleChange(info, "img2") }}
                            >
                                {imageUrl.img2 ? <img src={imageUrl.img2} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            label="符合要求的营业场所使用权有效证明文件"
                            help="上传入驻机构营业场所使用权有效证明文件，上传文件格式为jpg或png格式文件大小限制为5M"
                        >
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                onChange={(info) => { handleChange(info, "img4") }}
                            >
                                {imageUrl.img4 ? <img src={imageUrl.img4} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Form.Item>


                        <Form.Item
                            label="营业执照有效日期"
                        >
                            <Input placeholder="请输入营业执照有效日期" />
                        </Form.Item>

                        <Form.Item
                            label="企业注册日期"
                        >
                            <Input placeholder="请输入企业注册日期" />
                        </Form.Item>

                        <Form.Item
                            label="存在委托授权关系"
                            help="如您受法院、海关、中国铁塔等平台战略合作机构委托或监管，请选择委托授权关系"
                        >
                            <Radio.Group>
                                <Radio value={1}>不存在</Radio>
                                <Radio value={2}>存在</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label="保证金缴纳文件"
                            help="上传保证金缴纳文件，上传文件格式为jpg或png格式文件大小限制为5M"
                        >
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                onChange={(info) => { handleChange(info, "img5") }}
                            >
                                {imageUrl.img5 ? <img src={imageUrl.img5} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Form.Item>


                        <Form.Item {...tailLayout}>
                            <Popconfirm placement="top" title="确认无误提交" onConfirm={confirm} okText="确定" cancelText="取消">
                                <Button type="primary" htmlType="submit">确认无误，提交</Button>
                            </Popconfirm>

                        </Form.Item>
                    </Form>
                </div>
            </div>
            <Footer />

        </div>
    )
}

export default CourtIn;