import React, { useState, useEffect } from "react";
import axios from "./../api";
const Electronic = () => {
  const [seal, set_seal] = useState("");
  const getSeal = async () => {
    const result: any = await axios.http.get(axios.api.seal);
    set_seal(result.richText);
  };
  useEffect(() => {
    getSeal();
    document.title = "电子封条-讯拍网";
  }, []);
  return (
    <div style={{ margin: "auto" }}>
      <div style={{textAlign:"center",margin:"auto"}} dangerouslySetInnerHTML={{ __html: seal }}></div>
    </div>
  );
};
export default Electronic;
