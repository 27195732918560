import React, { useEffect, useState } from "react";
import Header from "./../../header";
import Footer from "./../../footer";
import axios from "./../../api";
import { Spin } from "antd";
import { Link, useLocation } from "react-router-dom";
import "./index.scss";

const typeList = [
    "",
    "政府机关",
    "银行",
    "AMC",
    "其他金融机构",
    "交易所",
    "电信运营商",
    "商业类",
    "拍卖行",
    "特定的处置小组（非法人单位）",
    "服务商",
    "其他"
]
const Cooperation: React.FC = () => {
    const ids: any = useLocation().search;
    const [cooperation_list, set_cooperation_list] = useState([]);
    const [loading, set_loading] = useState(true);
    const getByType = async () => {
        const result: any = await axios.http.newGet(`${axios.api.get_by_type}${ids}`,{});
        if (result.status === 0) {
            set_cooperation_list(result.result);
        }
        set_loading(false)
    };
    useEffect(() => {
        getByType();
    }, []);
    return (
        <div>
            <Header />
            <div className="session">
                <Spin spinning={loading} tip="数据加载中...">
                    <div className="cooperation_box">
                        <div className="cooperation_list">
                            <div className="cooperation_title">
                                <Link target="_blank" to={`/assets_announcement_type?${ids.slice(6)}`} >
                                    {typeList[ids.slice(6)]}
                                </Link>
                            </div>
                            {
                                cooperation_list && cooperation_list.map((child: any) => {
                                    return (
                                        <Link target="_blank" className={child.title.length > 18 ? "len" : ''} to={`/assets_court_list?${child.id}`} key={child.id}>{child.title}</Link>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Spin>
            </div>
            <Footer />
        </div>
    )
}

export default Cooperation;