import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  HashRouter as Router,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Input, message } from "antd";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import fetchJsonp from "fetch-jsonp";
import axios from "./../api";
import CodeLogin from "./../component/code";
import { observer } from "mobx-react";
import { HomeSearch, HeaderType } from "./../storesMobx";
import BindModal from "./../component/bindModal";
import "./index.scss";
import "./../component/bind.scss";
import { editTitle } from "../component/title";
import Phone from "./../component/phoneLogin";
import CityModal from "./../component/citySelect";

const nav_list = [
  {
    name: "司法拍卖",
    link: "/",
    type: "1",
    child: [
      {
        name: "首页",
        link: "/",
      },
      {
        name: "法院",
        link: "/all_court_list",
      },
      {
        name: "公告",
        link: "/announcement_list?1",
      },
      {
        name: "司辅联盟",
        link: "/union",
      },
      {
        name: "电子封条",
        link: "/electronic",
      },
      {
        name: "异地委托",
        link: "/another_place",
      },
      {
        name: "网络询价",
        link: "http://www.qqvfp.com",
      },
      {
        name: "增值营销",
        link: "/guest",
      },
      {
        name: "服务商入驻",
        link: "/instructions",
      },
    ],
  },
  {
    name: "破产拍卖",
    link: "/bankruptcy_auction",
    type: "2",
    child: [
      {
        name: "首页",
        link: "/",
      },
      {
        name: "公告",
        link: "/bankruptcy_announcement",
      },
      {
        name: "增值营销",
        link: "/bankruptcy_guest",
      },
      {
        name: "管理人入驻",
        link: "/bankruptcy_in",
      },
    ],
  },
  {
    name: "资产拍卖",
    link: "/asset_auction",
    type: "3",
    child: [
      {
        name: "首页",
        link: "/",
      },
      {
        name: "公告",
        link: "/assets_announcement_list",
      },
      {
        name: "增值营销",
        link: "/asset_auction_guest",
      },
      {
        name: "招商推介",
        link: "/assets_promote",
      },
      {
        name: "服务商入驻",
        link: "/asset_in",
      },
    ],
  },
  {
    name: "拍卖地图",
    link: "/map_housing",
    type: "4",
    child: []
  }
];

let code: any = "";
let bind_wx = true;

const Header = (props: any, ref: any) => {
  const history = useHistory();
  const { value, inputChange, onSearch, search_city_id } = HomeSearch();
  const {
    bindModal,
    headerType,
    changeType,
    wxModal,
    togLoginModal,
    loginWx,
    wxLogin,
    loginUser,
    chengeUser,
    bind_login_wx,
    bindType,
    changeCity,
    bindChange,
    changeBindType,
    city,
  }: any = HeaderType();
  const path = useLocation().pathname;
  const newTime = new Date().getTime();
  const helpType = useLocation().search;
  const citySelect: any = useRef(null);
  let user = window.localStorage.getItem("user") || loginUser;
  const time = (user && JSON.parse(user).time) || 0;
  if (newTime > time + 60 * 60 * 1000) {
    window.localStorage.setItem("user", "");
    user = "";
    chengeUser();
  } else {
    chengeUser(window.localStorage.getItem("user"));
  }

  const jsonUser = user ? JSON.parse(user) : {};
  if (!window.localStorage.getItem("user") && code) {
    code = "";
    chengeUser();
  }
  if (helpType) {
    const parameter = helpType.split("&");
    parameter &&
      parameter.map((item: string) => {
        if (item.indexOf("code") > -1) {
          code = item.split("=")[1];
        }
      });
    if (bindType === "wx" && bindModal && code && bind_wx) {
      bind_wx = false;
      bind_login_wx(code);
    }
  }
  const [homeActive, set_homeActive] = useState("首页");
  const [secondary_nav, set_secondary_nav] = useState(
    nav_list[headerType - 1].child
  );
  const getIp = () => {
    if (city) return;
    const url = `https://apis.map.qq.com/ws/location/v1/ip?key=ZA4BZ-7ZAKS-H3HOQ-6SEZT-V57F3-5QBGN&output=jsonp`;
    fetchJsonp(url, { jsonpCallback: "" })
      .then((response: any) => {
        return response.json();
      })
      .then((res: any) => {
        if (res.status === 0) {
          const ids = `${res.result.ad_info.adcode}`;
          changeCity(res.result.ad_info.city, `${ids.substring(0, 4)}00`);
          search_city_id(`${ids.substring(0, 4)}00`);
        }
      });
  };
  const [login_type, set_login_type] = useState("phone");
  // 获取城市列表
  const getCity = async () => {
    const result: any = await axios.http.newGet(
      `${axios.api.sub_city}?id=0`,
      {}
    );
    if (result.status === 0) {
      window.localStorage.setItem("cityList", JSON.stringify(result.result));
      //   set_city_list(result.result)
    }
  };
  useImperativeHandle(ref, () => ({
    wnLogin: (path: string, search: string) => {
      loginWx(path, search);
    },
  }));
  useEffect(() => {
    let homeActive = "";
    const pathObj: any = {
      "/": "首页",
      "/development": "首页",
      "/development2": "法拍金融",
      "/development3": "法拍律师",
      "/bankruptcy_auction": "首页",
      "/asset_auction": "首页",
      "/court_announcement": "公告",
      "/announcement_list": "公告",
      "/assets_announcement_list": "公告",
      "/bankruptcy_announcement": "公告",
      "/guest": "增值营销",
      "/instructions": "服务商入驻",
      "/court_in": "服务商入驻",
      "/auxiliary": "服务商入驻",
      "/bankruptcy_in": "管理人入驻",
      "/bankruptcy_process_user": "服务商入驻",
      "/bankruptcy_server_data": "服务商入驻",
      "/bankruptcy_user_data": "服务商入驻",
      "/service_in": "服务商入驻",
      "/asset_in": "服务商入驻",
      "/assets_bank_in": "服务商入驻",
      "/bankruptcy_guest": "增值营销",
      "/asset_auction_guest": "增值营销",
      "/all_court_list": "法院",
      "/court_list": "法院",
      "/assets_promote": "招商推介",
      "/cooperation": "资产推介",
      "/union": "司辅联盟",
      "/another_place": "异地委托",
      "/another_detail": "异地委托",
    };
    homeActive = pathObj[path];
    if (path === "/") {
      changeType("1");
      set_secondary_nav(nav_list[0].child);
    }
    if (path.indexOf("asset") > -1) {
      changeType("3");
      set_secondary_nav(nav_list[2].child);
    } else if (path.indexOf("bankruptcy") > -1) {
      changeType("2");
      set_secondary_nav(nav_list[1].child);
    } else if (path === "/development") {
      switch (helpType) {
        case "?4":
          changeType("4");
          set_secondary_nav(nav_list[3].child);
          break;
        case "?5":
          changeType("5");
          set_secondary_nav(nav_list[4].child);
          break;
        case "?6":
          changeType("6");
          set_secondary_nav(nav_list[5].child);
          break;
        case "?7":
          changeType("7");
          set_secondary_nav(nav_list[6].child);
          break;
      }
      const helpTypeObj: any = {
        "?1": "辅助机构",
        "?2": "法拍金融",
        "?3": "法拍律师",
        "?22": "辅助机构",
        "?23": "拍卖金融",
        "?24": "破产律师",
        "?31": "法拍律师",
        "?32": "辅拍机构",
        "?33": "拍卖金融",
        "?34": "产权交易所",
        "?35": "拍卖行",
        "?36": "其他机构",
      };
      if (helpTypeObj[helpType]) {
        homeActive = helpTypeObj[helpType];
      }
    } else if (path === "/help") {
      if (helpType.indexOf("?2") > -1) {
        changeType("2");
        set_secondary_nav(nav_list[1].child);
      } else if (helpType.indexOf("?3") > -1) {
        changeType("3");
        set_secondary_nav(nav_list[2].child);
      }
    }
    set_homeActive(homeActive);
    const cityList = window.localStorage.getItem("cityList");
    if (!cityList) {
      getCity();
    }
    getIp();
    document.title = editTitle(path);
    const dom = document.getElementById("header");
    dom && dom.scrollIntoView({ behavior: "smooth" });
  }, []);
  useEffect(() => {
    if (wxModal && code && !user) {
      wxLogin(code);
    }
  }, [code]);
  useEffect(() => {
    if (user && JSON.parse(user)) {
      let login_user = JSON.parse(user);
      if (!login_user.phone) {
        changeBindType("phone");
        bindChange();
      } else if (!login_user.wx) {
        changeBindType("wx");
        bindChange();
      }
    }
  }, []);

  const isVisible = () => {
    citySelect.current.isVisible();
  };
  const [visible, set_visible] = useState(false);

  return (
    <header id="header">
      <div className="header_top">
        <div className="header_top_box">
          <div className="header_home">
            <a href="https://www.qq.com" target="_blank">腾讯网</a>&emsp;
            <a href="https://cloud.tencent.com" target="_blank">腾讯云</a>&emsp;
            <a href="https://e.qq.com/ads" target="_blank">腾讯广告</a>&emsp;
            <em></em>
            <span onClick={isVisible}>
              {city}
              <i></i>
            </span>
          </div>
          <div className="header_user">
            <div className="mobile_hover">
              讯拍移动端
              <div className="mobile_qrcode">
                <img src={require('./../img/wx_code.jpg')} alt="" />
                <p>
                  使用微信扫描二维码
              </p>
                <p>
                  即可使用讯拍移动端
              </p>
              </div>
            </div>
            <span
              onClick={() => {
                if (jsonUser.nick_name) {
                  history.push("/personal");
                } else {
                  loginWx(path, helpType);
                }
              }}
            >
              {jsonUser.nick_name ? jsonUser.nick_name : "登录"}
            </span>
            &emsp; | &emsp;
            <span>客服专线：400-000-1102</span>
          </div>
        </div>
      </div>

      <div className="header_nav" style={{ display: path === "/map_housing" ? "none" : "block" }}>
        <div>
          <div className="header_nav_left">
            <Link to="/" className="logo"></Link>
            {nav_list.map((item: any) => {
              if (item.name === "拍卖地图") {
                return (
                  <Link
                    key={item.name}
                    to={item.link}
                    target="_blank"
                  >
                    {item.name}
                  </Link>
                );
              }
              return (
                <Link
                  key={item.name}
                  to={item.link}
                  className={item.type === headerType ? "active" : ""}
                  onClick={() => {
                    changeType(item.type);
                    set_secondary_nav(item.child);
                  }}
                >
                  {item.name}
                </Link>
              );
            })}
          </div>
          <div className="header_nav_right">
            <div className="header_search">
              <Input
                onChange={(e: any) => {
                  inputChange(e.target.value);
                }}
                value={value}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    let pattern = new RegExp(
                      "[`~!@$^&=|{}\\[\\]<>《》%/?~！@￥&|{}【】]"
                    );
                    if (pattern.test(value)) {
                      message.error("输入不能包含特殊字符");
                      return;
                    }
                    if (value.length < 2) {
                      message.error("输入长度不够");
                      return;
                    }
                    if (path === "/search_list") {
                      onSearch(headerType);
                    } else {
                      history.push("/search_list");
                    }
                  }
                }}
                placeholder="标的物名称"
              />

              <button
                onClick={() => {
                  let pattern = new RegExp(
                    "[`~!@$^&=|{}\\[\\]<>《》%/?~！@￥&|{}【】]"
                  );
                  if (pattern.test(value)) {
                    message.error("输入不能包含特殊字符");
                    return;
                  }
                  if (value.length < 2) {
                    message.error("输入长度不够");
                    return;
                  }
                  if (path === "/search_list") {
                    onSearch(headerType);
                  } else {
                    history.push("/search_list");
                  }
                }}
              >
                <SearchOutlined />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="header_search_box" style={{ display: path === "/map_housing" ? "none" : "block" }}>
        <div className="header_secondary_nav">
          {secondary_nav.map((item: any) => {
            if (item.name === "网络询价") {
              return (
                <a href={item.link} target="_blank">
                  {item.name}
                </a>
              );
            } else if (item.name === "电子封条" || item.name === "增值营销") {
              return (
                <Link
                  key={item.name}
                  className={homeActive === item.name ? "active" : ""}
                  to={item.link}
                  target="_blank"
                >
                  {item.name}
                </Link>
              );
            } else {
              return (
                <Link
                  key={item.name}
                  className={homeActive === item.name ? "active" : ""}
                  to={item.link}
                >
                  {item.name}
                </Link>
              );
            }
          })}
        </div>
      </div>
      <CodeLogin loginWx={loginWx} />
      <div
        className="wx_login_box"
        style={{ display: wxModal ? "flex" : "none" }}
      >
        <div
          className="login_modal"
        // style={{ height: login_type === "phone" ? "400px" : "500px" }}
        >
          <CloseCircleOutlined onClick={togLoginModal} />
          <div className="login_nav">
            <span
              onClick={() => {
                set_login_type("phone");
              }}
              className={login_type === "phone" ? "active" : ""}
            >
              手机号码登录
            </span>
            <b>|</b>
            <span
              onClick={() => {
                set_login_type("wx");
                loginWx(path, helpType, "wx");
              }}
              className={login_type === "wx" ? "active" : ""}
            >
              微信扫码登录
            </span>
          </div>
          <div
            className="login_content"
          // style={{ height: login_type === "phone" ? "300px" : "400px" }}
          >
            {login_type === "phone" && <Phone type="login" />}
            <div
              className="wx_login_bg"
              style={{ display: `${login_type === "wx" ? "block" : "none"}` }}
            >
              <div id="login_container" style={{ marginTop: "-46px" }}></div>
              <p style={{ textAlign: "center" }}>
                扫码登录，即表示您同意
                <Link to="/liability" target="_blank">
                  《免责声明》
                </Link>{" "}
                &nbsp;
                <Link to="/agreement" target="_blank">
                  《用户注册协议》
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <BindModal />

      <CityModal ref={citySelect} visible={visible} isVisible={isVisible} />
    </header>
  );
};

export default observer(forwardRef(Header));
