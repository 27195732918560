import React, { useEffect, useState } from "react";
import Header from "./../header";
import Footer from "./../footer";
import { useLocation } from "react-router-dom";
import axios from "./../api";
import "./index.scss";

const Banner: React.FC = () => {
  const typeIds = useLocation().search.slice(1);
  const [banner, set_banner] = useState([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getBanner = async () => {
    const banner: any = await axios.http.get(`${axios.api.bannerList}`);
    if (banner.status === 0) {
      let bannerList = banner.data;
      // eslint-disable-next-line array-callback-return
      bannerList.map((item: any) => {
        // eslint-disable-next-line eqeqeq
        if (item.index == typeIds) {
          set_banner(item.imgs_pc);
        }
      })
    }
  };
  useEffect(() => {
    getBanner();
  }, []);
  return (
    <div>
      <Header />
      <div className="session">
        <div className="c_banner_box">
          {banner &&
            banner.map((item: any) => {
              return (
                <img src={`https://file.qqpai.com${item}`} alt="" />
              );
            })}
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Banner;
