import React, { useEffect, useState, useRef } from "react";
import Header from "./../../header/index";
import { Link } from "react-router-dom";
import NotImg from "./../../img/da@2x.png";
import { message, Carousel } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import "./../../home/index.scss";
import List from "./../../component/list";
import Footer from "./../../footer/index";
import Data from "./../../home/data";
import axios from "./../../api";
// @ts-ignore
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, EffectFade } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import FooterCode from "./../../component/footer_code";

const Home = () => {
  const carousel: any = useRef(null);
  const localCityList = window.localStorage.getItem("cityList") || "[]";
  const city_list = JSON.parse(localCityList);
  const [latest_news, set_latest_news]: any = useState([]); //最近新闻列表
  const [latest_notice_list, set_latest_notice_list]: any = useState([]); //最近公告列表
  const [category_id, set_category_id] = useState("50025969"); //今日拍卖房产类型
  const [today_real_list, set_today_real_list] = useState([]); //今日拍卖房产拍卖列表
  const [today_real_total, set_today_real_total] = useState(0); //今日拍卖房产拍卖列表

  const [vehicle_id, set_vehicle_id] = useState("50025972"); //今日拍卖房产拍卖id
  const [vehicle_total, set_vehicle_total] = useState(0); //今日拍卖机动车总数
  const [today_vehicle_list, set_today_vehicle_list] = useState([]); //今日拍卖机动车拍卖列表

  const [equipment_id, set_equipment_id] = useState("200772003"); //机械设备id
  const [equipment_total, set_equipment_total] = useState(0); //今日拍卖机械设备总数
  const [today_equipment_list, set_today_equipment_list] = useState([]); //今日拍卖机械设备拍卖列表

  const [other_id, set_other_id] = useState("50025970"); //今日拍卖其他拍卖id
  const [other_total, set_other_total] = useState(0); //今日拍卖其他总数
  const [today_other_list, set_today_other_list] = useState([]); //今日拍卖其他拍卖列表

  const [enterprise_list, set_enterprise_list] = useState([]);
  const [banner, set_banner]: any = useState([]); //banner列表

  const [discount_list, set_discount_list]: any = useState([]) //折扣房源列表

  SwiperCore.use([Navigation])

  // 获取最近新闻
  const latestNews = async () => {
    const result: any = await axios.http.newGet(axios.api.latest_news, {
      belong: 3,
      page_num: 1,
      page_size: 3,
    });

    if (result.status === 0) {
      set_latest_news(result.result);
    } else {
      message.error(result.message);
    }
  };

  // 获取名企资产
  const getPcManage = async () => {
    // const result: any = await axios.http.newGet(axios.api.pc_manage_list, {
    const result: any = await axios.http.newGet(axios.api.main_page, {
      page_num: 1,
      page_size: 4,
    });

    if (result.status === 0) {
      set_enterprise_list(result.result);
    } else {
      message.error(result.message);
    }
  };

  // 获取最新公告
  const latestNotice = async () => {
    const result: any = await axios.http.newGet(axios.api.pc_notice_list, {
      page_num: 1,
      page_size: 3,
    });
    if (result.status === 0) {
      set_latest_notice_list(result.result.data);
    }
  };

  // 房产类数据列表
  const getCategory = async (
    category_id: string,
    page_size: number,
    type: string
  ) => {
    const result: any = await axios.http.newGet(axios.api.pc_auction_list, {
      category_id,
      page_num: 1,
      page_size: page_size,
    });
    if (result.status === 0) {
      const list = result.result.data || [];
      if (type === "real") {
        set_today_real_list(list);
        set_today_real_total(result.result.total);
      } else if (type === "vehicle") {
        set_today_vehicle_list(list);
        set_vehicle_total(result.result.total);
      } else if (type === "other") {
        set_today_other_list(list);
        set_other_total(result.result.total);
      } else if (type === "equipment") {
        set_today_equipment_list(list);
        set_equipment_total(result.result.total);
      }
    }
  };
  const getBanner = async () => {
    const banner: any = await axios.http.get(`${axios.api.bannerList}`);
    if (banner.status === 0) {
      set_banner(banner.data);
    }
  };

  // 获取banner页面
  // const getBanner = async () => {
  //   const banner: any = await axios.http.newGet(axios.api.value_add_data_list, {
  //     page_num: 1,
  //     page_size: 6,
  //     put_position: 1,
  //     put_channel: 3,
  //     city_id: cityId
  //   });
  //   if (banner.status === 0) {
  //     set_banner(banner.result.data);
  //   }
  // };
  // 获取折扣房源
  // const discountList = async () => {
  //   const banner: any = await axios.http.newGet(axios.api.value_add_data_list, {
  //     page_num: 1,
  //     page_size: 6,
  //     put_position: 2,
  //     put_channel: 3,
  //     city_id: cityId
  //   });
  //   if (banner.status === 0) {
  //     set_discount_list(banner.result.data);
  //   }
  // };
  // 围观次数 最新新闻 法院公告
  useEffect(() => {
    latestNews();
    latestNotice();
    getPcManage();
    getBanner();
  }, []);
  // 房产类数据加载
  useEffect(() => {
    getCategory(category_id, 8, "real");
  }, [category_id]);
  //机动车类
  useEffect(() => {
    getCategory(vehicle_id, 4, "vehicle");
    getCategory(equipment_id, 4, "equipment"); //机械设备
  }, [equipment_id, vehicle_id]);
  //其他
  useEffect(() => {
    getCategory(other_id, 4, "other");
  }, [other_id]);

  return (
    <div>
      <Header />
      <div className="session">
        <div className="home_banner_nav" style={{ height: "490px" }}>
          <div className="home_banner_left">
            <div className="home_banner_nav_box">
              <h2>
                <Link to="/bankruptcy_matter_list">标的物类型</Link>
              </h2>
              <div className="home_banner_nav_list">
                {Data.matterType.map((item: any) => {
                  return (
                    <Link
                      to={`/bankruptcy_matter_list?matterType=${item.id}`}
                      key={item.id}
                      style={{ width: "33.33%" }}
                    >
                      {item.name}
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className="home_banner_nav_box">
              <h2>
                <Link to="/bankruptcy_matter_list">标的所在地</Link>
              </h2>
              <div className="home_banner_nav_list">
                {city_list &&
                  city_list.map((item: any) => {
                    return (
                      <Link
                        key={item.id}
                        to={`/bankruptcy_matter_list?matterAdd=${item.id}`}
                      >
                        {item.shortname}
                      </Link>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="home_banner_middle">
            <div className="home_banner_box">
              <div
                className="right"
                onClick={() => {
                  carousel.current.next();
                }}
              >
                <RightOutlined />
              </div>
              <div
                className="left"
                onClick={() => {
                  carousel.current.prev();
                }}
              >
                <LeftOutlined />
              </div>
              <Carousel autoplay={true} ref={carousel}>
                {banner &&
                  banner.map((item: any, index: number) => {
                    if (item.main_pc) {
                      if (item.imgs_pc.length) {
                        return (
                          <div>
                            <Link to={`/banner_link?${item.index}`} target="_blank">
                              <img
                                src={`https://file.qqpai.com${item.main_pc}`}
                                alt=""
                              />
                            </Link>
                          </div>
                        );
                      } else {
                        return (
                          <div>
                            <img
                              src={`https://file.qqpai.com${item.main_pc}`}
                              alt=""
                            />
                          </div>
                        );
                      }
                    }
                  })}
              </Carousel>
            </div>

            <div className="home_new_box">
              <div className="news_box">
                <div className="news_title">
                  <h6>行业资讯</h6>
                  <Link to="/bankruptcy_news" target="_blank">
                    查看更多&gt;
                  </Link>
                </div>
                <ul className="news_list_box">
                  {latest_news &&
                    latest_news.map((item: any) => {
                      return (
                        <Link
                          title={item.title}
                          key={item.id}
                          target="_blank"
                          to={`/bankruptcy_news_details?${item.id}&${item.column_id}`}
                        >
                          <li>
                            <em>NEW</em>
                            <p>{item.title}</p>
                          </li>
                        </Link>
                      );
                    })}
                </ul>
              </div>
              <div className="news_box">
                <div className="news_title">
                  <h6>破产公告</h6>
                  <Link to="/bankruptcy_announcement" target="_blank">
                    查看更多&gt;
                  </Link>
                </div>
                <ul className="news_list_box">
                  {latest_notice_list &&
                    latest_notice_list.map((item: any) => {
                      return (
                        <Link
                          title={item.title}
                          key={item.id}
                          target="_blank"
                          to={`/bankruptcy_announcement_details?${item.id}`}
                        >
                          <li>
                            <p>{item.title}</p>
                          </li>
                        </Link>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
          <div className="home_banner_right">
            <h6 className="h6_title" style={{ marginTop: "-10px" }}>
              帮助中心
            </h6>
            <div className="home_help" style={{ height: "160px" }}>
              <Link to="/help?1&id=33">
                <img src={require("./../../img/process@2x.png")} alt="" />
                <p>竞价流程</p>
              </Link>
              <Link to="/help?1&id=31">
                <img src={require("./../../img/rule@2x.png")} alt="" />
                <p>竞价规则</p>
              </Link>
              <Link to="/help?1&id=8">
                <img src={require("./../../img/c_money.png")} alt="" />
                <p>交保证金</p>
              </Link>
              <Link to="/help?1&id=14">
                <img src={require("./../../img/delivery@2x.png")} alt="" />
                <p>标的交割</p>
              </Link>
              <Link to="/help?1&id=9">
                <img src={require("./../../img/c_pay.png")} alt="" />
                <p>支付限额</p>
              </Link>
              <Link to="/help?1">
                <img src={require("./../../img/c_right.png")} alt="" />
                <p>查看更多</p>
              </Link>
            </div>
            <h6 className="h6_title">客服专线</h6>
            <div
              className="home_server_box"
              style={{ height: "210px" }}
            >
              <h5 style={{ lineHeight: "30px" }}>400-000-1102</h5>
              <div className="home_right_code">
                <img src={require("./../../img/qrcode_for.png")} alt="" />
              </div>
              <p style={{ lineHeight: "30px" }}>扫码关注讯拍网</p>
            </div>
          </div>
        </div>

        <div className="home_process_box">
          <Link to="/help?2&id=233">
            <div className="home_process_title">竞拍流程</div>
          </Link>
          <Link to="/help?2&id=233">
            <div className="process_steps">
              <span></span>
              <p>阅读公告</p>
            </div>
          </Link>
          <RightOutlined style={{ color: "#DCE7F8" }} />
          <Link to="/help?2&id=233">
            <div className="process_steps">
              <span className="ste1"></span>
              <p>实地看样</p>
            </div>
          </Link>
          <RightOutlined style={{ color: "#DCE7F8" }} />
          <Link to="/help?2&id=233">
            <div className="process_steps">
              <span className="ste2"></span>
              <p>交保证金</p>
            </div>
          </Link>
          <RightOutlined style={{ color: "#DCE7F8" }} />
          <Link to="/help?2&id=233">
            <div className="process_steps">
              <span className="ste3"></span>
              <p>开始竞拍</p>
            </div>
          </Link>
          <RightOutlined style={{ color: "#DCE7F8" }} />
          <Link to="/help?2&id=233">
            <div className="process_steps">
              <span className="ste4"></span>
              <p>竞拍成功</p>
            </div>
          </Link>
          <RightOutlined style={{ color: "#DCE7F8" }} />
          <Link to="/help?2&id=207">
            <div className="process_steps">
              <span className="ste5"></span>
              <p>支付尾款</p>
            </div>
          </Link>
          <RightOutlined style={{ color: "#DCE7F8" }} />
          <Link to="/help?2&id=214">
            <div className="process_steps">
              <span className="ste6"></span>
              <p>办理交割</p>
            </div>
          </Link>
        </div>

        {/* 折扣房源 */}
        {
          banner.length > 0 &&
          <div className="today_title floor_home" id="jrpm">
            <h6>折扣房源</h6>
            <div className="today_onlookers">
              <Link to={`/discount?type=1`}>
                更多{">"}
              </Link>
            </div>
          </div>
        }
        <div className="discount_box">
          {
            banner.length > 0 &&
            <Swiper
              spaceBetween={50}
              slidesPerView={banner.length >= 3 ? 3 : 1}
              slidesPerGroup={3}
              navigation
              loop
            >
              {banner &&
                banner.map((item: any, index: number) => {
                  if (item.main_pc) {
                    if (item.imgs_pc.length) {
                      return (
                        <SwiperSlide className='swiperBanner'>
                          <Link to={`/banner_link?${item.index}`} target="_blank">
                            <img
                              src={`https://file.qqpai.com${item.main_pc}`}
                              alt=""
                            />
                          </Link>
                        </SwiperSlide>
                      );
                    } else {
                      return (
                        <SwiperSlide className='swiperBanner'>
                          <div>
                            <img
                              src={`https://file.qqpai.com${item.main_pc}`}
                              alt=""
                            />
                          </div>
                        </SwiperSlide>
                      );
                    }
                  }
                })}
            </Swiper>
          }
        </div>
        {/* 今日推荐 */}
        <div className="today_auction">
          <div className="today_left" style={{ width: "100%" }}>
            <div className="today_type_box">
              <em className="mq" style={{ width: "33px", height: "18px" }}></em>{" "}
              名企资产 &emsp;&emsp;
              <b style={{ fontSize: "12px" }}>企业破产资产拍卖</b>
              <Link
                style={{ lineHeight: "20px" }}
                to={`/bankruptcy_enterprise_list`}
                className="new"
              >
                更多企业{">"}
              </Link>
            </div>

            <div className="enterprise_list_box">
              {enterprise_list.map((item: any, index: number) => {
                let discount = 3.2;
                if (index === 1) {
                  discount = 4.8;
                }
                if (index === 2) {
                  discount = 5.5;
                }
                if (index === 3) {
                  discount = 6.7;
                }
                return (
                  <Link to={`/bankruptcy_enterprise_home?${item.id}`}>
                    <div className="li_img">
                      <img
                        src={
                          item.pic[0].indexOf("img.qqpai.com") > -1
                            ? `${item.pic[0]}!w400`
                            : item.pic[0]
                        }
                        onError={(e: any) => {
                          e.target.src = NotImg
                        }}
                        alt=""
                      />
                      <div className="li_status">
                        <div className={`li_status_left status_af`}>破</div>
                        <div className="li_status_right">
                          {item.start_time}开拍
                        </div>
                      </div>
                    </div>
                    <h5>{item.title}</h5>
                    <p>所在地：{item.city}</p>
                    <p>不只{discount} 折</p>
                    <p>{item.count}件拍品</p>
                  </Link>
                );
              })}
            </div>

            {today_real_total > 0 && (
              <div className="today_type_box" style={{ marginTop: "10px" }}>
                <em></em> 房产
                <span>
                  <Link
                    to={`/bankruptcy_matter_list?matterType=${category_id}`}
                  >
                    共{today_real_total}件 &emsp; 更多{">"}
                  </Link>
                </span>
              </div>
            )}

            <List
              notList={false}
              list={today_real_list}
              link="/bankruptcy_matter_details"
            />

            {vehicle_total > 0 && (
              <div className="today_type_box">
                <em className="jidongche"></em> 机动车
                <span>
                  <Link to={`/bankruptcy_matter_list?matterType=${vehicle_id}`}>
                    共{vehicle_total}件 &emsp; 更多{">"}
                  </Link>
                </span>
              </div>
            )}
            <List
              notList={false}
              list={today_vehicle_list}
              link="/bankruptcy_matter_details"
            />

            {equipment_total > 0 && (
              <div className="today_type_box">
                <em className="jixie"></em>
                <b>机械设备</b>
                <span>
                  <Link
                    to={`/bankruptcy_matter_list?matterType=${equipment_id}`}
                  >
                    共{equipment_total}件 &emsp; 更多{">"}
                  </Link>
                </span>
              </div>
            )}

            <List
              notList={false}
              list={today_equipment_list}
              link="/bankruptcy_matter_details"
            />
            <div className="today_type_box">
              <em className="qita"></em>
              <b>资产</b>
              <b
                onClick={() => {
                  set_other_id("50025970");
                }}
                className={other_id === "50025970" ? "active" : ""}
              >
                土地
              </b>
              <b
                onClick={() => {
                  set_other_id("50025976");
                }}
                className={other_id === "50025976" ? "active" : ""}
              >
                其他
              </b>
              <span>
                <Link to={`/bankruptcy_matter_list?matterType=${other_id}`}>
                  共{other_total}件 &emsp; 更多{">"}
                </Link>
              </span>
            </div>
            <List
              notList={false}
              list={today_other_list}
              link="/bankruptcy_matter_details"
            />
          </div>
        </div>
        <FooterCode />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
