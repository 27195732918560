import React, { useEffect, useState } from "react";
import Header from "./../../header";
import Footer from "./../../footer";
import axios from "./../../api";
import { Spin } from "antd";
import { Link,useLocation } from "react-router-dom";
import "./index.scss";

const Cooperation: React.FC = () => {
    const ids = useLocation().search;
    const [cooperation_list, set_cooperation_list] = useState([]);
    const [loading, set_loading] = useState(true);
    const getZcOrg = async () => {
        const result: any = await axios.http.get(axios.api.zc_org);
        if (result.status === 0) {
            set_cooperation_list(result.result);
        }
        set_loading(false)
    };
    
    useEffect(() => {
        getZcOrg();
    }, []);
    return (
        <div>
            <Header />
            <div className="session">
                <Spin spinning={loading} tip="数据加载中...">
                    <div className="cooperation_box">
                        {
                            cooperation_list && cooperation_list.map((item: any, index: number) => {
                                return (
                                    <div className="cooperation_list" key={item.value}>
                                        <div className="cooperation_title">
                                            <Link target="_blank" to={`/assets_announcement_type?${index}`} >
                                                {item.label}
                                            </Link>
                                        </div>
                                        {
                                            item.children && item.children.map((child: any) => {
                                                return (
                                                    <Link target="_blank" className={child.label.length > 18 ? "len" : ''} to={`/assets_court_list?${child.value}`} key={child.value}>{child.label}</Link>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }

                    </div>
                </Spin>
            </div>
            <Footer />
        </div>
    )
}

export default Cooperation;