import React, { useEffect, useState } from "react";
import Header from "./../../header";
import Footer from "./../../footer";
import List from "./list";


const MatterList = () => {
    

    return (
        <div id="list_top">
            <Header />
            <div className="session">
                <List />
            </div>
            <Footer />
        </div>
    )
}
export default MatterList;