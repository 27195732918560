import React, { useEffect, useState } from "react";
import Header from "./../header";
import Footer from "./../footer";
import axios from './../api';
import { Spin } from "antd";
import { Link } from "react-router-dom";
import "./index.scss";
const host = process.env.NODE_ENV === 'production';
const CourtList = () => {

    const [szm, set_szm] = useState("A");
    const [provinces_top, set_provinces_top]: any = useState([]);

    const [spinning, set_spinning] = useState(true);
    const scrollLocation = (indexStr: string) => {
        const widget: any = document.getElementById('provinces_' + indexStr);
        const scrollTop = window.scrollY;
        window.scrollTo(0, widget.getBoundingClientRect().top + scrollTop - 70)
    }

    const fullCityCourt = async () => {
        const result: any = await axios.http.get(axios.api.courtList, {});
        set_provinces_top(result);
        set_spinning(false);
        auctionCount()
    }

    const auctionCount = async () => {
        const result: any = await axios.http.newGet(axios.api.auction_count, {});
        if (result.status === 0) {
            for (let id in result.result) {
                const numberDom: any = document.getElementById(id);
                numberDom && (numberDom.innerText = result.result[id])
            }
        }
    }

    const provinces_rolling = () => {
        const court_szm_box: any = document.getElementById("court_szm_box");
        const dom_id: any = document.getElementById('provinces_A');

        window.addEventListener('scroll', (event: any) => {
            const provinces_boxs: any = Array.from(document.querySelectorAll(".provinces_box"));
            const A_top = dom_id && dom_id.getBoundingClientRect().top;
            provinces_boxs.map((item: any) => {
                const top = item.getBoundingClientRect().top;
                if (top > 100 && top < 200) {
                    const szm = item.getAttribute("title")
                    set_szm(szm);
                }
            })
            if (A_top > 200 && A_top < 430) {
                set_szm("A");
            }
            const scrollTop = (event.srcElement ? event.srcElement.documentElement.scrollTop : false) || window.pageYOffset || (event.srcElement ? event.srcElement.body.scrollTop : 0);
            if (scrollTop > 340) {
                court_szm_box.className = "court_szm_box szm_position"
            } else {
                court_szm_box.className = "court_szm_box";
            }
        })
    };
    useEffect(() => {
        provinces_rolling();
        fullCityCourt();
    }, []);
    return (
        <div>
            <Header />
            <div className="session">
                <Spin tip="数据加载中..." spinning={spinning}>
                    <div className="court_list_box">
                        <div className="court_szm_box" id="court_szm_box">
                            {
                                !spinning && <span>省份首字母</span>
                            }
                            {
                                provinces_top && provinces_top.map((item: any) => {
                                    return (
                                        <span key={item.label}
                                            className={item.label === szm ? "active" : "zmSpan"}
                                            onClick={() => { set_szm(item.label); scrollLocation(item.label) }}>{item.label}</span>
                                    )
                                })
                            }
                        </div>
                        {
                            provinces_top && provinces_top.map((item: any) => {
                                return (
                                    <div key={item.label} className="provinces_box" title={item.label} id={`provinces_${item.label}`}>
                                        <div className={item.label === szm ? "provinces_title active" : "provinces_title"}>
                                            {item.label}
                                        </div>


                                        {
                                            item.children && item.children.map((two: any) => {
                                                return (
                                                    <div key={two.label} className="two">
                                                        <div>
                                                            <h6>{two.label}</h6>
                                                            <div className="two1">
                                                                {/* {
                                                                    two.children && two.children.map((court: any) => {
                                                                        return (
                                                                            <Link className={court.label.length > 13 ? "len" : ''} to={`/court_list?${court.id}`}>
                                                                                {court.label}({court.count})
                                                                            </Link>
                                                                        )
                                                                    })
                                                                } */}
                                                            </div>
                                                        </div>
                                                        <div className="province_two_box">
                                                            {
                                                                two.children && two.children.map((three: any, index: number) => {
                                                                    return (
                                                                        <div key={three.label + index}>
                                                                            <h5>{three.label}</h5>
                                                                            <div className="province_three_box">
                                                                                {
                                                                                    three.children && three.children.map((four: any, index: number) => {
                                                                                        return <div key={index + four.length} style={{ paddingBottom: `${four.length ? 0 : 20}px` }}>
                                                                                            {
                                                                                                four && four.length > 0 && four.map((five: any, index: number) => {
                                                                                                    return <Link key={five.value} className={five.label && five.label.length > 13 ? "len" : ''} to={`/court_list?${five.value}`}>
                                                                                                        {five.label} (<span id={five.value}>0</span>)
                                                                                                            </Link>;
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </Spin>
            </div>
            <Footer />
        </div>
    )
}

export default CourtList;