import React, { useEffect, useImperativeHandle, useState,forwardRef } from "react";
import { Pagination } from "antd";


interface pag {
    pageSize: number;
    pageSizeOptions?: string[];
    current: number;
    total: number;
    onShowSizeChange: Function;
    onChange: Function;
}

const Paging = (props: pag) => {
    return (
        <Pagination showQuickJumper
            hideOnSinglePage={true}
            pageSize={props.pageSize}
            pageSizeOptions={props.pageSizeOptions ? props.pageSizeOptions : ['20', '30', '50']}
            onShowSizeChange={(current, size) => {
                props.onShowSizeChange(size)
            }}
            current={props.current}
            total={props.total}
            onChange={(page) => {
                props.onChange(page)
            }}
        />
    )
}

export default forwardRef(Paging);