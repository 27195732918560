import React, { useEffect, useState } from "react";
import Header from "./../../header";
import Footer from "./../../footer";
import { Button, Modal, Form, Input, message } from "antd";
import axios from "./../../api";
import "./index.scss";
import { useLocation } from 'react-router-dom';
import { observer } from "mobx-react";
import { HeaderType } from "./../../storesMobx";

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 11, span: 12 },
};

let time: any = null;
const AssetsDetails = () => {
    const ids = useLocation().search;
    const path = useLocation().pathname;
    const search = useLocation().search;
    const { loginWx, loginUser }: any = HeaderType();
    const [visible, set_visible] = useState(false);
    const [details, set_details] = useState({
        attachment: [],
        org_title: "",
        other: [],
        price: "",
        category: "",
        phone: "",
        title: "",
        status: 0,
        commit: false
    });
    const [phone, set_phone] = useState('');

    const [info, set_info] = useState("")
    const [more, set_more] = useState("")
    const [mortgage, set_mortgage] = useState("")

    const [times, set_times] = useState(60);
    const [interval, set_Interval] = useState(false);

    const onFinish = async (values: any) => {
        const id = ids.slice(1).split("&")[0]
        const result: any = await axios.http.post(axios.api.zc_investment_intent, { ...values, investment_id: id, content: values.content || "" })
        if (result.status === 0) {
            message.success("请保持电话畅通，我们将在7个工作日内与您联系");
            set_details({ ...details, commit: true })
            set_visible(!visible)
        } else if (result.status === -101) {
            loginWx(path, search)
        } else {
            message.error(result.message)
        }

    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const getZcTj = async () => {
        const id = ids.slice(1).split("&")[0]
        const result: any = await axios.http.post(`${axios.api.get_zc_tj}?id=${id}`, {})
        if (result.status === 0) {
            set_details({ ...result.result })
        }
    }

    const getInfo = async () => {
        const result: any = await axios.http.get(`${axios.api.zc_tj_info}${ids.slice(1)}.txt`, {})
        set_info(result)
    }
    const getTjMore = async () => {
        const result: any = await axios.http.get(`${axios.api.zc_tj_more}${ids.slice(1)}.txt`, {})
        set_more(result)
    }
    const getTjMortgage = async () => {
        const result: any = await axios.http.get(`${axios.api.zc_tj_mortgage}${ids.slice(1)}.txt`, {})
        set_mortgage(result)
    }
    const getVerificationCode = async () => {
        if (!/^1[3456789]\d{9}$/.test(phone)) {
            message.error("手机号码不正确")
            return
        }
        set_Interval(true);
        const result: any = axios.http.post(axios.api.get_verification_code, {
            phone,
            type: "4"
        })
    }

    useEffect(() => {
        getZcTj();
        getInfo();
        getTjMore();
        getTjMortgage();
    }, [])

    useEffect(() => {
        if (interval) {
            time = setInterval(() => {
                let num = times;
                num -= 1;
                if (num === 0) {
                    num = 60;
                    clearInterval(time);
                    set_Interval(false)
                }
                set_times(num);
            }, 1000);
        }
        return () => clearInterval(time);
    }, [times, interval]);
    return (
        <div>
            <Header />
            <div className="session promote_box">
                <h6>{details.title}</h6>
                <div className="promote_table">
                    <div>
                        <span>资产处置主体</span>
                        <div>{details.org_title}</div>
                    </div>
                    <div>
                        <span>资产类型</span>
                        <div>{details.category}</div>
                    </div>
                    <div>
                        <span>咨询电话</span>
                        <div>{details.phone || "无"}</div>
                    </div>
                    {
                        details.other && details.other.map((item: any) => {
                            return (
                                <div key={item.key}>
                                    <span>{item.key}</span>
                                    <div>{item.value}</div>
                                </div>
                            )
                        })
                    }

                    <div>
                        <span>抵押物</span>
                        <div dangerouslySetInnerHTML={{ __html: mortgage }}></div>
                    </div>
                    <div>
                        <span>公告详情</span>
                        <div dangerouslySetInnerHTML={{ __html: info }}></div>
                    </div>
                    <div>
                        <span>更多</span>
                        <div dangerouslySetInnerHTML={{ __html: more }}></div>
                    </div>
                </div>
                {
                    details.attachment && details.attachment.length > 0 && <h1>其他附件下载：</h1>
                }

                {
                    details.attachment && details.attachment.map((item: any) => {
                        return (
                            <p>
                                <a target="_blank" href={item.id}>{item.title}</a>
                            </p>
                        )
                    })
                }
                <Button type="primary" onClick={() => {
                    const user = loginUser || localStorage.getItem('user');
                    if (!user) { loginWx(path, search); return false }; set_visible(!visible)
                }} disabled={(!details.status || details.commit) ? true : false}>
                    我有意向
                </Button>
            </div>

            <Modal
                width={700}
                title="填写意向书"
                footer={null}
                destroyOnClose={true}
                onCancel={() => { set_visible(!visible) }}
                visible={visible}>
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="姓名"
                        name="name"
                        rules={[{ required: true, message: '姓名不能为空!' }]}
                    >
                        <Input maxLength={16} placeholder="请输入姓名" />
                    </Form.Item>

                    <Form.Item
                        label="电话号码"
                        name="phone"
                        rules={[{ required: true, pattern: /^1[3456789]\d{9}$/, message: '电话号码不正确' }]}
                    >
                        <Input maxLength={11} onChange={(e) => { set_phone(e.target.value) }} placeholder="请输入您的联系方式" />
                    </Form.Item>

                    <Form.Item
                        label="验证码"
                        rules={[{ required: true, message: '验证码不能为空' }]}
                    >
                        <Input.Group compact>
                            <Form.Item
                                rules={[{ required: true, message: '验证码不能为空' }]}
                                name="code" style={{ width: "200px" }}>
                                <Input placeholder="请输入验证码" style={{ width: "200px" }} />
                            </Form.Item> &emsp;
                                <Form.Item>
                                <Button type="primary"
                                    disabled={interval}
                                    onClick={getVerificationCode}>
                                    {
                                        !interval ? "获取验证码" : `${times}s后重新获取`
                                    }
                                </Button>
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>

                    <Form.Item
                        label="意向说明"
                        name="content"
                    >
                        <Input.TextArea maxLength={500} />
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" >
                            确认提交
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Footer />
        </div>
    )
}

export default AssetsDetails;