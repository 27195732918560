import React, { useEffect, useState } from "react";
import Header from "./../../header";
import Footer from "./../../footer";
import axios from "./../../api";
import NotImg from "./../../img/da@2x.png";
import { Spin, Result } from "antd";
import { Link } from "react-router-dom";
import Paging from "./../../component/pagination";
import "./../../matterList/index.scss";
import "./index.scss";

const Enterprise: React.FC = () => {
    const [place_province_left, set_place_province_left] = useState(0);
    const [province_id, set_province_id] = useState(0);  //省ID
    const [city_id, set_city_id] = useState(0);  //市ID
    const [area_id, set_area_id] = useState(0);  //县区ID
    const [id_type, set_id_type] = useState('province');  //城市级别
    const [province_list, set_province_list]: any = useState([]); //省级列表
    const [city_list, set_city_list]: any = useState([]); //市级列表
    const [area_list, set_area_list]: any = useState([]); //县区列表
    const [list_search, set_list_search]: any = useState({
        city_id: "",   //标的所在地
        status: "",   //标的状态 0进行中 1未开始 2已结束 3终止 4撤回
        title: "",
        page_num: 1,
        page_size: 30
    })

    const [list, set_list] = useState([]);
    const [total, set_total] = useState(-1);
    const [not_list, set_not_list] = useState(false);
    const [spinning, set_spinning] = useState(false);

    // 获取城市列表
    const getCity = async () => {
        let cityId = 0;
        if (id_type == "province" || id_type == "city") {
            cityId = province_id;
        }
        if (id_type == "area") {
            cityId = city_id;
        }
        const res: any = await axios.http.newGet(`${axios.api.sub_city}?id=${cityId}`, {})
        if (res.status === 0) {
            if (id_type === "province") {
                set_province_list(res.result)
            } else if (id_type == "city") {
                set_city_list(res.result)
            } else {
                set_area_list(res.result)
            }
        }
    }

    //省点击时位置的改变
    const provinceAdd = (e: any, id: number) => {
        let tagName = null;
        let left = 0;
        if (e) {
            tagName = e.target.tagName;
            left = e.target.offsetLeft;
        } else {
            const item: any = document.getElementById(`${id}`);
            tagName = item.tagName;
            left = item.offsetLeft
        }
        set_id_type("city");
        set_city_id(0);
        set_province_id(id);
        if (tagName == "SPAN") {
            set_place_province_left(-left + 22)
        } else {
            set_place_province_left(-left)
        }
    }

    //获取列表
    const auctionList = async () => {
        set_spinning(true)
        const queryBody: any = {};
        for (let key in list_search) {
            if ((list_search[key] || list_search[key] === 0) && list_search[key] !== -1) {
                queryBody[key] = list_search[key]
            }
        }
        const result: any = await axios.http.newGet(axios.api.pc_manage_list, { ...queryBody });
        if (result.status === 0) {
            set_not_list(result.result.data.length === 0);
            set_total(result.result.total);
            set_list(result.result.data);
            const dom = document.getElementById("list_top")
            dom && dom.scrollIntoView({ behavior: "smooth" });
        }
        set_spinning(false)
    }

    useEffect(() => {
        getCity();
    }, [province_id, city_id, getCity]);

    // 查询列表
    useEffect(() => {
        auctionList();
    }, [auctionList, list_search]);

    return (
        <div>
            <Header />
            <div className="session">
                <ul className="list_conditions_box">
                    <li>
                        <span>所在地</span>
                        <div className="class_list">
                            <div onClick={() => { set_province_id(0); set_list_search({ ...list_search, city_id: "", page_num: 1 }) }} className={province_id == 0 ? "class_list_span active" : "class_list_span"}>
                                <span>不限</span>
                            </div>
                            <div className="class_list_multilayer_box">
                                {
                                    province_list && province_list.map((item: any, index: number) => {
                                        return (
                                            <div onClick={(e) => { provinceAdd(e, item.id); set_list_search({ ...list_search, city_id: `${item.id}`, page_num: 1 }) }}
                                                key={item.id}
                                                id={item.id}
                                                className={province_id == item.id ? "place_province active" : "place_province"}>
                                                <span>
                                                    {item.shortname}
                                                    {
                                                        province_id !== item.id && " ∨"
                                                    }
                                                    {
                                                        province_id == item.id && " ∧"
                                                    }
                                                </span>
                                                <div className="place_province_secondary_box" style={{ left: `${place_province_left}px` }}>
                                                    <div style={{ width: "80px" }}
                                                        onClick={(e) => { e.stopPropagation(); set_city_id(0); set_list_search({ ...list_search, city_id: `${item.id}`, page_num: 1 }) }}
                                                        className={city_id == 0 ? "class_list_span active" : "class_list_span"}>
                                                        <span>全省</span>
                                                    </div>
                                                    <div className="class_list_multilayer_box">
                                                        {
                                                            city_list && city_list.map((city: any) => {
                                                                return (
                                                                    <div onClick={(e) => { e.stopPropagation(); set_id_type("area"); set_city_id(city.id); set_list_search({ ...list_search, city_id: `${city.id}`, page_num: 1 }) }}
                                                                        key={city.id}
                                                                        className={city_id == city.id ? "place_province active" : "place_province"}>
                                                                        <span>
                                                                            {city.shortname}
                                                                            {
                                                                                city_id !== city.id && " ∨"
                                                                            }
                                                                            {
                                                                                city_id == city.id && " ∧"
                                                                            }
                                                                        </span></div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            city_id !== 0 &&
                                                            <div className="class_list_multilayer_area">
                                                                <div className="place_province_secondary_box">
                                                                    <div style={{ width: "80px" }}
                                                                        onClick={(e) => { e.stopPropagation(); set_area_id(0);; set_list_search({ ...list_search, city_id: `${city_id}`, page_num: 1 }) }}
                                                                        className={area_id == 0 ? "class_list_span active" : "class_list_span"}>
                                                                        <span>全部</span>
                                                                    </div>
                                                                    <div className="class_list_multilayer_box">
                                                                        {
                                                                            area_list && area_list.map((area: any) => {
                                                                                return (
                                                                                    <div onClick={(e) => { e.stopPropagation(); set_id_type("area"); set_area_id(area.id); set_list_search({ ...list_search, city_id: `${area.id}`, page_num: 1 }) }}
                                                                                        key={area.id}
                                                                                        className={area_id === area.id ? "place_province active" : "place_province"}>
                                                                                        <span>
                                                                                            {area.shortname}
                                                                                        </span>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                    </li>
                    <li>
                        <span>上拍情况</span>
                        <div className="class_list">
                            <div onClick={() => { set_list_search({ ...list_search, status: "", page_num: 1 }) }} style={{ width: "60px" }} className={list_search.status === "" ? "class_list_span active" : "class_list_span"}>
                                <span>不限</span>
                            </div>
                            <div className="class_list_multilayer_box">
                                <div onClick={() => { set_list_search({ ...list_search, status: "1", page_num: 1 }) }} className={list_search.status === "1" ? "class_list_div active" : "class_list_div"}>
                                    <span >已上拍的企业</span>
                                </div> &emsp;
                                <div onClick={() => { set_list_search({ ...list_search, status: "0", page_num: 1 }) }} className={list_search.status === "0" ? "class_list_div active" : "class_list_div"}>
                                    <span >准备中的企业</span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <Spin tip="加载中..." spinning={spinning}>
                    {
                        total !== 0 && <div className="enterprise_box">
                            {
                                list && list.map((item: any) => {
                                    return (
                                        <Link key={item.id} to={`/bankruptcy_enterprise_home?${item.id}`}>
                                            <h5 title={item.title}>{item.title}</h5>
                                            <p>所在地：{item.city}</p>
                                            <div>
                                                <img src={item.pic[0] ? item.pic[0] && item.pic[0].indexOf("img.qqpai.com") > -1 ? `${item.pic[0]}!w400` : item.pic[0] : NotImg} alt="" onError={(e: any) => { e.target.src = NotImg }} />
                                                <div style={item.pic[1] && {
                                                    background: `rgba(0,0,0,.35) url(${item.pic[1] && item.pic[1].indexOf('img.qqpai.com') > -1 ? `${item.pic[1]}!w400` : item.pic[1]})`,
                                                    backgroundSize: "100% 100%"
                                                }}>
                                                    <span>{item.count}件拍品</span>
                                                    <button>点击查看详情</button>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })
                            }

                        </div>
                    }

                    {
                        total === 0 && <Result
                            status="404"
                            title="很抱歉"
                            subTitle="没有您要找的标的物，换个条件试试~"
                        />
                    }
                </Spin>



                <div className="more_box">
                    <Paging
                        pageSize={list_search.page_size}
                        current={list_search.page_num}
                        pageSizeOptions={['30', '60', '90']}
                        total={total}
                        onShowSizeChange={(size: number) => {
                            set_list_search({ ...list_search, page_size: size })
                        }}
                        onChange={(page: number) => {
                            set_list_search({ ...list_search, page_num: page })
                        }}
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Enterprise