import React, { useEffect } from "react";
import Header from "./../../header";
import Footer from "./../../footer";
import { Button } from "antd";
import "./index.scss";
import { useLocation, Link } from 'react-router-dom';
const ProcessUser = () => {
    const number = useLocation().search;

    useEffect(() => {
        const dom = document.getElementById("bank_box");
        dom && dom.scrollIntoView();
    }, [])
    return (
        <div id="bank_box">
            <Header />
            <div className="session">
                {
                    number === "?1" && <div className="in_box">
                        <h6 className="title_h6"><span>定义</span>及准入</h6>
                        <h5> 定义及准入 </h5>
                        <p>
                            破产管理人系指破产案件中经人民法院审定，编入管理人名册，在法院的指挥和监督之下全面接管破产财产并负责对其进行保管、清理、估价、处理和分配的
                            律师事务所、会计师事务所、破产清算事务所等社会中介机构。
                    </p>
                        <p>
                            破产资产推介功能仅对人民法院和人民法院指定的破产管理人开放。
                    </p>

                        <h6 className="title_h6" style={{ lineHeight: "100px" }}><span>破产资产</span>的发布与规范</h6>
                        <p>
                            1、标的发布管理： 破产管理人须通过司法拍卖、诉讼资产平台发布与管理破产资产标的物。<br />
                        2、资产推介管理：<br />
                        &emsp;（1）破产资产推介项目的发布说明及要求：<br />
                        &emsp;&emsp;1）发布前需要申请资产推介功能的权限。<br />
                        &emsp;&emsp;2）推介项目原则上不低于500万，项目本身较复杂或者价值大，需要买家长时间的了解、磋商。<br />
                        &emsp;&emsp;3）破产管理人发布的破产资产推介项目需满足下列条件：<br />
                        &emsp;&emsp;&emsp;i.破产申请已被法院裁定受理；<br />
                        &emsp;&emsp;&emsp;ii.法院已决定指定破产发布者为裁定受理案件的破产管理人；<br />
                        &emsp;&emsp;&emsp;iii.所发布的推介项目的资产为破产清算案件中的即将处置的破产资产，或者重整案件中涉及到需以拍卖方式处置的破产资产（暂不接受仅为招募企业重整投资人的推介）。<br />
                        4）破产管理人应保证推介项目的真实性和合法性，依据国家法律法规及相关监管政策之要求，对项目介绍、项目存在的质量或权利瑕疵等必须说明的信息进行真实、完整的描述。
                    </p>
                        <p>
                            &emsp;（2）推介项目的跟进管理：<br />
                        &emsp;&emsp;1）破产管理人需及时与意向人沟通、反馈。<br />
                        &emsp;&emsp;2）如果资产推介结束，或因其他特定原因不继续推介，请及时撤回推介中的项目。<br />
                        &emsp;&emsp;3）请注意意向人的信息安全，禁止将意向人信息转让给第三方。<br />
                        &emsp;（3）交易规范：<br />
                        &emsp;&emsp;1）当破产推介项目征集到意向人时，转至司法拍卖/诉讼资产平台发布破产标的的拍卖公告和竞买须知并进行拍卖。<br />
                        &emsp;&emsp;2）通过资产交易平台完成推介的项目，不得通过平台以外的渠道售卖标的物，如有特殊情况，请提前与平台工作人员沟通说明原因。<br />
                        &emsp;&emsp;3）撤回破产资产请说明撤回原因。
                    </p>

                        <Button type="primary">
                            <Link to="/bankruptcy_user_data">申请成为服务商</Link>
                        </Button>
                    </div>
                }

                {
                    number === "?2" && <div className="in_box">
                        <h6 className="title_h6"><span>破产资产</span>处置服务商入驻</h6>
                        <h5> 服务内容 </h5>
                        <p>
                            1、提供咨询服务-通过线下客服中心/及网络客服中心7*24小时提供竞买咨询服务，包括拍卖标的咨询、权属过户咨询、物业交接咨询、竞买流程咨询等。<br />
                            2、组织集中预展-提供集中实地看样服务，不符合现场预展条件的可提供网上预展服务。<br />
                            3、提交辅助服务工作报告-委托标的拍卖成交后1个工作日内，提供辅助服务工作报告。<br />
                            4、权属过户服务-提供权属过户服务，并接受买受人委托协助办理权属过户服务。<br />
                            5、税费代缴服务-提供税费缴纳咨询服务，并接受买受人委托协助办理税费代缴服务。<br />
                            6、融资咨询服务-提供融资咨询服务，接受买受人委托协助办理拍卖融资服务。<br />
                            7、物业交接服务-为买受人提供物业交接咨询服务，接受管理人委托协助办理物业交接服务。
                        </p>

                        <h5> 入驻要求 </h5>

                        <p>
                            （一）已入驻腾云讯拍专用资产交易服务类目； <br />
                            （二）企业店铺名必须与企业营业执照上的公司名称高度关联，店铺名称且需包含公司名称核心字段，店铺使用商标需为注册商标； <br />
                            （三）入驻需提供营业执照的清晰照片，且公司依据相关法律法规正常开展年审或提交企业年报； <br />
                            （四）入驻服务商具有破产资产处置、拍辅行业、竞买人服务经验的优先考虑；  <br />
                            （五）入驻服务商需具备破产资产处置的渠道资源和拓展、维护能力； <br />
                            （六）需缴纳不低于10万元的保证金费用；
                        </p>
                        <Button type="primary">
                            <Link to="/bankruptcy_server_data">申请成为服务商</Link>
                        </Button>
                    </div>
                }
            </div>
            <Footer />
        </div>
    )
}

export default ProcessUser;