import React from "react";

import zy from "./../img/中共中央政法委员会.png";
import zg from "./../img/最高人民法院.png";
import pm from "./../img/中国拍卖行业协会.png";
import cq from "./../img/中国企业国有产权交易机构协会.png";
import gg from "./../img/全国公共资源交易平台.png";
import kefu from "./../img/kefu_home.png";
import pc from "./../img/pc_code.jpg";
import Data from "./../home/data";
import { Link } from "react-router-dom";
import "./../home/index.scss";

const jigou = [
  {
    href: "http://www.chinapeace.gov.cn/",
    name: "中共中央政法委员会",
    url: zy,
  },
  {
    href: "http://www.court.gov.cn/",
    name: "中华人民共和国最高人民法院",
    url: zg,
  },
  {
    href: "https://www.rmfysszc.gov.cn/",
    name: "人民法院诉讼资产网",
    url: "",
  },
  {
    href: "http://pccz.court.gov.cn/pcajxxw/index/xxwsy",
    name: "全国企业破产重整案件信息网",
    url: pc,
  },
  {
    href: "http://www.caa123.org.cn",
    name: "中国拍卖行业协会",
    url: pm,
  },
  {
    href: "http://www.ggzy.gov.cn",
    name: "全国公共资源交易平台",
    url: gg,
  },
  {
    href: "http://www.cspea.org.cn",
    name: "中国企业国有产权交易机构协会",
    url: cq,
  },
];
const FooterCOde: React.FC = () => {
  return (
    <div className="cooperation_court">
      <div className="cooperation_court_box">
        <h6>指导机构</h6>
        <div className="cooperation_court_list">
          {jigou.map((item: any,index:number) => {
            return (
              <div className="guide_list" key={item.name}>
                <a target="_blank" rel="noopener noreferrer" href={item.href}>
                  {item.name}
                </a>
                {item.url && (
                  <div className="code">
                    <img src={item.url} alt="" />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <div className="cooperation_court_box">
        <h6>合作法院</h6>
        <div className="cooperation_court_list">
          {Data.courtList.map((item: any) => {
            return (
              <div className="court_list" key={item.name}>
                <a target="_blank" rel="noopener noreferrer" href={item.id}>
                  {item.name}
                </a>
                <div className="code">
                  <img src={item.code} alt="" />
                </div>
              </div>
            );
          })}
          <div className="court_list">
            <Link to="/all_court_list">更多合作法院》</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterCOde;
