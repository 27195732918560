import React, { useEffect } from "react";
import Header from "./../../header";
import Footer from "./../../footer";
import { Link } from "react-router-dom";
import "./index.scss";

const AssetIn = () => {
    useEffect(() => {
        document.body.scrollIntoView();
    }, [])
    return (
        <div>
            <Header />

            <div className="session">
                <div className="banner_box">
                   
                </div>
                {/* <div className="instructions_box">
                    <h6 className="title_h6"><span>服务商</span>招商介绍</h6>
                    <p className="instructions_p">
                        腾云讯拍服务平台现面向广大服务商进行公开招商，主要涉及腾云讯拍下属的司法拍卖、破产资产、金融资产、非金融资产、贷款、保险、代办等业务。其中，服务商主要分为资产处置服务商、资产处置渠道商两大类，请服务商按照各业务的规则、流程进行报名。如有问题请拨打400-000-1102进行相关咨询。
                    </p>
                    <h6 className="title_h6"><span>服务商</span>招商</h6>
                    <div className="instructions_merchants_box">
                        <div className="instructions_merchants">
                            <h6>破产管理人入驻</h6>
                            <p>
                                破产管理人系指破产案件中经人民法院审定，编入管理人名册，在法院的指挥和监督之下全面接管破产财产并负责对其进行保管、清理、估价、处理和分配的律师事务所、会计师事务所、破产清算事务所等社会中介机构。
                            </p>
                            <Link to="/bankruptcy_process_user?1">点击入驻</Link>
                        </div>
                        <div className="instructions_merchants">
                            <h6>破产资产处置服务商入驻</h6>
                            <p>
                                做为破产资产处置生态中非常重要的一部分，立足腾云讯拍破产强清频道的处置场景，通过为破产管理人等发拍机构提供处置资产分析、评估、发布等基础服务，结合平台的VR全景看房等产品功能，帮助实现破产资产线上处置最大化价值。
                            </p>
                            <Link to="/bankruptcy_process_user?2">点击入驻</Link>
                        </div>
                    </div>
                </div> */}
            </div>
            <Footer />
        </div>
    )
}
export default AssetIn;