import React, { useState, useEffect } from "react";
import Header from "./../../header";
import Footer from "./../../footer";
import axios from "./../../api";
import { Link, useLocation } from "react-router-dom";
import List from "./../../component/list";
import { Spin } from "antd";
import Paging from "./../../component/pagination";
import "./../../courtList/index.scss";


const CourtList = () => {
    const id = useLocation().search;
    const [spinning, set_spinning] = useState(true);
    const [court_count, set_court_count] = useState({
        auction: 0,
        notice: 0,
        title: ""
    })

    const [total, set_total] = useState(0);

    const [list_search, set_list_search]: any = useState({
        category_id: "", //标的类型 没有时不传
        city_id: "",   //标的所在地
        status: "",   //标的状态 0进行中 1未开始 2已结束 3终止 4撤回
        time_type: "",  //单选开拍时间 3三天 7七天 30一月
        start_time: "",  //输入开拍起始时间 没有不传
        end_time: "",  //开拍结束时间 没有不传
        court_id: id.slice(1),  //法院ID
        sell_type: "",  //拍卖阶段 1一拍 2二拍 3重新 4变卖 没有不传
        can_loan: false, //可贷款  勾选传 true  未勾选不传
        limit_purchase: false,  //是否限购 同上
        start_price: "", //价格 没有不传
        end_price: "", //价格 没有不传
        sort_type: 1, //1默认 2价格 3次数
        sort_order: 0, //0升序 1降序
        page_num: 1,
        page_size: 20,
        org_id: id.slice(1) //机构id
    })

    const [list, set_list] = useState([]);
    const [not_list, set_not_list] = useState(false)




    //获取列表
    const auctionList = async () => {
        const queryBody: any = {};
        for (let key in list_search) {
            if ((list_search[key] || list_search[key] === 0) && list_search[key] !== -1) {
                queryBody[key] = list_search[key]
            }
        }
        const result: any = await axios.http.newGet(axios.api.zc_auction_list, { ...queryBody });

        if (result.status === 0) {
            let newData = result.result.data;
            set_not_list(newData.length === 0);
            set_total(result.result.total);
            set_list(newData);
            const dom = document.getElementById("list_top")
            dom && dom.scrollIntoView({ behavior: "smooth" });
        }

        set_spinning(false);
    }

    const getOrgCount = async () => {
        const result: any = await axios.http.newGet(axios.api.zc_org_count, { org_id: id.slice(1) });
        if (result.status === 0) {
            set_court_count({ ...result.result })
        }
    }

    useEffect(() => {
        getOrgCount();
    }, [])
    useEffect(() => {
        auctionList();
    }, [list_search])
    return (
        <div id="list_top">
            <Header />
            <div className="session">
                <div className="court_mark_list_box">
                    <div className="court_title">
                        <h6>
                            {court_count.title}
                        </h6>
                        <p>
                            拍卖公告 <span>{court_count.notice}</span>&emsp;|&emsp;
                            上拍次数 <span>{court_count.auction}</span>
                        </p>
                        <div className="court_type">
                            <Link to={`/assets_court_list${id}`} className="court_type_nav active">
                                机构标的物
                            </Link>
                            <Link to={`/assets_announcement${id}`} className="court_type_nav">
                                机构公告
                            </Link>
                        </div>
                    </div>
                    <Spin tip="加载中..." spinning={spinning}>
                        <div style={{ paddingTop: "40px", background: "#fff" }}>
                            <List notList={not_list} list={list} link="asset_matter_details" />
                        </div>
                    </Spin>
                    <div className="more_box">
                        <Paging
                            pageSize={list_search.page_size}
                            pageSizeOptions={['20', '32', '56']}
                            current={list_search.page_num}
                            total={total}
                            onShowSizeChange={(size: number) => {
                                set_list_search({ ...list_search, page_size: size })
                            }}
                            onChange={(page: number) => {
                                set_list_search({ ...list_search, page_num: page })
                            }}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CourtList;
