import React, { useEffect, useState } from "react";
import { Input, Form, message, Button } from "antd";
import { HeaderType } from "./../storesMobx";
import { Link } from "react-router-dom";
import axios from "./../api";
import "./bind.scss";
interface phoneType {
  type: "login" | "bind";
}
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { span: 18 },
};
let setTime: any = null;
const BindModal: React.FC<phoneType> = (props) => {
  const {
    bindChange,
    codeLogin,
  }: any = HeaderType();
  const onFinish = async (values: any) => {
    if (props.type === "login") {
      codeLogin(values);
    } else {
      const result: any = await axios.http.post(axios.api.binding_phone, {
        ...values,
      });
      if (result.status === 0) {
        let user = window.localStorage.getItem("user");
        let JSONUser = user && JSON.parse(user);
        JSONUser.phone = values.phone;
        localStorage.setItem('user',JSON.stringify(JSONUser))
        message.success("手机号码绑定成功");
        bindChange();
      } else {
        message.error(result.message);
      }
    }
  };
  const [phone, set_phone] = useState("");
  const [times, set_times] = useState(60);
  const [interval, set_Interval] = useState(false);
  useEffect(() => {
    if (interval) {
      setTime = setInterval(() => {
        let num = times;
        num -= 1;
        if (num === 0) {
          num = 60;
          clearInterval(setTime);
          set_Interval(false);
        }
        set_times(num);
      }, 1000);
    }
    return () => clearInterval(setTime);
  }, [times, interval]);
  const getVerificationCode = async () => {
    if (!/^1[3456789]\d{9}$/.test(phone)) {
      message.error("手机号码不正确");
      return;
    }
    set_Interval(true);
    const result: any = axios.http.post(axios.api.get_verification_code, {
      phone,
      type: props.type === "login" ? "0" : "5",
    });
  };
  return (
    <div className="phone_login">
      <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          label=""
          name="phone"
          rules={[
            {
              required: true,
              pattern: /^1[3456789]\d{9}$/,
              message: "电话号码不正确",
            },
          ]}
        >
          <Input
            maxLength={11}
            onChange={(e) => {
              set_phone(e.target.value);
            }}
            placeholder="请输入您的联系方式"
          />
        </Form.Item>
        <Form.Item
          label=""
          rules={[{ required: true, message: "验证码不能为空" }]}
        >
          <Input.Group compact>
            <Form.Item
              rules={[{ required: true, message: "验证码不能为空" }]}
              name="code"
              className="input_width"
              // style={{ width: "250px" }}
            >
              <Input
                maxLength={6}
                placeholder="请输入验证码"
                style={{ width: "240px" }}
              />
            </Form.Item>{" "}
            &emsp; &emsp;
            <Form.Item>
              <Button
                type="primary"
                className="code_btn"
                disabled={interval}
                onClick={getVerificationCode}
              >
                {!interval ? "获取验证码" : `${times}s后重新获取`}
              </Button>
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" className="btn_sub">
            {props.type === "login" ? "登录" : "确定绑定手机号码"}
          </Button>
        </Form.Item>
      </Form>
      <p style={{padding:"0 56px"}}>
        手机登录，即表示您同意
        <Link to="/liability" target="_blank">
          《免责声明》
        </Link> &nbsp;
        <Link to="/agreement" target="_blank">
          《用户注册协议》
        </Link>
      </p>
    </div>
  );
};

export default BindModal;
