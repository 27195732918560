import React, { useEffect, useState } from "react";
import Header from "../header";
import Footer from "../footer";
import List from "../component/list";
import { Select, Spin, Pagination } from "antd";
import moment from "moment";
import 'moment/locale/zh-cn';
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { HomeSearch,HeaderType } from "./../storesMobx";
import Paging from "./../component/pagination";
import "./index.scss";
moment.locale('en');


const MatterList = () => {
    let { list, onSearch, total, inputChange, value, loading } = HomeSearch();
    const { headerType, changeType ,cityId,city }: any = HeaderType();
    const parameterId = useLocation().search;
    let categoryId = "";
    let isMatterAdd = false;
    let urlLink = "/matter_details";
    if(headerType === "2"){
        urlLink = "/bankruptcy_matter_details"
    }
    if(headerType === "3"){
        urlLink = "/asset_matter_details"
    }
    if (parameterId.indexOf("matterType") > -1) {
        const addSplit = parameterId.split("=");
        categoryId = addSplit[1];
    }
    if (parameterId.indexOf("matterAdd") > -1) {
        isMatterAdd = true;
    }
    const [sf_home_id, set_sf_home_id] = useState(0);
    const [select_court_list, set_select_court_list] = useState([]);
    const [list_search, set_list_search]: any = useState({
        category_id: categoryId, //标的类型 没有时不传
        city_id: cityId,   //标的所在地
        status: "",   //标的状态 0进行中 1未开始 2已结束 3终止 4撤回
        time_type: "",  //单选开拍时间 3三天 7七天 30一月
        start_time: "",  //输入开拍起始时间 没有不传
        end_time: "",  //开拍结束时间 没有不传
        court_id: "",  //法院ID
        sell_type: "",  //拍卖阶段 1一拍 2二拍 3重新 4变卖 没有不传
        can_loan: false, //可贷款  勾选传 true  未勾选不传
        limit_purchase: false,  //是否限购 同上
        start_price: "", //价格 没有不传
        end_price: "", //价格 没有不传
        sort_type: 1, //1默认 2价格 3次数
        sort_order: 0, //0升序 1降序
        page_num: 1,
        page_size: 20
    })
    //获取列表
    const auctionList = async () => {
        const queryBody: any = {};
        for (let key in list_search) {
            if ((list_search[key] || list_search[key] === 0) && list_search[key] !== -1) {
                queryBody[key] = list_search[key]
            }
        }
        onSearch(headerType,queryBody)
        const dom = document.getElementById("list_top")
        dom && dom.scrollIntoView({ behavior: "smooth" });
    }

    useEffect(() => {
        auctionList();
    }, [list_search])

    useEffect(() => {
        return () => { inputChange("") };
    }, [])

    // 获取法院所在地
    useEffect(() => {
        // findByCity()
    }, [sf_home_id]);
    return (
        <div id="list_top">
            <Header />
            <div className="session">
                <div className="search_results" >
                   在{city}搜索到有关 “ <span>{value}</span> ” 的搜索结果
                </div>

                <Spin tip="数据搜索中..." spinning={loading}>
                    <List notList={total === 0} list={list} link={urlLink}/>
                </Spin>


                <div className="more_box">
                    <Paging
                        pageSize={list_search.page_size}
                        current={list_search.page_num}
                        pageSizeOptions={['20', '32', '56']}
                        total={total}
                        onShowSizeChange={(size: number) => {
                            set_list_search({ ...list_search, page_size: size })
                        }}
                        onChange={(page: number) => {
                            set_list_search({ ...list_search, page_num: page })
                        }}
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default observer(MatterList);