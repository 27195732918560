import React, { useEffect, useState } from "react";
import Header from "./../header";
import Footer from "./../footer";
import { Link } from "react-router-dom";
import NotImg from "./../img/new_d.png";
import { Spin, Result } from "antd";
import axios from "./../api";
import { useLocation } from "react-router-dom"
import "./index.scss";
const News = () => {
    const pathName = useLocation().pathname;
    const time = new Date();
    const year = time.getFullYear();
    const month = time.getMonth() + 1;
    const day = time.getDate();
    const getDay = time.getDay();

    const [new_list, set_new_list]: any = useState([]); //新闻列表
    const [spinning, set_spinning] = useState(true);
    let belong = 1;
    let detailsNew = "/news_details";
    let newHome = "/";

    if (pathName === "/bankruptcy_news") {
        belong = 3;
        detailsNew = "/bankruptcy_news_details";
        newHome = "/bankruptcy_auction"
    }
    if (pathName === "/assets_news") {
        belong = 2;
        detailsNew = "/assets_news_details";
        newHome = "/asset_auction"
    }
    const newsCenter = async () => {
        const result: any = await axios.http.newGet(axios.api.news_center, { belong })
        if (result.status === 0 && result.result) {
            set_new_list(result.result)
        }
        set_spinning(false)
    }
    useEffect(() => {
        newsCenter();
    }, [])
    return (
        <div>
            <Header />
            <div className="session">
                <div className="news_page_box">
                    <div className="breadcrumb_box">
                        <Link to={newHome}>首页 {">"}</Link>
                        资讯中心
                    </div>
                    <div className="news_box">
                        <div className="news_title">
                            <h6>
                                {
                                    new_list[0] &&
                                    <span>
                                        {new_list[0].column}
                                    </span>
                                }
                            </h6>
                            <p>
                                {year}年{month}月{day}日  星期{`日一二三四五六`.charAt(getDay)}
                            </p>
                        </div>
                        <Spin tip="数据加载中..." spinning={spinning}>
                            <div className="news_list_box">
                                <div className="news_list_left">
                                    <div className="news_import_box" style={{ borderBottom: "solid 1px #CAE0F3" }}>
                                        <div className="news_import_list" style={{height:"260px"}}>
                                            {
                                                new_list[0] && new_list[0].data && new_list[0].data.map((item: any, index: number) => {
                                                    return (
                                                        <h1 key={item.id} title={item.title}>
                                                            <Link to={`${detailsNew}?${item.id}&${item.column_id}`}>{item.title}</Link>
                                                        </h1>
                                                    )
                                                })
                                            }
                                        </div>
                                        {/* <img onError={(e: any) => { e.target.src = NotImg }} src={new_list[0] && new_list[0].data && new_list[0].data[0] && (new_list[0].data[0].pic.indexOf("img.qqpai.com") > -1 ? `${new_list[0].data[0].pic}!w400` : new_list[0].data[0].pic)} alt="" /> */}
                                    </div>

                                    {
                                        new_list[0] && new_list[0].data && new_list[0].data.length == 0 && <Result
                                            title="暂无相关新闻"
                                            extra={''}
                                        />
                                    }
                                    <h5>
                                        {
                                            new_list[1] &&
                                            <span>
                                                {new_list[1].column}
                                            </span>
                                        }
                                    </h5>
                                    {
                                        new_list[1] && new_list[1].data && new_list[1].data.map((item: any, index: number) => {
                                            return (
                                                <div className="news_import_box" key={item.id}>
                                                    {/* <Link to={`${detailsNew}?${item.id}&${item.column_id}`} style={{ maxWidth: "360px" }}>
                                                        <img onError={(e: any) => { e.target.src = NotImg }} src={item.pic.indexOf("img.qqpai.com") > -1 ? `${item.pic}!w400` : item.pic} alt="" />
                                                    </Link> */}
                                                    <Link to={`${detailsNew}?${item.id}&${item.column_id}`}>
                                                        <div className="news_import_list">
                                                            <h6 title={item.title}>
                                                                <em></em> &emsp;
                                                        {item.title && item.title.length > 15 ? (item.title.substring(0, 15) + "...") : item.title}
                                                            </h6>
                                                            <div className="news_content_box" title={item.discription}>
                                                                {item.discription || ''}
                                                            </div>
                                                            <p>{item.put_time}</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        new_list[1] && new_list[1].data && new_list[1].data.length == 0 && <Result
                                            title="暂无相关新闻"
                                            extra={''}
                                        />
                                    }
                                </div>
                                <div className="news_list_right">
                                    <div className="topic_box">
                                        <h6>
                                            {
                                                new_list[2] &&
                                                <span>
                                                    {new_list[2].column}
                                                </span>
                                            }
                                        </h6>
                                        {/* {
                                            new_list[2] && new_list[2].data && new_list[2].data.map((item: any, index: number) => {
                                                if (index === 0) {
                                                    return (
                                                        <Link key={item.id} to={`${detailsNew}?${item.id}&${item.column_id}`}>
                                                            <div className="topic_news_box" >
                                                                <img onError={(e: any) => { e.target.src = NotImg }} src={item.pic.indexOf("img.qqpai.com") > -1 ? `${item.pic}!w400` : item.pic} alt="" />
                                                                <div className="topic_news_content">
                                                                    {item.title}
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    )
                                                }
                                            })
                                        } */}
                                        <div className="topic_news_list">

                                            {
                                                new_list[2] && new_list[2].data && new_list[2].data.map((item: any, index: number) => {
                                                    return (
                                                        <Link key={item.id} to={`${detailsNew}?${item.id}&${item.column_id}`}>
                                                            <p>
                                                                <span></span>
                                                                {item.title}
                                                            </p>
                                                        </Link>
                                                    )
                                                })
                                            }

                                            {
                                                new_list[2] && new_list[2].data && new_list[2].data.length === 0 && <Result
                                                    title="暂无相关新闻"
                                                    extra={''}
                                                />
                                            }

                                        </div>
                                    </div>

                                    <div className="topic_box">
                                        <h6>
                                            {
                                                new_list[3] &&
                                                <span>
                                                    {new_list[3].column}
                                                </span>
                                            }
                                        </h6>

                                        {/* {
                                            new_list[3] && new_list[3].data && new_list[3].data.map((item: any, index: number) => {
                                                if (index === 0) {
                                                    return (
                                                        <Link key={item.id} to={`${detailsNew}?${item.id}&${item.column_id}`}>
                                                            <div className="topic_news_box" >
                                                                <img onError={(e: any) => { e.target.src = NotImg }} src={item.pic.indexOf("img.qqpai.com") > -1 ? `${item.pic}!w400` : item.pic} alt="" />
                                                                <div className="topic_news_content">
                                                                    {item.title}
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    )
                                                }
                                            })
                                        } */}
                                        <div className="topic_news_list">
                                            {
                                                new_list[3] && new_list[3].data && new_list[3].data.map((item: any, index: number) => {
                                                    return (
                                                        <Link key={item.id} to={`${detailsNew}?${item.id}&${item.column_id}`}>
                                                            <p>
                                                                <span></span>
                                                                {item.title}
                                                            </p>
                                                        </Link>
                                                    )
                                                })
                                            }
                                        </div>

                                        {
                                            new_list[3] && new_list[3].data && new_list[3].data.length == 0 && <Result
                                                title="暂无相关新闻"
                                                extra={''}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </Spin>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default News;