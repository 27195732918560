import React, { useEffect, useState, useRef } from "react";
import Header from "./../header";
import axios from "./../api";
import fetchJsonp from "fetch-jsonp";
import { Select, Drawer, Checkbox, Carousel } from "antd";
import qq from "qq";
import { CloseCircleOutlined } from "@ant-design/icons";
import mapImg from "./../img/mapLogo.png";
import { observer } from "mobx-react";
import { HeaderType } from "./../storesMobx";
import { Link } from "react-router-dom";
import NotImg from "./../img/da@2x.png";
import "./index.scss";
// declare let TMap: any;
const { Option } = Select;
let map: any = null;
let isZomm = false;
let measureTool: any = null;
let markersArray: any = [];
let markers: any = [];
let markerLabel: any = [];
let lat = 0,
  lng = 0;

const sf_status = [
  {
    label: "进行中",
    value: 0,
  },
  {
    label: "未开始",
    value: 1,
  },
];
const Map: React.FC = () => {
  const carousel: any = useRef(null);
  const { city, cityId }: any = HeaderType();
  // const Map = qq.maps.Map;
  const Marker = qq.maps.Marker;
  const LatLng = qq.maps.LatLng;
  const Event = qq.maps.event;
  const [geo, set_geo] = useState({
    lng: 0,
    lat: 0,
  });
  const [post_body, set_body] = useState({
    category_id: [],
    cityId: 0,
    status: [],
  });
  const [banner, set_banner] = useState([]);
  const [drawer_visible, set_drawer_visible] = useState(false);
  const [area_list, set_area_list] = useState([]);
  const [zoomLevel, set_zoomLevel] = useState(11);
  const [map_details, set_map_details]: any = useState({});
  const [area_id, set_area_id]: any = useState("");
  const [map_list, set_list] = useState([]);
  const [sf_ategory_list, set_sf_ategory_list] = useState([
    {
      label: "住宅用房",
      value: 50025969,
    },
    {
      label: "商业用房",
      value: 200782003,
    },
    {
      label: "工业用房",
      value: 200788003,
    },
    {
      label: "其他用房",
      value: 200798003,
    },
    {
      label: "土地",
      value: 50025970,
    },
  ]);
  //   坐标解析
  const geocoder = () => {
    const url = `https://apis.map.qq.com/ws/geocoder/v1/?address=${city}&key=ZA4BZ-7ZAKS-H3HOQ-6SEZT-V57F3-5QBGN&output=jsonp`;
    fetchJsonp(url, { jsonpCallback: "" })
      .then((response: any) => {
        return response.json();
      })
      .then((res: any) => {
        if (res.status === 0) {
          const ids = `${res.result.ad_info.adcode}`;
          const cityId: any = `${ids.substring(0, 4)}00`;
          set_geo({
            lng: res.result.location.lng,
            lat: res.result.location.lat,
          });
          set_body({
            ...post_body,
            cityId: cityId * 1,
          });
        }
      });
  };
  //   根据浏览器获取ip城市坐标
  const getIp = () => {
    const url = `https://apis.map.qq.com/ws/location/v1/ip?key=ZA4BZ-7ZAKS-H3HOQ-6SEZT-V57F3-5QBGN&output=jsonp`;
    fetchJsonp(url, { jsonpCallback: "" })
      .then((response: any) => {
        return response.json();
      })
      .then((res: any) => {
        if (res.status === 0) {
          const ids = `${res.result.ad_info.adcode}`;
          const cityId: any = `${ids.substring(0, 4)}00`;
          set_geo({
            lng: res.result.location.lng,
            lat: res.result.location.lat,
          });
          set_body({
            ...post_body,
            cityId: cityId * 1,
          });
        }
      });
  };
  //   地图渲染
  const mapLog = (): void => {
    map = new qq.maps.Map(document.getElementById("map"), {
      center: new qq.maps.LatLng(geo.lat, geo.lng),
      zoom: 11,
    });
    // map = new TMap.Map(document.getElementById("map"), {
    //   zoom: 11, // 设置地图缩放级别
    //   center: new TMap.LatLng(geo.lat, geo.lng), // 设置地图中心点坐标
    // });
    // 鼠标位置移动监听
    qq.maps.event.addListener(map, "mousemove", function (event: any) {
      let latLng = event.latLng;
      lat = latLng.getLat().toFixed(5);
      lng = latLng.getLng().toFixed(5);
    });
  };

  // 地图缩放监听
  // @ts-ignore
  const mapZoom = useCallback(() => {
    if (!map) return;
    // 地图缩放级别监听
    qq.maps.event.addListener(map, "zoom_changed", function (event: any) {
      var zoomLevel = map.getZoom();
      if (zoomLevel < 12) {
        addlabel();
      } else {
        clearMapLabel();
        if (!lat || !lng) {
          set_area_id(post_body.cityId);
          return
        }
        const cityLocation = new qq.maps.CityService();
        cityLocation.searchCityByLatLng(new qq.maps.LatLng(lat * 1, lng * 1));
        cityLocation.setComplete(function (result: any) {
          map_list.map((item: any) => {
            if (result.detail.name.indexOf(item.shortname) > -1) {
              if (item.id === area_id) {
                sfMap();
              } else {
                set_area_id(item.id);
              }
            }
          });
        });
      }
      set_zoomLevel(zoomLevel);
    });
  });

  // 获取区的数据
  const sfAuctionMap = async () => {
    const postBody: any = {
      city_id: post_body.cityId,
      category_id: post_body.category_id,
    };
    if (post_body.status.length === 1) {
      postBody.status = post_body.status[0];
    }
    const res: any = await axios.http.newGet(axios.api.sf_statistical, {
      ...postBody,
    });
    if (res.status === 0) {
      set_list(res.result);
    }
  };

  // 获取市的所有数据
  // @ts-ignore
  const sfMap = useCallback(async () => {
    const postBody: any = {
      city_id: area_id,
      category_id: post_body.category_id,
    };
    if (post_body.status.length === 1) {
      postBody.status = post_body.status[0];
    }
    const res: any = await axios.http.newGet(axios.api.sf_auction_map, {
      ...postBody,
    });
    if (res.status === 0) {
      set_area_list(res.result);
    }
  });
  // 清除数据的点
  const clearMapLabel = () => {
    if (markersArray) {
      for (let i in markersArray) {
        markersArray[i].setMap(null);
      }
      markersArray.length = 0;
    }
    if (markers) {
      for (let i in markers) {
        markers[i].setMap(null);
      }
      markers.length = 0;
    }
    if (markerLabel) {
      for (let i in markerLabel) {
        markerLabel[i].setMap(null);
      }
      markerLabel.length = 0;
    }
  };

  // const getBanner = async () => {
  //   const banner: any = await axios.http.get(`${axios.api.bannerList}`);
  //   if (banner.status === 0) {
  //     set_banner(banner.data);
  //   }
  // };

  // 测绘工具
  // const initMap = () => {
  //   // 创建测量工具
  //   measureTool = new TMap.tools.MeasureTool({
  //     map: map,
  //   });
  // };

  const measure = () => {
    measureTool.measureDistance().then((res: any) => {
      //测量结束，将结果显示到页中
    });
  };

  //  添加点
  const addMarker = () => {
    clearMapLabel();
    const infoWin = new qq.maps.InfoWindow({
      map: map,
    });
    area_list &&
      area_list.map((item: any, i: number) => {
        if (item.main_pic.indexOf("/jfs/") > -1) {
          const jd_img = item.main_pic.substring(
            item.main_pic.indexOf("/jfs/")
          );
          item.main_pic = `https://m.360buyimg.com/mobilecms${jd_img}`;
        }
        const img =
          item.main_pic.indexOf("img.qqpai.com") > -1
            ? `${item.main_pic}!w300`
            : item.main_pic;
        let price: any = 0,
          isWan = "",
          init_wan = "",
          init_pic: any = 0,
          consult_price: any = 0,
          consult_price_wan = "";
        if (item.current_price >= 100000 && item.current_price < 100000000) {
          price = ((item.current_price * 1) / 10000).toFixed(4);
          isWan = "万";
        } else if (item.current_price >= 100000000) {
          price = (item.current_price / 100000000).toFixed(4);
          isWan = "亿";
        } else {
          price = item.current_price * 1;
          isWan = "";
        }

        if (item.consult_price >= 100000 && item.consult_price < 100000000) {
          consult_price = ((item.consult_price * 1) / 10000).toFixed(4);
          consult_price_wan = "万";
        } else if (item.consult_price >= 100000000) {
          consult_price = (item.consult_price / 100000000).toFixed(4);
          consult_price_wan = "亿";
        } else {
          consult_price = item.consult_price * 1;
          consult_price_wan = "";
        }

        if (item.initial_price >= 100000 && item.initial_price < 100000000) {
          init_pic = ((item.initial_price * 1) / 10000).toFixed(4);
          init_wan = "万";
        } else if (item.initial_price >= 100000000) {
          init_pic = (item.initial_price / 100000000).toFixed(4);
          init_wan = "亿";
        } else {
          init_pic = item.initial_price * 1;
          init_wan = "";
        }
        item.main_pic = img;
        item.current_price = `${price}${isWan}`;
        item.consult_price = `${consult_price}${consult_price_wan}`;
        item.initial_price = `${init_pic}${init_wan}`;
        if (item.geo) {
          let anchor = new qq.maps.Point(22, 56),
            size = new qq.maps.Size(44, 56),
            origin = new qq.maps.Point(0, 0),
            icon = new qq.maps.MarkerImage(mapImg, size, origin, anchor);
          let tpl = `<div class="map_label" data-longitude="${item.lng}" data-latitude=${item.lat}
        data-id=${item.id}>
        <p class="name" title=${item.title}>${item.title.replace(new RegExp(city + "市", 'g'), '')}</p>
        <p><span class="count">起拍价:${item.initial_price}</span></p></div>`;
          let visible = zoomLevel >= 15 ? true : false;

          const dinates = item.geo.split(",");
          const latLng = new LatLng(dinates[1], dinates[0]);
          const marker = new Marker({
            position: latLng,
            map,
            icon,
          });
          let label: any = new qq.maps.Label({
            position: latLng,
            map: map,
            content: tpl,
            visible,
          });
          const styleBg = {
            width: "auto", //宽
            height: "55px", //高度
            border: "0", //边
            background: "rgba(84,144,244,.8)", //背景颜色
            borderRadius: "4px",
            padding: "4px 6px",
            color: "#fff",
          };
          label.setStyle(styleBg);
          Event.addListener(marker, "mouseover", () => {
            marker.setAnimation(qq.maps.MarkerAnimation.BOUNCE);
            label.setVisible(true);
          });
          Event.addListener(marker, "mouseout", () => {
            marker.setAnimation(null);
            zoomLevel >= 15 ? label.setVisible(true) : label.setVisible(false);
          });
          Event.addListener(marker, "click", () => {
            set_drawer_visible(true);
            set_map_details(item);
          });
          markers.push(marker);
          markerLabel.push(label);
        }
      });
  };

  // 聚合点的数据
  // @ts-ignore
  const addlabel = useCallback(() => {
    clearMapLabel();
    map_list &&
      map_list.map((item: any, index: number) => {
        let center = new qq.maps.LatLng(item.lat, item.lng);
        let tpl = `<div class="bubble bubble-1" data-longitude="${item.lng}" data-latitude=${item.lat}
        data-id=${item.id}>
        <p class="name" title=${item.name}>${item.name}</p>
        <p><span class="count">共${item.count}个标的物</span></p></div>`;
        let styleBg = {
          width: "92px", //宽
          height: "92px", //高度
          border: "0", //边
          background: "rgba(84,144,244,.8)", //背景颜色
          borderRadius: "50%",
          cursor: "pointer",
          color: "#fff",
        };
        let label: any = new qq.maps.Label({
          position: center,
          map: map,
          content: tpl,
          data: item.id,
        });

        Event.addListener(label, "mouseover", function () {
          label.setStyle({ background: "rgba(241,80,68,.8)", zIndex: 999 }); //修改覆盖物背景颜色
        });
        // 当鼠标离开时在删除边界折线数据
        Event.addListener(label, "mouseout", function () {
          label.setStyle({ background: "rgba(84,144,244,.8)", zIndex: index }); //修改覆盖物背景颜色
        });

        Event.addListener(label, "click", function () {
          if (area_id === label.data) {
            sfMap();
          } else {
            set_area_id(label.data);
          }
          isZomm = true;
          if (zoomLevel < 11) {
            map.zoomTo(12)
          }
          map.panTo(center);
        });

        label.setStyle(styleBg);
        markersArray.push(label);
      });
  });

  useEffect(() => {
    if (city) {
      geocoder();
    } else {
      getIp();
    }
  }, [city, geocoder, getIp]);
  useEffect(() => {
    if (geo.lat && geo.lng) {
      mapLog();
    }
  }, [geo, mapLog]);
  useEffect(() => {
    if (post_body.cityId) {
      sfAuctionMap();
    }
  }, [post_body, sfAuctionMap]);
  useEffect(() => {
    addlabel();
    mapZoom();
  }, [addlabel, mapZoom, map_list]);
  useEffect(() => {
    if (area_id) addMarker();
  }, [addMarker, area_id, area_list]);
  useEffect(() => {
    if (area_id) sfMap();
    mapZoom();
  }, [area_id, mapZoom, sfMap]);
  // useEffect(() => {
  //   getBanner();
  // }, [])
  return (
    <div className="map_housing_box">
      <div className="map_position">
        <Header />
      </div>
      <div className="map_select">
        <div className="map_select_box">
          <div>
            标的物类型：&nbsp;
            <Checkbox.Group
              options={sf_ategory_list}
              defaultValue={[]}
              onChange={(e: any) => {
                set_body({ ...post_body, category_id: e });
              }}
            />
            &emsp; 拍卖状态: &nbsp;
            <Checkbox.Group
              options={sf_status}
              defaultValue={[]}
              onChange={(e: any) => {
                set_body({ ...post_body, status: e });
              }}
            />
          </div>
          <div>
            标的物区域：
            <div className="city_span">
              {
                map_list && map_list.map((item: any) => {
                  return (
                    <span
                      onClick={() => {
                        let center = new qq.maps.LatLng(item.lat, item.lng);
                        if (area_id === item.id) {
                          sfMap();
                        } else {
                          set_area_id(item.id);
                        }
                        map.panTo(center);
                      }}
                      className={area_id === item.id ? 'active' : ''}
                      key={item.id}>{item.name}({item.count})</span>
                  )
                })
              }
            </div>

          </div>
          {/* <p onClick={measure}>测绘工具</p> */}
        </div>
      </div>
      <div className="map" id="map"></div>

      <Drawer
        title="标的物基本信息"
        placement="right"
        onClose={() => {
          set_drawer_visible(false);
        }}
        visible={drawer_visible}
        key="right"
        width={332}
        mask={false}
      >
        <Link
          to={`/matter_details?${map_details && map_details.id}`}
          target="_blank"
        >
          <div className="map_details">
            <img
              src={map_details && map_details.main_pic}
              alt=""
              onError={(e: any) => {
                e.target.src = NotImg;
              }}
            />
            <h5>{map_details && map_details.title}</h5>
            <p>围观次数:{map_details && map_details.view_count}次</p>
            <p>报名次数: {map_details && map_details.apply_count}次</p>
            <p>当前价: ￥{map_details && map_details.current_price}</p>
            <p>评估价: ￥{map_details && map_details.consult_price}</p>
            <p>拍卖开始时间: {map_details && map_details.start_time}</p>
            <p>拍卖结束时间: {map_details && map_details.end_time}</p>
          </div>
        </Link>

        {/* <h1>爆品推荐</h1>
        <div>
          <Carousel autoplay={true} ref={carousel}>
            {banner &&
              banner.map((item: any, index: number) => {
                if (item.main_pc) {
                  if (item.imgs_pc.length) {
                    return (
                      <div>
                        <Link to={`/banner_link?${index}`} target="_blank">
                          <img
                            src={`https://file.qqpai.com${item.main_pc}`}
                            alt=""
                          />
                        </Link>
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <img
                          src={`https://file.qqpai.com${item.main_pc}`}
                          alt=""
                        />
                      </div>
                    );
                  }
                }
              })}
          </Carousel>
        </div> */}
      </Drawer>
    </div>
  );
};
export default observer(Map);
