import React, { useEffect, useState, useRef } from "react";
import Header from "./../header";
import Footer from "./../footer";
import { Link, useLocation } from "react-router-dom";
import {
  InputNumber,
  Button,
  Popover,
  Table,
  Tooltip,
  Spin,
  message,
  Modal,
} from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import axios from "./../api";
import fetchJsonp from "fetch-jsonp";
import Amplifier from "./../component/amplifier";
import { capital } from "./../component/capital";
import qq from "qq";
import { HeaderType } from "./../storesMobx";
import "./../home/index.scss";
import "./index.scss";
import yy from "./../img/yy.png";
import zx from "./../img/zx.png";
import sc from "./../img/sc.png";
import gj from "./../img/gj.png";
import yey from "./../img/yey.png";
import dyy from "./../img/dyy.png";
import dt from "./../img/dt.png";
import yh from "./../img/yh.png";
import yd from "./../img/yd.png";
import kf from "./../img/kf.png";
import tyg from "./../img/tyg.png";
import xx from "./../img/xx.png";
import ct from "./../img/ct.png";
import gy from "./../img/gy.png";
import sc1 from "./../img/sc1.png";
import dx from "./../img/dx.png";
import jsf from "./../img/jsf.png";
import cs from "./../img/cs.png";
import ATM from "./../img/ATM.png";

const mapSurroundingNav = [
  {
    name: "交通",
    child: ["公交", "地铁"],
  },
  {
    name: "教育",
    child: ["幼儿园", "小学", "中学", "大学"],
  },
  {
    name: "医疗",
    child: ["医院", "药店"],
  },
  {
    name: "购物",
    child: ["商场", "超市", "市场"],
  },
  {
    name: "生活",
    child: ["银行", "ATM", "餐厅", "咖啡馆"],
  },
  {
    name: "娱乐",
    child: ["公园", "电影院", "健身房", "体育馆"],
  },
];
const columns = [
  {
    title: "状态",
    dataIndex: "status",
    key: "status",
    render: (text: number) => {
      return <span>{text === -1 ? "出局" : "领先"}</span>;
    },
  },
  {
    title: "竞买号",
    dataIndex: "alias",
    key: "alias",
  },
  {
    title: "价格",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "时间",
    dataIndex: "date",
    key: "date",
  },
];
const loan_col = [
  {
    title: "银行",
    dataIndex: "bank",
    key: "bank",
  },
  {
    title: "最高可贷款比例",
    dataIndex: "column",
    key: "column",
  },
  {
    title: "利率",
    dataIndex: "rate",
    key: "rate",
  },
  {
    title: "其他费用",
    dataIndex: "cost",
    key: "cost",
  },
  {
    title: "",
    dataIndex: "status",
    key: "status",
    render: (text: any) => {
      return (
        <div style={{ textAlign: "center" }}>
          <Button type="primary">申请贷款</Button>
          <p>
            累计<span style={{ color: "#1479D7" }}>{text}</span>人申请
          </p>
        </div>
      );
    },
  },
];
const dataSource = [
  {
    key: "1",
    bank: "工商银行",
    column: "成交价的70%",
    rate: "基准利率1.1倍起",
    cost: "无",
    status: 66,
  },
  {
    key: "2",
    bank: "建设银行",
    column: "成交价的70%",
    rate: "基准利率1.12倍起",
    cost: "无",
    status: 88,
  },
  {
    key: "3",
    bank: "农业银行",
    column: "成交价的70%",
    rate: "基准利率1倍起",
    cost: "无",
    status: 118,
  },
];
//定义地图，标记数组
var markersArray: any = [];
let map: any = null;
const QRCode = require("qrcode.react");
const MatterDetails: React.FC = () => {
  const { loginWx, loginUser }: any = HeaderType();
  const loginRef: any = useRef(null);
  const [visible, set_visible] = useState(false);
  // const user = loginUser || window.localStorage.getItem("user");
  const user = true;
  const id = useLocation().search;
  const path = useLocation().pathname;
  const [map_surrounding, set_map_surrounding] = useState("交通");
  const [map_surrounding_2, set_map_surrounding_2] = useState("公交");
  const [map_surrounding_nav, set_map_surrounding_nav] = useState(
    mapSurroundingNav[0].child
  );
  const [details, set_details]: any = useState(null);
  const [sell_type, set_sell_type] = useState({
    type: "一拍",
    title: "拍卖",
  });
  const [notice, set_notice]: any = useState("");
  const [required, set_required]: any = useState("");
  const [introduce, set_introduce]: any = useState("");
  const [details_content_nav, set_details_content_nav] = useState(
    "provinces_a"
  );
  const [marker_list, set_marker_list] = useState([]);
  const [icon_class, set_icon_class] = useState("gj");
  const [li_active, set_li_active]: any = useState(null);
  const [end_time, set_end_time] = useState(["00", "00", "00", "00", "00"]);
  const [status, set_status] = useState("距离结束:");
  const [data_source, set_data_source] = useState([]);
  const [platform, set_platform] = useState("");
  const [spinning, set_spinning] = useState(true);
  // 地图加载
  const mapLog = (geo: any): void => {
    const dinates = geo.geo.split(",");
    map = new qq.maps.Map(document.getElementById("map"), {
      center: new qq.maps.LatLng(dinates[1], dinates[0]),
      zoom: 16,
    });
    const center = new qq.maps.LatLng(dinates[1], dinates[0]);
    const marker = new qq.maps.Marker({
      position: center,
      zIndex: 9999999999,
      map: map,
    });
    const cssLabel = { marginTop: "-70px", marginLeft: "-60px" };
    const label = new qq.maps.Label({
      position: center,
      map: map,
      content: geo.title,
    });
    label.setStyle(cssLabel);
    qq.maps.event.addListener(marker, "mouseover", () => {
      marker.setAnimation(qq.maps.MarkerAnimation.BOUNCE);
    });
    qq.maps.event.addListener(marker, "mouseout", () => {
      marker.setAnimation(null);
    });
  };

  // 获取周边列表
  const getSurrounding = (): void => {
    const geo = details && details.geo.split(",");
    if (!geo) return;
    const url = `https://apis.map.qq.com/ws/place/v1/search?keyword=${map_surrounding_2}&boundary=nearby(${geo[1]},${geo[0]},10)&key=ZA4BZ-7ZAKS-H3HOQ-6SEZT-V57F3-5QBGN&output=jsonp`;
    fetchJsonp(url, { jsonpCallback: "" })
      .then((response: any) => {
        return response.json();
      })
      .then((res: any) => {
        if (markersArray) {
          for (let i in markersArray) {
            markersArray[i].setMap(null);
          }
          markersArray.length = 0;
        }
        addSurrounding(res.data);
      });
  };

  const addSurrounding = (data: any): void => {
    const marker_list: any = [];
    data &&
      data.map((item: any) => {
        let icon = gj;
        let iconClass = "gj";
        switch (map_surrounding_2) {
          case "公交":
            icon = gj;
            break;
          case "地铁":
            icon = dt;
            iconClass = "dt";
            break;
          case "幼儿园":
            icon = yey;
            iconClass = "yey";
            break;
          case "小学":
            icon = xx;
            iconClass = "xx";
            break;
          case "中学":
            icon = zx;
            iconClass = "zx";
            break;
          case "大学":
            icon = dx;
            iconClass = "dx";
            break;
          case "医院":
            icon = yy;
            iconClass = "yy";
            break;
          case "药店":
            icon = yd;
            iconClass = "yd";
            break;
          case "商场":
            icon = sc1;
            iconClass = "sc1";
            break;
          case "超市":
            icon = cs;
            iconClass = "cs";
            break;
          case "市场":
            icon = sc;
            iconClass = "sc";
            break;
          case "银行":
            icon = yh;
            iconClass = "yh";
            break;
          case "ATM":
            icon = ATM;
            iconClass = "ATM";
            break;
          case "餐厅":
            icon = ct;
            iconClass = "ct";
            break;
          case "咖啡馆":
            icon = kf;
            iconClass = "kf";
            break;
          case "公园":
            icon = gy;
            iconClass = "gy";
            break;
          case "电影院":
            icon = dyy;
            iconClass = "dyy";
            break;
          case "健身房":
            icon = jsf;
            iconClass = "jsf";
            break;
          case "体育馆":
            icon = tyg;
            iconClass = "tyg";
            break;
        }
        set_icon_class(iconClass);
        const anchor = new qq.maps.Point(12, 36),
          size = new qq.maps.Size(24, 36),
          origin = new qq.maps.Point(0, 0),
          iconImg = new qq.maps.MarkerImage(icon, size, origin, anchor);
        const center = new qq.maps.LatLng(item.location.lat, item.location.lng);
        const marker = new qq.maps.Marker({
          icon: iconImg,
          position: center,
          map: map,
        });
        marker.setTitle(item.title);
        qq.maps.event.addListener(marker, "mouseover", () => {
          marker.setAnimation(qq.maps.MarkerAnimation.BOUNCE);
          set_li_active(marker);
        });
        qq.maps.event.addListener(marker, "mouseout", () => {
          marker.setAnimation(null);
          set_li_active(null);
        });
        marker_list.push(marker);
        markersArray.push(marker);
      });
    set_marker_list(marker_list);
  };
  // 获取详情
  const getAuction = async () => {
    const result: any = await axios.http.post(
      `${axios.api.get_auction}?id=${id.slice(1).split("&")[0]}`,
      {}
    );
    if (result.status == 0) {
      const jd_pic: string[] = [];
      result.result.pic &&
        result.result.pic.map((item: any) => {
          if (item.indexOf("/jfs/") > -1) {
            const jd_img = item.substring(item.indexOf("/jfs/"));
            jd_pic.push(`https://m.360buyimg.com/mobilecms${jd_img}`);
          }
        });
      if (jd_pic.length) {
        result.result.pic = jd_pic;
      }
      if (!result.result.geo) {
        //   后期需要更换正则
        let add = result.result.court;
        if (
          result.result.category_id === 50025969 ||
          result.result.category_id === 200782003 ||
          result.result.category_id === 200788003 ||
          result.result.category_id === 200798003 ||
          result.result.category_id === 50025970
        ) {
          add = result.result.title;
        }
        const url = `https://apis.map.qq.com/ws/geocoder/v1/?address=${add}&key=ZA4BZ-7ZAKS-H3HOQ-6SEZT-V57F3-5QBGN&output=jsonp`;
        fetchJsonp(url, { jsonpCallback: "" })
          .then((response: any) => {
            return response.json();
          })
          .then((res: any) => {
            if (res.status === 0) {
              result.result.geo = `${res.result.location.lng},${res.result.location.lat}`;
              mapLog(result.result);
              set_details(result.result);
              set_spinning(false);
            } else {
              set_details(result.result);
              set_spinning(false);
            }
          });
      } else {
        mapLog(result.result);
        set_details(result.result);
        set_spinning(false);
      }
      let sell_type = "第一次拍卖";
      let sell1_type = "拍卖";
      let status = "距离结束:";
      let platform = "讯拍网";
      switch (result.result.sell_type) {
        case 2:
          sell_type = "第二次拍卖";
          break;
        case 3:
          sell_type = "重新拍卖";
          break;
        case 4:
          sell_type = "变卖";
          sell1_type = "变卖";
          break;
      }
      switch (result.result.status) {
        case 1:
          status = "距离开始:";
          break;
        case 2:
          status = "结束时间:";
          break;
        case 3:
          status = "终止时间:";
          break;
        case 4:
          status = "撤回时间:";
          break;
      }

      switch (result.result.data_from) {
        case 1:
          platform = "淘宝拍卖";
          break;
        case 2:
          platform = "京东拍卖";
          break;
        case 3:
          platform = "北京产权交易所";
          break;
        case 4:
          platform = "工商银行“融e购”";
          break;
        case 5:
          platform = "公拍网";
          break;
        case 6:
          platform = "人民法院诉讼资产网";
          break;
        case 7:
          platform = "中国拍卖行业协会";
          break;
      }
      set_platform(platform);
      set_sell_type({
        type: sell_type,
        title: sell1_type,
      });
      set_status(status);
      getNoticeFile(result.result.notice_id);

      set_data_source(result.result.buy_log.records);
      if (result.result.status === 0) {
        countTime(result.result.end_time);
      }
      if (result.result.status === 1) {
        countTime(result.result.start_time);
      }

      document.body.scrollIntoView();
      document.title = `${result.result.title}-讯拍网`;
    }
  };
  // 获取竞买公告
  const getNoticeFile = async (notId: string) => {
    const notice = await axios.http.get(`${axios.api.notice_file}${notId}.txt`);
    set_notice(notice);
  };
  const getRequired = async () => {
    const required = await axios.http.get(
      `${axios.api.required_file}${id.slice(1).split("&")[0]}.txt`
    );
    set_required(required);
  };
  const getIntroduce = async () => {
    const introduce = await axios.http.get(
      `${axios.api.introduce_file}${id.slice(1).split("&")[0]}.txt`
    );
    set_introduce(introduce);
  };
  const scrollLocation = (indexStr: string) => {
    const widget: any = document.getElementById("provinces_" + indexStr);
    widget.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "center",
    });
  };
  const provinces_rolling = () => {
    const court_szm_box: any = document.getElementById("nav");
    const details_footer_pos_box: any = document.getElementById(
      "details_footer_pos"
    );
    window.addEventListener("scroll", (event: any) => {
      const provinces_boxs: any = Array.from(
        document.querySelectorAll(".h5_nav")
      );
      const provinces_a = document.getElementById("provinces_a");
      const provinces_a_top =
        provinces_a && provinces_a.getBoundingClientRect().top;

      provinces_boxs.map((item: any) => {
        const top = item.getBoundingClientRect().top;
        if (top > 100 && top < 200) {
          const szm = item.getAttribute("id");
          szm && set_details_content_nav(szm);
        }
      });
      if (provinces_a_top && provinces_a_top < 980 && provinces_a_top > 200) {
        set_details_content_nav("provinces_a");
      }
      const scrollTop =
        (event.srcElement
          ? event.srcElement.documentElement.scrollTop
          : false) ||
        window.pageYOffset ||
        (event.srcElement ? event.srcElement.body.scrollTop : 0);
      const contentHeight =
        document.body.scrollHeight || document.documentElement.scrollHeight;
      const clearHeight =
        document.body.clientHeight || document.documentElement.clientHeight;
      if (scrollTop > 1200) {
        court_szm_box.className = "details_content_nav details_content_nav_f";
      } else {
        court_szm_box.className = "details_content_nav";
      }
      if (contentHeight - scrollTop - clearHeight <= 600) {
        details_footer_pos_box.className = "details_footer_pos1";
      } else {
        details_footer_pos_box.className = "details_footer_pos";
      }
    });
  };

  const countTime = (time: any) => {
    var date = new Date();
    var now = date.getTime();
    var endDate = new Date(time); //设置截止时间
    var end = endDate.getTime();
    var leftTime = end - now; //时间差
    var d, h, m, s, ms;
    if (leftTime >= 0) {
      d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
      h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
      m = Math.floor((leftTime / 1000 / 60) % 60);
      s = Math.floor((leftTime / 1000) % 60);
      ms = Math.floor(leftTime % 10);
      // if (ms < 10) {
      //     ms = "0" + ms;
      // }
      if (s < 10) {
        s = "0" + s;
      }
      if (m < 10) {
        m = "0" + m;
      }
      if (h < 10) {
        h = "0" + h;
      }
      if (d < 10) {
        d = "0" + d;
      }
      set_end_time([`${d}`, `${h}`, `${m}`, `${s}`, `${ms}`]);
    } else {
      // getAuction();
    }
    //递归每秒调用countTime方法，显示动态时间效果
    setTimeout(() => {
      countTime(time);
    }, 100);
  };

  const signUp = () => {
    const isLogin = loginUser || window.localStorage.getItem("user");
    if (!isLogin) {
      loginRef && loginRef.current.wnLogin(path, id);
    } else {
      if (details && details.jd_url) {
        // setTimeout(() => {
        //   window.open(details && details.jd_url);
        // }, 500);
        set_visible(true);
      } else {
        message.error("该标的物暂时无法报名,如有疑问请联系我们");
      }
    }
  };
  useEffect(() => {
    getAuction();
    getRequired();
    getIntroduce();
    provinces_rolling();
  }, []);
  useEffect(() => {
    getSurrounding();
  }, [map_surrounding, map_surrounding_2, details]);

  const mustSee = () => {
    return (
      <div className="must_see">
        <p>
          拍卖开始以后也可以报名交保证金（建议您提前{" "}
          <span style={{ color: "red", fontWeight: "bold" }}>1-2天</span>{" "}
          交保证金，以免错过拍卖）。
        </p>
        <h4>1.银行卡网银支付</h4>
        <p>
          推荐农行、工行、中国银行、建行、招行、浦发、交行、光大、中信、邮储等
        </p>
        <h4>2.使用银行专用款项支付</h4>
        <p>专用于大额支付的功能，在手机端即可完成交保</p>
        <h4>3.微信支付</h4>
        <p>
          需确保微信账户中有充足的钱，如果保证金金额在20万以上的，建议使用网银交易
        </p>
      </div>
    );
  };

  const tooltipNode = (concat_phone: string) => {
    const pNode = concat_phone.split("\n");
    return (
      <div>
        {pNode &&
          pNode.map((phone) => {
            return (
              <p key={phone} style={{ color: "#fff" }}>
                {phone}
              </p>
            );
          })}
      </div>
    );
  };
  return (
    <div id="widget">
      <Header ref={loginRef} />
      <Spin tip="加载中..." spinning={spinning}>
        <div className="session session-details">
          <div className="matter_details_box">
            <div className="matter_details_left">
              <div className="matter_details_content_box">
                <div className="matter_details_big_box">
                  {details && details.pic.length > 0 && (
                    <Amplifier imgList={details && details.pic} />
                  )}
                  <div className="court_related">
                    <div className="court_left_box">
                      <p>
                        处置单位:
                        <Link to={`/court_list?${details && details.court_id}`}>
                          {details && details.court}
                        </Link>
                      </p>
                      <p>
                        联系人:{details && details.concat}
                        <Tooltip
                          title={() => {
                            return tooltipNode(details && details.concat_phone);
                          }}
                        >
                          <a href="javascript:;" className="btn">
                            <em></em> &nbsp; 联系方式
                          </a>
                        </Tooltip>
                      </p>
                      <p>
                        服务商:
                        {details &&
                          (details.auxiliary_id || details.auxiliary_id === 0) ? (
                          <Link
                            to={`/auxiliary_list?id=${details && details.auxiliary_id
                              }`}
                          >
                            {details && details.auxiliary}
                          </Link>
                        ) : (
                          <span>{details && details.auxiliary}</span>
                        )}
                      </p>
                      <p>
                        标的物公告：
                        <Link
                          to={`/announcement?${details && details.notice_id}`}
                        >
                          查看公告
                        </Link>
                      </p>
                    </div>
                    <div
                      className="court_middle_box"
                      style={
                        details && details.auxiliary_qr_code
                          ? {
                            background: `url(${details.auxiliary_qr_code}!w100) no-repeat center center`,
                            backgroundSize: "100px 100px",
                          }
                          : {}
                      }
                    ></div>
                    <div className="court_right_box">
                      微信扫描司辅机构二维码了解更多信息
                    </div>
                  </div>
                  <div className="details_applicant_box">
                    <em></em>
                    <p>我是标的申请人 想要资产处置 溢价增值</p>
                    <Link to="/guest" target="_blank">
                      <span>
                        立即
                        <br />
                        前往
                      </span>
                      <RightOutlined color={"#65A6FF"} />
                    </Link>
                  </div>
                </div>
                <div className="matter_details_content">
                  <h4>
                    <em>{sell_type.type}</em>
                    <p title={details && details.title}>
                      {details && details.title}
                    </p>
                    {/* {details && details.limit_purchase === 0 && (
                      <span className="btn_blue">不限购</span>
                    )} */}
                    {details && details.can_loan === 1 && (
                      <span className="btn_yellow">可贷款</span>
                    )}
                  </h4>
                  <div className="matter_details_label">
                    <Button>
                      <Link
                        to={`/appointment?id=${id.slice(1).split("&")[0]}-1-1`}
                        target="_blank"
                      >
                        拍卖咨询
                      </Link>
                    </Button>{" "}
                    &emsp;
                    <Button disabled={details && !details.can_appoint}>
                      <Link
                        to={`/appointment?id=${id.slice(1).split("&")[0]}-1-2`}
                        target="_blank"
                      >
                        预约看样
                      </Link>
                    </Button>{" "}
                    &emsp;
                    <Button>
                      <Link
                        target="_blank"
                        to={`/appointment?id=${id.slice(1).split("&")[0]}-1-3`}
                      >
                        银行贷款
                      </Link>
                    </Button>
                    <div className="set_remind">设置提醒</div>
                  </div>
                  {details && (details.status == 3 || details.status == 4) && (
                    <div className="matter_details_status">
                      <p>
                        <i></i>
                        {details && details.status == 3
                          ? "本场竞价已中止"
                          : "本场拍卖已撤回"}
                      </p>
                    </div>
                  )}
                  {details &&
                    (details.status == 0 ||
                      details.status == 1 ||
                      details.status == 2) && (
                      <div>
                        <h3>
                          <em>
                            {details.status == 2 && details.bid_count > 0
                              ? `成交时间:`
                              : `${status}`}
                          </em>
                          {details &&
                            (details.status == 1 || details.status == 0) && (
                              <i>
                                <span>{end_time[0]}</span>天
                                <span>{end_time[1]}</span>时
                                <span>{end_time[2]}</span>分
                                <span>{end_time[3]}</span>秒
                                <span>{end_time[4]}</span>
                              </i>
                            )}
                          {details &&
                            (details.status == 2 ||
                              details.status == 3 ||
                              details.status == 4) && (
                              <i>
                                {details && details.end_time}
                                &emsp;&emsp;
                                {details &&
                                  details.delay_count > 0 &&
                                  `${details.delay_count}次延时`}
                              </i>
                            )}
                        </h3>
                        <h3>
                          <em>
                            {details && details.status != 1
                              ? details.status == 2 && details.bid_count > 0
                                ? "成交价："
                                : "当前价："
                              : "起拍价："}
                          </em>

                          <Tooltip
                            title={() => {
                              return capital(details && details.current_price);
                            }}
                          >
                            <b>{details && details.current_price}元</b>
                          </Tooltip>
                        </h3>
                        {details && details.status != 2 && (
                          <div className="ongoing_warp">
                            <div className="ongoing_box">
                              <div className="ongoing_div">
                                {details && details.status == "1"
                                  ? "即将开始"
                                  : details && details.status == "0"
                                    ? "进行中"
                                    : "已结束"}
                              </div>
                              <div className="offer_box">
                                <span>出价</span>
                                <InputNumber
                                  value={details && details.current_price}
                                  min={0}
                                />
                              </div>

                              <div className="sign_up_box">
                                <Button className="btn_up" onClick={signUp}>
                                  点击报名
                                </Button>{" "}
                                &emsp;
                                <Popover
                                  content={mustSee}
                                  title="报名必看"
                                  trigger="hover"
                                >
                                  <span>
                                    报名必看 &nbsp;<em></em>
                                  </span>
                                </Popover>
                              </div>
                              <div className="remind_box">
                                <em></em>
                                提醒：先报名交保证金再出价。如果您已经交付，
                                请登录
                              </div>
                            </div>

                            <div className="share_code">
                              <QRCode
                                value={`https://m.qqpai.com/#${path}${id}`}
                                size={110}
                              />
                              <p>微信扫码分享此拍品</p>
                            </div>
                          </div>
                        )}
                        {details && details.status == 2 && (
                          <div
                            className="matter_details_status"
                            style={{ height: "150px" }}
                          >
                            <p>
                              <i></i>
                              {details && details.bid_count > 0
                                ? "本场拍卖已结束"
                                : `本场拍卖已流拍`}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  <div className="registration_number">
                    <em></em> &emsp;&emsp;
                    <span>{(details && details.apply_count) || 0}</span>人报名
                    &emsp;&emsp;
                    <span>{(details && details.remind_count) || 0}</span>
                    人设置提醒 &emsp;&emsp;
                    <span>{(details && details.view_count) || 0}</span>次围观
                  </div>
                  <div className="matter_details_instructions">
                    <div>
                      <p>
                        {details && details.sell_type === 4
                          ? "变卖价"
                          : "起拍价"}
                        :￥
                        <Tooltip
                          title={() => {
                            return capital(details && details.initial_price);
                          }}
                        >
                          {details && details.initial_price}
                        </Tooltip>
                      </p>
                      <p>
                        加价幅度:￥
                        <Tooltip
                          title={() => {
                            return capital(details && details.mark_up);
                          }}
                        >
                          {details && details.mark_up}
                        </Tooltip>
                      </p>
                      <p>类型:{sell_type.title}</p>
                      <p>
                        保证金:￥
                        <Tooltip
                          title={() => {
                            return capital(details && details.margin);
                          }}
                        >
                          {details && details.margin}
                        </Tooltip>
                      </p>
                    </div>
                    <div>
                      <p>
                        {details && details.sell_type === 4
                          ? "变卖周期"
                          : "竞价周期"}
                        :{details && details.sell_type === 4 ? 60 : 1} 天
                      </p>

                      {details && details.sell_type === 4 && (
                        <p>
                          变卖预缴款:￥
                          <Tooltip
                            title={() => {
                              return capital(details && details.initial_price);
                            }}
                          >
                            {details && details.initial_price}
                          </Tooltip>
                        </p>
                      )}
                      <p>
                        评估价:￥
                        <Tooltip
                          title={() => {
                            return capital(details && details.consult_price);
                          }}
                        >
                          {details && details.consult_price}
                        </Tooltip>
                      </p>
                    </div>
                    <div>
                      <p>延时周期:{details && (details.delay_period == 0 ? "5" : details.delay_period)} 分钟</p>
                      <p>
                        优先购买权人:
                        {details &&
                          details.prior_buyer &&
                          details.prior_buyer !== "无"
                          ? "有"
                          : "无"}
                      </p>
                    </div>
                    <div>
                      {details && details.sell_type === 4
                        ? "变卖规则"
                        : "竞价规则"}
                      :
                      <span
                        dangerouslySetInnerHTML={{
                          __html: details && details.rule
                        }}
                      ></span>
                    </div>
                    <div title={details && details.pay_type}>
                      尾款支付方式:
                      {details && details.pay_type
                        ? details.pay_type.length > 30
                          ? details.pay_type.substring(0, 30) + "..."
                          : details.pay_type
                        : "查看竞买公告中的说明"}
                    </div>
                    <div title={details && details.pay_dead_time}>
                      尾款线上支付截止时间:
                      {details && details.pay_dead_time
                        ? details.pay_dead_time.length > 20
                          ? details.pay_dead_time.substring(0, 20) + "..."
                          : details.pay_dead_time
                        : "按竞买公告中的截止时间及时支付"}
                    </div>
                    <p>
                      注:本标的物信息来源于{platform}
                      平台,若侵害了您的权益,请立即联系客服
                    </p>
                  </div>
                  <div className="matter_details_server">
                    <div>
                      <img src={require("./../img/one@2x.png")} alt="" />
                      <p>法拍无忧</p>
                    </div>
                    <div>
                      <img src={require("./../img/two@2x.png")} alt="" />
                      <p>律师尽调</p>
                    </div>
                    <div>
                      <img src={require("./../img/three@2x.png")} alt="" />
                      <p>过户办证</p>
                    </div>
                    <div>
                      <img src={require("./../img/four@2x.png")} alt="" />
                      <p>税费测算</p>
                    </div>
                    <div>
                      <img src={require("./../img/five@2x.png")} alt="" />
                      <p>委托看样</p>
                    </div>
                    <div>
                      <img src={require("./../img/six@2x.png")} alt="" />
                      <p>拍卖咨询</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home_process_box">
            <Link to="/help?1&id=33">
              <div className="home_process_title">竞拍流程</div>
            </Link>
            <Link to="/help?1&id=33">
              <div className="process_steps">
                <span></span>
                <p>阅读公告</p>
              </div>
            </Link>
            <RightOutlined style={{ color: "#DCE7F8" }} />
            <Link to="/help?1&id=33">
              <div className="process_steps">
                <span className="ste1"></span>
                <p>实地看样</p>
              </div>
            </Link>
            <RightOutlined style={{ color: "#DCE7F8" }} />
            <Link to="/help?1&id=33">
              <div className="process_steps">
                <span className="ste2"></span>
                <p>交保证金</p>
              </div>
            </Link>
            <RightOutlined style={{ color: "#DCE7F8" }} />
            <Link to="/help?1&id=33">
              <div className="process_steps">
                <span className="ste3"></span>
                <p>开始竞拍</p>
              </div>
            </Link>
            <RightOutlined style={{ color: "#DCE7F8" }} />
            <Link to="/help?1&id=33">
              <div className="process_steps">
                <span className="ste4"></span>
                <p>竞拍成功</p>
              </div>
            </Link>
            <RightOutlined style={{ color: "#DCE7F8" }} />
            <Link to="/help?1&id=7">
              <div className="process_steps">
                <span className="ste5"></span>
                <p>支付尾款</p>
              </div>
            </Link>
            <RightOutlined style={{ color: "#DCE7F8" }} />
            <Link to="/help?1&id=14">
              <div className="process_steps">
                <span className="ste6"></span>
                <p>办理交割</p>
              </div>
            </Link>
          </div>

          <div className="loan_title">
            <span>线上贷款</span>
            <p>
              线上贷款本标的由腾云讯拍合作银行提供贷款服务，如需贷款请提前2日提交贷款申请
            </p>
          </div>
          <Table
            className="details_table"
            columns={loan_col}
            dataSource={dataSource}
            pagination={false}
            bordered={true}
          ></Table>

          <div className="details_content">
            <div id="nav" className="details_content_nav">
              <div
                className={
                  details_content_nav === "provinces_a" ? "active" : ""
                }
                onClick={() => {
                  scrollLocation("a");
                  set_details_content_nav("provinces_a");
                }}
              >
                {details && details.sell_type === 4 ? "变卖公告" : "竞买公告"}
              </div>
              <div
                className={
                  details_content_nav === "provinces_b" ? "active" : ""
                }
                onClick={() => {
                  scrollLocation("b");
                  set_details_content_nav("provinces_b");
                }}
              >
                {details && details.sell_type === 4 ? "变卖须知" : "竞买须知"}
              </div>
              <div
                className={
                  details_content_nav === "provinces_c" ? "active" : ""
                }
                onClick={() => {
                  scrollLocation("c");
                  set_details_content_nav("provinces_c");
                }}
              >
                标的物介绍
              </div>
              <div
                className={
                  details_content_nav === "provinces_f" ? "active" : ""
                }
                onClick={() => {
                  scrollLocation("f");
                  set_details_content_nav("provinces_f");
                }}
                style={{ display: details && (details.geo ? "block" : "none") }}
              >
                标的物位置
              </div>
              <div
                className={
                  details_content_nav === "provinces_d" ? "active" : ""
                }
                onClick={() => {
                  scrollLocation("d");
                  set_details_content_nav("provinces_d");
                }}
              >
                优先购买权人
              </div>
              <div
                className={
                  details_content_nav === "provinces_e" ? "active" : ""
                }
                onClick={() => {
                  scrollLocation("e");
                  set_details_content_nav("provinces_e");
                }}
              >
                {" "}
                {details && details.sell_type === 4 ? "应买记录" : "竞价记录"}
              </div>
              {/* <div>竞买记录 <span>(8)</span></div> */}
            </div>

            {!user && (
              <div className="fuzzy_modal" key="fuzzy_modal_content">
                <Button
                  type="primary"
                  onClick={() => {
                    loginWx(path, id);
                  }}
                >
                  登录查看完整详情{">"}
                </Button>
              </div>
            )}
            <div className={!user ? "fuzzy fuzzy_ok" : "fuzzy"} key="fuzzy_ok">
              <h5 id="provinces_a" className="h5_nav">
                {details && details.sell_type === 4 ? "变卖公告" : "竞买公告"}
              </h5>

              {notice && (
                <div
                  className="bor_bon"
                  dangerouslySetInnerHTML={{ __html: notice }}
                ></div>
              )}

              {!notice && (
                <p style={{ fontSize: "16px" }}>详情请查看标的物公告</p>
              )}

              <h5 id="provinces_b" className="h5_nav">
                {details && details.sell_type === 4 ? "变卖须知" : "竞买须知"}
              </h5>

              {required && (
                <div
                  className="bor_bon"
                  dangerouslySetInnerHTML={{ __html: required }}
                ></div>
              )}

              {!required && (
                <p style={{ fontSize: "16px" }}>详情请查看标的物公告</p>
              )}

              <h5 id="provinces_c" className="h5_nav">
                标的物介绍
              </h5>
              {details && details.attachment.length > 0 && (
                <p style={{ lineHeight: "40px", fontSize: "15px" }}>
                  相关附件下载:
                </p>
              )}

              {details &&
                details.attachment.map((file: any) => {
                  return (
                    <p>
                      <a href={file.id}> {file.title} </a>
                    </p>
                  );
                })}
              {details && details.video && (
                <div
                  style={{ width: "800px", margin: "auto", padding: "10px" }}
                >
                  <video controls width="800" height="400">
                    <source src={details && details.video} type="video/mp4" />
                  </video>
                </div>
              )}
              <div
                className="bor_bon"
                dangerouslySetInnerHTML={{ __html: introduce }}
              ></div>

              <h5
                id="provinces_f"
                className="h5_nav"
                style={{ display: details && (details.geo ? "block" : "none") }}
              >
                标的物位置
              </h5>
              <div
                style={{ display: details && (details.geo ? "block" : "none") }}
              >
                <p style={{ textAlign: "center", marginBottom: "10px" }}>
                  {details && details.address}
                </p>
                <p
                  style={{
                    textAlign: "center",
                    marginBottom: "10px",
                    color: "red",
                  }}
                >
                  地图标注仅供参考，具体位置以标的物实际位置为准
                </p>
                <div className="map">
                  <div id="map"></div>
                  <div className="map_surrounding">
                    <div className="map_surrounding_nav">
                      {mapSurroundingNav.map((item: any) => {
                        return (
                          <span
                            key={item.name}
                            onClick={() => {
                              set_map_surrounding(item.name);
                              set_map_surrounding_2(item.child[0]);
                              set_map_surrounding_nav(item.child);
                            }}
                            className={
                              map_surrounding === item.name ? "active" : ""
                            }
                          >
                            {item.name}
                          </span>
                        );
                      })}
                    </div>
                    <div className="map_surrounding_list">
                      {map_surrounding_nav.map((item) => {
                        return (
                          <span
                            onClick={() => {
                              set_map_surrounding_2(item);
                            }}
                            className={
                              map_surrounding_2 == item ? "active" : ""
                            }
                            key={item}
                          >
                            {item}
                          </span>
                        );
                      })}
                    </div>
                    <div className="map_surrounding_box">
                      <ul>
                        {marker_list &&
                          marker_list.map((item: any) => {
                            return (
                              <li
                                className={li_active == item ? "active" : ""}
                                key={item.id}
                                onMouseEnter={() => {
                                  item.setAnimation(
                                    qq.maps.MarkerAnimation.BOUNCE
                                  );
                                }}
                                onMouseLeave={() => {
                                  item.setAnimation(null);
                                }}
                              >
                                <em className={icon_class}></em>
                                <p>{item.title}</p>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <h5 id="provinces_d" className="h5_nav">
                优先购买权人
              </h5>

              <div className="bor_bon">
                {details && (details.prior_buyer || "无")}
              </div>
              <h5 id="provinces_e" className="h5_nav">
                {details && details.sell_type === 4 ? "应买记录" : "竞价记录"}
              </h5>
              <div className="bor_bon">
                <Table
                  pagination={false}
                  dataSource={data_source}
                  columns={columns}
                />
              </div>
            </div>
          </div>

          <div className="details_footer_pos" id="details_footer_pos">
            <div className="details_footer">
              <Link
                target="_blank"
                to={`/appointment?id=${id.slice(1).split("&")[0]}-1-1`}
              >
                <em></em>
                拍卖咨询
                <div>
                  <p>微信扫码咨询</p>
                  <div></div>
                </div>
              </Link>
              <Link
                target="_blank"
                to={`/appointment?id=${id.slice(1).split("&")[0]}-1-2`}
              >
                <em className="ky"></em>
                预约看样
                <div>
                  <p>微信扫码咨询</p>
                  <div></div>
                </div>
              </Link>

              <Link
                target="_blank"
                to={`/appointment?id=${id.slice(1).split("&")[0]}-1-3`}
              >
                <em className="dk"></em>
                申请贷款
                <div>
                  <p>微信扫码咨询</p>
                  <div></div>
                </div>
              </Link>
              {details && details.jd_url && details.jd_url !== "无" && (
                <div onClick={signUp}>
                  <em className="bz"></em>
                  交保证金
                </div>
              )}
            </div>
          </div>
        </div>
      </Spin>
      <Footer />

      <Modal
        visible={visible}
        centered={true}
        onCancel={() => { set_visible(false) }}
        footer={
          <div style={{ textAlign: "center" }}>
            <Button type="primary" style={{ width: "120px", height: "40px" }}>
              <a href={details && details.jd_url} target="_blank" className="a_hover" onClick={() => { set_visible(false) }}>
                确定
              </a>
            </Button>{" "}
            &emsp;&emsp;
            <Button style={{ width: "120px", height: "40px" }} onClick={() => { set_visible(false) }}>取消</Button>
          </div>
        }
        title="温馨提示"
        maskClosable={false}
      >
        <p>
          本拍品信息来源于第三方平台，我方仅提供信息展示，即将跳转至第三方链接，若发生安全隐患与本平台无关，是否继续跳转
        </p>
      </Modal>
    </div>
  );
};

export default MatterDetails;
