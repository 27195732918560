import React, { useEffect, useState } from "react";
import Header from "./../../header";
import Footer from "./../../footer";
import axios from "./../../api";
import { Spin, Result } from "antd";
import { Link, useLocation } from "react-router-dom";
import NotImg from "./../../img/da@2x.png";
import Paging from "./../../component/pagination";
import "./../../matterList/index.scss";
import "./index.scss";

const Enterprise: React.FC = () => {
    const id = useLocation().search
    const [place_province_left, set_place_province_left] = useState(0);

    const [list, set_list] = useState([]);
    const [total, set_total] = useState(-1);
    const [spinning, set_spinning] = useState(false);
    const [manage_body, set_manage_body] = useState({
        id: id.slice(1),
        page_num: 1,
        page_size: 12
    });
    const [manage, set_manage]: any = useState({});



    //获取列表
    const auctionList = async () => {
        set_spinning(true)
        const result: any = await axios.http.newGet(`${axios.api.pc_manage_auction}`, { ...manage_body });
        if (result.status === 0) {
            set_total(result.result.total);
            set_list(result.result.data);
            const dom = document.getElementById("list_top")
            dom && dom.scrollIntoView({ behavior: "smooth" });
        }
        set_spinning(false)
    }

    const getManage = async () => {
        const result: any = await axios.http.newGet(`${axios.api.get_pc_manage}?id=${id.slice(1)}`, {});
        set_manage(result.result);
        document.title = `${result.result.name}企业主页-讯拍网`
    }


    // 查询列表
    useEffect(() => {
        auctionList();
        getManage();
    }, [auctionList, getManage, manage_body]);

    return (
        <div id="list_top">
            <Header />
            <div className="session">

                <div className="enterprise_home_box">
                    <div className="enterprise_home_title">
                        <h6>企业名称:{manage.name || ""}</h6>
                        <p>
                            所在地:{manage.city || ""} &emsp;&emsp;处置单位：{manage.disposal || ""}
                            <span>页面折扣以标的评估价或市场价为基础（具体价格见详情页）</span>
                        </p>
                    </div>
                </div>
                <Spin tip="加载中..." spinning={spinning}>
                    <div className="enterprise_list_ul_box">
                        {
                            list && list.map((item: any) => {
                                let current_price = item.current_price;
                                let wan = false;
                                let zhekou: any = ((item.initial_price / item.consult_price) * 10).toFixed(1);
                                if (item.current_price > 10000) {
                                    current_price = (item.current_price / 10000).toFixed(4);
                                    wan = true;
                                }
                                return (
                                    <Link to={`/bankruptcy_matter_details?${item.id}`}>
                                        <div className="li_img">
                                            <img onError={(e: any) => { e.target.src = NotImg }} src={item.main_pic ? (item.main_pic.indexOf("img.qqpai.com") > -1 ? `${item.main_pic}!w300` : item.main_pic) : NotImg} alt="" />
                                        </div>
                                        <h3>{item.title}</h3>
                                        <p >
                                            当前价：<b>￥{current_price}{wan ? "万" : ""}</b>
                                        </p>
                                        <p >
                                            {zhekou >= 10 ? "" : `${zhekou}折起拍`}
                                        </p>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </Spin>



                <div className="more_box" style={{ background: "#fff" }}>
                    <Paging
                        pageSize={manage_body.page_size}
                        current={manage_body.page_num}
                        pageSizeOptions={['12', '36', '60']}
                        total={total}
                        onShowSizeChange={(size: number) => {
                            set_manage_body({ ...manage_body, page_size: size })
                        }}
                        onChange={(page: number) => {
                            set_manage_body({ ...manage_body, page_num: page })
                        }}
                    />
                </div>

                {
                    total === 0 && <Result
                        status="404"
                        title="很抱歉"
                        subTitle="没有您要找的标的物，换个条件试试~"
                    />
                }
            </div>
            <Footer />
        </div>
    )
}
export default Enterprise