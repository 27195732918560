import axios from "axios";
import { message } from "antd";
import padding from "./../component/padding";
// const host = false;  //true 正式环境 false 测试环境
const host = process.env.NODE_ENV === 'production';  //true 正式环境 false 测试环境
const getHref = host ? "https://cloud.qqpai.com/container-qqpai-api" : "http://192.168.1.3:8090";
const href = host ? "https://m.qqpai.com/#" : "http://m.local.qqpai.com/#";
const port = host ? "https://qqtfp-cache-1255858180.file.myqcloud.com" : "http://192.168.1.3:8090";
const fileHttp = `https://file.qqpai.com${host ? "/" : "/test/"}`;
const wnLogin = host ? "https%3a%2f%2fwww.qqpai.com%2F%23" : "http%3a%2f%2flocal.qqpai.com%2F%23";
const appId = host ? "wx70f19ccce1ad483e" : "wxf83bcc83eab8343d";


let axiosHeader: any = {
    'Content-Type': 'application/json;charset=UTF-8'
};
axios.defaults.timeout = 20000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
let http = {
    get: function (url: string, params?: any) {
        params = params || {}
        return new Promise((resolve, reject) => {
            axios.get(url, {
                params: params,
                // responseType: 'blob',
                // transformResponse: [(data) => {
                //     var reader: any = new FileReader()
                //     reader.readAsText(data, 'GBK')
                //     reader.onload = (e: any) => {
                //         let music = reader.result;
                //         resolve(music);
                //     }
                //     return data
                // }]
            })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    },
    post: function (url: string, params: any, FormDate?: boolean) {
        params = params || {};
        const SESSION = window.localStorage.getItem("c_s") || '';
        axios.defaults.withCredentials = true;
        if (FormDate) {
            axiosHeader['Content-Type'] = 'multipart/form-data';
        }
        if (SESSION) {
            axiosHeader.SESSION = SESSION
        }
        axios.defaults.headers = axiosHeader;
        return new Promise((resolve, reject) => {
            axios.post(url, params)
                .then((res) => {
                    const session = res.headers.session;
                    if (session) {
                        // 存的session
                        window.localStorage.setItem("c_s", session);
                    }
                    resolve(res.data);
                    if (res.data.status === -101) {
                        window.localStorage.setItem("user", "");
                        message.error('登录超时,请重新登录')
                    } else {
                        const user = window.localStorage.getItem("user");
                        const JSONUser: any = (user && JSON.parse(user)) || ""
                        if (JSONUser) {
                            JSONUser.time = new Date().getTime();
                            window.localStorage.setItem("user", JSON.stringify(JSONUser));
                        }
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        })
    },
    newGet: function (url: string, params: any) {
        axios.defaults.withCredentials = false;
        axios.defaults.headers = { 'Content-Type': 'application/json;charset=UTF-8' }
        params = params || {};
        const date = new Date();
        const day = date.getDate();
        const hour = date.getHours();
        const minute = Math.floor(date.getMinutes() / 5) * 5;
        const t = `${padding(day)}${padding(hour)}${padding(minute)}`
        if (url.indexOf("?id=") > -1) {
            url = `${url.replace("?id=", "/")}-${t}.json`;
        } else if (url.indexOf("?type=") > -1) {
            url = `${url.replace("?type=", "/")}-${t}.json`;
        } else if (url.indexOf("?org_group_id=") > -1) {
            url = `${url.replace("?org_group_id=", "/")}-${t}.json`;
        } else {
            params.t = t;
            url = `${url}/${JSON.stringify(params)}.json`;
        }

        url = encodeURI(url);
        return new Promise((resolve, reject) => {
            axios.get(url, {
                params: {},
                // transformRequest:[(data, headers)=>{
                //     console.log(123456)
                //     delete headers.common.SESSION;
                //     return data;
                // }]
                // responseType: 'blob',
                // transformResponse: [(data) => {
                //     var reader: any = new FileReader()
                //     reader.readAsText(data, 'GBK')
                //     reader.onload = (e: any) => {
                //         let music = reader.result;
                //         resolve(music);
                //     }
                //     return data
                // }]
            })
                .then((res) => {
                    resolve(res.data);
                    if (res.data.status === -101) {
                        window.localStorage.setItem("user", "");
                        message.error('登录超时,请重新登录')
                    } else {
                        const user = window.localStorage.getItem("user");
                        const JSONUser: any = (user && JSON.parse(user)) || ""
                        if (JSONUser) {
                            JSONUser.time = new Date().getTime();
                            window.localStorage.setItem("user", JSON.stringify(JSONUser));
                        }
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
}
const api = {
    sf_category_list: port + "/public/sf_category_list", //获取司法分类
    zc_category_list: port + "/public/zc_category_list", //资产分类
    sub_city: port + "/public/sub_city", //获取省市区数据
    auction_list: port + "/sf/auction/list", //分页获取司法列表
    find_by_city: port + "/court/find_by_city", //根据城市查找法院
    view_count: port + "/main/view_count", //获取今日围观次数
    zc_view_count: port + "/main/zc_view_count", //获取资产今日围观次数
    latest_news: port + "/main/latest_news", //最近新闻
    auction_recommend: port + "/auction_recommend/list", //首页推荐数据
    latest_notice: port + "/main/latest_notice", //最近公告
    sf_notice_list: port + "/sf_notice/list", //获取公告列表
    get_news: port + "/news/get", //获取新闻详情
    news_latest: port + "/news/latest", //获取新闻相关推荐
    news_center: port + "/news/center", //资讯中心页面
    full_city_court: port + "/court/full_city_court", //拼音城市列表
    sf_statistical: port + "/sf/auction/map/statistical", //当前城市下各区数据统计
    sf_auction_map: port + "/sf/auction/map/auction", //获取地图区具体数据
    notice_auction: port + "/sf_notice/auction", //公告关联标的
    zc_notice_auction: port + "/zc_notice/auction", //资产公告关联标的
    pc_notice_auction: port + "/pc_notice/auction", //资产公告关联标的
    help_list: port + "/help/list", //帮助中心信息
    court_count: port + "/court/count", //获取法院的相关统计
    auction_count: port + "/court/auction_count", //获取法院的标的物数量
    zc_auction_list: port + "/zc/auction/list", //分页获取资产列表
    zc_org_count: port + "/zc_org/count", //资产机构标的物数量
    zc_notice_list: port + "/zc_notice/list", //资产公告
    pc_notice_list: port + "/pc_notice/list", //破产公告
    zc_tj_list: port + "/zc_tj/list", //招商推介列表
    pc_auction_list: port + "/pc/auction/list", //分页获取破产列表
    pc_manage_list: port + "/pc_manage/list", //名企资产列表
    get_by_type: port + "/zc_org/get_by_type", //根据机构分类获取机构
    pc_manage_auction: port + "/pc_manage/auction", //名企拍品
    org_group_sta: port + "/zc_org/org_group_sta", //资产机构分组
    main_page: port + "/pc_manage/main_page", //破产企业详情
    get_pc_manage: port + "/pc_manage/get", //破产企业数据
    pc_notice: port + "/pc_notice/get", //公告详情标题
    zc_notice: port + "/zc_notice/get", //公告详情标题
    sf_notice: port + "/sf_notice/get", //公告详情标题
    value_add_data_list: port + "/value_add_data/list", //获取banner和折扣房源列表
    value_add_data_get: port + "/value_add_data/get", //获取banner和折扣房源详情
    sf_auxiliary_tree: port + "/sf_auxiliary/tree", //获取司辅列表
    sf_auxiliary_auxiliary_count: port + "/sf_auxiliary/auxiliary_count", //获取司辅列表对应数量
    entrust_list: port + "/entrust/list", //获取异地委托列表
    get_entrust: port + "/entrust/get", //获取异地委托详情
    entrust_save: port + "/entrust/save", //异地委托领取任务
    code_login: getHref + "/base/code_login", //验证码登录
    binding_phone: getHref + "/base/binding_phone", //绑定手机号码
    binding_wx: getHref + "/base/binding_wx", //绑定微信
    get_zc_tj: getHref + "/zc_tj/get", //推介详情
    buyer_view_investment_list: getHref + "/buyer_view/investment_list", //招商记录
    buyer_view_list: getHref + "/buyer_view/auction_list", //拍品浏览记录
    appointment_list: getHref + "/appointment/list", //看样列表
    consult_list: getHref + "/consult/list", //咨询列表
    loan_list: getHref + "/loan/list", //看样列表
    zc_investment_intent_list: getHref + "/zc_investment_intent/list", //招商意向
    pc_get_auction: getHref + "/pc/auction/get", //资产标的物详情
    get_auction: getHref + "/sf/auction/get", //商品详情
    zc_get_auction: getHref + "/zc/auction/get", //资产标的物详情
    get_verification_code: getHref + "/base/get_verification_code", //获取验证码 0登录 1修改密码 2注册 3预约看样
    pc_login: getHref + "/base/wx_login", //登录
    appointment_save: getHref + "/appointment/save", //预约看样
    consult_save: getHref + "/consult/save", //咨询
    loan_save: getHref + "/loan/save", //贷款咨询
    feedback_save: getHref + "/feedback/save", //意见反馈
    zc_investment_intent: getHref + "/zc_investment_intent/save", //提交意向书
    notice_file: fileHttp + "file/sf/notice/", //公告文件
    required_file: fileHttp + "file/sf/required/", //竞买须知文件
    introduce_file: fileHttp + "file/sf/introduce/", //标的物介绍
    zc_notice_file: fileHttp + "file/zc/notice/", //资产公告文件
    zc_required_file: fileHttp + "file/zc/required/", //资产竞买须知文件
    zc_introduce_file: fileHttp + "file/zc/introduce/", //资产标的物介绍
    pc_notice_file: fileHttp + "file/pc/notice/", //破产公告文件
    pc_required_file: fileHttp + "file/pc/required/", //破产竞买须知文件
    pc_introduce_file: fileHttp + "file/pc/introduce/", //破产标的物介绍
    zc_tj_info: fileHttp + "file/zc_tj/info/", //资产推介详情附件  介绍
    zc_tj_more: fileHttp + "file/zc_tj/more/", //资产推介详情附件  更多
    zc_tj_mortgage: fileHttp + "file/zc_tj/mortgage/", //资产推介详情附件  抵押物
    help_content: fileHttp + `file/cache/sf/help.txt`, //帮助中心
    zc_help_content: fileHttp + `file/cache/zc/help.txt`, //帮助中心
    pc_help_content: fileHttp + `file/cache/pc/help.txt`, //帮助中心
    courtList: fileHttp + `file/cache/court.txt`, //法院列表
    zc_org: fileHttp + `file/cache/zc_org.txt`, //政府机构
    model_file: fileHttp + `file/model/申请资料模板.rar`, //政府机构
    bannerList: fileHttp + `swiper.txt`,  //banner获取
    seal: fileHttp + `seal/seal.json`,  //电子封条
    market: fileHttp + `market/market.json`,  //增值营销
    wx_login: wnLogin,   // 微信登录跳转
    wx_app_id: appId,   // 微信appId
    full_city: "https://file.qqpai.com/public/city_pinyin.json", //城市列表
}

export default { http, api, href };
