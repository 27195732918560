import React, { useEffect, useState } from "react";
import Header from "./../../header";
import Footer from "./../../footer";
import List from "./../matterList/list";
import PromoteList from "./../assetsPromote/list";
import imgType from "./../../img/jigou@2x.png";
import axios from "./../../api";
import { useLocation, Link } from "react-router-dom";
import "./index.scss";
const other_content: any = {
    "0": {
        content: "在广义上指中央和地方的全部立法、行政、司法等机关。狭义的政府机构仅指中央和地方的行政机关，即依照国家法律设立并享有行政权力、担负行政管理职能的那部分国家机构，在我国，亦称为“国家行政机关”。",
        name: "政府机关",
        reference: [
            {
                "label": "杭州市西湖区政府采购中心",
                "value": 2207953276942
            },
            {
                "label": "杭州市余杭区金融风险防范暨网络借贷风险处置领导小组办公室",
                "value": 2201230309484
            },
            {
                "label": "甘肃省农业产业联合会",
                "value": 2201230309484
            }
        ]
    },
    "1": {
        content: "银行是依法成立的经营货币信贷业务的金融机构。银行是商品货币经济发展到一定阶段的产物。 银行是金融机构之一，银行按类型分为：中央银行、商业银行、投资银行、政策性银行、世界银 行，它们的职责各不相同。",
        name: "银行",
        reference: [
            {
                "label": "中国农业银行股份有限公司盐城分行",
                "value": 2207703381668
            },
            {
                "label": "哈尔滨银行沈阳分行",
                "value": 4084282248
            },
            {
                "label": "平安银行总行",
                "value": 3335490533
            }
        ]
    },
    "2": {
        content: "资产管理，通常是指一种“受人之托，代人理财”的信托业务。一类进行正常资产管理业务的资产管理公司，没有金融机构许可证；另一类是专门处理金融机构不良资产的金融资产管理公司，持有银行业监督委员会颁发的金融机构许可证。",
        name: "AMC",
        reference: [
            {
                "label": "中国东方资产管理股份有限公司",
                "value": 2206778776303
            },
            {
                "label": "中国长城资产管理股份有限公司",
                "value": 4086698169
            },
            {
                "label": "中国东方资产管理股份有限公司北京市分公司",
                "value": 3322390013
            }
        ]
    },
    "3": {
        content: "在国民经济核算体系中指除中央银行、其它货币机构、保险公司和养老金基金会之外，在市场上主要从事包括承担负债和对其它单位获得金融权利的单位。",
        name: "其他金融机构",
        reference: [
            {
                "label": "佳木斯市郊区农村信用联社",
                "value": 2207906563368
            },
            {
                "label": "中银信达资产管理有限公司",
                "value": 3930392969
            }
        ]
    },
    "4": {
        content: "进行交易某种信息及物品等的信息平台，所需要用的一个固定的地点叫交易所。交易所，借助信 息平台，实现产权信息共享、异地交易，统一协调，产权交易市场及各种条款来平衡。",
        name: "交易所",
        reference: [
            {
                "label": "西部产权交易所",
                "value": 2387660143
            },
            {
                "label": "杭州文化产权交易所有限公司",
                "value": 3011778889
            }
        ]
    },
    "5": {
        content: "电信运营商是指提供固定电话、移动电话和互联网接入的通信服务公司。主营固定通信业务，移动通信业务，国内、国际通信设施服务业务，卫星国际专线业务、数据通信业务、网络接入业务和各类电信增值业务，与通信信息业务相关的系统集成业务等。",
        name: "电信运营商",
        reference: [
            {
                "label": "联通华盛广东分公司",
                "value": 2199709013
            },
            {
                "label": "联通华盛通信有限公司四川分公司",
                "value": 4235501978
            }
        ]
    },
    "6": {
        content: "除政府机构、金融机构外开展资产处置业务其他企业（如拍卖行、典当行、资产管理公司、投资类公司、交易所、国有控股企业，以及提供个体资产服务的服务商等。",
        name: "商业类",
        reference: [
            {
                "label": "江苏助拍信息科技有限公司",
                "value": 3372353591
            },
            {
                "label": "四川中环来拍网络科技有限公司",
                "value": 2206599204698
            },
            {
                "label": "杭州易槌科技有限公司",
                "value": 2206376394028
            },
            {
                "label": "中国集邮总公司",
                "value": 61166329
            },
            {
                "label": "北京创维互动投资有限公司",
                "value": 3199586802
            }
        ]
    },
    "7": {
        content: "拍卖行是指为买卖双方提供交易场所和各种服务项目，以公开拍卖形式决定价格，组织买卖成交的代理商。",
        name: "拍卖行",
        reference: [
            {
                "label": "中都国际拍卖有限公司",
                "value": 1750237941
            },
            {
                "label": "杭州开源拍卖有限公司",
                "value": 1946346090
            }
        ]
    },
    "8": {
        content: "系指破产案件中经人民法院指定，依照《中华人民共和国企业破产法》的规定执行职务，向人民法院报告工作，并接受债权人会议和债权人委员会的监督的机构或个人。",
        name: "特定的处置小组(非法人单位)",
        reference: [
            {
                "label": "贵州中铝恒泰合矿业有限公司破产管理人",
                "value": 2200771134829
            },
            {
                "label": "江苏新茂置业有限公司管理人",
                "value": 2200807924663
            }
        ]
    },
    "9": {
        content: "基于司法拍卖辅助服务的工作场景，为企业提供预约看样、VR全景看房、移动工作台、专题推广等线上服务/产品支持，利用技术深度赋能，有效提升服务效率及客户满意度。",
        name: "服务商",
        reference: [
            {
                "label": "杭州助拍信息技术有限公司",
                "value": 3413828947
            },
            {
                "label": "南京同伦拍拍科技有限公司",
                "value": 3481567481
            },
            {
                "label": "四川中环来拍网络科技有限公司",
                "value": 4115114229
            },
            {
                "label": "创维智通（北京）网络科技有限公司",
                "value": 2201206387709
            },
            {
                "label": "杭州易槌科技有限公司",
                "value": 3253666300
            }
        ]
    },
    "10": {
        content: "",
        name: "其他",
        reference: [
            {
                "label": "陕西方瑞拍卖有限责任公司",
                "value": 2996751824
            },
            {
                "label": "辽宁省拍卖行司法拍卖",
                "value": 3423667268
            },
            {
                "label": "中鼎元品拍辅科技北京有限公司",
                "value": 4257330802
            },
            {
                "label": "西部产权交易所有限责任公司破产拍卖",
                "value": 3549100568
            }
        ]
    },
    "4001145": {
        name: "中国信达",
        content: "中国信达是经国务院批准设立的首家金融资产管理公司，并成功完成了股份制改革、引入境内外战略投资者，实现了在香港联合交易所主板挂牌上市。作为不良资产行业的先行者和领导者，中国信达引领并推动了行业发展，并致力于为股东、客户创造卓越价值。"
    },
    "4001147": {
        name: "中国长城",
        content: "中国长城资产管理股份有限公司：中国长城资产管理股份有限公司成立之初的主要任务是收购、管理和处置国有银行剥离的不良资产。自成立以来，先后收购、管理和处置了银行剥离的不良资产8000多亿元，圆满完成了国家赋予的“化解金融风险，支持国有银行和国企改革，最大限度保全资产、减少损失”的历史使命。"
    },
    "4001176": {
        name: "中国东方",
        content: "中国东方始终根植于不良资产领域，化解金融风险，服务实体经济"
    },
    "4000207": {
        name: "中国华融",
        content: "中国华融资产管理股份有限公司成立于2012年9月28日，是经国务院批准，由财政部、中国人寿保险（集团）公司共同发起设立的国有大型非银行金融企业。中国华融将坚持“稳中求进”发展主基调，努力打造“治理科学、管控有序、主业突出、综合经营、业绩优良”的一流资产管理公司。"
    },
    "4001203": {
        name: "中国银行",
        content: "中国银行经孙中山先生批准，于1912年2月5日正式成立。总行位于北京复兴门内大街1号，是中央管理的大型国有银行，也是中国四大银行之一。中国银行是香港、澳门地区的发钞行，业务范围涵盖商业银行、投资银行、基金、保险、航空租赁，旗下有中银国际、中银投资、中银基金、中银保险、中银航空租赁、中银消费金融、中银金融商务、中银香港等控股金融机构，在全球范围内为个人和公司客户提供金融服务。"
    },
    "4001206": {
        name: "工商银行",
        content: "中国工商银行成立于1984年1月1日。总行位于北京复兴门内大街55号，是中央管理的大型国有银行，也是中国四大银行之一。中国工商银行拥有中国最大的客户群，是中国最大的商业银行之一，也是世界五百强企业之一，基本任务是依据国家的法律和法规，通过国内外开展融资活动筹集社会资金，加强信贷资金管理，支持企业生产和技术改造，为我国经济建设服务。"
    },
    "4001204": {
        name: "建设银行",
        content: "中国建设银行，在全球范围内为台湾、香港、美国、澳大利亚等国家或地区提供全面金融服务，主要经营公司银行业务、个人银行业务和资金业务，包括居民储蓄存款、信贷资金贷款、住房类贷款、外汇、信用卡，以及投资理财等多种业务。"
    },
    "4001205": {
        name: "交通银行",
        content: "交通银行始建于1908年，总行设在上海，是中国历史最悠久的国有商业银行之一，也是近代中国的发钞行之一。1987年4月1日，重新组建后的交通银行正式对外营业，成为中国第一家全国性的国有股份制商业银行。交行分别于2005年、2007年先后在香港联合交易所、和上海证券交易所上市，是第一家在境外上市的国有控股大型商业银行。"
    },
    "4001188": {
        name: "农业银行",
        content: "中国农业银行成立于1951年。总行位于北京建国门内大街69号，是中央管理的大型国有银行，也是中国四大银行之一。中国农业银行是中国金融体系的重要组成部分，也是世界五百强企业之一，在全球银行1000强中排名前7位，穆迪信用评级为A1。2010年7月15日和16日，农行A股、H股分别在上海证券交易所和香港联交所成功挂牌上市，总市值位列全球上市银行第5位。"
    },
    "4001187": {
        name: "邮政银行",
        content: "中国邮政储蓄银行（以下简称“邮储银行”）是中国领先的大型零售银行，定位于服务社区、服务中小企业、服务“三农”，致力于为中国经济转型中最具活力的客户群体提供服务。同时，邮储银行积极服务于大型客户并参与重大项目建设，为中国经济发展作出了重要贡献。"
    }
}
const AssetType: React.FC = () => {
    const typeList = useLocation().search;
    const indexNumber: any = typeList.slice(1);
    const [list_type, set_list_type] = useState(true);
    const [reference, set_reference] = useState([]);

    const orgGroupSta = async () => {
        const result: any = await axios.http.newGet(`${axios.api.org_group_sta}?org_group_id=${indexNumber}`, {})
        if (result.status === 0) {
            set_reference(result.result)
        }
    }

    useEffect(() => {
        if (indexNumber > 12) {
            orgGroupSta();
        }
    }, [])
    return (
        <div id="list_top">
            <Header />
            <div className="session" >
                <div className="asset_type_box">
                    <div className="asset_type_bg_introduce">
                        <div className="asset_type_left">
                            <img src={imgType} alt="" />
                            <h3>{other_content[indexNumber].name}</h3>
                        </div>
                        <div className="asset_type_right">
                            <p>
                                {other_content[indexNumber].content}
                            </p>
                        </div>
                    </div>
                    <div className="asset_type_recommended_box">
                        <h6>
                            {other_content[indexNumber].name}
                            {/* {
                                indexNumber < 11 && <Link to={`assets_cooperation_type?type=${indexNumber * 1 + 1}`}>查看全部》</Link>
                            } */}
                        </h6>
                        <div className="asset_type_recommended_list">
                            {
                                indexNumber < 11 && other_content[indexNumber].reference.map((item: any) => {
                                    return (
                                        <Link title={item.label} target="_blank" to={`/assets_court_list?${item.value}`} key={item.value}>
                                            {item.label.length > 10 ? `${item.label.substring(0, 10)}...` : item.label}
                                        </Link>
                                    )
                                })
                            }
                            {
                                indexNumber > 11 && reference && reference.map((item: any) => {
                                    return (
                                        <Link title={item.name} target="_blank" to={`/assets_court_list?${item.id}`} key={item.id}>
                                            {item.name.length > 10 ? `${item.name.substring(0, 10)}...` : item.name}
                                        </Link>
                                    )
                                })
                            }
                        </div>

                    </div>
                </div>
                <div className="asset_type_box_nav">
                    <div onClick={() => { set_list_type(!list_type) }} className={list_type ? "asset_type_nav_list active" : "asset_type_nav_list"}>
                        近期处理
                    </div>
                    <div onClick={() => { set_list_type(!list_type) }} className={!list_type ? "asset_type_nav_list active" : "asset_type_nav_list"} style={{ left: "172px" }}>
                        近期推介
                    </div>
                    {
                        list_type && <List org_type={(indexNumber * 1)} />
                    }
                    {
                        !list_type && <PromoteList org_id={(indexNumber * 1)} />
                    }
                </div>

            </div>

            <Footer />
        </div>
    )
}
export default AssetType;