import anhui from "./../img/安徽.png";
import bj from "./../img/北京.png";
import cq from "./../img/重庆.png";
import fj from "./../img/福建.png";
import gd from "./../img/广东.png";
import gs from "./../img/甘肃.png";
import gz from "./../img/贵州.png";
import gx from "./../img/广西.png";
import henan from "./../img/河南.png";
import hubei from "./../img/湖北.png";
import hunan from "./../img/湖南.png";
import hebei from "./../img/河北.png";
import hainan from "./../img/海南.png";
import heilongjiang from "./../img/黑龙江.png";
import jiangsu from "./../img/江苏.png";
import jiangxi from "./../img/江西.png";
import jilin from "./../img/吉林.png";
import liaoning from "./../img/辽宁.png";
import neimenggu from "./../img/内蒙.png";
import ningxia from "./../img/宁夏.png";
import qinhai from "./../img/青海.png";
import shandong from "./../img/山东.png";
import shanxi from "./../img/山西.png";
import sichuan from "./../img/四川.png";
import shanxi1 from "./../img/陕西.png";
import shanghai from "./../img/上海.png";
import tianjin from "./../img/天津.png";
import xinjiang from "./../img/新疆.png";
import xizang from "./../img/西藏.png";
import yunnan from "./../img/云南.png";
import zhejiang from "./../img/浙江.png";

const matterType = [

    {
        name: "住宅用房",
        id: "50025969"
    },
    {
        name: "商业用房",
        id: "200782003"
    },
    {
        name: "工业用房",
        id: "200788003"
    },
    {
        name: "其他用房",
        id: "200798003"
    },
    {
        name: "机动车",
        id: "50025972"
    },
    {
        name: "机械设备",
        id: "200772003"
    },
    {
        name: "租赁权",
        id: "200816003"
    },
    {
        name: "使用权",
        id: "200816004"
    }
]

const zcMatterType = [
    {
        name: "房产",
        id: "56950002"
    },
    {
        name: "机动车",
        id: "56968001"
    },
    {
        name: "债权",
        id: "56956002"
    },
    {
        name: "股权",
        id: "56950003"
    },
    {
        name: "土地",
        id: "56972001"
    },

    {
        name: "船舶",
        id: "57734016"
    },
    {
        name: "林权",
        id: "57746020"
    },
    {
        name: "矿权",
        id: "57728018"
    },
    {
        name: "机械设备",
        id: "56936003"
    },
    {
        name: "农资产品",
        id: "201280014"
    },
    {
        name: "奢侈品",
        id: "201290015"
    },
]

const courtList = [
    { name: "安徽省高级人民法院", code: anhui, id: "http://ahfy.chinacourt.gov.cn" },
    { name: "北京市高级人民法院", code: bj, id: "http://bjgy.chinacourt.gov.cn" },
    { name: "重庆市高级人民法院", code: cq, id: "http://cqfy.chinacourt.gov.cn" },
    { name: "福建省高级人民法院", code: fj, id: "http://www.fjcourt.gov.cn" },
    { name: "广东省高级人民法院", code: gd, id: "http://www.gdcourts.gov.cn" },
    { name: "甘肃省高级人民法院", code: gs, id: "http://www.chinagscourt.gov.cn" },
    { name: "贵州省高级人民法院", code: gz, id: "http://www.guizhoucourt.gov.cn" },
    { name: "广西壮族自治区高级人民法院", code: gx, id: "http://www.gxcourt.gov.cn" },
    { name: "河南省高级人民法院", code: henan, id: "http://www.hncourt.gov.cn" },
    { name: "湖北省高级人民法院", code: hubei, id: "http://www.hbfy.gov.cn" },
    { name: "湖南省高级人民法院", code: hunan, id: "http://hunanfy.chinacourt.gov.cn" },
    { name: "河北省高级人民法院", code: hebei, id: "http://www.hebeicourt.gov.cn" },
    { name: "海南省高级人民法院", code: hainan, id: "http://www.hicourt.gov.cn" },
    { name: "黑龙江省高级人民法院", code: heilongjiang, id: "http://www.hljcourt.gov.cn" },
    { name: "江苏省高级人民法院", code: jiangsu, id: "http://www.jsfy.gov.cn" },
    { name: "江西省高级人民法院", code: jiangxi, id: "http://jxfy.chinacourt.gov.cn" },
    { name: "吉林省高级人民法院", code: jilin, id: "http://jlfy.chinacourt.gov.cn" },
    { name: "辽宁省高级人民法院", code: liaoning, id: "http://lnfy.chinacourt.gov.cn" },
    { name: "内蒙古自治区高级人民法院", code: neimenggu, id: "http://nmgfy.chinacourt.gov.cn" },
    { name: "宁夏回族自治区高级人民法院", code: ningxia, id: "http://www.nxfy.gov.cn" },
    { name: "青海省高级人民法院", code: qinhai, id: "http://qhfy.chinacourt.gov.cn" },
    { name: "山东省高级人民法院", code: shandong, id: "http://sdcourt.gov.cn" },
    { name: "山西省高级人民法院", code: shanxi, id: "http://shanxify.chinacourt.gov.cn" },
    { name: "四川省高级人民法院", code: sichuan, id: "http://www.sccourt.gov.cn" },
    { name: "陕西省高级人民法院", code: shanxi1, id: "http://sxfy.chinacourt.gov.cn" },
    { name: "上海市高级人民法院", code: shanghai, id: "http://www.hshfy.sh.cn" },
    { name: "天津市高级人民法院", code: tianjin, id: "http://tjfy.chinacourt.gov.cn" },
    { name: "新疆维吾尔自治区高级人民法院", code: xinjiang, id: "http://xjfy.chinacourt.gov.cn" },
    { name: "西藏自治区高级人民法院", code: xizang, id: "http://xzgy.chinacourt.gov.cn" },
    { name: "云南省高级人民法院", code: yunnan, id: "http://yngy.ynfy.gov.cn" },
    { name: "浙江省高级人民法院", code: zhejiang, id: "http://www.zjsfgkw.cn" }
]

const statusList = [
    {
        name: "正在进行",
        value: 0,
    },
    {
        name: "未开始",
        value: 1,
    },
    {
        name: "已结束",
        value: 2,
    },
    {
        name: "中止",
        value: 3,
    },
    {
        name: "撤回",
        value: 4,
    }
]

const zcCourtList = [
    { name: "安徽省高级人民法院", code: anhui, id: "3980379131" },
    { name: "北京市高级人民法院", code: bj, id: "2240288195" },
    { name: "重庆市高级人民法院", code: cq, id: "3342995665" },
    { name: "福建省高级人民法院", code: fj, id: "9123372036854771600" },
    { name: "广东省高级人民法院", code: gd, id: "9123372036854772550" },
    { name: "甘肃省高级人民法院", code: gs, id: "3166764991" }
]

const assetsList = [
    { name: "中国华融", id: "4000207", link: "/assets_announcement_type" },
    { name: "中国信达", id: "4001145", link: "/assets_announcement_type" },
    { name: "中国长城", id: "4001147", link: "/assets_announcement_type" },
    { name: "中国东方", id: "4001176", link: "/assets_announcement_type" },
    { name: "浙商资产", id: "2851080905", link: "/assets_court_list" },
    { name: "广东粤财", id: "2831360521", link: "/assets_court_list" },
]

const bankList = [
    { name: "邮政银行", id: "4001187", link: "/assets_announcement_type" },
    { name: "农业银行", id: "4001188", link: "/assets_announcement_type" },
    { name: "中国银行", id: "4001203", link: "/assets_announcement_type" },
    { name: "建设银行", id: "4001204", link: "/assets_announcement_type" },
    { name: "交通银行", id: "4001205", link: "/assets_announcement_type" },
    { name: "工商银行", id: "4001206", link: "/assets_announcement_type" }
]
const zcStatusList = [
    {
        name: "500万以下",
        value: "0,5000000",
        active: 1
    },
    {
        name: "500-1500万",
        value: "5000000,15000000",
        active: 2
    },
    {
        name: "1500-2500万",
        value: "15000000,25000000",
        active: 3
    },
    {
        name: "2500-3500万",
        value: "25000000,35000000",
        active: 4
    },
    {
        name: "3500万以上",
        value: "35000000",
        active: 5
    }
]

const zc_type = [
    {
        name: "国有资产",
        id: 1
    },
    {
        name: "普通资产",
        id: 2
    },
    {
        name: "涉案资产",
        id: 4
    },
    {
        name: "涉公资产",
        id: 5
    },
    {
        name: "金融资产",
        id: 7
    }
]

const cooperationList = [
    {
        name: "人民法院诉讼资产网",
        id: 6
    },
    {
        name: "北京产权交易所",
        id: 3
    },
    {
        name: "工商银行“融e购”",
        id: 4
    },
    {
        name: "公拍网",
        id: 5
    },
    {
        name: "京东拍卖",
        id: 2
    },
    {
        name: "淘宝拍卖",
        id: 1
    },
    {
        name: "中国拍卖行业协会",
        id: 7
    },
]

const cooperationHomeList = [
    {
        name: "人民法院诉讼资产网",
        id: 6
    },
    {
        name: "北京产权交易所",
        id: 3
    },
    {
        name: "公拍网",
        id: 5
    },
    {
        name: "工商银行“融e购”",
        id: 4
    },
    {
        name: "京东拍卖",
        id: 2
    },

]

// 腾云讯拍微信开放平台：账号：2214833145@qq.com  密码：zfz123!@#
// 小程序 appID : wx19039c0ef80f1239

export default {
    matterType,
    courtList,
    statusList,
    zcCourtList,
    assetsList,
    bankList,
    zcStatusList,
    zcMatterType,
    zc_type,
    cooperationList,
    cooperationHomeList
}