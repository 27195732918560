import React, { useEffect, useState } from "react";
import Header from "./../../header";
import Footer from "./../../footer";
import List from "./list";
import moment from "moment";
import 'moment/locale/zh-cn';
moment.locale('en');


const AssetsPromote = () => {


    return (
        <div >
            <Header />
            <List />
            <Footer />
        </div>
    )
}
export default AssetsPromote;