import { createContext, useContext } from 'react';
import { STORE_TODO, Search } from './search';
import { HEADER_TODO, Header } from './header';

function createStores() {
    return {
      [STORE_TODO]: new Search(),
      [HEADER_TODO] : new Header()
    };
  }
  
  const stores = createStores();
  const StoresContext = createContext(stores);
  const useStores = () => useContext(StoresContext);
  
  function HomeSearch() {
    const { todoStore } = useStores();
    return todoStore;
  }

  function HeaderType(){
    const { headerType } = useStores();
    return headerType;
  }
  
  export {
    stores,
    StoresContext,
    HomeSearch,
    HeaderType
  };
