import { action, observable } from 'mobx';
import { message } from "antd";
import WxLogin from "WxLogin";
import axios from "./../api";
export class Header {
    @observable headerType = "1";
    @observable wxModal = false;
    @observable loginUser = "";
    @observable bindModal = false;
    @observable bindType = "";
    @observable city = localStorage.getItem("cityName") || '';
    @observable cityId = localStorage.getItem("city_id") || 0;

    @action changeType = (type: string) => {
        this.headerType = type;
    }
    @action loginWx = (path: string, search: string, type: string) => {
        if (!this.wxModal) {
            this.togLoginModal();
        }
        if (type === "wx") {
            this.obj_wn_login(path, search);
        }
    }
    @action togLoginModal = () => {
        this.wxModal = !this.wxModal
    }

    @action wxLogin = async (code: string) => {
        const result: any = await axios.http.post(axios.api.pc_login, { code })
        if (result.status === 0) {
            this.togLoginModal();
            message.success('登录成功');

            if (!result.result.phone) {
                this.bindModal = true;
                this.bindType = 'phone'
            }
            result.result.time = new Date().getTime();
            window.localStorage.setItem('user', JSON.stringify(result.result));
            this.loginUser = JSON.stringify(result.result)
        } else {
            message.error(result.message)
        }
    }
    @action changeBindType = (type: string) => {
        this.bindType = type
    }
    @action codeLogin = async (values: any) => {
        const result: any = await axios.http.post(axios.api.code_login, { ...values })
        if (result.status === 0) {
            this.togLoginModal();
            message.success('登录成功');
            if (!result.result.wx) {
                this.bindType = 'wx';
                this.bindModal = true;
            }
            result.result.time = new Date().getTime();
            window.localStorage.setItem('user', JSON.stringify(result.result));
            this.loginUser = JSON.stringify(result.result)
        } else {
            message.error(result.message)
        }
    }
    @action chengeUser = (user?: string) => {
        this.loginUser = user ? user : '';
    }

    @action bindChange = () => {
        this.bindModal = !this.bindModal;
    }

    @action obj_wn_login = (path: string, search: string) => {
        let obj = new WxLogin({
            self_redirect: false,
            id: "login_container",
            appid: axios.api.wx_app_id,
            scope: "snsapi_login",
            redirect_uri: `${axios.api.wx_login}${path}${search}`,
            state: "",
            style: "",
            href: ""
        })
    }
    @action bind_login_wx = async (code: string) => {
        const result: any = await axios.http.post(axios.api.binding_wx, { code })
        if (result.status === 0) {
            message.success("微信绑定成功")
            this.bindChange();
            let user = window.localStorage.getItem("user");
            let JSONUser = user && JSON.parse(user);
            JSONUser.wx = true;
            localStorage.setItem('user', JSON.stringify(JSONUser))
        } else {
            message.error(result.message)
        }
        // this.loginUser = user ? user : ''
    }
    @action changeCity = (city: string, cityId: any) => {
        let city_active = city.lastIndexOf("市");
        let cityName = city.substring(0, city_active === -1 ? city.length : city_active);
        this.city = cityName;
        this.cityId = cityId * 1;
        window.localStorage.setItem("city_id", cityId)
        window.localStorage.setItem("cityName", cityName)
    }
}

export const HEADER_TODO = 'headerType'