import React, { useEffect } from "react";
import Header from "./../header";
import Footer from "./../footer";
import { Link } from "react-router-dom";
import "./index.scss";

const Instructions = () => {
    useEffect(() => {
        document.body.scrollIntoView();
    }, [])
    return (
        <div>
            <Header />

            <div className="session">
                <div className="banner_box">
                    
                </div>
                {/* <div className="instructions_box">
                    <h6 className="title_h6"><span>服务商</span>招商介绍</h6>
                    <p className="instructions_p">
                        腾云讯拍服务平台现面向广大服务商进行公开招商，主要涉及腾云讯拍下属的司法拍卖、破产资产、金融资产、非金融资产、贷款、保险、代办等业务。其中，服务商主要分为资产处置服务商、资产处置渠道商两大类，请服务商按照各业务的规则、流程进行报名。如有问题请拨打400-000-1102进行相关咨询。
                    </p>
                    <h6 className="title_h6"><span>服务商</span>招商</h6>
                    <div className="instructions_merchants_box">
                        <div className="instructions_merchants">
                            <h6>法院入驻</h6>
                            <p>
                                人民法院将查封、扣押、冻结的被执行人财产，在被执行人逾期不履行义务时，依法以公开竞价的方式卖与出价最高的竞买人，并用所得金额清偿债务的执行行为，是人民法院执行中的一种强制处分措施。
                            </p>
                            <Link to="/court_in">点击入驻</Link>
                        </div>
                        <div className="instructions_merchants">
                            <h6>司法拍卖辅助服务</h6>
                            <p>
                                基于司法拍卖辅助服务的工作场景，为企业提供预约看样、VR全景看房、移动工作台、专题推广等线上服务/产品支持，利用技术深度赋能，有效提升服务效率及客户满意度。
                            </p>
                            <Link to="/auxiliary">点击入驻</Link>
                        </div>
                    </div>
                </div> */}
            </div>
            <Footer />
        </div>
    )
}
export default Instructions;