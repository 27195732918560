import React from "react";
import Header from "./../header";
import { HashRouter as Router, Switch, Route, Redirect, Link, useLocation } from 'react-router-dom';
import Footer from "./../footer";
import "./index.scss";


const Personal: React.FC = (props: any) => {
    const path = useLocation().pathname
    return (
        <div>
            <Header />
            <div className="session">
                <div className="personal_box">
                    <div className="personal_left">
                        <ul>
                            <li>
                                <em></em>
                                账户中心
                                <em></em>
                            </li>
                            <li className={path === "/personal" ? "active" : ""}>
                                <Link to="/personal">个人中心</Link>
                            </li>
                            <li>
                                <em></em>
                                我的记录
                                <em></em>
                            </li>
                            <li className={path === "/personal/browsing_history" ? "active" : ""}>
                                <Link to="/personal/browsing_history">浏览记录</Link>
                            </li>
                            <li className={path === "/personal/look_list" ? "active" : ""}>
                                <Link to="/personal/look_list">看样申请</Link>
                            </li>
                            <li className={path === "/personal/consulting" ? "active" : ""}>
                                <Link to="/personal/consulting">咨询记录</Link>
                            </li>
                            <li className={path === "/personal/loan" ? "active" : ""}>
                                <Link to="/personal/loan">贷款申请</Link>
                            </li>
                            <li className={path === "/personal/intention" ? "active" : ""}>
                                <Link to="/personal/intention">意向招商</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="personal_right">
                        <div className="personal-center-content">
                            <Switch>
                                {
                                    props.route.children && props.route.children.map((route: any, index: number) => {
                                        return <Route
                                            key={route.path || index}
                                            path={route.path}
                                            exact={route.exact}
                                            render={() => {
                                                return <route.component route={route}></route.component>
                                            }}
                                        />
                                    })
                                }
                            </Switch>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default Personal;