import React from "react";
import { Link } from "react-router-dom";
import notImg from "./../img/gg_d.png";
import "./announcement.scss";
interface parameter {
    list: any[];
    link?: string
}
const List: React.FC<parameter> = (props) => {
    return (
        <div className="court_ann_box" style={{ paddingTop: "10px" }}>
            {
                props.list && props.list.map((item: any) => {
                    return (
                        <Link target="_blank" to={props.link ? `${props.link}?${item.id}` : `/announcement?${item.id}`}>
                            <div className="court_ann_list" key={item.id}>
                                <img onError={(e: any) => { e.target.src = notImg }} src={item.pic.indexOf("img.qqpai.com") > -1 ? `${item.pic}!w200` : item.pic} alt="" />
                                <div className="court_ann_content">
                                    <h6 title={item.title}>
                                        {item.title && item.title.length > 50 ? `${item.title.substring(0, 50)}...` : item.title}
                                    </h6>
                                    <div className="court_ann_text" title={item.content}>
                                        {
                                            item.content && item.content.length > 80 ? `${item.content.substring(0, 80)}...` : item.content
                                        }
                                    </div>
                                    <p>
                                        来自{item.court}    |    {item.put_time}
                                    </p>
                                </div>
                            </div></Link>
                    )
                })
            }
        </div>
    )
}

export default List;