import React, { useEffect, useState } from "react";
import { Table, Tooltip } from "antd";
import axios from "./../api";
import { observer } from "mobx-react";
import { HomeSearch, HeaderType } from "./../storesMobx";
import { useLocation } from "react-router-dom";

const columns = [
    {
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
        render: (text: string) => {
            return (
                <Tooltip title={text}>
                    <span>{text}</span>
                </Tooltip>
            )
        }
    },
    {
        title: '电话号码',
        dataIndex: 'phone',
        key: 'phone',
    },
    {
        title: '申请时间',
        dataIndex: 'create_time',
        key: 'create_time',
    },
];

const UserTable: React.FC = () => {
    const path = useLocation().pathname;
    const search = useLocation().search;
    const [list, set_list] = useState([]);
    const [total, set_total] = useState(0);
    const [page, set_page] = useState({
        page_num: 1,
        page_size: 10
    })
    const { loginWx }: any = HeaderType();

    const appointmentList = async () => {
        const result: any = await axios.http.post(axios.api.consult_list, { ...page })
        if (result.status === 0) {
            set_list(result.result.data);
            set_total(result.result.total)
        } else if (result.status === -101) {
            loginWx(path, search);
        }
    }
    useEffect(() => {
        appointmentList()
    }, [page])
    return (
        <div className="user_table">
            <Table
                dataSource={list} columns={columns}
                pagination={{ current: page.page_num, pageSize: page.page_size, total: total, hideOnSinglePage: true }}
            ></Table>
        </div>
    )
}
export default UserTable;