import React, { useEffect } from "react";
import Header from "./../header";
import Footer from "./../footer";
import { Link } from "react-router-dom";
import "./index.scss";

const Auxiliary = () => {
    useEffect(() => {
        document.body.scrollIntoView();
    }, [])
    return (
        <div>
            <Header />
            <div className="session">
                <div className="instructions_box">
                    <h6 className="title_h6"><span>司法拍卖</span>辅助机构入驻</h6>
                    <p className="instructions_p">
                        需要提供资料：<br />
                        1、具有相应成功案例或与法院签订有效期内的合作协议的司法辅助服务机构；<br />
                        2、司法辅助服务机构须具有合法营业执照以及固定的经营场所，并提供以下相关资料：<br />
                        1）法人有效身份证件影印件；<br />
                        2）有效期内的营业执照影印件；<br />
                        3）符合要求的营业场所使用权有效证明文件；<br />
                        3、司法辅助服务机构须在平台缴纳不少于10万元保证金；<br /><br />
                        申请流程：<br /><br />
                        1、先开通企业微信和微信公众号，支付商户号。<br />
                        2、缴纳10万元足额保证金。<br />
                        3、准备相关资料：公用邮箱；法人身份信息；营业执照清晰照片；联系人信息；其他所需资料等等(具体参照线上报名)。<br />
                        4、通过在线入口提交资料进行报名。
                    </p>

                    <Link to="/service_in" className="link no">申请成为服务商</Link>

                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Auxiliary;