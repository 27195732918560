import React, { useEffect, useState } from "react";
import Header from "../header/index";
import format from "./../component/format";
import { Link } from "react-router-dom";
import Footer from "./../footer/index";
import "./index.scss";

const Liability: React.FC = () => {
    return (
        <div>
            <Header />
            <div className="session">
                <div className="about_cooperation_box">
                    <div className="cooperation_title" style={{ height: "auto" }}>
                        <h1>免责声明</h1>
                        <h2>DISCLAIMER</h2>
                        <div>
                            <p>
                                使用者在接受本网站服务之前，请务必仔细阅读本声明。访问者访问本网站的行为以及通过各类方式利用本网站的行为，都将被视作是对本声明全部内容的无异议的认可。
                    </p>
                            <p>
                                一、释义 <br />
                        除非上下文另有所指，本声明中下列用语具有如下含义：<br />
                        本网站指：讯拍网
                    </p>
                            <p>
                                二、知识产权 <br />
                        本网站包括但不限于文字报导、图片、声音、录像、图表、标志、标识、广告、商标、商号、域名、软件、程序、版面设计、专栏目录与名称、内容分类标准以及为注册用户提供的任何或所有信息等之所有权归属四川腾云法智互联网科技有限公司及本网站的内容/信息提供者，受中国及国际版权法的保护。对本网站上所有内容之复制（意指收集、组合和重新组合），本网站享有排他权并受中国及国际版权法的保护。<br />
                        本网站及其任何组成部分不得被再造、复制、抄袭、交易，或为任何未经本网站允许的商业目的所使用。如确定客户行为违法或有损本网站和企业的利益，则本网站将保留诉讼法律的权利。<br />
                        本网站充分尊重转载文章之作者的著作权以及客户的商标权等知识产权。本网站合理信赖转载文章之原登载网站已经征得著作权人的同意并与著作权人就相关问题作出了妥善处理，同时本网站合理信赖客户对其在本网站登载的企业商标享有知识产权，并许可本网站合理使用。本网站对于有关文章的转载以及客户商标的使用属于合理使用，因此与之有关的知识产权纠纷本网站不承担任何责任。本网站郑重提醒访问者：请在转载有关文章或者使用有关企业商标时务必尊重其知识产权，否则与之有关的知识产权纠纷本网站免责。 同时，对本网站原创作品以及本网站标识，本网站享有自主知识产权。侵犯本网站之知识产权的，本网站有权追究其法律责任。
                    </p>
                            <p>
                                三、权利保护：<br />
                        如果您是本网站上发布的某一作品的著作权人和/或依法可以独立行使信息网络传播权的权利人，且您认为本网站上发布的内容侵犯了您对该等作品的合法权利，请您务必书面通知四川腾云法智互联网科技有限公司，但您应对书面通知陈述之真实性负责。
                    </p>
                            <p>
                                四、链接 <br />
                        本网站上的外网链接服务可以直接进入其他站点，这些链接的站点不受四川腾云法智互联网科技有限公司的控制，本网站不保证链接网站的真实性、准确性、完整性和安全性。对于任何因使用或信赖从此类网站或资源上获取的内容、产品、服务或其他材料而造成（或声称造成）的任何直接或间接损失，本网站均不承担任何责任。
                    </p>
                            <p>
                                五、免责 <br />
                        1、本网站刊载的各类文章、广告、访问者在本网站发表的观点以及以链接形式推荐的其他网站内容，仅为提供更多信息以参考使用或者学习交流，并不代表本网站观点，也不构成任何实质建议。本网站不能也没有甄别所有网站用户提供的内容真实性，也不保证其内容、产品、服务或其他材料的真实性和合法性，更不提供明示或暗示的担保。对于访问者根据本网站提供的信息所做出的一切行为，本网站极其关联企业不承担任何责任。<br />
                        2、 除本网站注明之服务条款外，其他一切因使用本网站而引致之任何意外、疏忽、诽谤、版权或知识产权侵犯及其所造成的损失，本网站不承担任何法律责任。
                    </p>
                            <p>
                                六、其他要求 <br />
                        访问者在从事与本网站相关的所有行为（包括但不限于访问浏览、利用、转载、宣传介绍）时，必须以善意且谨慎的态度行事；访问者不得故意或者过失的损害本网站的各类合法权益，不得利用本网站以任何方式直接或者间接的从事违反中华人民共和国法律、国际公约以及社会公德的行为。
                    </p>
                            <p>
                                七、适用法律 <br />
                        若因本网站及其旗下子网站产生任何诉诸于诉讼程序的法律争议，均以本网站所有者四川腾云法智互联网科技有限公司所在地的法院，即四川省成都市人民法院为管辖法院。<br />
                        本网站之上述声明以及其最终解释权、修改权、更新权均四川腾云法智互联网科技有限公司独家享有。
                    </p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Liability;