import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./code.scss";
import { useLocation } from "react-router-dom";

const QRCode = require("qrcode.react");
const Code = (props: { loginWx: any }) => {
  const user = window.localStorage.getItem("user");
  const path = useLocation().pathname;
  const search = useLocation().search;
  const top = () => {
    window.scrollTo(0, 0);
  };
  const announcementObj:any = {
    "/announcement_list": "/notice/1",
    "/bankruptcy_announcement": "/notice/3",
    "/assets_announcement_list": "/notice/2",
    "/assets_announcement_details": `/notice/2/${search.slice(1)}`,
    "/bankruptcy_announcement_details": `/notice/3/${search.slice(1)}`,
    "/announcement": `/notice/1/${search.slice(1)}`,
    "/matter_list": `/paipin/sf/list`,
    "/bankruptcy_matter_list": `/paipin/pc/list`,
    "/assets_matter_list": `/paipin/zc/list`,
  };
  const urlPath = announcementObj[path] || path;

  return (
    <div className="code_box">
      <div className="code_list">
        <div className="user_code">
          {user ? (
            <Link to="/personal">个人<br/>中心</Link>
          ) : (
            <span
              onClick={() => {
                props.loginWx(path, search);
              }}
            >
              登录
            </span>
          )}
        </div>
      </div>

      {/* <div className="code_list">
                <div className="user_content">
                    <Link to="/appointment">预约看样</Link>
                </div>
            </div> */}
      <div className="code_list">
        <div className="user_content">400-000-1102</div>
      </div>
      <div className="code_list">
        <div className="code_after"></div>
      </div>
      <div className="code_list">
        <div className="user_code">
          <Link to="/opinion">意见<br/>反馈</Link>
        </div>
      </div>
      <div className="code_list">
        <div className="share_box" id="share_box">
          <QRCode value={`https://m.qqpai.com/#${urlPath}${search}`} size={110} />
        </div>
      </div>
      <div onClick={top} className="code_list"></div>
    </div>
  );
};

export default Code;
