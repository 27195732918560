import React, { useState, useEffect, useRef } from "react";
import Header from "./../header";
import Footer from "./../footer";
import { Spin, Result } from 'antd';
import axios from './../api';
import { Link } from "react-router-dom";
import Paging from "./../component/pagination";
import "./index.scss";
interface param {
    type?: number;
    page_num: number;
    page_size: number;
}
const anotherList = [
    {
        name: '全部',
        type: 0
    },
    {
        name: '勘验委托',
        type: 2
    },
    {
        name: '看样委托',
        type: 1
    },
    {
        name: '其他委托',
        type: 3
    }
]
const AnotherPlace: React.FC = () => {
    const [spinning, set_spinning] = useState(true)
    const [total, set_total] = useState(0);
    const [list, set_list] = useState([]);
    const [param, set_param] = useState({
        type: 0,
        page_num: 1,
        page_size: 12
    })
    // 获取列表
    const get_entrust = async () => {
        set_spinning(true)
        const param_post: param = {
            page_num: param.page_num,
            page_size: param.page_size
        };
        if (param.type) {
            param_post.type = param.type
        }
        const result: any = await axios.http.newGet(axios.api.entrust_list, { ...param_post });
        if (result.status === 0) {
            set_total(result.result.total)
            set_list(result.result.data)
        }
        set_spinning(false)
    }
    //  回显类型
    const shoeType = (type: number): string => {
        let typeName = '看样委托'
        if (type == 2) {
            typeName = '勘验委托'
        } else if (type == 3) {
            typeName = '其他委托'
        }
        return typeName;
    }
    useEffect(() => {
        get_entrust();
    }, [param])
    return (
        <div>
            <Header />

            <Spin spinning={spinning} tip="加载中...">
                <div className="session">
                    <div className="another_place_box">
                        <div className="another_place_nav">
                            委托类型
                        {
                                anotherList.map((item: any, index: number) => {
                                    return (
                                        <span onClick={() => { set_param({ ...param, type: item.type }) }} className={param.type == item.type ? 'active' : ''}>{item.name}</span>
                                    )
                                })
                            }
                        </div>

                        <div className="another_place_list">
                            {
                                list.length > 0 &&
                                list.map((item: any) => {
                                    return (
                                        <Link to={`/another_detail?id=${item.id}`} className="another_place" key={item.id}>
                                            <div className="another_left">
                                                <div className="another_header">
                                                    <em></em>
                                                    <div>
                                                        <h6>{item.title}</h6>
                                                        <p>{item.create_time}</p>
                                                    </div>
                                                </div>
                                                <p>委托类型: <span>{shoeType(item.type)}</span></p>
                                                <p>委托机构: <span>{item.agency}</span></p>
                                            </div>
                                            <div className={item.state == "0" ? 'another_right another_bg' : 'another_right'}>
                                                <em className={item.state == "1" ? "yiling" : item.state == "2" ? "yiwan" : ''}></em>
                                            </div>
                                        </Link>
                                    )
                                })

                            }
                        </div>

                        <div className="more_box">
                            <Paging
                                pageSize={param.page_size}
                                current={param.page_num}
                                pageSizeOptions={['20', '32', '56']}
                                total={total}
                                onShowSizeChange={(size: number) => {
                                    set_param({ ...param, page_size: size })
                                }}
                                onChange={(page: number) => {
                                    set_param({ ...param, page_num: page })
                                }}
                            />
                        </div>
                    </div>

                    {list.length == 0 && (
                        <Result
                            status="404"
                            title="很抱歉"
                            subTitle="暂时没有相关数据~"
                        />
                    )}
                </div>
            </Spin>
            <Footer />
        </div>
    )
}
export default AnotherPlace;
