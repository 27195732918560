
import React from 'react';
import ReactDOM from 'react-dom';
import Home from './router';
import * as serviceWorker from './serviceWorker';
import { ConfigProvider } from "antd";
import zhCN from 'antd/es/locale/zh_CN';
import { Provider } from "mobx-react";
import { stores, StoresContext } from "./storesMobx";
import 'antd/dist/antd.css';
import './index.scss';

import './polyfill';
import 'core-js/es';
import 'mutation-observer';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

ReactDOM.render(
  <React.StrictMode>
    <Provider  {...stores}>
      <StoresContext.Provider value={stores}>
        <ConfigProvider locale={zhCN}>
          <Home />
        </ConfigProvider>
      </StoresContext.Provider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
serviceWorker.unregister();
