import React, { useEffect, useState } from "react";
import Header from "./../../header";
import Footer from "./../../footer";
import axios from "./../../api";
import { message, Spin, Pagination, Result } from "antd";
import { Link, useLocation } from "react-router-dom";
import List from "./../../component/announcementList";
import Paging from "./../../component/pagination";
import "./../../courtList/index.scss";

const CourtAnnouncement = () => {
    const id = useLocation().search;
    const [list, set_list] = useState([]);
    const [spinning, set_spinning] = useState(false);
    const [get_body, set_body] = useState({
        org_id: id.slice(1),
        page_num: 1,
        page_size: 50
    })
    const [court_count, set_court_count] = useState({
        auction: 0,
        notice: 0,
        title: ""
    })

    const [total, set_total] = useState(-1);

    const getNoticeList = async () => {
        set_spinning(true)
        const result: any = await axios.http.newGet(axios.api.zc_notice_list, { ...get_body });
        if (result.status === 0) {
            set_list(result.result.data);
            set_total(result.result.total);
            const dom = document.getElementById("list_top")
            dom && dom.scrollIntoView({ behavior: "smooth" });
        } else {
            message.error(result.message)
        }
        set_spinning(false)
    }

    // 获取法院信息
    const courtCount = async () => {
        const result: any = await axios.http.newGet(`${axios.api.zc_org_count}`, { org_id: id.slice(1) })
        if (result.status === 0) {
            set_court_count(result.result)
        }
    }
    useEffect(() => { courtCount() }, [])

    useEffect(() => {
        getNoticeList()
    }, [get_body])
    return (
        <div id="list_top">
            <Header />
            <div className="session">
                <div className="court_mark_list_box">
                    <div className="court_title">
                        <h6>
                            {court_count.title}
                        </h6>
                        <p>
                            拍卖公告 <span>{court_count.notice}</span>&emsp;|&emsp;
                            上拍次数 <span>{court_count.auction}</span>
                        </p>
                        <div className="court_type">
                            <Link to={`/assets_court_list${id}`} className="court_type_nav">
                                机构标的物
                            </Link>
                            <Link to={`/assets_announcement${id}`} className="court_type_nav active">
                                机构公告
                            </Link>
                        </div>
                    </div>

                    <Spin tip="数据加载中..." spinning={spinning}>
                        <div style={{ paddingTop: "40px", background: "#fff" }}>
                            <List list={list || []} link="/assets_announcement_details" />
                        </div>
                        <div className="more_box">
                            <Paging
                                pageSize={get_body.page_size}
                                current={get_body.page_num}
                                total={total}
                                onShowSizeChange={(size: number) => {
                                    set_body({ ...get_body, page_size: size })
                                }}
                                onChange={(page: number) => {
                                    set_body({ ...get_body, page_num: page })
                                }}
                            />
                        </div>
                    </Spin>
                    {
                        total === 0 && <Result
                            status="404"
                            title="很抱歉"
                            subTitle="没有公告列表~"
                        />
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CourtAnnouncement;