import React from "react";
import NotImg from "./../img/da@2x.png";
import { Result, Button } from "antd";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import "./list.scss";

interface listProps {
    list: string[];
    color?: boolean;
    narrow?: boolean;
    notList: boolean;
    link: string;
}
const List = (props: listProps) => {
    return (
        <div>
            <ul className={props.narrow ? "narrow list_box" : "list_box"}>
                {
                    props.list.map((item: any) => {
                        let isWan = false;
                        let price = "欢迎询价";
                        if (item.price > 10000) {
                            price = (item.price / 10000).toFixed(4) + "";
                            isWan = true
                        } else {
                            price = item.price ? item.price : "欢迎询价"
                        }
                        let srcImg = item.pic || item.main_pic;
                        return (
                            <Link target="_blank" to={`/${props.link}?${item.id}`} key={item.id}>
                                <li style={{ height: "388px" }}>
                                    <div className="li_img">
                                        <img onError={(e: any) => { e.target.src = NotImg }} src={srcImg ? (srcImg.indexOf("img.qqpai.com") > -1 ? `${srcImg}!w300` : srcImg) : NotImg} alt="" />
                                    </div>
                                    <h3 title={item.title}>{item.title}</h3>
                                    <p >
                                        {(item.category && item.category === "债权") ? "债权本息" : "参考价值"}: <b>￥{price}{isWan ? "万" : ""}</b>
                                    </p>
                                    <p >
                                        {item.category}
                                    </p>
                                    <p className="nowrap" title={item.org_title}>
                                        处置机构：{item.org_title}
                                    </p>

                                    <p>
                                        {item.view_count}次围观  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                        {item.want_count}人意向
                                    </p>
                                    {
                                        item.status === 1 &&
                                        <button className={props.color ? "green" : ""}>
                                            <LeftOutlined className="one8" />
                                            <LeftOutlined className="one6" />
                                            <LeftOutlined className="one4" />
                                            &emsp;
                                            招商中
                                            &emsp;
                                            <RightOutlined className="one4" />
                                            <RightOutlined className="one6" />
                                            <RightOutlined className="one8" />
                                        </button>
                                    }
                                    {
                                        item.status === 0 && <button className={props.color ? "green" : "end"}>
                                            已结束
                                        </button>
                                    }

                                </li>
                            </Link>

                        )
                    })
                }
            </ul>
            {
                props.notList && <Result
                    status="404"
                    title="很抱歉"
                    subTitle="没有您要找的招商项目，换个条件试试~"
                />
            }
        </div>

    )
}

export default List;