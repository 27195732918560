import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Modal } from "antd";
import axios from "./../api";
import { useHistory } from "react-router-dom";
import { HeaderType,HomeSearch } from "./../storesMobx";
import { CloseCircleOutlined } from '@ant-design/icons';
import "./city.scss";

const CitySelect = (props: { visible: boolean; isVisible: any }, ref: any) => {
  const history = useHistory();
  const [visible, set_visible] = useState(false);
  const [city_list, set_city_list] = useState([]);
  const { changeCity, city }: any = HeaderType();
  const { search_city_id }: any = HomeSearch();
  const hotCity = [
    {
      name: "北京",
      cityId: 110000,
    },
    {
      name: "上海",
      cityId: 310000,
    },
    {
      name: "广州",
      cityId: 440100,
    },
    {
      name: "深圳",
      cityId: 440300,
    },
    {
      name: "成都",
      cityId: 510100,
    },
    {
      name: "重庆",
      cityId: 500000,
    },
    {
      name: "杭州",
      cityId: 330100,
    },
    {
      name: "武汉",
      cityId: 420100,
    },
    {
      name: "西安",
      cityId: 610100,
    },
    {
      name: "天津",
      cityId: 120000,
    },
    {
      name: "苏州",
      cityId: 320500,
    },
    {
      name: "南京",
      cityId: 320100,
    },
    {
      name: "郑州",
      cityId: 410100,
    },
    {
      name: "长沙",
      cityId: 430100,
    },
    {
      name: "东莞",
      cityId: 441900,
    },
    {
      name: "沈阳",
      cityId: 210100,
    },
    {
      name: "青岛",
      cityId: 370200,
    },
    {
      name: "合肥",
      cityId: 340100,
    },
    {
      name: "佛山",
      cityId: 440600,
    },
  ];
  useImperativeHandle(ref, (): any => ({
    isVisible: () => {
      set_visible(!visible);
    },
  }));
  const getCity = async () => {
    const res: any = await axios.http.get(axios.api.full_city);
    if (res.status === 0) {
      set_city_list(res.result);
      window.localStorage.setItem("city_list",JSON.stringify(res.result))
    }
  };
  const cityClick = (city: string, cityId: string) => {
    changeCity(city, cityId);
    search_city_id(cityId)
    set_visible(!visible);
  };
  useEffect(() => {
    let city_list = window.localStorage.getItem("city_list");
    
    city_list ? set_city_list(JSON.parse(city_list)) : getCity();
    
  }, []);
  return (
    <Modal
      title="请选择所在城市"
      visible={visible}
      width={1000}
      footer={null}
      closeIcon={<CloseCircleOutlined />}
      onCancel={() => {
        set_visible(!visible);
      }}
    >
      <div className="hot_city">
        热门城市:
        {hotCity.map((item: any) => {
          return (
            <span
              key={item.cityId}
              onClick={() => {
                cityClick(item.name, `${item.cityId}`);
                
              }}
            >
              {item.name}
            </span>
          );
        })}
      </div>
      <div className="city-list-box">
        <ul>
          {city_list &&
            city_list.map((item: any) => {
              if (item.children) {
                return (
                  <li key={item.value}>
                    <span>{item.label}</span>
                    <div className="city-list-1-box">
                      {item.children &&
                        item.children.map((child: any) => {
                          return (
                            <div key={child.value} className="city-list-1">
                              <em></em>
                              <span>{child.label}</span>
                              <div className="city-list-2">
                                {child.children &&
                                  child.children.map((child2: any) => {
                                    return (
                                      <span
                                        onClick={() => {
                                          cityClick(
                                            child2.label,
                                            `${child2.value}`
                                          );
                                        }}
                                        key={child2.value}
                                      >
                                        {child2.label}
                                      </span>
                                    );
                                  })}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </li>
                );
              }
            })}
        </ul>
      </div>
    </Modal>
  );
};

export default forwardRef(CitySelect);
