export const editTitle = (path: string): string => {
    const objPath:any= {
        "/":"司法首页-讯拍网",
        "/asset_auction":"资产首页-讯拍网",
        "/bankruptcy_auction":"破产首页-讯拍网",
        "/matter_list":"司法拍品列表-讯拍网",
        "/bankruptcy_matter_list":"破产拍品列表-讯拍网",
        "/assets_matter_list":"资产拍品列表-讯拍网",
        "/matter_details":"标的物详情-讯拍网",
        "/news":"资讯中心-讯拍网",
        "/bankruptcy_news":"资讯中心-讯拍网",
        "/assets_news":"资讯中心-讯拍网",
        "/news_details":"新闻详情-讯拍网",
        "/assets_news_details":"新闻详情-讯拍网",
        "/bankruptcy_news_details":"新闻详情-讯拍网",
        "/announcement":"公告详情-讯拍网",
        "/court_list":"法院标的物列表-讯拍网",
        "/court_announcement":"法院公告-讯拍网",
        "/all_court_list":"法院列表-讯拍网",
        "/announcement_list":"司法公告列表-讯拍网",
        "/help":"帮助中心-讯拍网",
        "/search_list":"搜索-讯拍网",
        "/guest":"增值营销-讯拍网",
        "/bankruptcy_guest":"增值营销-讯拍网",
        "/service_in":"司法辅助入驻资料填写-讯拍网",
        "/instructions":"服务商入驻-讯拍网",
        "/auxiliary":"司法辅助说明-讯拍网",
        "/court_in":"法院入驻资料填写-讯拍网",
        "/assets_promote":"资产推介-讯拍网",
        "/assets_details":"资产推介详情-讯拍网",
        "/asset_in":"资产拍卖入驻流程-讯拍网",
        "/assets_bank_in":"服务商入驻流程介绍-讯拍网",
        "/assets_cooperation":"平台合作机构-讯拍网",
        "/assets_court_list":"合作机构详情-讯拍网",
        "/asset_matter_details":"标的物详情-讯拍网",
        "/assets_announcement":"机构公告列表-讯拍网",
        "/assets_announcement_list":"资产公告列表-讯拍网",
        "/assets_announcement_type":"资产分类-讯拍网",
        "/assets_announcement_details":"公告详情-讯拍网",
        "/bankruptcy_in":"破产拍卖-管理人入驻-讯拍网",
        "/bankruptcy_announcement":"破产公告列表-讯拍网",
        "/bankruptcy_announcement_details":"破产公告详情-讯拍网",
        "/bankruptcy_process_user":"破产管理人入驻流程-讯拍网",
        "/bankruptcy_user_data":"破产管理人入驻资料-讯拍网",
        "/bankruptcy_server_data":"破产管理服务商入驻资料-讯拍网",
        "/bankruptcy_enterprise_list":"企业列表-讯拍网",
        "/bankruptcy_enterprise_home":"企业主页-讯拍网",
        "/auxiliary_list":"服务商详情-讯拍网",
        "/agreement":"用户注册协议-讯拍网",
        "/electronic":"电子封条-讯拍网",
        "/cooperation":"合作招商-讯拍网",
        "/opinion":"意见反馈-讯拍网",
        "/map_housing":"拍卖地图-讯拍网",
    }
    return objPath[path] || "讯拍网"
} 