import React from "react";
import { Result } from 'antd';
import Header from "./../header";
import Footer from "./../footer";
const Development: React.FC = () => {
    return (
        <div>
            <Header />
            <Result
                status="403"
                title="功能在努力开发中..."
                subTitle="该功能正在开发中,敬请期待..."
            />
            <Footer />
        </div>

    )
}

export default Development;