import React, { useEffect, useState } from "react";
import { HeaderType } from "./../storesMobx";
import WxLogin from "WxLogin";
import axios from "./../api";
import Phone from "./phoneLogin";
import { useLocation } from "react-router-dom";
import "./bind.scss";
const BindModal = () => {
  const { pathname, search } = useLocation();
  const {
    bindType,
    bindChange,
    bindModal,
    wxModal,
    togLoginModal,
    loginWx,
    wxLogin,
    loginUser,
    chengeUser,
    codeLogin,
    obj_wn_login,
  }: any = HeaderType();
  useEffect(() => {
    if (bindType === "wx" && bindModal) {
      let obj = new WxLogin({
        self_redirect: false,
        id: "login_container_modal",
        appid: axios.api.wx_app_id,
        scope: "snsapi_login",
        redirect_uri: `${axios.api.wx_login}${pathname}${search}`,
        state: "",
        style: "",
        href: "",
      });
    }
  }, [bindModal]);
  return (
    <div
      className="wx_login_box"
      style={{ display: bindModal ? "flex" : "none" }}
    >
      <div
        className="login_modal"
        style={{ height: bindType === "phone" ? "400px" : "500px" }}
      >
        <h6>{bindType === "phone" ? "绑定手机号码" : "扫码绑定微信"}</h6>
        <div
          className="login_content"
          style={{ height: bindType === "phone" ? "350px" : "400px" }}
        >
          {bindType === "phone" && <Phone type="bind" />}
          <div
            className="wx_login_bg"
            style={{ display: `${bindType === "wx" ? "block" : "none"}` }}
          >
            <div id="login_container_modal"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BindModal;
