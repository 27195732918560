import React, { useEffect, useState } from "react";
import List from "./../../component/assetsList";
import { Checkbox, InputNumber, message, Pagination, Spin, Input } from "antd";
import axios from "./../../api";
import moment from "moment";
import 'moment/locale/zh-cn';
import data from "./../../home/data";
import { useLocation } from "react-router-dom";
import "./../../matterList/index.scss";
import "./index.scss";
moment.locale('en');
const { Search } = Input;
interface promote {
    org_id?: number
}
const AssetsPromote: React.FC<promote> = (props) => {
    const parameterId = useLocation().search;
    let categoryId = "";
    let isMatterAdd = false;
    if (parameterId.indexOf("matterType") > -1) {
        const addSplit = parameterId.split("=");
        categoryId = addSplit[1];
    }
    if (parameterId.indexOf("matterAdd") > -1) {
        isMatterAdd = true;
    }
    const [is_matter_add, set_is_matter_add] = useState(isMatterAdd)
    const [place_province_left, set_place_province_left] = useState(0);
    const [province_id, set_province_id] = useState(0);  //省ID
    const [city_id, set_city_id] = useState(0);  //市ID
    const [area_id, set_area_id] = useState(0);  //县区ID
    const [id_type, set_id_type] = useState('province');  //城市级别
    const [province_list, set_province_list]: any = useState([]); //省级列表
    const [city_list, set_city_list]: any = useState([]); //市级列表
    const [area_list, set_area_list]: any = useState([]); //县区列表
    const [sf_ategory_list, set_sf_ategory_list] = useState([]); //司法分类


    const [list_search, set_list_search]: any = useState({
        category_id: categoryId, //标的类型 没有时不传
        city_id: "",   //标的所在地
        status: "",   //标的状态 0进行中 1未开始 2已结束 3终止 4撤回
        start_price: "", //价格 没有不传
        end_price: "", //价格 没有不传
        sort_type: 1, //1默认 2价格 3次数
        sort_order: 0, //0升序 1降序
        page_num: 1,
        page_size: 20,
        title: "",
        org_id: (props.org_id && props.org_id < 12 ? props.org_id + 1 : "") || "",
        org_group_id: (props.org_id && props.org_id > 12 ? props.org_id : "") || ""
    })

    const [list, set_list] = useState([]);
    const [total, set_total] = useState(0);
    const [not_list, set_not_list] = useState(false);
    const [spinning, set_spinning] = useState(false);
    const [price_active, set_price_active] = useState(0);

    // 获取城市列表
    const getCity = async () => {
        let cityId = 0;
        if (id_type === "province" || id_type === "city") {
            cityId = province_id;
        }
        if (id_type === "area") {
            cityId = city_id;
        }
        const res: any = await axios.http.newGet(`${axios.api.sub_city}?id=${cityId}`, {})
        if (res.status === 0) {
            if (id_type === "province") {
                set_province_list(res.result)
            } else if (id_type === "city") {
                set_city_list(res.result)
            } else {
                set_area_list(res.result)
            }
        }
        if (is_matter_add) {
            set_is_matter_add(false);
            const addSplit: any = parameterId.split("=");
            set_id_type("city");
            set_province_id(addSplit[1] * 1);
            provinceAdd(null, addSplit[1] * 1)
        }
    }
    // 获取司法分类
    const sfCategoryList = async () => {
        const res: any = await axios.http.newGet(axios.api.zc_category_list, {});
        if (res.status === 0) {
            set_sf_ategory_list(res.result)
        }

    }
    //省点击时位置的改变
    const provinceAdd = (e: any, id: number) => {
        let tagName = null;
        let left = 0;
        if (e) {
            tagName = e.target.tagName;
            left = e.target.offsetLeft;
        } else {
            const item: any = document.getElementById(`${id}`);
            tagName = item.tagName;
            left = item.offsetLeft
        }
        set_id_type("city");
        set_city_id(0);
        set_province_id(id);
        if (tagName === "SPAN") {
            set_place_province_left(-left + 22)
        } else {
            set_place_province_left(-left)
        }
    }

    //获取列表
    const auctionList = async () => {
        set_spinning(true)
        const queryBody: any = {};
        for (let key in list_search) {
            if ((list_search[key] || list_search[key] === 0) && list_search[key] !== -1) {
                queryBody[key] = list_search[key]
            }
        }
        const result: any = await axios.http.newGet(axios.api.zc_tj_list, { ...queryBody });
        if (result.status === 0) {
            set_not_list(result.result.data.length === 0);
            set_total(result.result.total);
            set_list(result.result.data);
            const dom = document.getElementById("list_top")
            dom && dom.scrollIntoView({ behavior: "smooth" });
        } else {
            message.error(result.message)
        }
        set_spinning(false)
    }
    useEffect(() => {
        getCity();
    }, [province_id, city_id]);

    useEffect(() => {
        sfCategoryList();
    }, []);

    // 查询列表
    useEffect(() => {
        auctionList();
    }, [list_search]);

    return (
        <div className="session" >
            <ul className="list_conditions_box">
                <li>
                    <span>处置类型</span>
                    <div className="class_list">
                        <div onClick={() => { set_list_search({ ...list_search, category_id: "", page_num: 1 }) }} style={{ width: "60px" }} className={list_search.category_id == "" ? "class_list_span active" : "class_list_span"}>
                            <span>不限</span>
                        </div>
                        <div className="class_list_multilayer_box">
                            {
                                sf_ategory_list && sf_ategory_list.map((item: any) => {
                                    return (
                                        <div onClick={() => { set_list_search({ ...list_search, category_id: `${item.id}`, page_num: 1 }) }} key={item.id} className={item.id == list_search.category_id ? "class_list_div active" : "class_list_div"}>
                                            <span >{item.name}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </li>
                <li>
                    <span>所在地</span>
                    <div className="class_list">
                        <div onClick={() => { set_province_id(0); set_list_search({ ...list_search, city_id: "", page_num: 1 }) }} className={province_id == 0 ? "class_list_span active" : "class_list_span"}>
                            <span>不限</span>
                        </div>
                        <div className="class_list_multilayer_box">
                            {
                                province_list && province_list.map((item: any, index: number) => {
                                    return (
                                        <div onClick={(e) => { provinceAdd(e, item.id); set_list_search({ ...list_search, city_id: `${item.id}`, page_num: 1 }) }}
                                            key={item.id}
                                            id={item.id}
                                            className={province_id === item.id ? "place_province active" : "place_province"}>
                                            <span>
                                                {item.shortname}
                                                {
                                                    province_id !== item.id && " ∨"
                                                }
                                                {
                                                    province_id === item.id && " ∧"
                                                }
                                            </span>
                                            <div className="place_province_secondary_box" style={{ left: `${place_province_left}px` }}>
                                                <div style={{ width: "80px" }}
                                                    onClick={(e) => { e.stopPropagation(); set_city_id(0); set_list_search({ ...list_search, city_id: `${item.id}`, page_num: 1 }) }}
                                                    className={city_id == 0 ? "class_list_span active" : "class_list_span"}>
                                                    <span>全省</span>
                                                </div>
                                                <div className="class_list_multilayer_box">
                                                    {
                                                        city_list && city_list.map((city: any) => {
                                                            return (
                                                                <div onClick={(e) => { e.stopPropagation(); set_id_type("area"); set_city_id(city.id); set_list_search({ ...list_search, city_id: `${city.id}`, page_num: 1 }) }}
                                                                    key={city.id}
                                                                    className={city_id === city.id ? "place_province active" : "place_province"}>
                                                                    <span>
                                                                        {city.shortname}
                                                                        {
                                                                            city_id !== city.id && " ∨"
                                                                        }
                                                                        {
                                                                            city_id === city.id && " ∧"
                                                                        }
                                                                    </span></div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        city_id !== 0 &&
                                                        <div className="class_list_multilayer_area">
                                                            <div className="place_province_secondary_box">
                                                                <div style={{ width: "80px" }}
                                                                    onClick={(e) => { e.stopPropagation(); set_area_id(0);; set_list_search({ ...list_search, city_id: `${city_id}`, page_num: 1 }) }}
                                                                    className={area_id === 0 ? "class_list_span active" : "class_list_span"}>
                                                                    <span>全部</span>
                                                                </div>
                                                                <div className="class_list_multilayer_box">
                                                                    {
                                                                        area_list && area_list.map((area: any) => {
                                                                            return (
                                                                                <div onClick={(e) => { e.stopPropagation(); set_id_type("area"); set_area_id(area.id); set_list_search({ ...list_search, city_id: `${area.id}`, page_num: 1 }) }}
                                                                                    key={area.id}
                                                                                    className={area_id === area.id ? "place_province active" : "place_province"}>
                                                                                    <span>
                                                                                        {area.shortname}
                                                                                    </span>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                </li>
                <li>
                    <span>参考价值</span>
                    <div className="class_list">
                        <div
                            onClick={() => { set_list_search({ ...list_search, end_price: -1, start_price: -1, page_num: 1 }) }}
                            className={price_active === 0 ? "class_list_span active" : "class_list_span"}>
                            <span>不限</span>
                        </div>
                        {
                            data.zcStatusList.map((item: any) => {
                                return (
                                    <div
                                        style={{ width: "100px", textAlign: "center" }}
                                        onClick={() => {
                                            set_price_active(item.active)
                                            set_list_search({
                                                ...list_search,
                                                page_num: 1,
                                                start_price: item.value.split(",")[0],
                                                end_price: item.value.split(",")[1],
                                            })
                                        }}
                                        key={item.value} className={price_active === item.active ? "class_list_div active" : "class_list_div"}>
                                        <span>{item.name}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </li>
            </ul>
            <div className="list_conditions_nav" >
                <div className="court_matter_number">
                    招商公告({total})
                        </div> &emsp;
                    参考价值：
                    <InputNumber formatter={value => `￥${value}`} min={0} onBlur={(e: any) => { set_list_search({ ...list_search, start_price: e.target.value.slice(1), page_num: 1 }) }} />&emsp;-&emsp;
                    <InputNumber formatter={value => `￥${value}`} min={0} onBlur={(e: any) => { set_list_search({ ...list_search, end_price: e.target.value.slice(1), page_num: 1 }) }} /> &emsp;
                    <Checkbox.Group
                    onChange={(value) => {


                        let status: any = -1;
                        if (value.length === 1) {
                            status = value[0];
                        } else {
                            status = -1;
                        }
                        set_list_search({ ...list_search, status, page_num: 1 })
                    }}
                >
                    <Checkbox value={1}>招商中</Checkbox>
                    <Checkbox value={0}>招商结束</Checkbox>
                </Checkbox.Group>
                {/* <Checkbox.Group
                    onChange={(value) => {
                        let status = -1;
                        if (value.length) {
                            status = 0;
                        }
                        set_list_search({ ...list_search, status, page_num: 1 })
                    }}
                >
                    
                </Checkbox.Group> */}
                <span
                    className={list_search.sort_type === 2 ? "active" : ""}
                    onClick={() => {
                        const sort_order = list_search.sort_type === 2 ? list_search.sort_order ? 0 : 1 : 0
                        set_list_search({ ...list_search, sort_type: 2, sort_order, page_num: 1 })
                    }}>
                    参考价值
                        {
                        list_search.sort_order && list_search.sort_type === 2 ? "↓" : "↑"
                    }
                </span> &emsp;
                    <span
                    className={list_search.sort_type === 1 ? "active" : ""}
                    onClick={() => {
                        const sort_order = list_search.sort_type === 1 ? list_search.sort_order ? 0 : 1 : 0
                        set_list_search({ ...list_search, sort_type: 1, sort_order, page_num: 1 })
                    }}>
                    默认
                    </span> &emsp;

                    <Search
                    style={{ width: "450px" }}
                    placeholder="请输入招商信息"
                    enterButton="搜索"
                    size="large"
                    onSearch={(value: any) => { set_list_search({ ...list_search, title: value, page_num: 1 }) }}
                />
            </div>

            <Spin tip="加载中..." spinning={spinning}>
                <List notList={not_list} list={list} link="assets_details" />
            </Spin>



            <div className="more_box">
                <Pagination showQuickJumper
                    hideOnSinglePage={true}
                    pageSize={list_search.page_size}
                    pageSizeOptions={['20']}
                    current={list_search.page_num} total={total}
                    onChange={(page, pageSize) => {
                        set_list_search({ ...list_search, page_num: page, page_size: pageSize })
                    }} />
            </div>
        </div>
    )
}
export default AssetsPromote;