import React, { useEffect, useState } from "react";
import Header from "../header/index";
import format from "./../component/format";
import { Link } from "react-router-dom";
import Footer from "./../footer/index";
import us1 from "./../img/us-1.png";
import us2 from "./../img/us-2.png";
import us3 from "./../img/us-3.png";
import us4 from "./../img/us-4.png";
import us5 from "./../img/us-5.png";
import us6 from "./../img/us-6.png";
import us7 from "./../img/us-7.png";
import us_code from "./../img/us-code.png";
import "./index.scss";

const Cooperation: React.FC = () => {
    return (
        <div>
            <Header />
            <div className="session">
                <div className="about_cooperation_box">
                    <div className="cooperation_title">
                        <h1>合作招商</h1>
                        <h2>COOPERATION INVESTMENT</h2>
                        <p>
                            腾云拍卖·讯拍网平台面向全国吸纳合作伙伴，从营销、金融、经营、数据、供应链等方面为其提供全维度的持续赋能。平台不仅以本身的精准流量直接助力合作伙伴的业务增长，提高拍卖溢价率，还将通过技术、数据、SaaS系统等实现平台资源共享，以提升其运营效率，共建拍卖行业良性竞争环境
                        </p>
                    </div>
                    <div className="cooperation_list">
                        <div className="cooperation_list_content">
                            <h3>打通接口，数据同步</h3>
                            <p>讯拍网平台与合作伙伴打通数据接口，通过PC端、手机微信端同步发布拍卖公告，实现同一标的多渠道发布，以增加标的物展示途径，匹配更多优质买家。后期，讯拍网平台将持续对合作伙伴技术赋能</p>
                        </div>
                        <div className="cooperation_list_img">
                            <img src={us2} alt="" />
                        </div>
                    </div>
                    <div className="cooperation_list">
                        <div className="cooperation_list_img">
                            <img src={us5} alt="" />
                        </div>
                        <div className="cooperation_list_content">
                            <h3>精准投放，增值营销</h3>
                            <p>平台可根据标的物市场价值潜力，进行精细化营销。依托腾讯全平台大数据挖掘，借助腾讯社交媒体交互性，利用腾讯网、腾讯新闻APP、天天快报、企鹅号等媒体资源，根据用户地区、年龄、收入、兴趣度等多重标签进行用户画像，以实现标的广告精准投放，让买家和卖家双方利益最大化</p>
                        </div>

                    </div>
                    <div className="cooperation_list">
                        <div className="cooperation_list_content">
                            <h3>大数据匹配，智能获客</h3>
                            <p>讯拍网平台根据行业资产信息、标的搜索关键词、兴趣买家匹配度、拍卖成交价等信息沉淀构建基础数据库（即意向买家数据库，该数据库将与合作伙伴共建共享），通过腾讯云计算和大数据分析实现标的和买家双向智能匹配，以增加标的物曝光量和成交率，同时也利于提高意向买家对平台的留存度，从而形成“标的+买家+平台”的正向交易闭环</p>
                        </div>
                        <div className="cooperation_list_img">
                            <img src={us3} alt="" />
                        </div>
                    </div>
                    <div className="cooperation_list">
                        <div className="cooperation_list_img">
                            <img src={us1} alt="" />
                        </div>
                        <div className="cooperation_list_content">
                            <h3>全景看样，直播拍卖</h3>
                            <p>平台将对精品标的进行全媒体多渠道展示，除了上述图片、文字方式外，还增加视频直播的途径。用户可通过腾讯视频、抖音视频对标的物进行直播看样、VR全景看样、直播拍卖。而对于线下拍卖会，平台也可利用在线直播的方式，通过转发分享、弹幕吐槽、有奖竞猜、竞拍抽奖等互动方式增加拍卖直播线上互动的趣味性和体验感</p>
                        </div>

                    </div>
                    <div className="cooperation_list">
                        <div className="cooperation_list_content">
                            <h3>全网上拍，全民参与</h3>
                            <p>讯拍平台通过互联网技术将标的上拍任务（实地勘验、尽职调查、预约看样、过户交付等等）与相关人员进行智能匹配，根据标的物地图坐标按照就近原则将任务分派给指定拍卖师、房产经纪人、专职律师等</p>
                            <p>同时，上述所有任务结果（调查报告、标的图片、上拍公告、询价报告等等）都将在讯拍平台与各方合作平台进行同步展示，形成任务分配、结果反馈、同步上拍的标准化工作模式，从而实现真正意义上标的全网络展示、竞拍全民众参与、成交全线上涨的良性业态循环</p>
                        </div>
                        <div className="cooperation_list_img">
                            <img src={us4} alt="" />
                        </div>
                    </div>
                    <div className="cooperation_list">
                        <div className="cooperation_list_img">
                            <img src={us6} alt="" />
                        </div>
                        <div className="cooperation_list_content">
                            <h3>同步竞拍，在线确认</h3>
                            <p>针对同一标的物，意向买家在讯拍网和其他网络拍卖平台均可进行登录报名、设置提醒、缴纳保证金、出价竞拍、在线确认等操作，多家平台交易数据实时同步。同时，平台将引入人脸识别、电子签章、一键送达等技术，买受人可在线上完成拍卖成交确认书、裁定书的签名确认环节，真正实现为客户提供“一站式”便捷化拍卖交易服务</p>
                        </div>

                    </div>
                    <div className="cooperation_list">
                        <div className="cooperation_list_content">
                            <h3>微信支付，安全便捷</h3>
                            <p>对于拍卖交易最重要的资金交付问题，平台通过微信支付进行保证金缴纳和尾款支付。另外，平台为拍卖资产匹配按揭贷款、抵押贷款等金融服务，用户可在线申请金融贷款服务，根据需要选择不同的贷款方案，同时为确保拍卖交易的安全性，平台对接保险机构，对交易行为提供履约保证保险</p>
                        </div>
                        <div className="cooperation_list_img">
                            <img src={us7} alt="" />
                        </div>
                    </div>
                    <div className="cooperation_list">
                        <div className="cooperation_list_content">
                            <h3>招商合作联系</h3>
                            <p>
                                <em></em>总机号码：400-000-1102</p>
                            <p>
                                <em className="wx"></em>
                                微信：w13348879335
                            </p>
                        </div>
                        <div className="cooperation_list_img">
                            <img src={us_code} style={{ width: "260px", height: "280px" }} alt="" />
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Cooperation;