import React, { useState, useEffect, useRef } from "react";
import Header from "./../header";
import Footer from "./../footer";
import { Spin } from 'antd';
import { Link, useLocation } from "react-router-dom";
import { HeaderType } from "./../storesMobx";
import Paging from "./../component/pagination";
import axios from "./../api";
import "./index.scss";
const Discount: React.FC = () => {
    const type = useLocation().search;
    const { city, cityId }: any = HeaderType();
    const [discount_list, set_discount_list] = useState([]);
    const [spinning, set_spinning] = useState(false);
    const [total, set_total] = useState(0);
    const [post, set_post] = useState({
        page_num: 1,
        page_size: 12
    })
    // 获取折扣房源列表
    const getList = async () => {
        const banner: any = await axios.http.get(`${axios.api.bannerList}`);
        if (banner.status === 0) {
            set_discount_list(banner.data);
        }
        // const { page_num, page_size } = post;
        // const put_channel = type.split("=")[1];
        // const res: any = await axios.http.newGet(axios.api.value_add_data_list, {
        //     page_num,
        //     page_size,
        //     put_position: 2,
        //     put_channel,
        //     city_id: cityId
        // });
        // if (res.status === 0) {
        //     const data = res.result.data;
        //     set_discount_list(data);
        //     set_total(res.result.count)
        // }
    }
    useEffect(() => {
        getList();
    }, [post])
    return (
        <div>
            <Header />
            <div className="session">
                <Spin tip="加载中..." spinning={spinning}>
                    <div className="discount_box">
                        {discount_list.length &&
                            discount_list.map((item: any) => {
                                if (item.main_pc) {
                                    if (item.imgs_pc.length) {
                                        return (
                                            <div className="dis_box">
                                                <Link to={`/banner_link?${item.index}`} target="_blank">
                                                    <img
                                                        src={`https://file.qqpai.com${item.main_pc}`}
                                                        alt=""
                                                    />
                                                </Link>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div className="dis_box">
                                                <img
                                                    src={`https://file.qqpai.com${item.main_pc}`}
                                                    alt=""
                                                />
                                            </div>
                                        );
                                    }
                                }
                            })
                        }
                    </div>
                </Spin>
                <div className="more_box">
                    <Paging
                        pageSize={post.page_size}
                        current={post.page_num}
                        pageSizeOptions={['20', '32', '56']}
                        total={total}
                        onShowSizeChange={(size: number) => {
                            set_post({ ...post, page_size: size })
                        }}
                        onChange={(page: number) => {
                            set_post({ ...post, page_num: page })
                        }}
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Discount;