import { lazy } from 'react';
const configRouter = [
    {
        path: "/",
        name: "首页",
        exact: true,
        component: lazy(() => import('./../home'))
    },
    {
        path: "/asset_auction",
        name: "资产首页",
        exact: true,
        component: lazy(() => import('./../assetAuction/home'))
    },
    {
        path: "/bankruptcy_auction",
        name: "破产首页",
        exact: true,
        component: lazy(() => import('./../bankruptcyAuction/home'))
    },
    {
        path: "/matter_list",
        name: "列表",
        component: lazy(() => import('./../matterList'))
    },
    {
        path: "/matter_details",
        name: "标的物详情",
        component: lazy(() => import('./../matterDetails'))
    },
    {
        path: "/bankruptcy_matter_list",
        name: "列表",
        component: lazy(() => import('./../matterList/bankruptcyIndex'))
    },
    {
        path: "/auxiliary_list",
        name: "辅助机构列表",
        component: lazy(() => import('./../matterList/auxiliaryList'))
    },
    {
        path: "/bankruptcy_matter_details",
        name: "标的物详情",
        component: lazy(() => import('./../matterDetails/pc_index'))
    },
    {
        path: "/asset_matter_details",
        name: "标的物详情",
        component: lazy(() => import('./../matterDetails/zc_index'))
    },
    {
        path: "/news",
        name: "新闻",
        component: lazy(() => import('./../news'))
    },
    {
        path: "/news_details",
        name: "新闻详情",
        component: lazy(() => import('./../news/newsDetails'))
    },
    {
        path: "/bankruptcy_news",
        name: "新闻",
        component: lazy(() => import('./../news'))
    },
    {
        path: "/bankruptcy_news_details",
        name: "新闻详情",
        component: lazy(() => import('./../news/newsDetails'))
    },
    {
        path: "/assets_news",
        name: "新闻",
        component: lazy(() => import('./../news'))
    },
    {
        path: "/assets_news_details",
        name: "新闻详情",
        component: lazy(() => import('./../news/newsDetails'))
    },
    {
        path: "/announcement",
        name: "公告详情",
        component: lazy(() => import('./../announcement'))
    },
    {
        path: "/assets_announcement_details",
        name: "公告详情",
        component: lazy(() => import('./../announcement'))
    },
    {
        path: "/court_list",
        name: "法院标的物列表",
        component: lazy(() => import('./../courtList'))
    },
    {
        path: "/court_announcement",
        name: "法院公告",
        component: lazy(() => import('./../courtList/announcement'))
    },
    {
        path: "/all_court_list",
        name: "法院列表",
        component: lazy(() => import('./../courtList/courtList'))
    },
    {
        path: "/announcement_list",
        name: "公告列表",
        component: lazy(() => import('./../announcement/list'))
    },
    {
        path: "/help",
        name: "帮助中心",
        component: lazy(() => import('./../help'))
    },
    {
        path: "/search_list",
        name: "搜索列表",
        component: lazy(() => import('./../matterList/searchList'))
    },
    {
        path: "/guest",
        name: "精准获客",
        component: lazy(() => import('./../guest'))
    },
    {
        path: "/asset_auction_guest",
        name: "精准获客",
        component: lazy(() => import('./../guest'))
    },
    {
        path: "/bankruptcy_guest",
        name: "精准获客",
        component: lazy(() => import('./../guest'))
    },
    {
        path: "/service_in",
        name: "司法辅助入驻资料填写",
        component: lazy(() => import('./../serviceIn'))
    },
    {
        path: "/instructions",
        name: "服务商说明",
        component: lazy(() => import('./../serviceIn/instructions'))
    },
    {
        path: "/auxiliary",
        name: "司法辅助说明",
        component: lazy(() => import('./../serviceIn/auxiliary'))
    },
    {
        path: "/court_in",
        name: "法院入驻资料填写",
        component: lazy(() => import('./../serviceIn/courtIn'))
    },
    {
        path: "/assets_promote",
        name: "资产推介",
        component: lazy(() => import('./../assetAuction/assetsPromote'))
    },
    {
        path: "/assets_details",
        name: "资产推介详情",
        component: lazy(() => import('./../assetAuction/assetsPromote/details'))
    },
    {
        path: "/asset_in",
        name: "资产拍卖入驻流程",
        component: lazy(() => import('./../assetAuction/assetIn'))
    },
    {
        path: "/assets_bank_in",
        name: "银行入驻",
        component: lazy(() => import('./../assetAuction/assetIn/bank'))
    },
    {
        path: "/assets_cooperation",
        name: "平台合作机构",
        component: lazy(() => import('./../assetAuction/cooperationAgency'))
    },
    {
        path: "/assets_cooperation_type",
        name: "平台合作机构",
        component: lazy(() => import('./../assetAuction/cooperationAgency/indexType'))
    },
    {
        path: "/assets_court_list",
        name: "合作机构详情",
        component: lazy(() => import('./../assetAuction/cooperationAgency/list'))
    },
    {
        path: "/assets_matter_list",
        name: "标的物列表",
        component: lazy(() => import('./../assetAuction/matterList'))
    },
    {
        path: "/assets_announcement",
        name: "机构公告列表",
        component: lazy(() => import('./../assetAuction/cooperationAgency/announcement'))
    },
    {
        path: "/assets_announcement_list",
        name: "资产公告列表",
        component: lazy(() => import('./../announcement/assetList'))
    },
    {
        path: "/assets_announcement_type",
        name: "资产分类",
        component: lazy(() => import('./../assetAuction/assetType'))
    },
    {
        path: "/bankruptcy_in",
        name: "破产服务商入驻",
        component: lazy(() => import('./../bankruptcyAuction/processIn'))
    },
    {
        path: "/bankruptcy_announcement",
        name: "破产公告列表",
        component: lazy(() => import('./../announcement/bankruptcyList'))
    },
    {
        path: "/bankruptcy_announcement_details",
        name: "破产公告详情",
        component: lazy(() => import('./../announcement'))
    },
    {
        path: "/bankruptcy_process_user",
        name: "破产管理人入驻流程",
        component: lazy(() => import('./../bankruptcyAuction/processIn/processUser'))
    },
    {
        path: "/bankruptcy_user_data",
        name: "破产管理人入驻资料",
        component: lazy(() => import('./../bankruptcyAuction/processIn/userData'))
    },
    {
        path: "/bankruptcy_server_data",
        name: "破产管理服务商入驻资料",
        component: lazy(() => import('./../bankruptcyAuction/processIn/serverData'))
    },
    {
        path: "/bankruptcy_enterprise_list",
        name: "企业列表",
        component: lazy(() => import('./../bankruptcyAuction/enterprise'))
    },
    {
        path: "/bankruptcy_enterprise_home",
        name: "企业主页",
        component: lazy(() => import('./../bankruptcyAuction/enterprise/enterHome'))
    },
    {
        path: "/map_housing",
        name: "拍卖地图",
        component: lazy(() => import('./../map'))
        // component: lazy(() => import('./../map/map_3d'))
    },
    {
        path: "/banner_link",
        name: "banner详情",
        component: lazy(() => import('./../bannerLink'))
    },
    {
        path: "/union",
        name: "司辅联盟",
        component: lazy(() => import('./../union'))
    },
    {
        path: "/another_place",
        name: "异地委托",
        component: lazy(() => import('./../anotherPlace'))
    },
    {
        path: "/another_detail",
        name: "异地委托详情",
        component: lazy(() => import('./../anotherPlace/anotherDetail'))
    },
    {
        path: "/discount",
        name: "折扣房源列表",
        component: lazy(() => import('./../discount'))
    },
    {
        path: "/personal",
        name: "个人中心",
        component: lazy(() => import('./../personalCenter')),
        children: [
            {
                path: "/personal",
                name: "个人中心",
                exact: true,
                component: lazy(() => import('./../personalCenter/user')),
            },
            {
                path: "/personal/browsing_history",
                name: "浏览记录",
                exact: true,
                component: lazy(() => import('./../personalCenter/browsingHistory'))
            },
            {
                path: "/personal/look_list",
                name: "看样记录",
                exact: true,
                component: lazy(() => import('./../personalCenter/lookList'))
            },
            {
                path: "/personal/consulting",
                name: "咨询记录",
                exact: true,
                component: lazy(() => import('./../personalCenter/consulting'))
            },
            {
                path: "/personal/loan",
                name: "贷款申请记录",
                exact: true,
                component: lazy(() => import('./../personalCenter/loan'))
            },
            // {
            //     path: "/personal/intention",
            //     name: "招商意向",
            //     exact: true,
            //     component: lazy(() => import('./../personalCenter/intention'))
            // }
        ]
    },
    {
        path: "/appointment",
        name: "预约看样",
        component: lazy(() => import('./../appointment'))
    },
    {
        path: "/opinion",
        name: "意见反馈",
        component: lazy(() => import('./../appointment/opinion'))
    },
    {
        path: "/about",
        name: "关于我们",
        component: lazy(() => import('./../aboutUs'))
    },
    {
        path: "/cooperation",
        name: "合作招商",
        component: lazy(() => import('./../aboutUs/cooperation'))
    },
    {
        path: "/liability",
        name: "免责声明",
        component: lazy(() => import('./../aboutUs/liability'))
    },
    {
        path: "/agreement",
        name: "注册协议",
        component: lazy(() => import('./../personalCenter/agreement'))
    },
    {
        path: "/electronic",
        name: "电子封条",
        component: lazy(() => import('./../electronic'))
    },
    {
        path: "/development",
        name: "开发中",
        component: lazy(() => import('./../component/development'))
    },
    {
        path: "/development2",
        name: "开发中",
        component: lazy(() => import('./../component/development'))
    },

    {
        path: "/development3",
        name: "开发中",
        component: lazy(() => import('./../component/development'))
    },
    {
        path: "*",
        name: "404",
        exact: true,
        component: lazy(() => import('./../home'))
    },
























    //
    // {
    //     path:"/PersonalCenter",
    //     name:"个人中心",
    //     component:lazy(()=> import('./../component/personalCenter')),
    //     children:[
    //         {
    //             path:"/PersonalCenter",
    //             name:"个人中心",
    //             exact:true,
    //             component:lazy(()=> import('./../component/personalCenter/user')),
    //         },
    //         {
    //             path:"/PersonalCenter/editPassword",
    //             name:"修改密码",
    //             exact:true,
    //             component:lazy(()=> import('./../component/personalCenter/changePassword')),
    //         }
    //     ]
    // }
]

export default configRouter
