import React, { useState, useEffect } from "react";
import Header from "../header/index";
import Footer from "./../footer/index";
import axios from "./../api";
import "./index.scss";
const Guest = () => {
  const [seal, set_seal] = useState("");
  const getSeal = async () => {
    const result: any = await axios.http.get(axios.api.market);
    set_seal(result.richText);
  };
  useEffect(() => {
    getSeal();
    document.title = "增值营销-讯拍网";
  }, []);
  return (
    <div style={{ margin: "auto" }}>
      <Header />
      <div
          style={{ textAlign: "center", margin: "auto" }}
          dangerouslySetInnerHTML={{ __html: seal }}
        ></div>
      <Footer />
    </div>
  );
};
export default Guest;
