import React, { useEffect } from "react";
import Header from "./../../header";
import Footer from "./../../footer";
import axios from "./../../api";
import "./index.scss";
import { useLocation } from 'react-router-dom';
const BankIn = () => {
    const number = useLocation().search;

    useEffect(() => {
        const dom = document.getElementById("bank_box");
        dom?.scrollIntoView();
    }, [])
    return (
        <div id="bank_box">
            <Header />
            <div className="session">
                {
                    number.slice(1) === "0" &&
                    <div className="in_box">
                        <h6 className="title_h6"><span>银行</span>入驻</h6>
                        <h5> 定义及准入 </h5>
                        <p>
                            为腾云讯拍用户提供线上化的，便捷高效，实惠标准的“一键贷款”金融服务，解决用户融资需求。入驻主体需为银行。用户直接通过平台一键申请贷款，银行2个工作日内完成线上预审，拍卖成交后，用户只需跑一趟银行进行复审和面签，银行将在法院要求的收款期限内发放贷款。
                    </p>
                        <h5> 入驻条件 </h5>
                        <p>
                            1、入驻主体：<br />
                        腾云讯拍平台金融贷款服务合作机构的入驻主体需为银行。以地级市为单位实行限额招商。<br />
                        2、贷款模式：<br />
                        为保障用户综合体验，入驻银行需接入线上贷款保证保险模式。<br />
                        3、额度保障：<br />
                        银行需为腾云讯拍平台用户的贷款业务提供稳定的贷款额度保障，不得因额度问题拒绝或拖延用户贷款申请及发放贷款。<br />
                        4、人员要求：<br />
                        银行需安排一名及以上工作人员按时处理银行信息维护、线上打标、用户咨询、线上贷款审核及状态同步等金融贷款服务相关工作。
                    </p>

                        <h5> 入驻流程 </h5>

                        <div className="process_box_in">
                            <div>邮件申请预审</div>
                            <em></em>
                            <div>邮寄相关文件</div>
                            <em></em>
                            <div>平台复审</div>
                        </div>

                        <p>

                            第一步 邮件申请预审：<br />
                        &emsp;1、发送至3194017532@qq.com<br />
                        &emsp;&emsp;1）邮件标题：“XX省XX市XX 银行金融贷款合作意向”<br />
                        &emsp;&emsp;2）邮件内容：<br />
                        &emsp;&emsp;申请资料模板下载：
                        &emsp;<a target="_blank" href={axios.api.model_file}>下载申请资料模板</a><br />
                        &emsp;&emsp;3）银行贷款入驻申请资料表（请参照申请资料模板），原则上银行入驻与贷款业务开展以支行为主体，服务地域以地级市为单位，不同支行需提交不同资料表<br />
                        &emsp;&emsp;4）银行logo图（请参照申请资料模板）<br />
                        &emsp;&emsp;5）企业营业执照（正本或副本）的扫描件，复印件图片皆可<br />
                        &emsp;&emsp;注意：入驻资料表中的“账号会员名”需提前注册并填写：<br />
                        &emsp;2、银行注册企业账号，企业账号名称与银行名称保持一致<br />
                        &emsp;3、企业账号注册步骤：<br />
                        &emsp;&emsp;1）登录腾云讯拍网站<br />
                        &emsp;&emsp;2）点击左上角“免费注册”<br />
                        &emsp;&emsp;3）点击“切换成企业账户注册”<br />
                        &emsp;&emsp;4）用企业公共邮箱注册账号<br />
                        第二步：预审通过后，邮寄已盖章用印的框架协议、确认函、银行营业执照复印件至腾云讯拍指定地址，要求如下：<br />
                        &emsp;1、框架协议签订的主体为各银行总行与分行管理单位，确认函签订主体为各业务落地分/支行单位<br />
                        &emsp;2、框架协议请加盖骑缝章+落款章，须为银行的公章或合同专用章，不可用部门章<br />
                        &emsp;3、框架协议一式四份，确认函一式一份，银行营业执照一式一份，打印盖章后请邮寄到如下地址：四川省成都市高新区天府三街219号腾讯成都大厦B座306 收（400-000-1102）<br />
                        第三步：平台收到资料后将尽快进行复审，复审通过后，将通知贵司参与线上培训及上线前考试，考试通过，即可开通线上权限开展业务。<br />
                        </p>
                    </div>
                }


                {/* 保险 */}
                {
                    number.slice(1) === "1" &&
                    <div className="in_box">
                        <h6 className="title_h6"><span>保险</span>入驻</h6>
                        <h5> 定义及准入 </h5>
                        <p>
                            基于腾云讯拍业务场景，为拍卖用户提供交易保障险、房产贷款保证保险等保险服务，保障用户权益。入驻主体需为保险公司
                    </p>
                        <h5> 工作职能 </h5>
                        <p>
                            目前平台开放两大类保险服务：交易保障险及房产贷款保证保险。<br />
                        1、交易保障险：在线投保，实时出单，由于保险标的物缺失、毁灭或权利存在瑕疵等原因导致无法过户，保险将及时赔偿被保险人的损失。<br />
                        2、房产贷款保证保险：由保险公司来承保银行放款后到用户抵押登记完成期间，因意外等原因导致用户未完成抵押而引起的银行产权悬空风险。
                    </p>

                        <h5> 入驻条件 </h5>
                        <p>
                            保险服务仅向知名保险公司开放，定向邀约合作，如有合作意向请致电：400-000-1102
                    </p>
                    </div>
                }

                {
                    number.slice(1) === "2" &&
                    <div className="in_box">
                        <h6 className="title_h6"><span>代办机构</span>入驻</h6>
                        <h5> 定义及准入 </h5>
                        <p>
                            拍卖代办服务，包含但不限于为腾云讯拍用户提供拍卖咨询、尽职调查、税费测算、评估鉴定、实地看样、过户办证等服务。
                        </p>
                        <h5> 工作职能 </h5>
                        <p>
                            拍卖咨询：为买家提供竞拍规则指引、拍卖流程解答服务。 <br />
                            尽职调查：为买家提供一系列资产标的情况的调查，包括房产、土地、对外投资、负债、现场经营情况等调查内容。 <br />
                            税费测算：为买家提供公司税务 、标的物税费测算等服务。 <br />
                            评估鉴定：为买家提供项目评估、可行性研究、土地招投标和房地产投资咨询、评估审核等评估服务。 <br />
                            实地看样：接受买家的委托前往标的现场实地看样，以文字、图片、视频等形式提供标的信息供买家参考。 <br />
                            过户办证：协助买家取得拍卖成交法律文书，为买家提供房产、机动车等过户服务。
                        </p>

                        <h5> 入驻条件 </h5>
                        <p>
                            （一）已开设企业店铺，并入驻拍卖会专用资产交易服务类目，提供交易保障及金融贷款服务的机构除外； <br />
                            （二）企业店铺名必须与企业营业执照上的公司名称相符，且需包含企业字号,店铺使用商标需为注册商标； <br />
                            （三）入驻需提供营业执照的清晰照片，机构注册成立不少于6个月且营业有效期限不少于1年，依据相关法律法规正常开展年审或提交企业年报； <br />
                            （四）服务需覆盖 1个地级城市以上（包含1个地级城市），覆盖多个城市可优先入驻；服务覆盖地域必须包含营业执照注册城市，若覆盖多个城市
                            的，不得超出执照注册地所在省份； <br />
                            （五）除交易保障及金融贷款服务机构外，其他服务机构需缴纳不少于10万元保证金，提供交易保障的机构按照入驻的腾云讯拍保险服务类目要求缴
                            纳相应保证金，提供金融贷款服务的机构免交保证金； <br />
                            （六）通过全国企业信用信息公示系统http://www.gsxt.gov.cn/等平台，查询公司的经营范围与业务匹配，无行政处罚、无经营异常及违法等
                            信息。 <br />
                            如有合作意向请致电：400-000-1102
                        </p>
                    </div>
                }
                {
                    number.slice(1) === "3" &&
                    <div className="in_box">
                        <h6 className="title_h6"><span>金融授权机构</span>入驻</h6>
                        <h5> 定义及准入 </h5>
                        <p>
                            金融授权服务机构其职责在于拓展各地有资产处置需求的金融机构入驻腾云讯拍平台，通过腾云讯拍处置资产。<br />
                            机构类型包括：<br />
                            1)取得中国银行保险监督管理委员会等监管机构发放牌照或批文的金融机构，
                            如银行、保险、证券、四大资产管理公司、地方资产管理公司、信托公司、
                            财务公司、金融租赁公司、汽车金融公司、贷款公司、消费金融公司等<br />
                            2)地方金融办/金融工作局批准设立的金融机构，如小贷公司等
                            </p>


                        <h5> 入驻条件 </h5>
                        <p>
                            有金融机构委托该机构处置项目本金5亿的机构（提供盖章证明材料）或具备5家新金融机构推荐入驻盖章确认。<br />
                            申请材料：<br />
                            1)提供上述邀约条件中相应的盖章证明材料（如金融机构项目委托合作协议或新金融机构推荐入驻盖章确认等）<br />
                            2)公司证照（企业法人营业执照；企业法定代表人身份证复印件、公司简介）
                        </p>


                        <h5> 申请流程 </h5>
                        <p>
                            1、申请资料电子版发邮件至：3194017532@qq.com，平台客服在7个工作日内审核材料并答复。<br />
                            2、审核通过的申请机构，办理开户和保证金锁定操作，约定考核期考核标准；（协议保证金十万元）<br />
                            3、开户和保证金锁定工作办理完毕后，协议盖章。
                        </p>
                    </div>
                }
                {
                    number.slice(1) === "4" &&
                    <div className="in_box">
                        <h6 className="title_h6"><span>非金融授权机构</span>入驻</h6>
                        <h5> 定义及准入 </h5>
                        <p>
                            授权服务机构其职责在于拓展各地有资产处置需求的机构入驻腾云讯拍平台，通过腾云讯拍处置资产，机构类型包括政府、国企、商业等，是腾云讯拍在各地的风控中心、服务中心、业务中心。
                        </p>

                        <h5> 入驻条件 </h5>
                        <p>
                            1、机构资源邮件给客服备案，腾云讯拍平台审核后通知机构安排拜访考察，平台判断是否邀约合作。<br />
                            2、备案机构入驻平台成交达到1000万交易规模，邀约合作。
                        </p>


                        <h5> 申请流程 </h5>
                        <p>
                            一、申请资料<br />
                            &emsp;1、授权服务机构资格申请表<br />
                            &emsp;2、公司证照（企业法人营业执照、企业法定代表人身份证复印件、公司简介）；<br />
                            二、签署流程<br />
                            &emsp;1、申请资料电子版发邮件至3194017532@qq.com，平台客服审核资料。<br />
                            &emsp;2、审核通过的申请机构，办理开户和保证金锁定操作。<br />
                            &emsp;3、开户和保证金锁定工作办理完毕后，协议盖章。
                        </p>
                    </div>
                }


            </div>
            <Footer />
        </div>
    )
}

export default BankIn;