import React, { useEffect, useState } from "react";
import Heard from "./../header";
import Footer from "./../footer";
import axios from "./../api";
import { Spin } from "antd";
import "./index.scss";
import { useLocation } from 'react-router-dom';

let urlId = '';
const Help = () => {
    const helpType = useLocation().search;
    urlId = helpType;
    const [help, set_help] = useState([]);
    const [right_html, set_right_html] = useState('');
    const [help_active, set_help_active] = useState('');
    const [help_title, set_help_title] = useState('');
    const [spinning, set_spinning] = useState(true);
    const getHelp = async () => {
        let url = axios.api.help_content;
        if (helpType.indexOf("?2") > -1) {
            url = axios.api.pc_help_content
        }
        if (helpType.indexOf("?3") > -1) {
            url = axios.api.zc_help_content
        }
        const result: any = await axios.http.get(url, {});
        if (result.status === 0) {
            const id = helpType.split("=")[1];
            if (id) {
                result.result && result.result.map((item: any) => {
                    item.data.map((data: any) => {
                        if (data.id == id) {
                            set_right_html(data.content);
                            set_help_title(data.title);
                            set_help_active(data.id)
                        }
                    })
                })
            } else {
                set_right_html(result.result[0].data[0].content);
                set_help_title(result.result[0].data[0].title);
                set_help_active(result.result[0].data[0].id)
            }
            set_help(result.result);
        }
        set_spinning(false)
    }
    useEffect(() => {
        getHelp();
    }, [urlId])
    return (
        <div>
            <Heard />
            <div className="session">
                <Spin tip="数据加载中..." spinning={spinning}>
                    <div className="help_box">
                        <div className="help_left">
                            {
                                help && help.map((item: any, index: number) => {
                                    return (
                                        <div className="help_h2" key={index}>
                                            <h2>{item.cotegory}</h2>
                                            <div>
                                                {
                                                    item.data && item.data.map((title: any) => {
                                                        return (
                                                            <p key={title.id} className={help_active == title.id ? "active" : ''} onClick={() => { set_right_html(title.content); set_help_active(title.id); set_help_title(title.title); document.body.scrollIntoView(); }}>
                                                                {title.title}
                                                            </p>
                                                        )
                                                    })
                                                }
                                            </div>

                                        </div>

                                    )
                                })
                            }
                        </div>
                        <div className="help_right">
                            <h6>{help_title}</h6>
                            <div dangerouslySetInnerHTML={{ __html: right_html }}>

                            </div>
                        </div>
                    </div>
                </Spin>

            </div>
            <Footer />
        </div>
    )
}

export default Help;